import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Filter,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Toolbar, Edit } from "@syncfusion/ej2-react-grids";
import { useSelector, useDispatch } from "react-redux";
import { Browser, extend } from "@syncfusion/ej2-base";
import * as ProcessActions from "../../redux/actions/process.actions";
import DialogFormTemplate from "../dialog/DialogFormTemplate";
import DialogFormItem from "../dialog/DialogFormItem";
import DialogTemplates from "../dialog/DialogTemplates";
import DialogFlowVarsTemplate from "../dialog/DialogFlowVarsTemplate";
import DialogVersionsTemplate from "../dialog/DialogVersionsTemplate";
import DialogInitFlowTemplate from "../dialog/DialogInitFlowTemplate";
import DialogSetActorToUserTemplate from "../dialog/DialogSetActorToUserTemplate";
import DialogReports from "../dialog/DialogReports";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
//import DialogSetUsersToActorTemplate from "../dialog/DialogSetUsersToActorTemplate";
import EndPoint from "../../api/Endpoints";
import ApiClient from "../../api/ApiClient";
var gridInstance;
var dialogInstance;
var dialogInstanceForm;

var position = { X: "Right", Y: "Bottom" };
const animationSettings = { effect: "SlideRight" };
const animationSettings2 = { effect: "SlideLeft" };
const animationSettings1 = { effect: "Fade" };

const StyledTable = (props) => {
  let { componentName } = useParams();

  const { processSuccess, componentConfig, requesting } = useSelector(
    (state) => state.Process
  );

  const { usertoken } = useSelector((state) => state.Login);

  const [formDialog, setFormDialog] = React.useState(false);

  const [formDialogABM, setformDialogABM] = React.useState(false);

  const [reportDialog, setReportDialog] = React.useState(false);
  const [flowVariableDialog, setFlowVariableDialog] = React.useState(false);
  const [versionsDialog, setVersionsDialog] = React.useState(false);
  const [initFlowDialog, setInitFlowDialog] = React.useState(false);
  const [formTemplateDialog, setTemplateFormDialog] = React.useState(false);
  const [actorUserDialog, setActorUserDialog] = React.useState(false);

  const [itemType, setItemType] = React.useState(false);

  const [ADmanagediagram, setADmanagediagram] = React.useState(false);
  const [ADmanageOrgChart, setADmanageOrgChart] = React.useState(false);
  const [ADvariables, setADvariables] = React.useState(false);
  const [filters, setFilters] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState(false);
  const [reportInfo, setReportInfo] = React.useState({});

  const [deployApi, setDeployApi] = React.useState("");
  const [deldeployApi, setDeldeployApi] = React.useState("");

  const [formReport, setFormReport] = React.useState(false);

  const [ADdefineversion, setADdefineversion] = React.useState(false);
  const [ADinitflow, setADinitflow] = React.useState(false);
  const [ADactorUser, setADactorUser] = React.useState(false);
  const [action, setAction] = React.useState("add");

  const [privateTemplates, setPrivateTemplates] = React.useState([]);

  const [id, setId] = React.useState(0);
  const [code, setCode] = React.useState("");
  const [header, setHeader] = React.useState("");

  const { defConfig, defLang } = useSelector((state) => state.Login);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const flowVariableDialogClose = () => {
    setFlowVariableDialog(false);
  };
  const flowVariableDialogOpen = () => {
    setFlowVariableDialog(true);
  };

  const reportDialogClose = () => {
    setReportDialog(false);
  };
  const reportDialogOpen = () => {
    setReportDialog(true);
  };

  const formDialogClose = () => {
    setFormDialog(false);
  };
  const formDialogOpen = () => {
    setFormDialog(true);
  };

  const formTemplateDialogOpen = () => {
    setTemplateFormDialog(true);
  };
  const formTemplateDialogClose = () => {
    setTemplateFormDialog(false);
  };

  const [toolbarOptions, setToolbarOptions] = React.useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roles, setRoles] = React.useState([]);
  const [rolesLoaded, setRolesLoaded] = React.useState(false);

  const getRoles = async (event) => {
    let response = await ApiClient.doPost(EndPoint.getRoles);
    let _roles = [];
    if (response.status) {
      _roles = response.result;
    }
    setRolesLoaded(true);
    setRoles(_roles);
  };

  const actionComplete = (args) => {
    ///console.log("actionComplete", args.requestType)
    if (args) {
      if (!args.requestType) {
        //	console.log("close")
        //dialogInstance.hide()
      } else {
        if (args.requestType !== "refresh") {
          //	dialogInstance = args.dialog
          //	dialogInstance.position ={  X: 'right', Y: 'top' }
          //	dialogInstance.height = window.innerHeight ;
          //	dialogInstance.allowDragging = false
        }

        if (args.requestType == "beginEdit") {
          if (args.dialog) {
            dialogInstance = args.dialog;
            dialogInstance.hide();
            setHeader(
              (defConfig[defLang][componentConfig.sigleComponentName] ||
                componentConfig.sigleComponentName) +
                " - " +
                defConfig[defLang].updatelabel
            );
            let id = args.rowData[args.primaryKey[0]];

            setId(id);
            dispatch(
              ProcessActions.getFormData({
                code: componentName,
                domainid: 6,
                defLang: defLang,
                id: id,
				componentConfig:componentConfig
              })
            );
            setFormDialog(true);
            setAction("edit");
          }
        } else if (args.requestType === "delete") {
          let keys = Object.keys(args.data[0]);
          let id = args.data[0][keys[0]];
		  console.log(componentConfig)
		  if(componentConfig.customcomponent){
			dispatch(
				ProcessActions.processData("customcomponents", "delete", { id: id, componentConfig:componentConfig })
			  );
		  }else{
			dispatch(
				ProcessActions.processData(componentName, "delete", { id: id })
			  );
		  }
          
        }
      }
    }
  };

  const editSettings = {
    showDeleteConfirmDialog: true,
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Dialog" /*template: dialogTemplate,  footerTemplate:dialogFooterTemplate,headerTemplate:dialogHeaderTemplate*/,
  };

  const onUploadSuccess = (args) => {
    let file1 = args.file;
    let file = file1.rawFile;
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = loadDiagram;
  };

  const setTemplate = (formData) => {
    //console.log(formData)
    if (componentConfig.componentName == "flows") {
      //console.log("navigate")
      navigate("/manageDiagram/0?template=" + formData.ID);
    } else {
      dispatch(
        ProcessActions.refreshFormData({
          code: componentName,
          domainid: 6,
          defLang: defLang,
          id: 0,
          importData: formData,
        })
      );
      setHeader(
        (defConfig[defLang][componentConfig.sigleComponentName] ||
          componentConfig.sigleComponentName) +
          " - " +
          defConfig[defLang].createlabel
      );
      setFormDialog(true);
      setId(0);
      formTemplateDialogClose();
    }
  };

  //Load the diagraming object.
  const loadDiagram = (event) => {
    let formData = JSON.parse(event.target.result);
    dispatch(
      ProcessActions.refreshFormData({
        code: componentName,
        domainid: 6,
        defLang: defLang,
        id: 0,
        importData: formData,
      })
    );
    setHeader(
      (defConfig[defLang][componentConfig.sigleComponentName] ||
        componentConfig.sigleComponentName) +
        " - " +
        defConfig[defLang].createlabel
    );
    setFormDialog(true);
    setId(0);

    //console.log (formData)
  };

  React.useEffect(() => {
    let _toolbarOptions = [];
    let componentOptions = componentConfig.actions.map(function (a) {
      return a.name;
    });
    setToolbarOptions([]);

    if (componentOptions.indexOf("adddashboard") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["createlabel"],
        tooltipText: defConfig[defLang]["createlabel"],
        prefixIcon: "e-plus",
        id: "adddashboard",
      });
    }
    if (componentOptions.indexOf("editdashboard") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["editlabel"],
        tooltipText: defConfig[defLang]["editlabel"],
        prefixIcon: "e-edit",
        id: "editdashboard",
      });
    }

    if (componentOptions.indexOf("addform") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["createlabel"],
        tooltipText: defConfig[defLang]["createlabel"],
        prefixIcon: "e-plus",
        id: "addform",
      });
    }
    if (componentOptions.indexOf("editform") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["editlabel"],
        tooltipText: defConfig[defLang]["editlabel"],
        prefixIcon: "e-edit",
        id: "editform",
      });
    }

    if (componentOptions.indexOf("addconnector") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["createlabel"],
        tooltipText: defConfig[defLang]["createlabel"],
        prefixIcon: "e-plus",
        id: "addconnector",
      });
    }
    if (componentOptions.indexOf("editconnector") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["editlabel"],
        tooltipText: defConfig[defLang]["editlabel"],
        prefixIcon: "e-edit",
        id: "editconnector",
      });
    }

    if (
      componentOptions.indexOf("create") >= 0 ||
      componentOptions.indexOf("update") >= 0
    ) {
      setformDialogABM(true);
    } else {
      setformDialogABM(false);
    }

    if (componentOptions.indexOf("create") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["createlabel"],
        tooltipText: defConfig[defLang]["createlabel"],
        prefixIcon: "e-plus",
        id: "add",
      });
    }

    if (componentOptions.indexOf("update") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["editlabel"],
        tooltipText: defConfig[defLang]["editlabel"],
        prefixIcon: "e-edit",
        id: "edit",
      });
    }

    if (componentOptions.indexOf("delete") >= 0) {
      //_toolbarOptions.push({ text: 'Delete', tooltipText: 'Delete', prefixIcon: 'e-trash', id: 'delete' })
      _toolbarOptions.push("Delete");
    }

    if (componentOptions.indexOf("manageorgchart") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["manageorgchartlabel"],
        tooltipText: "Organigram Chart",
        prefixIcon: "e-group-1",
        id: "manageorgchart",
      });
      setADmanageOrgChart(true);
    } else {
      setADmanageOrgChart(false);
    }
    if (componentOptions.indexOf("managevariables") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["managevariableslabel"],
        tooltipText: "Variables",
        prefixIcon: "e-list-unordered",
        id: "managevariables",
      });
      setADvariables(true);
      getRoles();
    } else {
      setADvariables(false);
    }
    if (componentOptions.indexOf("managediagram") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["managediagramlabel"],
        tooltipText: "Diagram",
        prefixIcon: "e-group-1",
        id: "managediagram",
      });
      setADmanagediagram(true);
    } else {
      setADmanagediagram(false);
    }
    if (componentOptions.indexOf("defineversions") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["defineversionslabel"],
        tooltipText: "Define Version",
        prefixIcon: "e-restart-at-1",
        id: "defineversions",
      });
      setADdefineversion(true);
    } else {
      setADdefineversion(false);
    }
    if (componentOptions.indexOf("initflow") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["initflowlabel"],
        tooltipText: "Init Flow",
        prefixIcon: "e-flip-horizontal",
        id: "initflow",
      });
      setADinitflow(true);
    } else {
      setADinitflow(false);
    }

    if (
      componentOptions.indexOf("addactors") >= 0 ||
      componentOptions.indexOf("addusers") >= 0 ||
      componentOptions.indexOf("addoffices") >= 0 ||
      componentOptions.indexOf("addusersO") >= 0
    ) {
      setADactorUser(true);
      if (componentOptions.indexOf("addactors") >= 0) {
        _toolbarOptions.push({
          text: defConfig[defLang]["addactorslabel"],
          tooltipText: "Add Actors",
          prefixIcon: "e-flip-horizontal",
          id: "addactors",
        });
      }
      if (componentOptions.indexOf("addusers") >= 0) {
        _toolbarOptions.push({
          text: defConfig[defLang]["adduserslabel"],
          tooltipText: "Add Users",
          prefixIcon: "e-flip-horizontal",
          id: "addusers",
        });
      }
      if (componentOptions.indexOf("addoffices") >= 0) {
        _toolbarOptions.push({
          text: defConfig[defLang]["addofficeslabel"],
          tooltipText: "Add Actors",
          prefixIcon: "e-flip-horizontal",
          id: "addoffices",
        });
      }
      if (componentOptions.indexOf("addusersO") >= 0) {
        _toolbarOptions.push({
          text: defConfig[defLang]["addusersOlabel"],
          tooltipText: "Add Users",
          prefixIcon: "e-flip-horizontal",
          id: "addusersO",
        });
      }
    } else {
      setADactorUser(false);
    }

    if (componentOptions.indexOf("import") >= 0) {
      _toolbarOptions.push({
        text: defConfig[defLang]["importlabel"],
        tooltipText: defConfig[defLang]["importlabel"],
        prefixIcon: "e-file",
        id: "Import",
      });
      _toolbarOptions.push({
        text: defConfig[defLang]["templateslabel"],
        tooltipText: defConfig[defLang]["templateslabel"],
        prefixIcon: "e-bring-forward",
        id: "Templates",
      });
    }

    if (componentOptions.indexOf("report") >= 0) {
      let pos = componentConfig.actions.findIndex(
        (item) => item.name === "report"
      );
      let info = componentConfig.actions[pos];
      let label = info.label;
      let conditionfields = info.conditionfields;
      setFilters(conditionfields);
      setReportInfo(info);
      _toolbarOptions.push({
        text: label,
        tooltipText: label,
        prefixIcon: "e-list-unordered",
        id: "report",
      });
      setFormReport(true);
    } else {
      setFormReport(false);
      setFilters({});
    }

    if (componentOptions.indexOf("createdeploy") >= 0) {
      let pos = componentConfig.actions.findIndex(
        (item) => item.name === "createdeploy"
      );
      let info = componentConfig.actions[pos];
      console.log(info);
      setDeployApi(info.apiName);
      let label = info.label;
      _toolbarOptions.push({
        text: label,
        tooltipText: label,
        prefixIcon: "e-list-unordered",
        id: "createdeploy",
      });
    }

    if (componentOptions.indexOf("deletedeploy") >= 0) {
      let pos = componentConfig.actions.findIndex(
        (item) => item.name === "deletedeploy"
      );
      let info = componentConfig.actions[pos];
      console.log(info);
      setDeldeployApi(info.apiName);
      let label = info.label;
      _toolbarOptions.push({
        text: label,
        tooltipText: label,
        prefixIcon: "e-list-unordered",
        id: "deletedeploy",
      });
    }

    _toolbarOptions.push("Search");
    setTimeout(
      async function () {
        setToolbarOptions(_toolbarOptions);

        if (gridInstance) {
          gridInstance.toolbar = _toolbarOptions;
          gridInstance.refresh();
        }
      }.bind(this),
      10
    );

    if (usertoken !== "") {
      getPrivateTemplates();
    }
  }, [props, usertoken]);

  const getPrivateTemplates = async () => {
    let response = await ApiClient.doPost(EndPoint.getPrivateTemplates, {
      type: "tasks",
      componenttypecode: "templates",
    });
    setPrivateTemplates(response.result);
  };

  const clickHandler = async (args) => {
    setAction(args.item.id);

    const selectedrecords = gridInstance.getSelectedRecords();
    setHeader(
      defConfig[defLang][componentConfig.sigleComponentName] ||
        componentConfig.sigleComponentName
    );

    //console.log(" args.item.id ", args.item.id);
    if (args.item.id === "Templates") {
      formTemplateDialogOpen();
    } else if (args.item.id === "Import") {
      document
        .getElementsByClassName("e-file-select-wrap")[0]
        .querySelector("button")
        .click();
    } else if (args.item.id === "add") {
      //dialogInstanceForm.header= ("Add new "+componentName)
      //dialogInstanceForm.show()
      dispatch(
        ProcessActions.refreshFormData({
          code: componentName,
          domainid: 6,
          defLang: defLang,
          id: 0,
        })
      );
      setHeader(
        (defConfig[defLang][componentConfig.sigleComponentName] ||
          componentConfig.sigleComponentName) +
          " - " +
          defConfig[defLang].createlabel
      );
      setFormDialog(true);
      setId(0);
      //dispatch(ProcessActions.refreshFormData())
    } else if (args.item.id === "edit") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        setId(id);
        //console.log("id", id)
        dispatch(
          ProcessActions.getFormData({
            code: componentName,
            domainid: 6,
            defLang: defLang,
            id: id,
			componentConfig:componentConfig
          })
        );
        setFormDialog(true);
        setHeader(
          (defConfig[defLang][componentConfig.sigleComponentName] ||
            componentConfig.sigleComponentName) +
            " - " +
            defConfig[defLang].updatelabel
        );
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "addoffices") {
      setItemType("offices");
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.fetchData("offices", "get-offices-by-userid", {
            id: selectedrecords[0][keys[0]],
          })
        );
        setActorUserDialog(true);
        setId(id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "addform") {
      navigate("/manageForm/0");
    } else if (args.item.id === "editform") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.getFormData({
            code: "forms",
            domainid: 6,
            defLang: defLang,
            id: parseInt(id),
			componentConfig:componentConfig
          })
        );
        navigate("/manageForm/" + id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "addconnector") {
      navigate("/manageConnector/0");
    } else if (args.item.id === "editconnector") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.getFormData({
            code: "connector",
            domainid: 6,
            defLang: defLang,
            id: parseInt(id),
			componentConfig:componentConfig
          })
        );
        navigate("/manageConnector/" + id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "adddashboard") {
      navigate("/manageDashboard/0");
    } else if (args.item.id === "editdashboard") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.getFormData({
            code: "dashboard",
            domainid: 6,
            defLang: defLang,
            id: parseInt(id),
			componentConfig:componentConfig
          })
        );
        navigate("/manageDashboard/" + id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "managediagram") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        navigate("/manageDiagram/" + id);
      } else {
        navigate("/manageDiagram/0");
      }
    } else if (args.item.id === "manageorgchart") {
      navigate("/manageOrgChart");
    } else if (args.item.id === "managevariables") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id =
          selectedrecords[0][keys[0]] |
          dispatch(
            ProcessActions.fetchData("flows", "get-flow-vars", {
              id: selectedrecords[0][keys[0]],
            })
          );
        setFlowVariableDialog(true);
        setId(id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "initflow") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.fetchData("flows", "get-flow-vars", {
            id: selectedrecords[0][keys[0]],
          })
        );
        setInitFlowDialog(true);
        setId(id);
        //console.log(selectedrecords[0].FLOWCODE)
        setCode(selectedrecords[0].FLOWCODE);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "defineversions") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.fetchData("flows", "get-flow-versions", {
            id: selectedrecords[0][keys[0]],
          })
        );
        setVersionsDialog(true);
        setId(id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "addactors") {
      setItemType("actors");
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.fetchData("actors", "get-actors-by-userid", {
            id: selectedrecords[0][keys[0]],
          })
        );
        setActorUserDialog(true);
        setId(id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "addusers") {
      setItemType("users");
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.fetchData("actors", "get-users-by-actorid", {
            id: selectedrecords[0][keys[0]],
          })
        );
        setActorUserDialog(true);
        setId(id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "addusersO") {
      setItemType("usersO");
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        dispatch(
          ProcessActions.fetchData("offices", "get-users-by-officeid", {
            id: selectedrecords[0][keys[0]],
          })
        );
        setActorUserDialog(true);
        setId(id);
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "report") {
      if (selectedrecords.length > 0) {
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        setFilterValue(selectedrecords[0][filters[0]]);
        setId(id);
        setFormReport(false);
        setTimeout(
          async function () {
            setReportDialog(true);
            setFormReport(true);
          }.bind(this),
          10
        );
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "createdeploy") {
      if (selectedrecords.length > 0) {
        console.log("insdeploy");
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        setId(id);
        dialogObj = DialogUtility.confirm({
          title: "Deploy Component",
          content: "Confirm to deployment this component?",
          okButton: {
            click: () => {
              confirmDeploy(id, deployApi);
            },
          },
          cancelButton: {
            click: () => {
              dialogObj.hide();
            },
          },
          position: { X: "center", Y: "center" },
          closeOnEscape: true,
        });
      } else {
        alert("No records selected for selected operation");
      }
    } else if (args.item.id === "deletedeploy") {
      if (selectedrecords.length > 0) {
        console.log("deldeploy");
        let keys = Object.keys(selectedrecords[0]);
        let id = selectedrecords[0][keys[0]];
        setId(id);
        dialogObj = DialogUtility.confirm({
          title: "Delete Deploy",
          content: "Confirm to cancel this deployment?",
          okButton: {
            click: () => {
              confirmDeploy(id, deldeployApi);
            },
          },
          cancelButton: {
            click: () => {
              dialogObj.hide();
            },
          },
          position: { X: "center", Y: "center" },
          closeOnEscape: true,
        });
      } else {
        alert("No records selected for selected operation");
      }
    } else {
      console.log("undefined", args.item.id);
    }
  };

  const confirmDeploy = async (id, deployApi) => {
    console.log("cof", id, deployApi, componentConfig.componentName);
    let values = {
      id,
      componentName: componentConfig.componentName,
    };
    dispatch(
      ProcessActions.processData(
        componentConfig.componentName,
        deployApi,
        values
      )
    );
    dialogObj.hide();
  };

  let dialogObj;
  return (
    <>
      {componentConfig.componentName == "flows" ? (
        <DialogTemplates
          privateTemplates={privateTemplates}
          setTemplate={setTemplate}
          status={formTemplateDialog}
          componentName={"template"}
          animationSettings={animationSettings2}
          templateType={"flow"}
          close={formTemplateDialogClose}
          open={formTemplateDialogOpen}
          hideButton={true}
        />
      ) : (
        <DialogTemplates
          privateTemplates={privateTemplates}
          setTemplate={setTemplate}
          status={formTemplateDialog}
          componentName={"template"}
          animationSettings={animationSettings2}
          templateType={"task"}
          close={formTemplateDialogClose}
          open={formTemplateDialogOpen}
          hideButton={true}
        />
      )}
      {formDialogABM && (
        <DialogFormItem
          header={header}
          id={id}
          status={formDialog}
          action={action}
          componentName={componentConfig.componentName}
          animationSettings={animationSettings1}
          close={formDialogClose}
          open={formDialogOpen}
        />
      )}
      {ADvariables && (
        <DialogFlowVarsTemplate
          roles={roles}
          header={header}
          id={id}
          status={flowVariableDialog}
          animationSettings={animationSettings}
          close={flowVariableDialogClose}
          open={flowVariableDialogOpen}
        />
      )}

      {formReport && (
        <DialogReports
          filters={filters}
          header={header}
          filterValue={filterValue}
          reportInfo={reportInfo}
          id={id}
          status={reportDialog}
          animationSettings={animationSettings}
          close={reportDialogClose}
          open={reportDialogOpen}
        />
      )}

      {ADdefineversion && (
        <DialogVersionsTemplate
          header={header}
          id={id}
          status={versionsDialog}
          animationSettings={animationSettings}
          close={() => {
            setVersionsDialog(false);
          }}
          open={() => {
            setVersionsDialog(true);
          }}
        />
      )}
      {ADinitflow && (
        <>
          <DialogInitFlowTemplate
            header={header}
            id={id}
            code={code}
            status={initFlowDialog}
            animationSettings={animationSettings}
            close={() => {
              setInitFlowDialog(false);
            }}
            open={() => {
              setInitFlowDialog(true);
            }}
          />
        </>
      )}
      {ADactorUser && (
        <DialogSetActorToUserTemplate
          header={header}
          id={id}
          status={actorUserDialog}
          itemType={itemType}
          componentName={componentConfig.componentName}
          animationSettings={animationSettings}
          close={() => {
            setActorUserDialog(false);
          }}
          open={() => {
            setActorUserDialog(true);
          }}
        />
      )}

      <div style={{ display: "none" }}>
        <UploaderComponent
          type="file"
          id="fileupload"
          asyncSettings={{
            saveUrl:
              "https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save",
            removeUrl:
              "https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove",
          }}
          success={onUploadSuccess}
        />
      </div>
      <GridComponent
        cssClass="e-grid-full"
        id="gridTable"
        ref={(grid) => (gridInstance = grid)}
        dataSource={componentConfig.rows}
        allowPaging={true}
        pageSettings={{ pageCount: 10 }}
        allowTextWrap={true}
        toolbar={toolbarOptions}
        actionComplete={actionComplete.bind(this)}
        editSettings={editSettings}
        toolbarClick={clickHandler.bind(this)}
      >
        <ColumnsDirective>
          {componentConfig.columns.map((col, i) => {
            return (
              <ColumnDirective
                isPrimaryKey={i === 0}
                key={"col" + i}
                field={col.id}
                headerText={col.label}
                width={col.minWidth ? col.minWidth : 100}
                textAlign={col.textAlign ? col.textAlign : "Right"}
              ></ColumnDirective>
            );
          })}
        </ColumnsDirective>
        <Inject services={[Filter, Toolbar, Page, Edit]} />
      </GridComponent>
    </>
  );
};

StyledTable.propTypes = {};

export default StyledTable;
