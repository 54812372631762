export default {
  //default labels
  defConfig: {
    "es-es": {
      processSuccMessage: "Ejecutado correctamente!",
      processSuccdelMessage: "Eliminado correctamente!",
      currency: "Bs.",
      joinwaitlist: "Unirse a la lista de Espera",
      mob_joinwaitlist: "lista de Espera",
      homelabel: "Inicio",
      company: "Compañia",
      massiveloadtitle: "Carga masiva",
      uploadfilelabel: "Subir archivo",
      statusloadtitle:"Estado de la carga masiva",
      contact: "Contacto",
      welcomeh1: "Bienvenido",
      welcomeTo: "Bienvenido a ",
      signup: "Registrarse",
      signupmessage: "Sign up to create an account and explore many things",
      agreeTermsRegister: "I agree to the Privacy Policy and Terms of Use",
      signin: "Ingresar",
      signin2: "Ingresar",
      fieldinfo: "Información del campo",
      aditionalcommentary: "Comentarios",
      or: "O",
      formfullname: "Nombres",
      commenthistory: "Historial de comentarios",
      formphonenumber: "Número de Telefono",
      formemail: "Correo electr\u00F3nico",
      acceptlabel: "Aceptar",
      formlogingoogle: "Ingrese con Google",
      formloginfacebook: "Ingrese con Facebook",
      formpassword: "Contraseña",
      formpassword2: "Repita el contraseña",
      gootologin: "Tienes una cuenta?",
      forgotpassword: "Olvido su contraseña?",
      recoverypassword: "Enviar correo",
      createAccount: "Empezar el trial",
      confirmlogout: "Confirma que desea salir?",
      myaccountlabel: "Mi cuenta",
      logoutlabel: "Salir",
      showloadslabel:"Mostrar estado de carga masiva",
      statuslabel :"Estado",
      closelabel:"Cerrar",
      createlabel: "Crear nuevo registro",
      filterlabel: "Filtros",
      actionslabel: "Acciones",
      deleteItemlabel: "Eliminar Registro",
      deletelabel: "Eliminar",
      cancellabel: "Cancelar",
      backlabel: "Atras",
      previewlabel: "Vista Previa",
      refreshlabel: "Refrescar",
      totaltaskslabel: "Total tareas",
      filterlabelsmessage: "Filtro por etiqueta",
      novaluesmessage: "No hay datos",
      deteledisclaimer: "Esta seguro de eliminar este registro?",
      notloggedmessage1: "Want to know more about your property?",
      notloggedmessage2: "Sign-up today to learn more.",
      savedatalabel: "Guardar Datos",
      chooseimagelabel: "Elegir Imagen",
      changeimagelabel: "Cambiar Imagen",
      createfieldslabel: "Crear Campos",
      editfieldslabel: "Administrar Campos",
      createlistlabel: "Crear Lista",
      editlistlabel: "Editar Lista",
      createitemslabel: "Crear Elementos",
      edititemslabel: "Editar Elementos",
      addfieldlabel: "Agregar Campo",
      addcolumnlabel: "Agregar Columna",
      savelabel: "Guardar",
      updatelabel: "Editar registro",
      fieldnamelabel: "Nombre del Campo",
      fieldlabellabel: "Etiqueta del Campo",
      fieldtypelabel: "Tipo de Campo",
      colsizemobilelabel: "Tama\u00F1o Mobile",
      colsizeweblabel: "Tama\u00F1o Web",
      stylelabel: "Estilo",
      validationsabel: "Validaciones",
      fontsizelabel: "Tama\u00F1o del Texto",
      fontcolorlabel: "Color",
      alertmessagelabel: "Mensaje de Alerta",
      fieldrequiredlabel: "Campo Requerido",
      fieldlabel:"Campo",
      justtextlabel: "Solo Texto",
      justnumberlabel: "Solo N\u00FAmero",
      justemaillabel: "Solo email",
      minvaluelabel: "Valor m\u00EDnimo",
      maxvaluelabel: "Valor m\u00E1ximo",
      staticdatalabel: "Datos Estaticos",
      dynamiclabel: "Datos din\u00E1micos",
      maxsizemobilemessage:
        "En la versi\u00F3 movil solo se podra dividir hasta en 2 por fila",
      multimediamanagerlabel: "Gestor Multimedia",
      pathlabel: "Ruta",
      searchinputlabel: "Buscar Archivo",
      searchlabel: "Buscar",
      viewlogstitle: "Ver logs del registro seleccionado",
      previouslabel: "Anterior",
      nextlabel: "Siguiente",
      testscriptlabel: "Probar Script",
      loadformlabel: "Carga Formulario",
      refreshingformlabel: "Refrescando",
      optionslabel: "Opciones",
      urlapilabel: "url de la API",
      methodlabel: "Metodo",
      datatosendlabel: "Datos a enviar",
      tokenlabel: "Token",
      labellabel: "Etiqueta",
      valuelabel: "Valor",
      fieldactionlabel: "Accion",
      confirmlabel: "Confirmar",
      oldpasswordlabel: "Contraseña actual",
      newpasswordlabel: "Nueva Contraseña",
      repeatpasswordlabel: "Repita la Contraseña",
      contact: "Contacto",
      support: "Soporte",
      taken: "Asignadas",
      openexternal: "Abrir en nueva ventana",
      memoryheader: "Memoria (ID Flujo:",
      memorylabel: "Memoria",
      nomemorymessage: "Sin datos de memoria para esta tarea",
      taskformtitle: "Formulario de la tarea",
      nopendings: "Sin " + (process.env.REACT_APP_CUSTOM_TASKSLABEL || "Tareas") + " pendientes",
      take: "Tomar tarea",
      grouptasks: "Agrupar",
      expired: "Expiradas",
      refreshingtasks: "Actualizando pendientes",
      ccomponents: "Componentes",
      changepassword: "Cambiar contraseña",
      messagePasswordVal0: "Las contraseña nueva debe tener al menos 8 dígitos",
      messagePasswordVal1: "Las contraseñas no coinciden",
      messagePasswordVal2: "Las contraseña no se puede repetir",
      changelabel: "Cambiar",
      activeflowslabel: (process.env.REACT_APP_CUSTOM_FLOWSLABEL || "Flujos")+" Activos",

      activetaskslabel: (process.env.REACT_APP_CUSTOM_TASKSLABEL || "Tareas") +" Activas",
      takentaskslabel: (process.env.REACT_APP_CUSTOM_TASKSLABEL || "Tareas") +" Asignadas",
      expiredtaskslabel: (process.env.REACT_APP_CUSTOM_TASKSLABEL || "Tareas") +" Expiradas",
      mytaskslabel: "Mis " + (process.env.REACT_APP_CUSTOM_TASKSLABEL || "Tareas") +" pendientes",

      notaskslabel: "No hay "+ (process.env.REACT_APP_CUSTOM_TASKSLABEL || "Tareas") +" pendientes en este momento",
      pageslabel:  process.env.REACT_APP_CUSTOM_PAGESLABEL || "Páginas",
      developercenterlabel: "Repositorio de Apis",
      myapislabel: "Mis APIs",
      communityapislabel: "APIs públicas",
      administrationlabel: "Administración",
      flowslabel:  (process.env.REACT_APP_CUSTOM_FLOWSMENULABEL || "Flujos"),
      searchflowslabel: "Buscar "+(process.env.REACT_APP_CUSTOM_FLOWSLABEL || "Flujos"),
      formorganization: "Organización",
      ORGANIZATION: "Organización",
      organizations: "Áreas",
      actors: "Actores",
      ORGANIZATIONPROCESS: "Procesos de negocio",
      tasks: + (process.env.REACT_APP_CUSTOM_TASKSLABEL || "Tareas") ,
      flows: (process.env.REACT_APP_CUSTOM_FLOWSLABEL || "Flujos"),
      flowgroups: "Grupos",
      COMPONENTS: "Componentes de negocio",
      forms: "Formularios",
      dashboards: "Reportes y Gráficos",
      templates: "Plantillas",
      jobs: "Trabajos agendados",
      pages:  "Páginas",
      SYSTEM: "Seguridad",
      users: "Usuarios",
      usergroups: "Roles",
      ENABLED: "Habilitado",
      DISABLED: "Deshabilitado",
      ACTIVITY: "Manual",
      AUTOMATIC: "Automática",
      executetasklabel: "Ejecutar tarea",
      executelabel: "Ejecutar",
      executetaskslabel: "Ejecutar "+ (process.env.REACT_APP_CUSTOM_TASKSLABEL || "Tareas") ,
      editlabel: "Editar Registro",
      addcomment: "Agregar Comentario",
      comment: "Comentario",
      view: "Ver",
      manageorgchartlabel: "Organigrama",
      managevariableslabel: "Variables",
      managediagramlabel: "Diagrama",
      defineversionslabel: "Definir Version",
      startdatelabel: "Fecha Inicio",

      initflowlabel: "Iniciar flujo",
      addactorslabel: "Agregar actores",
      adduserslabel: "Agregar usuarios",
      importlabel: "Importar",
      templateslabel: "Plantillas",
      deselectall: "Quitar todo",
      selectall: "Sel. Todo",
      notaskselected: "Ninguna tarea seleccionada",
      ungrouplabel: "Desaagrupar",
      assigned: "asignadas",
      tasktaken: "asignada",
      takelabel: "tomar",
      unassigned: "sin asignar",
      all: "todas",
      release: "Liberar",
      "ALL FLOWS": "Todos los "+(process.env.REACT_APP_CUSTOM_FLOWSLABEL || "Flujos"),
    },

    "en-us": {
      processSuccMessage: "Process executed Succesfully!",
      processSuccdelMessage: "Successfully deleted!",
      currency: "$",
      joinwaitlist: "Join Waitlist",
      mob_joinwaitlist: "Join Waitlist",
      homelabel: "Home",
      company: "Company",
      massiveloadtitle: "Massive load",
      uploadfilelabel: "Upload File",
      statusloadtitle:"Massive load status",
      contact: "Contact",
      welcomeh1: "Welcome",
      welcomeTo: "Welcome to ",
      signup: "Sign Up",
      signupmessage: "Sign up to create an account and explore many things",
      agreeTermsRegister: "I agree to the Privacy Policy and Terms of Use",
      signin: "Sign in",
      signin2: "Sign in",
      fieldinfo: "Field Info",
      aditionalcommentary: "Aditional Commentary",
      or: "OR",
      formfullname: "Full Name",
      commenthistory: "Comment history",
      formphonenumber: "Phone Number",
      formemail: "Email Address",
      acceptlabel: "Accept",
      formlogingoogle: "Sign up with Google",
      formloginfacebook: "Sign up with Facebook",
      formpassword: "Password",
      formpassword2: "Re-type password",
      gootologin: "Do you already have an account?",
      forgotpassword: "Forgot password?",
      recoverypassword: "Send Mail",
      createAccount: "Start free trial",
      confirmlogout: "Confirm to logout?",
      myaccountlabel: "My Account",
      logoutlabel: "Logout",
      showloadslabel:"show massive load status",
      statuslabel :"Status",
      closelabel:"Close",
      createlabel: "Create new item",
      filterlabel: "Filters",
      actionslabel: "Actions",
      deleteItemlabel: "Delete Item",
      deletelabel: "Delete",
      cancellabel: "Cancel",
      backlabel: "Back",
      previewlabel: "Preview",
      refreshlabel: "Refresh",
      totaltaskslabel: "Total Tasks",
      filterlabelsmessage: "Filter labels",
      novaluesmessage: "No labels",
      deteledisclaimer: "Are you sure to delete this item",
      notloggedmessage1: "Want to know more about your property?",
      notloggedmessage2: "Signup today to learn more.",
      savedatalabel: "Save Data",
      chooseimagelabel: "Choose Image",
      changeimagelabel: "Change Image",
      createfieldslabel: "Create Fields",
      editfieldslabel: "Manage Fields",
      createlistlabel: "Create List",
      editlistlabel: "Update List",
      createitemslabel: "Create Items",
      edititemslabel: "Update Items",
      addfieldlabel: "Add Field",
      addcolumnlabel: "Add Column",
      savelabel: "Save",
      updatelabel: "Update item",
      fieldnamelabel: "Field Name",
      fieldlabellabel: "Label Name",
      fieldtypelabel: "Field Type",
      colsizemobilelabel: "Mobile Size",
      colsizeweblabel: "Web Size",
      stylelabel: "Style",
      validationsabel: "Validations",
      fontsizelabel: "Font Size",
      fontcolorlabel: "Color",
      alertmessagelabel: "Alert Message",
      fieldrequiredlabel: "Field Required",
      fieldlabel:"Field",
      justtextlabel: "Just Text",
      justnumberlabel: "Just Number",
      justemaillabel: "Just email",
      minvaluelabel: "Min value",
      maxvaluelabel: "Max value",
      staticdatalabel: "Static Data",
      dynamiclabel: "Dynamic Data",
      maxsizemobilemessage:
        "Mobile Version only accepts max 2 fields per Column",
      multimediamanagerlabel: "Multimedia Manager",
      pathlabel: "Path",
      searchinputlabel: "Search File",
      searchlabel: "Search",
      viewlogstitle: "View logs for selected item",
      previouslabel: "Previous",
      nextlabel: "Next",
      testscriptlabel: "Test Script",
      loadformlabel: "Load Form",
      refreshingformlabel: "Refresing...",
      optionslabel: "Options",
      urlapilabel: "API url",
      methodlabel: "Method",
      datatosendlabel: "Data to send",
      tokenlabel: "Token",
      labellabel: "Label",
      valuelabel: "value",
      fieldactionlabel: "Action",
      confirmlabel: "Confirm",
      oldpasswordlabel: "Old Password",
      newpasswordlabel: "New Password",
      repeatpasswordlabel: "Confirm Password",
      contact: "Contact",
      support: "Support",
      taken: "Taken",
      openexternal: "Open in new window",
      memoryheader: "Memory (Flow id:",
      memorylabel: "Memory",
      nomemorymessage: "No data in memory for this task",
      taskformtitle: "Task Form",
      nopendings: "No pending tasks to display",
      take: "Take task",
      grouptasks: "Group Tasks",
      expired: "Expired",
      refreshingtasks: "Updating Tasks...",
      ccomponents: "Custom Components",
      changepassword: "Change Password",
      messageP1: "The password and confirmation password do not match",
      changelabel: "Change",
      activeflowslabel: "Active Flows",
      activetaskslabel: "Active tasks",
      takentaskslabel: "Assigned Tasks",
      expiredtaskslabel: "Expired Tasks",
      mytaskslabel: "My tasks",
      notaskslabel: "There are no tasks at the moment",
      pageslabel:  process.env.REACT_APP_CUSTOM_PAGESLABEL || "Pages",
      developercenterlabel: "APIs Center",
      myapislabel: "My APIs",
      communityapislabel: "Community APIs",
      administrationlabel: "Administration",
      flowslabel: "Flows",
      searchflowslabel: "Search Flows",
      tasktaken: "taken",
      takelabel: "take",
      formorganization: "Organization",
      ORGANIZATION: "My Organization",
      organizations: "Organizational Chart",
      actors: "Actors",
      ORGANIZATIONPROCESS: "Organization Process",
      tasks: "Tasks",
      flows: "Flows",
      flowgroups: "Flow Groups",
      COMPONENTS: "Business Components",
      forms: "Forms",
      dashboards: "Dashboards & Dashboards",
      templates: "Templates",
      jobs: "Jobs",
      pages: "Pages",
      SYSTEM: "Security",
      users: "Users",
      usergroups: "User Groups",
      ENABLED: "Enabled",
      DISABLED: "Disabled",
      ACTIVITY: "Manual",
      AUTOMATIC: "Automatic",
      executetasklabel: "Execute task",
      executelabel: "Execute",
      executetaskslabel: "Execute tasks",
      editlabel: "Edit item",
      addcomment: "Add Comment",
      comment: "Comment",
      view: "View",
      manageorgchartlabel: "Organigram Chart",
      managevariableslabel: "Variables",
      managediagramlabel: "Diagram",
      defineversionslabel: "Define Version",
      startdatelabel: "Start Date",

      initflowlabel: "Init Flow",
      addactorslabel: "Add Actors",
      adduserslabel: "Add Users",
      importlabel: "Importar",
      templateslabel: "Templates",
      deselectall: "Unselect All",
      selectall: "Select All",
      notaskselected: "No tasks selected",
      ungrouplabel: "Ungroup",
      assigned: "assigned",
      unassigned: "unassigned",
      all: "all",
      release: "Release",
    },
  },
  conpanyInfo: process.env.REACT_APP_COMPANYNAME,
  //reference emal
  RefEmail: "mail@mail.com",

  //home header link items
  MenuLinks: {
    "en-us": [
      { mainLink: "Home", subLink: [], path: "/" } /*
			{ mainLink: "FAQs", subLink: [], path: '/faqs' },
	    { mainLink: "Blog", subLink: [], path: '/blogs' },    */,
    ],
    "es-es": [
      { mainLink: "Inicio", subLink: [], path: "/" } /*
			{ mainLink: "FAQs", subLink: [], path: '/faqs' },
	    { mainLink: "Blog", subLink: [], path: '/blogs' },  */,
    ],
  },

  SideBarLinks: {
    "en-us": [
      { label: "Dashboard", subLink: [], path: "/dashboard" },
      { label: "Project Planner", subLink: [], path: "/project-planer" },
      { label: "Equity", subLink: [], path: "/blogs" },
      { label: "Renovation", subLink: [], path: "/blogs" },
      { label: "Resources", subLink: [], path: "/blogs" },
      { label: "Account Settings", subLink: [], path: "/blogs" },
      { label: "Log-off", subLink: [], path: "/blogs" },
    ],
    "es-es": [
      { label: "Dashboard", subLink: [], path: "/dashboard" },
      { label: "Project Planner", subLink: [], path: "/project-planer" },
      { label: "Equity", subLink: [], path: "/blogs" },
      { label: "Renovation", subLink: [], path: "/blogs" },
      { label: "Resources", subLink: [], path: "/blogs" },
      { label: "Account Settings", subLink: [], path: "/blogs" },
      { label: "Log-off", subLink: [], path: "/blogs" },
    ],
  },

  //home header link items
  footerLinks: {
    "en-us": [
      { mainLink: "Terms Of Service", path: "/home" },
      { mainLink: "Privacy Policy", path: "/faqs" },
    ],
    "es-es": [
      { mainLink: "Terminos y Condiciones", path: "/home" },
      { mainLink: "Politica de Privacidad", path: "/faqs" },
    ],
  },

  //home header steps
  footerSteps: {
    "en-us": [
      '1. The Chloe HELOC is a variable rate product. The annual percentage rate (APR) is based on the Prime Rate plus a margin that doesn\'t change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of 03/21/2022 is 3.50%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account.',
      "2. Some transactions may require in-person signatures, including loans secured by properties located in counties that do not permit recording of e-signatures or that otherwise require an in-person closing.",
    ],
    "es-es": [
      '1. The Chloe HELOC is a variable rate product. The annual percentage rate (APR) is based on the Prime Rate plus a margin that doesn\'t change. The Prime Rate is the highest prime rate published in The Wall Street Journal "Money Rates" table (the "Index"). The Index as of 03/21/2022 is 3.50%. Your initial APR is based on your creditworthiness when you open your account. The maximum APR will not exceed 18% during the life of your account.',
      "2. Some transactions may require in-person signatures, including loans secured by properties located in counties that do not permit recording of e-signatures or that otherwise require an in-person closing.",
    ],
  },

  //home - list of services
  Services: {
    "en-us": [
      {
        icon: "./images/img1.PNG",
        title: "Renovate my home",
        button: "Discover More",
        link: "/doc/bdp_lab.pdf",
        description:
          "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
      },
      {
        icon: "./images/img2.PNG",
        title: "Consolidate my debt",
        link: "/doc/programa_aceleracion.pdf",
        button: "Start Now",
        description:
          "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
      },
      {
        icon: "./images/img3.PNG",
        title: "Valuate my home",
        link: "/doc/asistencia_tecnica.pdf",
        button: "Start Today",
        description:
          "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
      },
    ],
    "es-es": [
      {
        icon: "./images/img1.PNG",
        title: "Renovate my home",
        button: "Discover More",
        link: "/doc/bdp_lab.pdf",
        description:
          "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
      },
      {
        icon: "./images/img2.PNG",
        title: "Consolidate my debt",
        link: "/doc/programa_aceleracion.pdf",
        button: "Start Now",
        description:
          "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
      },
      {
        icon: "./images/img3.PNG",
        title: "Valuate my home",
        link: "/doc/asistencia_tecnica.pdf",
        button: "Start Today",
        description:
          "Metus, diam pretium at at morbi vulputate et. Tellus ipsum sollicitudin ut eu a lectus potenti maecenas ",
      },
    ],
  },
};
