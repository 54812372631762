import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as UserActions from "../../redux/actions/user.actions";
import GenericForm from "../../components/forms/GenericForm";

import "./Login.css";

const Login = (props) => {
  const { defConfig, params, defLang, activeForm, usertoken } = useSelector(
    (state) => state.Login
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  var _loginFormInputs = [
    {
      name: "domain",
      label: defConfig[defLang].formorganization,
      type: "text",
      placeholder: "Enter Organization",
      value: process.env.REACT_APP_DEF_ORG ? process.env.REACT_APP_DEF_ORG : "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "email",
      label: defConfig[defLang].formemail,
      type: "text",
      placeholder: "Enter Email",
      value: process.env.REACT_APP_DEF_USER
        ? process.env.REACT_APP_DEF_USER
        : "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "password",
      label: defConfig[defLang].formpassword,
      type: "password",
      placeholder: "Enter your Password",
      value: process.env.REACT_APP_DEF_PSS ? process.env.REACT_APP_DEF_PSS : "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "forgotPassword",
      label: defConfig[defLang].forgotpassword,
      values: [],
      type: "link",
      value: "/forgot-password",
      style: { textAlign: "center" },
      size: {
        xs: 12,
        lg: 12,
      },
    },
  ];
  const [loginFormInputs, setLoginFormInputs] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [values, setValues] = React.useState({
    domain: process.env.REACT_APP_DEF_ORG ? process.env.REACT_APP_DEF_ORG : "",
    email : process.env.REACT_APP_DEF_USER ? process.env.REACT_APP_DEF_USER : "",
    password : process.env.REACT_APP_DEF_PSS ? process.env.REACT_APP_DEF_PSS : "",    

  });
  
  React.useEffect(() => {
    //console.log("hello from", window.location.hostname);
    if (usertoken) {
      navigate("/dashboard");
    } else {
      let hostName = window.location.hostname.split(".");

      if (hostName.length === 3) {
        if (hostName[0] ==="www"){
          setLoginFormInputs(_loginFormInputs);
        }else{
          let _values = values
          _values["domain"] = hostName[0]
          _loginFormInputs[0]["type"] = "hidden";
          _loginFormInputs[0]["value"] = hostName[0];
          setLoginFormInputs(_loginFormInputs);
        }
       

      } else {
        setLoginFormInputs(_loginFormInputs);
      }
      setTimeout(
        function () {
          setLoading(false);
        }.bind(this),
        50
      );
    }
  }, [usertoken]);

  const onChange = async (event) => {
    let _values = values
    _values[event.target.name] = event.target.value
    setValues(_values)
  };

  const submitForm = (e) => {

    e.preventDefault();    
    dispatch(UserActions.login(values));
  };

  const registerlink = (values) => {
    //console.log("registerlink");
    navigate("/register");
  };

  return (
    <div>
      <div
        className="main-sidebar-content"
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="row">
          <div className="col-xs-12">
            <div className="row image">
              <div className="col-xs-12 mt-50-web mt-30-mobile flex-center">
                <img style={{ width: 218 }} src={process.env.REACT_APP_CUSTOM_LOGO_IMAGE || "/images/logo.png"} />
              </div>
            </div>
            <div className="row form flex-center">
              <div className="col-xs-12 col-lg-6 col-xl-4 col-md-8">
                <form onSubmit={submitForm}>
                {!loading && (
                  <GenericForm
                    data={loginFormInputs}
                    onChange={onChange}
                    buttonposition="center"
                    buttons={[
                      /*
									{label     : defConfig[defLang].createAccount,
										style: {textTransform:"initial" ,   width:"100%" },
										name      : "register",
										action    : registerlink,
										className : "e-secondary",
										size      : "col-xs-12 col-md-6 col-lg-6"
									},
                      {
                        label: defConfig[defLang].signin,
                        style: {
                          textTransform: "initial",
                          width: "100%",
                          height: 250,
                        },
                        name: "login",
                        action: submitForm,
                        className: "e-primary",
                        size: "col-xs-12 col-md-12 col-lg-12 mt-1-mobile",
                      },*/
                    ]}
                  />
                )}
                <div className="form-group">
								  <button className="e-btn e-primary btn-block" type="submit">{defConfig[defLang].signin}</button>
								</div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row copyright">
        <div className="col-xs-12 col-xl-12 col-12 text-center">
          2023 © Copyright {process.env.REACT_APP_COMPANY}
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {};

export default Login;
