import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-react-layouts";
import "./Home.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Actions from "../../redux/actions/user.actions";
import { useSelector, useDispatch } from "react-redux";
import CompTreeGridV2 from "../../components/treegrid/CompTreeGridV2";
import Typography from "../../components/customdesign/Typography";
import { getResumeTasksByUser, prepareGetTasks } from "../../utils/Functions";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import { Link } from "react-router-dom";
let dialogObj;
var windowWidth = window.innerWidth;
const Home = ({ openPanel, setOpenPanel }) => {
  let WFListInstance;
  let MListInstance;

  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const {
    defConfig,
    params,
    defLang,
    activeForm,
    usertoken,
    username,
    menu,
    menuWFL,
    tasks,
  } = useSelector((state) => state.Login);
  const { refreshResumeTasks } = useSelector((state) => state.Process);

  const cellSpacing = [5, 5];

  const [menuFields, setMenuFields] = React.useState({});

  const [dataSourceWFL, setDataSourceWFL] = React.useState([]);
  const [totalAssigned, setTotalAssigned] = React.useState(0);
  const [totalActives, setTotalActives] = React.useState(0);
  const [totalOuts, setTotalOuts] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();

  const refreshData = async (e) => {
    setIsLoading(true);
    let data = {
      flowId: 0,
    };
    const response = await prepareGetTasks(data);
    setTasksheader(response.tasksheader);
    setRows(response.activetasks);
    setVars(response.flowvars);
    const response2 = await getResumeTasksByUser();
    //console.log("response",response)
    //console.log("response2",response2)

    let tasks = response2.result;
    let dataWFL = [];
    let groups = [];
    let flows = [];

    let groupAssingned = 0;
    let groupActives = 0;
    let groupOuts = 0;
    menuWFL.map(function (g) {
      if (groups.indexOf(g.FLOWGROUPID) < 0) {
        let gid = g.FLOWGROUPID;

        let hasChildG = false;
        menuWFL.map((f) => {
          let flowActives = 0;
          let flowAssigned = 0;
          let flowOuts = 0;
          if (f.FLOWGROUP === g.FLOWGROUP) {
            hasChildG = true;
            if (flows.indexOf(f.FLOWID) < 0) {
              let hasChildF = false;
              let fid = f.FLOWID;
              let _tasks = [];
              menuWFL.map((t) => {
                if (f.FLOWID === t.FLOWID) {
                  if (_tasks.indexOf(t.TASKID) < 0) {
                    let taskActives = 0;
                    let taskAssigned = 0;
                    let taskOuts = 0;
                    tasks.map(function (key) {
                      if (key.FLOWID === t.FLOWID && key.TASKID === t.TASKID) {
                        (taskActives = key.TOTAL),
                          (taskAssigned = parseInt(key.TOTAL_ASSIGNED));
                        taskOuts = parseInt(key.TOTAL_OUT);
                      }
                    });
                    hasChildF = true;
                    flowActives = flowActives + taskActives;
                    flowAssigned = flowAssigned + taskAssigned;
                    flowOuts = flowOuts + taskOuts;
                    if (t.oldVersion) {
                      if (taskActives > 0) {
                        let item = {
                          id: "TASK_" + fid + "_" + t.TASKID,
                          taskid: t.TASKID,
                          name: t.TASK.toLowerCase(),
                          isTask: true,
                          hasChild: false,
                          pid: fid,
                          link: "/getTasks/" + t.TASKID + "/" + fid,
                          actives: taskActives,
                          assigned: taskAssigned,
                          out: taskOuts,
                          sort: t.SORTORDER_F + "_" + t.SORTORDER_T,
                        };
                        dataWFL.push(item);
                        _tasks.push(fid + "_T" + t.TASKID);
                      }
                    } else {
                      let item = {
                        id: "TASK_" + fid + "_" + t.TASKID,
                        taskid: t.TASKID,
                        isTask: true,
                        name: t.TASK.toLowerCase(),
                        hasChild: false,
                        pid: fid,
                        link: "/getTasks/" + t.TASKID + "/" + fid,
                        actives: taskActives,
                        assigned: taskAssigned,
                        out: taskOuts,
                        sort: t.SORTORDER_F + "_T" + t.SORTORDER_T,
                      };
                      dataWFL.push(item);
                      _tasks.push(fid + "_" + t.TASKID);
                    }
                  }
                }
              });

              let item = {
                id: f.FLOWID,
                name: f.FLOW.toLowerCase(),
                hasChild: hasChildF,
                pid: gid,
                isFlow: true,
                actives: flowActives,
                assigned: flowAssigned,
                out: flowOuts,
                sort: f.SORTORDER_F + "",
              };
              dataWFL.push(item);
              flows.push(f.FLOWID);
            }
            groupActives = groupActives + flowActives;
            groupAssingned = groupAssingned + flowAssigned;
            groupOuts = groupOuts + flowOuts;
          }
        });

        groups.push(g.FLOWGROUPID);
        let item = {
          id: gid,
          pid: 999999,
          name: g.FLOWGROUP.toLowerCase(),
          hasChild: hasChildG,
          actives: groupActives,
          assinged: groupAssingned,
          out: groupOuts,
          sort: "2", //change when use more flowgroups
        };
        dataWFL.push(item);
      }
    });

    dataWFL.sort(function (a, b) {
      return b.sort > a.sort ? -1 : b.sort < a.sort ? 1 : 0;
    });
    setDataSourceWFL(dataWFL);
    setTotalActives(groupActives);
    setTotalAssigned(groupAssingned);
    setTotalOuts(groupOuts);

    //console.log("dataWFL",dataWFL)

    setIsLoading(false);
  };

  const [tasksheader, setTasksheader] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [vars, setVars] = React.useState([]);

  const initData2 = async () => {
    setIsLoading(true);
    let data = {
      flowId: 0,
    };
    const response = await prepareGetTasks(data);
    setTasksheader(response.tasksheader);
    setRows(response.activetasks);
    setVars(response.flowvars);
    setIsLoading(false);
  };
  const initData = (args) => {
    let dataWFL = [];
    let groups = [];
    let flows = [];
    let groupAssingned = 0;
    let groupActives = 0;
    let groupOuts = 0;
    menuWFL.map(function (g) {
      if (groups.indexOf(g.FLOWGROUPID) < 0) {
        let gid = g.FLOWGROUPID;

        let hasChildG = false;
        menuWFL.map((f) => {
          let flowActives = 0;
          let flowAssigned = 0;
          let flowOuts = 0;
          if (f.FLOWGROUP === g.FLOWGROUP) {
            hasChildG = true;
            if (flows.indexOf(f.FLOWID) < 0) {
              let hasChildF = false;
              let fid = f.FLOWID;
              let _tasks = [];
              menuWFL.map((t) => {
                if (f.FLOWID === t.FLOWID) {
                  if (_tasks.indexOf(t.TASKID) < 0) {
                    let taskActives = 0;
                    let taskAssigned = 0;
                    let taskOuts = 0;
                    tasks.map(function (key) {
                      if (key.FLOWID === t.FLOWID && key.TASKID === t.TASKID) {
                        (taskActives = key.TOTAL),
                          (taskAssigned = parseInt(key.TOTAL_ASSIGNED));
                        taskOuts = parseInt(key.TOTAL_OUT);
                      }
                    });
                    hasChildF = true;
                    flowActives = flowActives + taskActives;
                    flowAssigned = flowAssigned + taskAssigned;
                    flowOuts = flowOuts + taskOuts;
                    if (t.oldVersion) {
                      if (taskActives > 0) {
                        let item = {
                          id: "TASK_" + fid + "_" + t.TASKID,
                          taskid: t.TASKID,
                          name: t.TASK.toLowerCase(),
                          isTask: true,
                          hasChild: false,
                          pid: fid,
                          link: "/getTasks/" + t.TASKID + "/" + fid,
                          actives: taskActives,
                          assigned: taskAssigned,
                          out: taskOuts,
                          sort: t.SORTORDER_F + "_" + t.SORTORDER_T,
                        };
                        dataWFL.push(item);
                        _tasks.push(fid + "_T" + t.TASKID);
                      }
                    } else {
                      let item = {
                        id: "TASK_" + fid + "_" + t.TASKID,
                        taskid: t.TASKID,
                        isTask: true,
                        name: t.TASK.toLowerCase(),
                        hasChild: false,
                        pid: fid,
                        link: "/getTasks/" + t.TASKID + "/" + fid,
                        actives: taskActives,
                        assigned: taskAssigned,
                        out: taskOuts,
                        sort: t.SORTORDER_F + "_T" + t.SORTORDER_T,
                      };
                      dataWFL.push(item);
                      _tasks.push(fid + "_" + t.TASKID);
                    }
                  }
                }
              });

              let item = {
                id: f.FLOWID,
                name: f.FLOW.toLowerCase(),
                hasChild: hasChildF,
                pid: gid,
                isFlow: true,
                actives: flowActives,
                assigned: flowAssigned,
                out: flowOuts,
                sort: f.SORTORDER_F + "",
              };
              dataWFL.push(item);
              flows.push(f.FLOWID);
            }
            groupActives = groupActives + flowActives;
            groupAssingned = groupAssingned + flowAssigned;
            groupOuts = groupOuts + flowOuts;
          }
        });

        groups.push(g.FLOWGROUPID);
        let item = {
          id: gid,
          pid: 999999,
          name: g.FLOWGROUP.toLowerCase(),
          hasChild: hasChildG,
          actives: groupActives,
          assinged: groupAssingned,
          out: groupOuts,
          sort: "2", //change when use more flowgroups
        };
        dataWFL.push(item);
      }
    });

    dataWFL.sort(function (a, b) {
      return b.sort > a.sort ? -1 : b.sort < a.sort ? 1 : 0;
    });
    setDataSourceWFL(dataWFL);
    setTotalActives(groupActives);
    setTotalAssigned(groupAssingned);
    setTotalOuts(groupOuts);
  };

  React.useEffect(() => {
    if (!usertoken) {
      navigate("/login");
      //window.location.reload();
    } else {
      if (tasks.length > 0) {
        initData();
      }
      if (rows.length === 0) {
        initData2();
      }
    }
  }, [usertoken, menu, menuWFL, tasks]);

  var card4 = (args) => {
    return (
      <div className="">
        <Typography
          variant="h3"
          mb="1"
          mt="1"
          pl="1"
          style={{ textTransform: "capitalize" }}
        >
          <span className="e-component-title e-btn-icon e-icons e-group-2 e-icon-left"></span>
          <span className="e-component-title">
            {" "}
            {defConfig[defLang].mytaskslabel}
          </span>
        </Typography>
        {rows.length === 0 ? (
          <Typography variant="h5" mt={3} mb="0" pl="1">
            <span className=""> {defConfig[defLang].notaskslabel} </span>
          </Typography>
        ) : (
          <CompTreeGridV2
            refreshData={refreshData}
            noToolBar={true}
            defConfig={defConfig}
            defLang={defLang}
            showTaskInfo={true}
            taskId={0}
            fixedHeight={windowWidth < 999 ? 560 : 265}
            flowId={0}
            header={tasksheader}
            dataSource={rows}
            vars={vars}
            name={"hometasks"}
            cssClass="template-list"
          />
        )}
      </div>
    );
  };

  const skeleton = (args) => {
    return (
      <div className="control-pane pl-1 pt-1">
        <div>
          <SkeletonComponent
            shape="Circle"
            width="85x"
            style={{
              width: 85,
              height: 85,
              position: "relative",
              top: 45,
              left: 10,
            }}
            shimmerEffect="Pulse"
          ></SkeletonComponent>
          <SkeletonComponent
            shape="Rectangle"
            width="60%"
            style={{
              width: "60%",
              height: 45,
              top: 10,
              float: "right",
              marginRight: 15,
            }}
            height="45px"
            shimmerEffect="Fade"
          ></SkeletonComponent>
          <SkeletonComponent
            shape="Rectangle"
            width="60%"
            style={{
              width: "60%",
              height: 45,
              top: -12,
              float: "right",
              marginRight: 15,
            }}
            height="45px"
            shimmerEffect="Fade"
          ></SkeletonComponent>
          <SkeletonComponent
            shape="Rectangle"
            width="60%"
            style={{
              width: "60%",
              height: 45,
              float: "right",
              marginRight: 15,
            }}
            height="45px"
            shimmerEffect="Fade"
          ></SkeletonComponent>
        </div>
      </div>
    );
  };

  const skeleton2 = (args) => {
    return (
      <div className="control-pane pl-1 pt-1">
        <div>
          <div>
            <SkeletonComponent
              shape="Rectangle"
              width="100%"
              style={{ float: "left", marginBottom: 10 }}
              height="45px"
              shimmerEffect="Fade"
            ></SkeletonComponent>
          </div>
          <div>
            <SkeletonComponent
              shape="Rectangle"
              width="100%"
              style={{ float: "left", marginBottom: 10 }}
              height="45px"
              shimmerEffect="Fade"
            ></SkeletonComponent>
          </div>
          <div>
            <SkeletonComponent
              shape="Rectangle"
              width="100%"
              style={{ float: "left", marginBottom: 10 }}
              height="45px"
              shimmerEffect="Fade"
            ></SkeletonComponent>
          </div>
          <div>
            <SkeletonComponent
              shape="Rectangle"
              width="100%"
              style={{ float: "left", marginBottom: 10 }}
              height="45px"
              shimmerEffect="Fade"
            ></SkeletonComponent>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div>
        <div className="control-section" id="target_dash">
          <div
            className="col-lg-12 col-sm-12 col-md-12"
            id="dashboard_sidebar_section"
          >
            <div id="analytic_target">
              <div
                className="sidebar-content"
                style={{
                  width: windowWidth > 999 ? windowWidth - 340 : "100%",
                }}
              >
                <div className="dashboardParent">
                  <DashboardLayoutComponent
                    allowResizing={true}
                    id="analytic_dashboard"
                    cellAspectRatio={100 / 85}
                    cellSpacing={cellSpacing}
                    columns={6}
                  >
                    <PanelsDirective>
                      <PanelDirective
                        sizeX={2}
                        sizeY={windowWidth > 999 ? 2 : 1}
                        row={0}
                        col={0}
                        content={skeleton}
                      ></PanelDirective>
                      <PanelDirective
                        sizeX={2}
                        sizeY={windowWidth > 999 ? 2 : 1}
                        row={0}
                        col={2}
                        content={skeleton}
                      ></PanelDirective>
                      <PanelDirective
                        sizeX={2}
                        sizeY={windowWidth > 999 ? 2 : 1}
                        row={0}
                        col={4}
                        content={skeleton}
                      ></PanelDirective>

                      <PanelDirective
                        sizeX={12}
                        sizeY={3}
                        row={3}
                        col={0}
                        content={skeleton2}
                      ></PanelDirective>
                    </PanelsDirective>
                  </DashboardLayoutComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="control-section" id="target_dash">
          <div
            className="col-lg-12 col-sm-12 col-md-12"
            id="dashboard_sidebar_section"
          >
            <div id="analytic_target">
              <div
                className="sidebar-content"
                style={{
                  width: windowWidth > 999 ? windowWidth - 340 : "100%",
                }}
              >
                <div className="dashboardParent">
                  <div className="row" id="analytic_dashboard">
                    <div className="col-12 col-md-12 col-lg-4 mb-10 p-025">
                      <div className="card text-success">
                        <span className="e-btn-icon e-icons h3 text-bold bold-bordered-success">
                          {" "}
                          {totalAssigned}
                        </span>
                        <div className="card-content text message-success">
                          {defConfig[defLang].takentaskslabel}
                        </div>

                        {dataSourceWFL.map((key2, index2) => {
                          return (
                            key2.isTask &&
                            key2.assigned > 0 && (
                              <div key={index2} className="card-content item">
                                <button
                                  onClick={() => {
                                    navigate(key2.link);
                                  }}
                                >
                                  {(key2.name.length > 27
                                    ? key2.name.substring(0, 27) + ".."
                                    : key2.name) + ": "}{" "}
                                  <span className="text-bold h5">
                                    {key2.actives}
                                  </span>
                                </button>
                                
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-4 mb-10 p-025">
                      <div className="card text-primary">
                        <span className="e-btn-icon e-icons h3 text-bold bold-bordered">
                          {" "}
                          {totalActives}
                        </span>
                        <div className="card-content text message-primary">
                          {defConfig[defLang].activetaskslabel}
                        </div>

                        {dataSourceWFL.map((key, index) => {
                          return (
                            key.isTask && (
                              <div key={index} className="card-content item ">
                                <button
                                  onClick={() => {
                                    navigate(key.link);
                                  }}
                                >
                                  {(key.name.length > 27
                                    ? key.name.substring(0, 27) + ".."
                                    : key.name) + ": "}{" "}
                                  <span className="text-bold h5">
                                    {key.actives}
                                  </span>
                                </button>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-4 mb-10 p-025">
                      <div className="card text-danger">
                        <span className="e-btn-icon e-icons bold-bordered-danger h3 text-bold">
                          {" "}
                          {totalOuts}
                        </span>
                        <div className="card-content text message-danger">
                          {defConfig[defLang].expiredtaskslabel}
                        </div>

                        {dataSourceWFL.map((key2, index2) => {
                          return (
                            key2.isTask &&
                            key2.out > 0 && (
                              <div key={index2} className="card-content item">
                                <button
                                  onClick={() => {
                                    navigate(key2.link);
                                  }}
                                >
                                  {(key2.name.length > 27
                                    ? key2.name.substring(0, 27) + ".."
                                    : key2.name) + ": "}{" "}
                                  <span className="text-bold h5">
                                    {key2.actives}
                                  </span>
                                </button>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  {/*}
                  <DashboardLayoutComponent
                    allowResizing={true}
                    id="analytic_dashboard"
                    cellAspectRatio={100 / 85}
                    cellSpacing={cellSpacing}
                    columns={6}
                  >
                    <PanelsDirective>
                      <PanelDirective
                        sizeX={2}
                        sizeY={windowWidth > 999 ? 2 : 1}
                        row={0}
                        col={2}
                        content={card2}
                      ></PanelDirective>
                      <PanelDirective
                        sizeX={2}
                        sizeY={windowWidth > 999 ? 2 : 1}
                        row={0}
                        col={4}
                        content={card3}
                      ></PanelDirective>
                      <PanelDirective
                        sizeX={2}
                        sizeY={windowWidth > 999 ? 2 : 1}
                        row={0}
                        col={0}
                        content={card1}
                      ></PanelDirective>
                    </PanelsDirective>
                  </DashboardLayoutComponent>
                  */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-12 col-sm-12 col-md-12"
            id="dashboard_sidebar_section"
          >
            <div className="">
              <Typography
                variant="h3"
                mb="1"
                mt="1"
                pl="1"
                style={{ textTransform: "capitalize" }}
              >
                <span className="e-component-title e-btn-icon e-icons e-group-2 e-icon-left"></span>
                <span className="e-component-title">
                  {" "}
                  {defConfig[defLang].mytaskslabel}
                </span>
              </Typography>
              {rows.length === 0 ? (
                <Typography variant="h5" mt={3} mb="0" pl="1">
                  <span className=""> {defConfig[defLang].notaskslabel} </span>
                </Typography>
              ) : (
                <CompTreeGridV2
                  refreshData={refreshData}
                  noToolBar={true}
                  showTaskInfo={true}
                  defConfig={defConfig}
                  defLang={defLang}
                  taskId={0}
                  fixedHeight={windowWidth < 999 ? 560 : 265}
                  flowId={0}
                  header={tasksheader}
                  dataSource={rows}
                  vars={vars}
                  name={"hometasks"}
                  cssClass="template-list"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

Home.propTypes = {};

export default Home;
