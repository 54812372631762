export function openPage(url) {
  console.log("hola", url);
  window.open(url + "/manageForm/0", "Add Form");
}

export function saveFlowField() {
  console.log("emulating saveFlowField, no action");
  return true;
}
export function executeTask() {
  console.log("emulating executeTask, no action");
  return true;
}

export function getVarByFlow(varname, varvalue, officecode) {
  console.log("emulating getVarByFlow, no action");
  let response = { total: 1, result: [], status: true };
  return response;
}

export function getPendingBrothersFlows() {
  console.log("emulating getPendingBrothersFlows, no action");
  return { status: true, result: [] };
}

export function initFlow() {
  console.log("emulating initFlow, no action");
  return { status: true, result: [] };
}

export function finishFlow() {
  console.log("emulating finishFlow, no action");
  return true;
}

export function getPalette() {
  if (process.env.REACT_APP_DEFCOLOR) {
    if (process.env.REACT_APP_DEFCOLOR === "Default") {
      return {
        primary: "#4c38c9",
        secondary: "#a238c9",
        manualTask: "#c6c0ec",
        autoTask: "#F0EEFF",
        traTask: "#f0eeff",
        connector: "#8dce55",
      };
    } else if (process.env.REACT_APP_DEFCOLOR === "Green") {
      return {
        primary: "#296140",
        secondary: "#8fbd51",
        manualTask: "#679b7c",
        autoTask: "#b7e6ca",
        traTask: "#4af28f",
        connector: "#8dce55",
      };
    }
  } else {
    return {
      primary: "#4c38c9",
      secondary: "#a238c9",
      manualTask: "#c6c0ec",
      autoTask: "#F0EEFF",
      traTask: "#f0eeff",
      connector: "#8dce55",
    };
  }
  return { status: true, result: [] };
}

export default {
  excludeSideBar: ["/login", "/forgot-password", "/register"],

  registerFormInputs: [
    {
      name: "contactfirstname",
      label: "First name",
      type: "text",
      placeholder: "First name",
      value: "",
      is_required: true,
      is_required_message: "Complete your First name",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },
    {
      name: "contactlastname",
      label: "Last name",
      type: "text",
      is_required: true,
      is_required_message: "Complete your Last name",
      placeholder: "Enter last name",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },
    {
      name: "contactemail",
      label: "Email",
      type: "text",
      placeholder: "Enter Email",
      is_required: true,
      is_required_message: "Complete your email",
      is_mail: true,
      is_mail_message: "Invalid email",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },
    {
      name: "contactphone",
      label: "Phone number",
      type: "text",
      placeholder: "Enter Phone number",
      is_required: true,
      is_required_message: "Complete Phone number",
      is_phonenumber: true,
      is_phonenumber_message: "Incorrect Phone number",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Enter your Password",
      is_required: true,
      is_required_message: "Complete your password",
      is_password: true,

      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },
    {
      name: "password2",
      label: "Repeat Password",
      type: "password",
      placeholder: "Enter your Password",
      is_required: true,
      is_required_message: "Repeat your password",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },
    {
      name: "name",
      label: "Company name",
      type: "text",
      placeholder: "Enter Organization",
      is_required: true,
      is_required_message: "Complete your Organization name",
      is_name: true,
      is_name_message: "Just letters and number are accepted ",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "country",
      label: "Country",
      type: "select",
      placeholder: "Enter a Contry",
      value: "US",
      values: [
        { value: "US", text: "USA" },
        { value: "BO", text: "Bolivia" },
      ],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "Enter State",
      value: "",
      is_required: true,
      is_required_message: "Complete your State",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "city",
      label: "Ciy",
      type: "text",
      placeholder: "Enter Organization",
      value: "",
      is_required: true,
      is_required_message: "Complete your City",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "address1",
      label: "Address line 1",
      type: "text",
      placeholder: "Address line 1",
      is_required: true,
      is_required_message: "Complete Address line 1",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "address2",
      label: "Address line 2",
      type: "text",
      placeholder: "Address line 2",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "description",
      label: "Short description about your company",
      type: "textarea",
      placeholder: "Enter a description",
      is_required: true,
      is_required_message: "Complete a short description",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
  ],
  confirmFormInputs: [
    {
      name: "label1",
      label: "Please insert your Confirmation Code",
      type: "title",
      placeholder: "Confirmation Code",
      value: "Please copy code here",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
    {
      name: "token",
      label: "Confirmation Code",
      type: "text",
      placeholder: "Confirmation Code",
      is_required: true,
      is_required_message:
        "Complete the Confirmation Code received in your mail",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 12,
      },
    },
  ],
  paymentFormInputs: [
    {
      name: "cardfirstname",
      label: "First Name",
      type: "text",
      placeholder: "First name",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },
    {
      name: "cardlastname",
      label: "Last Name",
      type: "text",
      placeholder: "Enter last name",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },
    {
      name: "cardnumber",
      label: "Credit Card Number",
      type: "text",
      placeholder: "Enter card Credit Card Number",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 12,
        lg: 6,
      },
    },

    {
      name: "cardexpire",
      label: "Expiry",
      type: "text",
      placeholder: "Enter Card Expiration",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 6,
        lg: 3,
      },
    },
    {
      name: "cardcvv",
      label: "CVV",
      type: "password",
      placeholder: "CVV",
      value: "",
      values: [],
      rules: {
        required: true,
      },
      size: {
        xs: 6,
        lg: 3,
      },
    },
  ],

  //ProfileMenus: [ "Perfil", "Administaci\u00F3n", "Salir"],
  formSearchWF: [
    {
      name: "daterange",
      label: "Date Range",
      type: "daterange",
      action: "",
      variant: "",
      value: "",
      values: [],
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 1,
      layout_id: "1_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 0,
      layout_col: 0,
      layout_sizeX: 12,
      layout_sizeY: 2,
    },
    {
      name: "flowid",
      label: "Flow",
      type: "select",
      path: "",
      action: "",
      values: {
        datatype: "dynamic",
        apidata: "",
        apimethod: "post",
        apitoken: "NOTOKEN",
        apiurl: "/flows/get-all",
        text: "FLOW",
        value: "FLOWID",
      },
      style: "",
      variant: "h1",
      color: "primary",
      validations: "",
      is_required: "",
      is_required_message: "",
      id: 1,
      layout_id: "1_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 2,
      layout_row: 0,
      layout_col: 0,
      layout_sizeX: 12,
      layout_sizeY: 2,
    },
  ],

  formSearchJobs: [
    {
      name: "daterange",
      label: "Date Range",
      type: "daterange",
      action: "",
      variant: "",
      value: "",
      values: [],
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 1,
      layout_id: "1_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 0,
      layout_col: 0,
      layout_sizeX: 12,
      layout_sizeY: 2,
    },
  ],

  filterFiledEditor: [
    {
      name: "name",
      label: " Name",
      type: "text",
      action: "",
      variant: "",
      value: "",
      values: [],
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 1,
      layout_id: "1_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 0,
      layout_col: 0,
      layout_sizeX: 6,
      layout_sizeY: 2,
    },
    {
      name: "label",
      label: "Label",
      type: "text",
      action: "",
      value: "",
      values: [],
      variant: "",
      fontsize: "h1",
      color: "primary",
      validations: "",
      is_required: "",
      is_required_message: "",
      id: 2,
      layout_id: "2_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 0,
      layout_col: 6,
      layout_sizeX: 6,
      layout_sizeY: 2,
    },
    {
      name: "type",
      label: " Type",
      type: "select",
      action: `if (field.value==="select" 
							|| field.value==="radiov"
							|| field.value==="radio"
							|| field.value==="autocomplete"
							|| field.value==="multiple"
							|| field.value==="multipleautocomplete" 
						){
							formData.values.type='options';
						}else{
							formData.values.type='hidden';
						}
						if (field.value==="iframe"){
							formData.path.type='text'
							formData.iframetype.type='select'
						}else{
							formData.path.type='space';
							formData.iframetype.type='hidden'
							
						}
						`,
      value: "",
      values: [
        { value: "text", text: "text" },
        { value: "numeric", text: "numeric" },
        { value: "textarea", text: "textarea" },
        { value: "hidden", text: "hidden" },
        { value: "password", text: "password" },
        { value: "select", text: "select" },
        { value: "autocomplete", text: "autocomplete" },
        { value: "multiple", text: "multiple" },
        { value: "values", text: "values" },
        { value: "options", text: "options" },
        { value: "actions", text: "actions" },
        { value: "inputs", text: "inputs" },
        { value: "link", text: "link" },
        { value: "dropdown", text: "dropdown" },
        { value: "componentvalues", text: "componentvalues" },
        { value: "multipleautocomplete", text: "multiple autocomplete" },
        { value: "radio", text: "Radio button" },
        { value: "radiov", text: "Radio button (Vertical)" },
        { value: "checkbox", text: "checkbox" },
        { value: "switch", text: "switch" },
        { value: "date", text: "date" },
        { value: "daterange", text: "daterange" },
        { value: "title", text: "title" },
        { value: "image", text: "image" },
        { value: "staticimage", text: "Static image" },
        { value: "multipleimages", text: "multipleimages" },
        { value: "space", text: "space" },
        { value: "orderitems", text: "order items" },
        { value: "iframe", text: "iframe" },
        { value: "button", text: "button" },
        { value: "scriptarea", text: "script" },
        { value: "file", text: "file" },
      ],
      variant: "",
      fontsize: "h1",
      color: "primary",
      validations: "",
      is_required: "",
      is_required_message: "",
      id: 3,
      layout_id: "3_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 2,
      layout_col: 0,
      layout_sizeX: 6,
      layout_sizeY: 2,
    },

    {
      name: "path",
      label: "path",
      type: "space",
      action: "",
      variant: "",
      value: "",
      values: [],
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 4,
      layout_id: "4_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 2,
      layout_col: 6,
      layout_sizeX: 6,
      layout_sizeY: 2,
    },

    {
      name: "iframetype",
      label: "iFrame Type",
      type: "space",
      action: "",
      variant: "",
      value: "INTERNAL",
      values: [
        { value: "INTERNAL", text: "INTERNAL" },
        { value: "EXTERNAL", text: "EXTERNAL" },
      ],
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 4,
      layout_id: "4_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 2,
      layout_col: 6,
      layout_sizeX: 6,
      layout_sizeY: 2,
    },

    {
      name: "action",
      label: "Action (js)",
      type: "scriptarea",
      action: "",
      value: "",
      className: "source-code",
      values: [],
      variant: "",
      fontsize: "h1",
      color: "primary",
      validations: "",
      is_required: "",
      is_required_message: "",
      id: 5,
      layout_id: "6_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 4,
      layout_col: 0,
      layout_sizeX: 12,
      layout_sizeY: 2,
    },

    {
      name: "values",
      label: "Values",
      type: "hidden",
      action: "",
      value: "",
      values: [],
      variant: "h4",
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 13,
      layout_id: "7_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 6,
      layout_col: 0,
      layout_sizeX: 12,
      layout_sizeY: 2,
    },
  ],
  "es-es": {
    formFieldEditor: [
      {
        name: "name",
        label: "Nombre",
        type: "text",
        action: "",
        variant: "",
        value: "",
        values: [],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 1,
        layout_id: "1_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 0,
        layout_col: 0,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "label",
        label: "Etiqueta",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 2,
        layout_id: "2_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 0,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "type",
        label: "Tipo",
        type: "select",
        action: `if (field.value==="select" 
						|| field.value==="radiov"
						|| field.value==="radio"
						|| field.value==="autocomplete"
						|| field.value==="multiple"
						|| field.value==="multipleautocomplete" 
					){
						formData.values.type='options';
					}else{
						formData.values.type='hidden';
					}
					if (field.value==="iframe"){
						formData.path.type='text'
						formData.iframetype.type='select'
					}else{
						formData.path.type='space';
						formData.iframetype.type='hidden'
						
					}
					`,
        value: "",
        values: [
          { value: "text", text: "text" },
          { value: "numeric", text: "numeric" },
          { value: "textarea", text: "textarea" },
          { value: "hidden", text: "hidden" },
          { value: "password", text: "password" },
          { value: "select", text: "select" },
          { value: "autocomplete", text: "autocomplete" },
          { value: "multiple", text: "multiple" },
          { value: "values", text: "values" },
          { value: "options", text: "options" },
          { value: "actions", text: "actions" },
          { value: "inputs", text: "inputs" },
          { value: "link", text: "link" },
          { value: "dropdown", text: "dropdown" },
          { value: "componentvalues", text: "componentvalues" },
          { value: "multipleautocomplete", text: "multiple autocomplete" },
          { value: "radio", text: "Radio button" },
          { value: "radiov", text: "Radio button (Vertical)" },
          { value: "checkbox", text: "checkbox" },
          { value: "switch", text: "switch" },
          { value: "date", text: "date" },
          { value: "daterange", text: "daterange" },
          { value: "title", text: "title" },
          { value: "image", text: "image" },
          { value: "staticimage", text: "Static image" },
          { value: "multipleimages", text: "multipleimages" },
          { value: "space", text: "space" },
          { value: "orderitems", text: "order items" },
          { value: "iframe", text: "iframe" },
          { value: "button", text: "button" },
          { value: "scriptarea", text: "script" },
          { value: "file", text: "Archivo" },
        ],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 3,
        layout_id: "3_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 2,
        layout_col: 0,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },

      {
        name: "defaultvalue",
        label: "Valor por defecto",
        type: "text",
        action: "",
        variant: "",
        value: "",
        values: [],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 4,
        layout_id: "4_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 2,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "path",
        label: "url",
        type: "space",
        action: "",
        variant: "",
        value: "",
        values: [],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 4,
        layout_id: "4_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 2,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "iframetype",
        label: "Tipo de iframe",
        type: "space",
        action: "",
        variant: "",
        value: "INTERNAL",
        values: [
          { value: "INTERNAL", text: "INTERNO" },
          { value: "EXTERNAL", text: "EXTERNO" },
        ],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 4,
        layout_id: "4_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 2,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "action",
        label: "Action (js)",
        type: "scriptarea",
        action: "",
        value: "",
        className: "source-code",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 5,
        layout_id: "6_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 4,
        layout_col: 0,
        layout_sizeX: 12,
        layout_sizeY: 2,
      },
      {
        name: "values",
        label: "Valores",
        type: "hidden",
        action: "",
        value: "",
        values: [],
        variant: "h4",
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 13,
        layout_id: "7_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 6,
        layout_col: 0,
        layout_sizeX: 12,
        layout_sizeY: 2,
      },
      {
        name: "style",
        label: "Estilo",
        type: "title",
        action: "",
        variant: "h4",
        value: "",
        values: [],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 6,
        layout_id: "8_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 6,
        layout_col: 0,
        layout_sizeX: 12,
        layout_sizeY: 2,
      },
      {
        name: "variant",
        label: "Variante",
        type: "select",
        action: "",
        variant: "",
        value: "",
        values: [
          { value: "h1", text: "h1" },
          { value: "h2", text: "h2" },
          { value: "h3", text: "h3" },
          { value: "h4", text: "h4" },
          { value: "h5", text: "h5" },
          { value: "h6", text: "h6" },
        ],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 8,
        layout_id: "9_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 8,
        layout_col: 0,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "color",
        label: "Color",
        type: "select",
        action: "",
        value: "",
        values: [
          { value: "primary", text: "primary" },
          { value: "secondary", text: "secondary" },
          { value: "success", text: "success" },
          { value: "warning", text: "warning" },
          { value: "info", text: "info" },
          { value: "error", text: "error" },
        ],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 9,
        layout_id: "10_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 8,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "validations",
        label: "Validaciones",
        type: "title",
        action: "",
        value: "",
        values: [],
        variant: "h4",
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 12,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 10,
        layout_col: 0,
        layout_sizeX: 12,
        layout_sizeY: 2,
      },
      {
        name: "is_required",
        label: "Es requerido?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_required_message",
        label: "Mensaje",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
      {
        name: "is_name",
        label: "Solo letras?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_name_message",
        label: "Mensaje",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
      {
        name: "is_number",
        label: "Solo números?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_number_message",
        label: "Mensaje",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
      {
        name: "is_mail",
        label: "Es correo electrónico?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_mail_message",
        label: "Mensaje",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
      {
        name: "is_phonenumber",
        label: "Es número telefónico?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_phonenumber_message",
        label: "Mensaje",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
    ],
  },
  "en-us": {
    formFieldEditor: [
      {
        name: "name",
        label: " Name",
        type: "text",
        action: "",
        variant: "",
        value: "",
        values: [],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 1,
        layout_id: "1_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 0,
        layout_col: 0,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "label",
        label: "Label",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 2,
        layout_id: "2_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 0,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "type",
        label: " Type",
        type: "select",
        action: `if (field.value==="select" 
						|| field.value==="radiov"
						|| field.value==="radio"
						|| field.value==="autocomplete"
						|| field.value==="multiple"
						|| field.value==="multipleautocomplete" 
					){
						formData.values.type='options';
					}else{
						formData.values.type='hidden';
					}
					if (field.value==="iframe"){
						formData.path.type='text'
						formData.iframetype.type='select'
					}else{
						formData.path.type='space';
						formData.iframetype.type='hidden'
						
					}
					`,
        value: "",
        values: [
          { value: "text", text: "text" },
          { value: "numeric", text: "numeric" },
          { value: "textarea", text: "textarea" },
          { value: "hidden", text: "hidden" },
          { value: "password", text: "password" },
          { value: "select", text: "select" },
          { value: "autocomplete", text: "autocomplete" },
          { value: "multiple", text: "multiple" },
          { value: "values", text: "values" },
          { value: "options", text: "options" },
          { value: "actions", text: "actions" },
          { value: "inputs", text: "inputs" },
          { value: "link", text: "link" },
          { value: "dropdown", text: "dropdown" },
          { value: "componentvalues", text: "componentvalues" },
          { value: "multipleautocomplete", text: "multiple autocomplete" },
          { value: "radio", text: "Radio button" },
          { value: "radiov", text: "Radio button (Vertical)" },
          { value: "checkbox", text: "checkbox" },
          { value: "switch", text: "switch" },
          { value: "date", text: "date" },
          { value: "daterange", text: "daterange" },
          { value: "title", text: "title" },
          { value: "image", text: "image" },
          { value: "staticimage", text: "Static image" },
          { value: "multipleimages", text: "multipleimages" },
          { value: "space", text: "space" },
          { value: "orderitems", text: "order items" },
          { value: "iframe", text: "iframe" },
          { value: "button", text: "button" },
          { value: "scriptarea", text: "script" },
          { value: "file", text: "file" },
        ],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 3,
        layout_id: "3_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 2,
        layout_col: 0,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },

      {
        name: "defaultvalue",
        label: "Default value",
        type: "text",
        action: "",
        variant: "",
        value: "",
        values: [],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 4,
        layout_id: "4_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 2,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "path",
        label: "path",
        type: "space",
        action: "",
        variant: "",
        value: "",
        values: [],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 4,
        layout_id: "4_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 2,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "iframetype",
        label: "iFrame Type",
        type: "space",
        action: "",
        variant: "",
        value: "INTERNAL",
        values: [
          { value: "INTERNAL", text: "INTERNAL" },
          { value: "EXTERNAL", text: "EXTERNAL" },
        ],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 4,
        layout_id: "4_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 2,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "action",
        label: "Action (js)",
        type: "scriptarea",
        action: "",
        value: "",
        className: "source-code",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 5,
        layout_id: "6_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 4,
        layout_col: 0,
        layout_sizeX: 12,
        layout_sizeY: 2,
      },
      {
        name: "values",
        label: "Values",
        type: "hidden",
        action: "",
        value: "",
        values: [],
        variant: "h4",
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 13,
        layout_id: "7_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 6,
        layout_col: 0,
        layout_sizeX: 12,
        layout_sizeY: 2,
      },
      {
        name: "style",
        label: "Style",
        type: "title",
        action: "",
        variant: "h4",
        value: "",
        values: [],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 6,
        layout_id: "8_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 6,
        layout_col: 0,
        layout_sizeX: 12,
        layout_sizeY: 2,
      },
      {
        name: "variant",
        label: "Variant",
        type: "select",
        action: "",
        variant: "",
        value: "",
        values: [
          { value: "h1", text: "h1" },
          { value: "h2", text: "h2" },
          { value: "h3", text: "h3" },
          { value: "h4", text: "h4" },
          { value: "h5", text: "h5" },
          { value: "h6", text: "h6" },
        ],
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 8,
        layout_id: "9_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 8,
        layout_col: 0,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "color",
        label: "Color",
        type: "select",
        action: "",
        value: "",
        values: [
          { value: "primary", text: "primary" },
          { value: "secondary", text: "secondary" },
          { value: "success", text: "success" },
          { value: "warning", text: "warning" },
          { value: "info", text: "info" },
          { value: "error", text: "error" },
        ],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 9,
        layout_id: "10_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 8,
        layout_col: 6,
        layout_sizeX: 6,
        layout_sizeY: 2,
      },
      {
        name: "validations",
        label: "Validations",
        type: "title",
        action: "",
        value: "",
        values: [],
        variant: "h4",
        color: "primary",
        validations: "",
        validationrequired: "",
        validationmessagerequired: "",
        id: 12,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 10,
        layout_col: 0,
        layout_sizeX: 12,
        layout_sizeY: 2,
      },
      {
        name: "is_required",
        label: "Is required?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_required_message",
        label: "Message",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
      {
        name: "is_name",
        label: "Is name?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_name_message",
        label: "Message",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
      {
        name: "is_number",
        label: "Is number?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_number_message",
        label: "Message",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
      {
        name: "is_mail",
        label: "Is mail?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_mail_message",
        label: "Message",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
      {
        name: "is_phonenumber",
        label: "Is phonenumber?",
        type: "switch",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 10,
        layout_id: "12_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 0,
        layout_sizeX: 3,
        layout_sizeY: 2,
      },
      {
        name: "is_phonenumber_message",
        label: "Message",
        type: "text",
        action: "",
        value: "",
        values: [],
        variant: "",
        fontsize: "h1",
        color: "primary",
        validations: "",
        is_required: "",
        is_required_message: "",
        id: 11,
        layout_id: "11_layout",
        layout_maxSizeY: 2,
        layout_minSizeY: 1,
        layout_row: 12,
        layout_col: 2,
        layout_sizeX: 9,
        layout_sizeY: 2,
      },
    ],
  },
  dashboardPanelEditor: [
    {
      name: "component",
      label: "Component",
      type: "text",
      action: "",
      variant: "",
      value: "",
      values: [],
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 1,
      layout_id: "1_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 0,
      layout_col: 0,
      layout_sizeX: 6,
      layout_sizeY: 2,
    },
    {
      name: "title",
      label: "Title",
      type: "text",
      action: "",
      variant: "",
      value: "",
      values: [],
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 1,
      layout_id: "1_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 0,
      layout_col: 0,
      layout_sizeX: 6,
      layout_sizeY: 2,
    },

    {
      name: "type",
      label: " Type",
      type: "select",
      action: `if (  field.value==="bar" || field.value==="pie"  ){
						formData.values.type='optionsChart';
					}else{
						formData.values.type='options';
					}
					 
					`,
      value: "",
      values: [
        { value: "grid", text: "grid" },
        { value: "bar", text: "bar" },
      ],
      variant: "",
      fontsize: "h1",
      color: "primary",
      validations: "",
      is_required: "",
      is_required_message: "",
      id: 3,
      layout_id: "3_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 2,
      layout_col: 0,
      layout_sizeX: 6,
      layout_sizeY: 2,
    },

    {
      name: "values",
      label: "Values",
      type: "options",
      action: "",
      value: "",
      values: [],
      variant: "h4",
      color: "primary",
      validations: "",
      validationrequired: "",
      validationmessagerequired: "",
      id: 13,
      layout_id: "7_layout",
      layout_maxSizeY: 2,
      layout_minSizeY: 1,
      layout_row: 6,
      layout_col: 0,
      layout_sizeX: 12,
      layout_sizeY: 2,
    },
  ],

  countryList: [
    "Bolivia",
    "Afganist\u00E1n",
    "Albania",
    "Alemania",
    "Andorra",
    "Angola",
    "Anguila",
    "Ant\u00E1rtida",
    "Antigua y Barbuda",
    "Antillas holandesas",
    "Arabia Saud\u00ED",
    "Argelia",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaiy\u00E1n",
    "Bahamas",
    "Bahrein",
    "Bangladesh",
    "Barbados",
    "B\u00E9lgica",
    "Belice",
    "Ben\u00EDn",
    "Bermudas",
    "Bhut\u00E1n",
    "Bielorrusia",
    "Birmania",
    "Bosnia y Herzegovina",
    "Botsuana",
    "Brasil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Camboya",
    "Camer\u00FAn",
    "Canad\u00E1",
    "Chad",
    "Chile",
    "China",
    "Chipre",
    "Ciudad estado del Vaticano",
    "Colombia",
    "Comores",
    "Congo",
    "Corea",
    "Corea del Norte",
    "Costa del Marf\u00EDl",
    "Costa Rica",
    "Croacia",
    "Cuba",
    "Dinamarca",
    "Djibouri",
    "Dominica",
    "Ecuador",
    "Egipto",
    "El Salvador",
    "Emiratos Arabes Unidos",
    "Eritrea",
    "Eslovaquia",
    "Eslovenia",
    "España",
    "Estados Unidos",
    "Estonia",
    "c",
    "Ex-Rep\u00FAblica Yugoslava de Macedonia",
    "Filipinas",
    "Finlandia",
    "Francia",
    "Gab\u00F3n",
    "Gambia",
    "Georgia",
    "Georgia del Sur y las islas Sandwich del Sur",
    "Ghana",
    "Gibraltar",
    "Granada",
    "Grecia",
    "Groenlandia",
    "Guadalupe",
    "Guam",
    "Guatemala",
    "Guayana",
    "Guayana francesa",
    "Guinea",
    "Guinea Ecuatorial",
    "Guinea-Bissau",
    "Hait\u00ED",
    "Holanda",
    "Honduras",
    "Hong Kong R. A. E",
    "Hungr\u00EDa",
    "India",
    "Indonesia",
    "Irak",
    "Ir\u00E1n",
    "Irlanda",
    "Isla Bouvet",
    "Isla Christmas",
    "Isla Heard e Islas McDonald",
    "Islandia",
    "Islas Caim\u00E1n",
    "Islas Cook",
    "Islas de Cocos o Keeling",
    "Islas Faroe",
    "Islas Fiyi",
    "Islas Malvinas Islas Falkland",
    "Islas Marianas del norte",
    "Islas Marshall",
    "Islas menores de Estados Unidos",
    "Islas Palau",
    "Islas Salom\u00F3n",
    "Islas Tokelau",
    "Islas Turks y Caicos",
    "Islas V\u00EDrgenes EE.UU.",
    "Islas V\u00EDrgenes Reino Unido",
    "Israel",
    "Italia",
    "Jamaica",
    "Jap\u00F3n",
    "Jordania",
    "Kazajist\u00E1n",
    "Kenia",
    "Kirguizist\u00E1n",
    "Kiribati",
    "Kuwait",
    "Laos",
    "Lesoto",
    "Letonia",
    "L\u00EDbano",
    "Liberia",
    "Libia",
    "Liechtenstein",
    "Lituania",
    "Luxemburgo",
    "Macao R. A. E",
    "Madagascar",
    "Malasia",
    "Malawi",
    "Maldivas",
    "Mal\u00ED",
    "Malta",
    "Marruecos",
    "Martinica",
    "Mauricio",
    "Mauritania",
    "Mayotte",
    "M\u00E9xico",
    "Micronesia",
    "Moldavia",
    "M\u00F3naco",
    "Mongolia",
    "Montserrat",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Nicaragua",
    "N\u00EDger",
    "Nigeria",
    "Niue",
    "Norfolk",
    "Noruega",
    "Nueva Caledonia",
    "Nueva Zelanda",
    "Om\u00E1n",
    "Panam\u00E1",
    "Papua Nueva Guinea",
    "Paquist\u00E1n",
    "Paraguay",
    "Per\u00FA",
    "Pitcairn",
    "Polinesia francesa",
    "Polonia",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reino Unido",
    "Rep\u00FAblica Centroafricana",
    "Rep\u00FAblica Checa",
    "Rep\u00FAblica de Sud\u00E1frica",
    "Rep\u00FAblica Democr\u00E1tica del Congo Zaire",
    "Rep\u00FAblica Dominicana",
    "Reuni\u00F3n",
    "Ruanda",
    "Rumania",
    "Rusia",
    "Samoa",
    "Samoa occidental",
    "San Kitts y Nevis",
    "San Marino",
    "San Pierre y Miquelon",
    "San Vicente e Islas Granadinas",
    "Santa Helena",
    "Santa Luc\u00EDa",
    "Santo Tom\u00E9 y Pr\u00EDncipe",
    "Senegal",
    "Serbia y Montenegro",
    "Sychelles",
    "Sierra Leona",
    "Singapur",
    "Siria",
    "Somalia",
    "Sri Lanka",
    "Suazilandia",
    "Sud\u00E1n",
    "Suecia",
    "Suiza",
    "Surinam",
    "Svalbard",
    "Tailandia",
    "Taiw\u00E1n",
    "Tanzania",
    "Tayikist\u00E1n",
    "Territorios brit\u00E1nicos del oc\u00E9ano Indico",
    "Territorios franceses del sur",
    "Timor Oriental",
    "Togo",
    "Tonga",
    "Trinidad y Tobago",
    "T\u00FAnez",
    "Turkmenist\u00E1n",
    "Turqu\u00EDa",
    "Tuvalu",
    "Ucrania",
    "Uganda",
    "Uruguay",
    "Uzbekist\u00E1n",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wallis y Futuna",
    "Yemen",
    "Zambia",
    "Zimbabue",
  ],

  languageEs: {
    grid: {
      EmptyRecord: "No hay registros que mostrar",
      True: "cierto",
      False: "falso",
      InvalidFilterMessage: "Datos de filtro no válidos",
      GroupDropArea:
        "Arrastre el encabezado de una columna aquí para agrupar su columna",
      UnGroup: "Haga clic aquí para desagrupar",
      GroupDisable: "La agrupación está deshabilitada para esta columna",
      FilterbarTitle: "celda de barra de filtro",
      EmptyDataSourceError:
        "DataSource no debe estar vacío en la carga inicial ya que las columnas se generan a partir de dataSource en AutoGenerate Column Grid",
      Add: "Añadir",
      Edit: "Editar",
      Cancel: "Cancelar",
      Update: "Actualizar",
      Delete: "Eliminar",
      Print: "Impresión",
      Pdfexport: "Exportar PDF",
      Excelexport: "Exportación Excel",
      Wordexport: "Exportación de palabras",
      Csvexport: "Exportación CSV",
      Search: "Buscar",
      Columnchooser: "Columnas",
      Save: "Guardar",
      Item: "item",
      Items: "items",
      EditOperationAlert:
        "No hay registros seleccionados para la operación de edición",
      DeleteOperationAlert:
        "No hay registros seleccionados para la operación de eliminación",
      SaveButton: "Guardar",
      OKButton: "Okay",
      CancelButton: "Cancelar",
      EditFormTitle: "Detalles de",
      AddFormTitle: "Añadir nuevo récord",
      BatchSaveConfirm: "¿Seguro que quieres guardar los cambios?",
      BatchSaveLostChanges:
        "Los cambios no guardados se perderán. Estás seguro de que quieres continuar?",
      ConfirmDelete: "¿Estás seguro de que deseas eliminar el registro?",
      CancelEdit: "¿Estás seguro de que deseas cancelar los cambios?",
      ChooseColumns: "Elegir columna",
      SearchColumns: "columnas de búsqueda",
      Matchs: "No se encontraron coincidencias",
      FilterButton: "Filtrar",
      ClearButton: "Claro",
      StartsWith: "Comienza con",
      EndsWith: "Termina con",
      Contains: "Contiene",
      Equal: "Igual",
      NotEqual: "No es igual",
      LessThan: "Menos que",
      LessThanOrEqual: "Menor o igual",
      GreaterThan: "Mas grande que",
      GreaterThanOrEqual: "Mayor que o igual",
      ChooseDate: "Elige una fecha",
      EnterValue: "Ingrese el valor",
      Copy: "Copiar",
      Group: "Agrupar por esta columna",
      Ungroup: "Desagrupar por esta columna",
      autoFitAll: "Ajuste automático de todas las columnas",
      autoFit: "Ajustar automáticamente esta columna",
      Export: "Exportar",
      FirstPage: "Primera página",
      LastPage: "Última página",
      PreviousPage: "Pagina anterior",
      NextPage: "Siguiente página",
      SortAscending: "Orden ascendente",
      SortDescending: "Orden descendiente",
      EditRecord: "Editar registro",
      DeleteRecord: "Eliminar el registro",
      FilterMenu: "Filtrar",
      SelectAll: "Seleccionar todo",
      Blanks: "Espacios en blanco",
      FilterTrue: "Cierto",
      FilterFalse: "Falso",
      NoResult: "No se encontraron coincidencias",
      ClearFilter: "Filtro claro",
      NumberFilter: "Filtros de número",
      TextFilter: "Filtros de texto",
      DateFilter: "Filtros de fecha",
      DateTimeFilter: "Filtros de fecha y hora",
      MatchCase: "Caso de coincidencia",
      Between: "Entre",
      CustomFilter: "Filtro personalizado",
      CustomFilterPlaceHolder: "Ingrese el valor",
      CustomFilterDatePlaceHolder: "Elige una fecha",
      AND: "Y",
      OR: "O",
      ShowRowsWhere: "Mostrar filas donde:",
      NotStartsWith: "No comienza con",
      Like: "Como",
      NotEndsWith: "No termina con",
      NotContains: "No contiene",
      IsNull: "Nula",
      NotNull: "No nula",
      IsEmpty: "Vacía",
      IsNotEmpty: "No vacía",
      AddCurrentSelection: "Agregar selección actual al filtro",
      UnGroupButton: "Haga clic aquí para desagrupar",
      AutoFitAll: "Autoajustar todas las columnas",
      AutoFit: "Autoajustar esta columna",
      Clear: "Clara",
      FilterMenuDialogARIA: "Cuadro de diálogo del menú de filtro",
      ExcelFilterDialogARIA: "Diálogo de filtro de Excel",
      DialogEditARIA: "Cuadro de diálogo Editar",
      ColumnChooserDialogARIA: "Selector de columnas",
      ColumnMenuDialogARIA: "Diálogo de menú de columna",
      CustomFilterDialogARIA: "Diálogo de filtro personalizado",
      SortAtoZ: "Ordenar de la A a la Z",
      SortZtoA: "Ordenar de Z a A",
      SortByOldest: "Ordenar por más antigua",
      SortByNewest: "Ordenar por más nueva",
      SortSmallestToLargest: "Ordenar más pequeña a más grande",
      SortLargestToSmallest: "Ordenar más grande a más pequeña",
      Sort: "Clasificar",
      FilterDescription: "Presione Alt Abajo para abrir el menú de filtro",
      SortDescription: "Presiona Enter para ordenar",
      ColumnMenuDescription: "Presiona Alt Abajo para abrir el menú Columna",
      GroupDescription: "Presione Ctrl espacio para agrupar",
      ColumnHeader: " encabezado de la columna ",
      TemplateCell: " es plantilla de celda",
      CommandColumnAria:
        "es el encabezado de la columna de la columna Comando ",
      DialogEdit: "Editar cuadro de diálogo",
      ClipBoard: "portapapeles",
      GroupButton: "Botón de grupo",
      UnGroupAria: "botón de desagrupar",
      GroupSeperator: "Separador para las columnas agrupadas",
      UnGroupIcon: "desagrupar la columna agrupada ",
      GroupedSortIcon: "ordenar la columna agrupada ",
      GroupedDrag: "Arrastre la columna agrupada",
      GroupCaption: " es celda de subtítulos de grupo",
      CheckBoxLabel: "casilla de verificación",
      Expanded: "Expandida",
      Collapsed: "Contraída",
      SelectAllCheckbox: "Seleccionar todo Casilla de verificación",
      SelectRow: "Seleccionar fila",
    },
    pager: {
      currentPageInfo: "{0} de {1} páginas",
      totalItemsInfo: "({0} items)",
      firstPageTooltip: "Ir a la primera página",
      lastPageTooltip: "Ir a la última página",
      nextPageTooltip: "Ir a la página siguiente",
      previousPageTooltip: "Regresar a la pagina anterior",
      nextPagerTooltip: "Ir a los siguientes elementos del paginador",
      previousPagerTooltip: "Ir a elementos anteriores del paginador",
      pagerDropDown: "items por página",
      pagerAllDropDown: "items",
      All: "Todos",
      totalItemInfo: "({0} item)",
      Container: "Contenedor del paginador",
      Information: "Información del paginador",
      ExternalMsg: "Mensaje externo del paginador",
      Page: "Página ",
      Of: " de ",
      Pages: " Paginas",
    },
    kanban: {
      items: "items",
      min: "mínimo",
      max: "máx.",
      cardsSelected: "Tarjetas Seleccionadas",
      addTitle: "Agregar nueva tarjeta",
      editTitle: "Editar detalles de la tarjeta",
      deleteTitle: "Eliminar tarjeta",
      deleteContent: "¿Estás seguro de que deseas eliminar esta tarjeta?",
      save: "Guardar",
      delete: "Eliminar",
      cancel: "Cancelar",
      yes: "si",
      no: "No",
      close: "Cerrar",
      noCard: "No hay tarjetas para mostrar",
      unassigned: "Sin asignar",
      cards: "Mapa",
    },
    dropdowns: {
      noRecordsTemplate: "No se encontraron registros",
      actionFailureTemplate: "La solicitud falló",
      overflowCountTemplate: "+${count} más ..",
      selectAllText: "Seleccionar todo",
      unSelectAllText: "Deselecciona todo",
      totalCountTemplate: "${count} seleccionado",
    },
    "drop-down-list": {
      noRecordsTemplate: "No se encontraron registros",
      actionFailureTemplate: "La solicitud falló",
    },
    "combo-box": {
      noRecordsTemplate: "No se encontraron registros",
      actionFailureTemplate: "La solicitud falló",
    },
    "auto-complete": {
      noRecordsTemplate: "No se encontraron registros",
      actionFailureTemplate: "La solicitud falló",
    },
    "multi-select": {
      noRecordsTemplate: "No se encontraron registros",
      actionFailureTemplate: "La solicitud falló",
      overflowCountTemplate: "+${count} más ..",
      selectAllText: "Seleccionar todo",
      unSelectAllText: "Deselecciona todo",
      totalCountTemplate: "${count} seleccionado",
    },
    listbox: {
      noRecordsTemplate: "No se encontraron registros",
      actionFailureTemplate: "La solicitud falló",
      selectAllText: "Seleccionar todo",
      unSelectAllText: "Deselecciona todo",
      moveUp: "Ascender",
      moveDown: "Mover hacia abajo",
      moveTo: "Mover a",
      moveFrom: "Mover de",
      moveAllTo: "Mover todo a",
      moveAllFrom: "Mover todo de",
    },
    spreadsheet: {
      InsertingEmptyValue: "El valor de referencia no es válido.",
      FindValue: "Encuentra valor",
      ReplaceValue: "Reemplazar valor",
      FindReplaceTooltip: "Buscar y reemplazar",
      ByRow: "por filas",
      ByColumn: "por columnas",
      MatchExactCellElements: "Coincidir con todo el contenido de la celda",
      EnterCellAddress: "Ingrese la dirección de la celda",
      FindAndReplace: "Encontrar y reemplazar",
      ReplaceAllEnd: " partidos reemplazados por",
      FindNextBtn: "Buscar siguiente",
      FindPreviousBtn: "Buscar anterior",
      ReplaceBtn: "Reemplazar",
      ReplaceAllBtn: "Reemplaza todo",
      GotoHeader: "Ir a",
      Sheet: "Hoja",
      SearchWithin: "Buscar dentro",
      SearchBy: "Búsqueda por",
      Reference: "Referencia",
      Workbook: "Libro de trabajo",
      NoElements: "No pudimos encontrar lo que estabas buscando.",
      FindWhat: "Encontrar que",
      ReplaceWith: "Reemplazar con",
      FileName: "Nombre del archivo",
      ExtendValidation:
        "La selección contiene algunas celdas sin validación de datos. ¿Quiere extender la validación a estas celdas?",
      Yes: "Sí",
      No: "No",
      PROPER:
        "Convierte un texto a mayúsculas y minúsculas; primera letra a mayúsculas y las demás letras a minúsculas.",
      Cut: "Corte",
      Copy: "Copiar",
      Paste: "Pegar",
      PasteSpecial: "Pegado especial",
      All: "Todas",
      Values: "Valores",
      Formats: "Formatos",
      Font: "Fuente",
      FontSize: "Tamaño de fuente",
      Bold: "Atrevido",
      Italic: "Itálico",
      Underline: "Subrayar",
      Strikethrough: "tachado",
      TextColor: "Color de texto",
      FillColor: "Color de relleno",
      HorizontalAlignment: "Alineación horizontal",
      AlignLeft: "Alinear a la izquierda",
      AlignCenter: "Centro",
      AlignRight: "Alinear a la derecha",
      VerticalAlignment: "Alineamiento vertical",
      AlignTop: "Alinear arriba",
      AlignMiddle: "Alinear medio",
      AlignBottom: "Alinear abajo",
      MergeCells: "Combinar células",
      MergeAll: "fusionar todo",
      MergeHorizontally: "Combinar horizontalmente",
      MergeVertically: "Combinar verticalmente",
      Unmerge: "Separar",
      UnmergeCells: "Separar celdas",
      SelectMergeType: "Seleccionar tipo de fusión",
      MergeCellsAlert:
        "La combinación de celdas solo conservará el valor superior izquierdo (superior). ¿Combinar de todos modos?",
      PasteMergeAlert: "No podemos hacer eso a una celda de combinación.",
      Borders: "Fronteras",
      TopBorders: "Bordes superiores",
      LeftBorders: "Bordes izquierdos",
      RightBorders: "Bordes derechos",
      BottomBorders: "Bordes inferiores",
      AllBorders: "Todas las fronteras",
      HorizontalBorders: "Bordes horizontales",
      VerticalBorders: "Bordes Verticales",
      OutsideBorders: "fuera de las fronteras",
      InsideBorders: "Fronteras interiores",
      NoBorders: "Sin Fronteras",
      BorderColor: "Color del borde",
      BorderStyle: "Estilo de borde",
      InsertFunction: "Función de inserción",
      Insert: "Insertar",
      Delete: "Borrar",
      DuplicateSheet: "Duplicar",
      MoveRight: "Moverse a la derecha",
      MoveLeft: "Mover hacia la izquierda",
      Rename: "Rebautizar",
      Hide: "Esconder",
      NameBox: "Nombre de Caja",
      ShowHeaders: "Mostrar encabezados",
      HideHeaders: "Ocultar encabezados",
      ShowGridLines: "Mostrar líneas de cuadrícula",
      HideGridLines: "Ocultar líneas de cuadrícula",
      FreezePanes: "Congelar paneles",
      FreezeRows: "Congelar filas",
      FreezeColumns: "Congelar columnas",
      UnfreezePanes: "Descongelar paneles",
      UnfreezeRows: "Descongelar filas",
      UnfreezeColumns: "Descongelar columnas",
      AddSheet: "Agregar hoja",
      ListAllSheets: "Listar todas las hojas",
      CollapseToolbar: "Contraer barra de herramientas",
      ExpandToolbar: "Expandir barra de herramientas",
      CollapseFormulaBar: "Contraer barra de fórmulas",
      ExpandFormulaBar: "Expandir la barra de fórmulas",
      File: "Archivo",
      Home: "Casa",
      Formulas: "Fórmulas",
      View: "Vista",
      New: "Nuevo",
      Open: "Abierto",
      SaveAs: "Guardar como",
      ExcelXlsx: "Microsoft Excel",
      ExcelXls: "Microsoft Excel 97-2003",
      CSV: "Valores Separados por Comas",
      FormulaBar: "Barra de formulas",
      Sort: "Clasificar",
      SortAscending: "ascendente",
      SortDescending: "Descendente",
      CustomSort: "Clasificación personalizada",
      AddColumn: "Añadir columna",
      ContainsHeader: "Los datos contienen encabezado",
      CaseSensitive: "Distingue mayúsculas y minúsculas",
      SortBy: "Ordenar por",
      ThenBy: "Entonces por",
      SelectAColumn: "Seleccione una columna",
      SortEmptyFieldError:
        "Todos los criterios de clasificación deben tener una columna especificada. Compruebe los criterios de clasificación seleccionados e inténtelo de nuevo.",
      SortDuplicateFieldError:
        " está siendo ordenado por valores más de una vez. Elimine los criterios de clasificación duplicados y vuelva a intentarlo.",
      SortOutOfRangeError:
        "Seleccione una celda o rango dentro del rango usado y vuelva a intentarlo.",
      MultiRangeSortError:
        "Esto no se puede hacer en una selección de rango múltiple. Seleccione un solo rango y vuelva a intentarlo.",
      HideRow: "Ocultar fila",
      HideRows: "Ocultar filas",
      UnhideRows: "Mostrar filas",
      HideColumn: "Ocultar columna",
      HideColumns: "Ocultar columnas",
      UnhideColumns: "Mostrar columnas",
      InsertRow: "Insertar fila",
      InsertRows: "Insertar filas",
      Above: "Encima",
      Below: "Debajo",
      InsertColumn: "Insertar columna",
      InsertColumns: "Insertar columnas",
      Before: "Antes",
      After: "Después",
      DeleteRow: "Borrar fila",
      DeleteRows: "Eliminar filas",
      DeleteColumn: "Eliminar columna",
      DeleteColumns: "Eliminar columnas",
      Ok: "DE ACUERDO",
      Close: "Cerca",
      MoreOptions: "Mas opciones",
      Cancel: "Cancelar",
      Apply: "Solicitar",
      MoreColors: "Mas colores",
      StandardColors: "Colores estándar",
      General: "General",
      Number: "Número",
      Currency: "Moneda",
      Accounting: "Contabilidad",
      ShortDate: "Cita corta",
      LongDate: "Fecha larga",
      Time: "Hora",
      Percentage: "Porcentaje",
      Fraction: "Fracción",
      Scientific: "Científico",
      Text: "Texto",
      NumberFormat: "Formato numérico",
      MobileFormulaBarPlaceHolder: "Ingrese valor o fórmula",
      PasteAlert:
        "No puede pegar esto aquí, porque el área de copia y el área de pegado no tienen el mismo tamaño. Intente pegar en un rango diferente.",
      DestroyAlert:
        "¿Está seguro de que desea destruir el libro de trabajo actual sin guardar y crear un nuevo libro de trabajo?",
      SheetRenameInvalidAlert:
        "El nombre de la hoja contiene un carácter no válido.",
      SheetRenameEmptyAlert: "El nombre de la hoja no puede estar vacío.",
      SheetRenameAlreadyExistsAlert:
        "El nombre de la hoja ya existe. Introduzca otro nombre.",
      DeleteSheetAlert: "¿Está seguro de que desea eliminar esta hoja?",
      DeleteSingleLastSheetAlert:
        "Un libro de trabajo debe contener al menos una hoja de trabajo visible.",
      PickACategory: "Elige una categoría",
      Description: "Descripción",
      UnsupportedFile: "Archivo no soportado",
      DataLimitExceeded:
        "Los datos del archivo son demasiado grandes y lleva más tiempo procesarlos. ¿Desea continuar?",
      FileSizeLimitExceeded:
        "El tamaño del archivo es demasiado grande y lleva más tiempo procesarlo. ¿Desea continuar?",
      InvalidUrl: "URL invalida",
      SUM: "Añade una serie de números y/o celdas.",
      SUMIF: "Agrega las celdas según la condición especificada.",
      SUMIFS: "Agrega las celdas según las condiciones especificadas.",
      ABS: "Devuelve el valor de un número sin su signo.",
      RAND: "Devuelve un número aleatorio entre 0 y 1.",
      RANDBETWEEN:
        "Devuelve un entero aleatorio basado en valores especificados.",
      FLOOR:
        "Redondea un número hacia abajo al múltiplo más cercano de un factor dado.",
      CEILING: "Redondea un número al múltiplo más cercano de un factor dado.",
      PRODUCT: "Multiplica una serie de números y/o celdas.",
      AVERAGE:
        "Calcula el promedio de la serie de números y/o celdas excluyendo el texto.",
      AVERAGEIF:
        "Calcula el promedio de las celdas según el criterio especificado.",
      AVERAGEIFS:
        "Calcula el promedio de las celdas en función de las condiciones especificadas.",
      AVERAGEA:
        "Calcula el promedio de las celdas que evalúan VERDADERO como 1, texto y FALSO como 0.",
      COUNT: "Cuenta las celdas que contienen valores numéricos en un rango.",
      COUNTIF: "Cuenta las celdas según la condición especificada.",
      COUNTIFS: "Cuenta las celdas según las condiciones especificadas.",
      COUNTA: "Cuenta las celdas que contienen valores en un rango.",
      MIN: "Devuelve el número más pequeño de los argumentos dados.",
      MAX: "Devuelve el mayor número de los argumentos proporcionados.",
      DATE: "Devuelve la fecha en función del año, mes y día dados.",
      DAY: "Devuelve el día a partir de la fecha dada.",
      DAYS: "Devuelve el número de días entre dos fechas.",
      IF: "Devuelve el valor basado en la expresión dada.",
      IFS: "Devuelve el valor basado en las múltiples expresiones dadas.",
      CalculateAND:
        "Devuelve VERDADERO si todos los argumentos son VERDADEROS, de lo contrario devuelve FALSO.",
      CalculateOR:
        "Devuelve VERDADERO si alguno de los argumentos es VERDADERO, de lo contrario devuelve FALSO.",
      IFERROR:
        "Devuelve el valor si no se encuentra ningún error; de lo contrario, devolverá el valor especificado.",
      CHOOSE:
        "Devuelve un valor de la lista de valores, según el número de índice.",
      INDEX:
        "Devuelve un valor de la celda en un rango dado basado en el número de fila y columna.",
      FIND: "Devuelve la posición de una cadena dentro de otra cadena, que distingue entre mayúsculas y minúsculas.",
      CONCATENATE: "Combina dos o más cuerdas juntas.",
      CONCAT: "Concatena una lista o un rango de cadenas de texto.",
      SUBTOTAL:
        "Devuelve el subtotal de un rango usando el número de función dado.",
      RADIANS: "Convierte grados en radianes.",
      MATCH:
        "Devuelve la posición relativa de un valor especificado en un rango dado.",
      SLOPE:
        "Devuelve la pendiente de la línea de la regresión lineal de los puntos de datos.",
      INTERCEPT:
        "Calcula el punto de la línea de intersección Y a través de una regresión lineal.",
      UNIQUE: "Devuelve valores únicos de un rango o matriz",
      TEXT: "Convierte un valor en texto en el formato de número especificado.",
      DefineNameExists: "Este nombre ya existe, pruebe con otro nombre.",
      CircularReference:
        "Cuando una fórmula se refiere a una o más referencias circulares, esto puede resultar en un cálculo incorrecto.",
      SORT: "Ordena un rango de una matriz",
      T: "Comprueba si un valor es texto o no y devuelve el texto.",
      EXACT:
        "Comprueba si dos cadenas de texto son exactamente iguales y devuelve VERDADERO o FALSO.",
      LEN: "Devuelve un número de caracteres en una cadena dada.",
      MOD: "Devuelve un resto después de dividir un número por un divisor.",
      ODD: "Redondea un número positivo hacia arriba y un número negativo hacia abajo al entero impar más cercano.",
      PI: "Devuelve el valor de pi.",
      COUNTBLANK:
        "Devuelve el número de celdas vacías en un rango de celdas específico.",
      EVEN: "Redondea un número positivo hacia arriba y un número negativo hacia abajo al entero par más cercano.",
      DECIMAL:
        "Convierte una representación de texto de un número en una base determinada en un número decimal.",
      ADDRESS:
        "Devuelve una referencia de celda como texto, dados los números de fila y columna especificados.",
      CHAR: "Devuelve el carácter del número especificado.",
      CODE: "Devuelve el código numérico del primer carácter de una cadena dada.",
      DOLLAR: "Convierte el número en texto con formato de moneda.",
      SMALL: "Devuelve el k-ésimo valor más pequeño de una matriz determinada.",
      LARGE: "Devuelve el k-ésimo valor más grande en una matriz determinada.",
      TIME: "Convierte horas, minutos, segundos al texto con formato de hora.",
      DEGREES: "Convierte radianes a grados.",
      FACT: "Devuelve el factorial de un número.",
      MEDIAN: "Devuelve la mediana del conjunto de números dado.",
      EDATE:
        "Devuelve una fecha con un número dado de meses antes o después de la fecha especificada.",
      DATEVALUE: "Convierte una cadena de fecha en valor de fecha.",
      NOW: "Devuelve la fecha y hora actual.",
      HOUR: "Devuelve el número de horas en una cadena de tiempo especificada.",
      MINUTE:
        "Devuelve el número de minutos en una cadena de tiempo especificada.",
      SECOND:
        "Devuelve el número de segundos en una cadena de tiempo especificada.",
      MONTH: "Devuelve el número de meses en una cadena de fecha especificada.",
      OR: "O",
      AND: "Y",
      CustomFilterDatePlaceHolder: "Elige una fecha",
      CustomFilterPlaceHolder: "Introduce el valor",
      CustomFilter: "Filtro personalizado",
      Between: "Entre",
      MatchCase: "Caso de partido",
      DateTimeFilter: "Filtros de fecha y hora",
      Undo: "Deshacer",
      Redo: "Rehacer",
      ClearAllFilter: "Claro",
      ReapplyFilter: "Aplicar de nuevo",
      DateFilter: "Filtros de fecha",
      TextFilter: "Filtros de texto",
      NumberFilter: "Filtros numéricos",
      ClearFilter: "Filtro claro",
      NoResult: "No se encontraron coincidencias",
      FilterFalse: "Falso",
      FilterTrue: "Verdadero",
      Blanks: "espacios en blanco",
      SelectAll: "Seleccionar todo",
      GreaterThanOrEqual: "Mayor que o igual",
      GreaterThan: "Mas grande que",
      LessThanOrEqual: "Menor o igual",
      LessThan: "Menos que",
      NotEqual: "No es igual",
      Equal: "Igual",
      Contains: "Contiene",
      NotContains: "No Contiene",
      EndsWith: "Termina con",
      NotEndsWith: "no termina con",
      StartsWith: "Comienza con",
      NotStartsWith: "no comienza con",
      IsEmpty: "Vacío",
      IsNotEmpty: "No vacío",
      ClearButton: "Claro",
      FilterButton: "Filtrar",
      CancelButton: "Cancelar",
      OKButton: "DE ACUERDO",
      Search: "Buscar",
      DataValidation: "Validación de datos",
      CellRange: "Rango de celdas",
      Allow: "Permitir",
      Data: "Datos",
      Minimum: "Mínimo",
      Maximum: "Máximo",
      IgnoreBlank: "Ignorar en blanco",
      WholeNumber: "Número entero",
      Decimal: "Decimal",
      Date: "Fecha",
      TextLength: "Longitud del texto",
      List: "Lista",
      NotBetween: "No entre",
      EqualTo: "Igual a",
      NotEqualTo: "No igual a",
      GreaterThanOrEqualTo: "Mayor qué o igual a",
      LessThanOrEqualTo: "Menos que o igual a",
      InCellDropDown: "Desplegable en la celda",
      Sources: "Fuentes",
      Value: "Valor",
      Retry: "Rever",
      DialogError:
        "El origen de la lista debe ser una referencia a una sola fila o columna.",
      MinMaxError: "El Máximo debe ser mayor o igual que el Mínimo.",
      Spreadsheet: "Hoja de cálculo",
      MoreValidation: "Esta selección contiene más de una validación.",
      FileNameError:
        'Un nombre de archivo no puede contener caracteres como \\ / : * ? " < > [ ] |',
      ValidationError:
        "Este valor no coincide con las restricciones de validación de datos definidas para la celda.",
      ListLengthError:
        "Los valores de la lista solo permiten hasta 256 caracteres",
      ProtectSheet: "hoja de protección",
      UnprotectSheet: "Desproteger hoja",
      SelectCells: "Seleccionar celdas bloqueadas",
      SelectUnlockedCells: "Seleccionar celdas desbloqueadas",
      Save: "Salvar",
      EmptyFileName: "El nombre del archivo no puede estar vacío.",
      LargeName: "El nombre es muy largo.",
      FormatCells: "Formato de celdas",
      FormatRows: "Filas de formato",
      FormatColumns: "Columnas de formato",
      InsertLinks: "Insertar enlaces",
      ProtectContent: "Proteger el contenido de las celdas bloqueadas",
      ProtectAllowUser:
        " Permita que todos los usuarios de esta hoja de trabajo:",
      EditAlert:
        "La celda que intenta cambiar está protegida. Para hacer cambio, desproteja la hoja.",
      ReadonlyAlert:
        "Estás intentando modificar una celda que está en modo de solo lectura. Para realizar cambios, desactive el estado de solo lectura.",
      ClearValidation: "Borrar Validación",
      ISNUMBER:
        "Devuelve verdadero cuando el valor se analiza como un valor numérico.",
      ROUND: "Redondea un número a un número especificado de dígitos.",
      GEOMEAN:
        "Devuelve la media geométrica de una matriz o rango de datos positivos.",
      POWER: "Devuelve el resultado de un número elevado a la potencia",
      LOG: "Devuelve el logaritmo de un número en la base que especifique.",
      TRUNC:
        "Devuelve el valor truncado de un número a un número especificado de lugares decimales.",
      EXP: "Devuelve e elevado a la potencia del número dado.",
      LOOKUP:
        "Encuentra un valor en un rango de una sola fila o una sola columna y luego devuelve un valor de la misma posición en un rango de una sola fila o una sola columna.",
      HLOOKUP:
        "Encuentra el valor en la fila superior de la matriz de valores y luego devuelve el valor en la misma columna de la fila de la matriz que especifica.",
      VLOOKUP:
        "Busca el valor especificado en la primera columna del rango de búsqueda y devuelve un valor coincidente de otra columna en la misma fila.",
      NOT: "Devuelve el inverso de la expresión lógica especificada.",
      EOMONTH:
        "Devuelve el último día del mes que es el número de meses especificado antes o después de la fecha de inicio especificada al principio.",
      SQRT: "Devuelve la raíz cuadrada de un número positivo.",
      ROUNDDOWN: "Redondea el número a cero.",
      RSQ: "Devuelve el cuadrado del coeficiente de correlación instantánea del producto de Pearson en función de los puntos de datos en conocido_y y conocido_x.",
      HighlightCellsRules: "Resaltar reglas de celdas",
      CFEqualTo: "Igual a",
      TextThatContains: "Texto que contiene",
      ADateOccuring: "Una fecha que ocurre",
      DuplicateValues: "Valores duplicados",
      TopBottomRules: "Reglas superior/inferior",
      Top10Items: "10 items principales",
      Top10: "10 mejores",
      Bottom10Items: "10 items inferiores",
      Bottom10: "10 inferiores",
      AboveAverage: "Por encima del promedio",
      BelowAverage: "Por debajo del promedio",
      FormatCellsGreaterThan: "Formato de celdas que son MAYORES QUE:",
      FormatCellsLessThan: "Formato de celdas que son MENOS QUE:",
      FormatCellsBetween: "Formato de celdas que están ENTRE:",
      FormatCellsEqualTo: "Formato de celdas que son IGUAL A:",
      FormatCellsThatContainTheText:
        "Dar formato a las celdas que contienen el texto:",
      FormatCellsThatContainADateOccurring:
        "Dar formato a las celdas que contienen una fecha en curso:",
      FormatCellsDuplicate: "Dar formato a las celdas que contienen:",
      FormatCellsTop: "Formato de celdas que se clasifican en el TOP:",
      FormatCellsBottom:
        "Formatee las celdas que se clasifican en la PARTE INFERIOR:",
      FormatCellsAbove:
        "Formatee las celdas que están POR ENCIMA DEL PROMEDIO:",
      FormatCellsBelow:
        "Dar formato a las celdas que están POR DEBAJO DEL PROMEDIO:",
      With: "con",
      DataBars: "Barras de datos",
      ColorScales: "Escalas de colores",
      IconSets: "Conjuntos de iconos",
      ClearRules: "Reglas claras",
      SelectedCells: "Borrar reglas de celdas seleccionadas",
      EntireSheet: "Borrar reglas de toda la hoja",
      LightRedFillWithDarkRedText: "Relleno rojo claro con texto rojo oscuro",
      YellowFillWithDarkYellowText:
        "Relleno amarillo con texto amarillo oscuro",
      GreenFillWithDarkGreenText: "Relleno verde con texto verde oscuro",
      RedFill: "relleno rojo",
      RedText: "Texto rojo",
      Duplicate: "Duplicar",
      Unique: "Único",
      And: "y",
      WebPage: "Página web",
      ThisDocument: "Este documento",
      DisplayText: "Mostrar texto",
      Url: "URL",
      CellReference: "Referencia de celda",
      DefinedNames: "Nombres definidos",
      EnterTheTextToDisplay: "Ingrese el texto a mostrar",
      EnterTheUrl: "Introduce la URL",
      INT: "Devuelve un número al entero más cercano.",
      SUMPRODUCT: "Devuelve la suma del producto de rangos dados de arreglos.",
      TODAY: "Devuelve la fecha actual como valor de fecha.",
      ROUNDUP: "Redondea un número lejos de cero.",
      Link: "Enlace",
      Hyperlink: "Hipervínculo",
      EditHyperlink: "Editar hipervínculo",
      OpenHyperlink: "Abrir hipervínculo",
      RemoveHyperlink: "Eliminar hipervínculo",
      InvalidHyperlinkAlert:
        "La dirección de este sitio no es válida. Compruebe la dirección e inténtelo de nuevo.",
      InsertLink: "Insertar el link",
      EditLink: "Editar enlace",
      WrapText: "Ajustar texto",
      Update: "Actualizar",
      SortAndFilter: "Filtro de clasificación",
      Filter: "Filtrar",
      FilterCellValue: "Filtrar por valor de la celda seleccionada",
      FilterOutOfRangeError:
        "Seleccione una celda o rango dentro del rango usado y vuelva a intentarlo.",
      ClearFilterFrom: "Borrar filtro de",
      LN: "Devuelve el logaritmo natural de un número.",
      DefineNameInValid: "El nombre que ingresó no es válido.",
      EmptyError: "Debes ingresar un valor",
      ClearHighlight: "Borrar resaltado",
      HighlightInvalidData: "Resaltar datos no válidos",
      Clear: "Claro",
      ClearContents: "Contenidos claros",
      ClearAll: "Limpiar todo",
      ClearFormats: "Borrar formatos",
      ClearHyperlinks: "Borrar hipervínculos",
      Image: "Imagen",
      ConditionalFormatting: "Formato condicional",
      BlueDataBar: "Barra de datos azul",
      GreenDataBar: "Barra de datos verde",
      RedDataBar: "Barra de datos roja",
      OrangeDataBar: "Barra de datos naranja",
      LightBlueDataBar: "Barra de datos azul claro",
      PurpleDataBar: "Barra de datos morada",
      GYRColorScale: "Escala de color verde - amarillo - rojo",
      RYGColorScale: "Escala de color rojo - amarillo - verde",
      GWRColorScale: "Verde - Blanco - Escala de color rojo",
      RWGColorScale: "Escala de color rojo - blanco - verde",
      BWRColorScale: "Azul - Blanco - Escala de color rojo",
      RWBColorScale: "Escala de color rojo - blanco - azul",
      WRColorScale: "Escala de color blanco - rojo",
      RWColorScale: "Escala de color rojo - blanco",
      GWColorScale: "Escala de color verde - blanco",
      WGColorScale: "Blanco - Escala de color verde",
      GYColorScale: "Escala de color verde - amarillo",
      YGColorScale: "Escala de color amarillo - verde",
      ThreeArrowsColor: "3 flechas (de colores)",
      ThreeArrowsGray: "3 flechas (gris)",
      ThreeTriangles: "3 triángulos",
      FourArrowsColor: "4 flechas (gris)",
      FourArrowsGray: "4 flechas (de colores)",
      FiveArrowsColor: "5 flechas (gris)",
      FiveArrowsGray: "5 flechas (de colores)",
      ThreeTrafficLights1: "3 semáforos (sin marco)",
      ThreeTrafficLights2: "3 semáforos (con borde)",
      ThreeSigns: "3 señales",
      FourTrafficLights: "4 semáforos",
      RedToBlack: "rojo a negro",
      ThreeSymbols1: "3 símbolos (en un círculo)",
      ThreeSymbols2: "3 símbolos (sin círculo)",
      ThreeFlags: "3 banderas",
      ThreeStars: "3 estrellas",
      FourRatings: "4 valoraciones",
      FiveQuarters: "5 cuartos",
      FiveRatings: "5 valoraciones",
      FiveBoxes: "5 Cajas",
      Chart: "Gráfico",
      Column: "Columna",
      Bar: "Bar",
      Area: "Área",
      Pie: "Tarta",
      Doughnut: "Rosquilla",
      PieAndDoughnut: "pastel/rosquilla",
      Line: "Línea",
      Radar: "Radar",
      Scatter: "Dispersión",
      ChartDesign: "Diseño de gráficos",
      ClusteredColumn: "Columna agrupada",
      StackedColumn: "Columna apilada",
      StackedColumn100: "Columna 100% apilada",
      ClusteredBar: "Barra agrupada",
      StackedBar: "barra apilada",
      StackedBar100: "Barra 100% apilada",
      StackedArea: "Área apilada",
      StackedArea100: "Área 100% apilada",
      StackedLine: "línea apilada",
      StackedLine100: "Línea 100% apilada",
      LineMarker: "Línea con marcadores",
      StackedLineMarker: "Línea apilada con marcadores",
      StackedLine100Marker: "Línea 100% apilada con marcadores",
      AddChartElement: "Agregar elemento de gráfico",
      Axes: "hachas",
      AxisTitle: "Título del eje",
      ChartTitle: "Titulo del gráfico",
      DataLabels: "Etiquetas de datos",
      Gridlines: "Líneas de cuadrícula",
      Legends: "Leyendas",
      PrimaryHorizontal: "Horizontales primarios",
      PrimaryVertical: "Vertical primaria",
      None: "Ninguno",
      AboveChart: "Gráfico superior",
      Center: "Centro",
      InsideEnd: "extremo interior",
      InsideBase: "base interior",
      OutsideEnd: "Extremo exterior",
      PrimaryMajorHorizontal: "Primaria Mayor Horizontal",
      PrimaryMajorVertical: "Principal Principal Vertical",
      PrimaryMinorHorizontal: "Primario Menor Horizontal",
      PrimaryMinorVertical: "Primaria Menor Vertical",
      Right: "Derecho",
      Left: "Izquierda",
      Bottom: "Fondo",
      Top: "Cima",
      SwitchRowColumn: "Cambiar fila/columna",
      ChartTheme: "Tema de gráfico",
      ChartType: "Tipo de gráfico",
      Material: "Material",
      Fabric: "Tela",
      Bootstrap: "Oreja",
      HighContrastLight: "Luz de alto contraste",
      MaterialDark: "Material Oscuro",
      FabricDark: "Tela Oscura",
      HighContrast: "Alto contraste",
      BootstrapDark: "Bootstrap oscuro",
      Bootstrap4: "Bootstrap4",
      Bootstrap5Dark: "Bootstrap5 oscuro",
      Bootstrap5: "Bootstrap5",
      Tailwind: "Viento de cola",
      TailwindDark: "Viento de cola oscuro",
      VerticalAxisTitle: "Título del eje vertical",
      HorizontalAxisTitle: "Título del eje horizontal",
      EnterTitle: "Ingrese el título",
      UnprotectWorksheet: "Desproteger hoja",
      ReEnterPassword: "Vuelva a introducir la contraseña para continuar",
      SheetPassword: "Contraseña para desproteger hoja:",
      ProtectWorkbook: "Proteger libro de trabajo",
      Password: "Contraseña (opcional):",
      EnterThePassword: "Introduce la contraseña",
      ConfirmPassword: "Confirmar contraseña",
      EnterTheConfirmPassword: "reingresa tu contraseña",
      PasswordAlert: "La contraseña de confirmación no es idéntica",
      UnprotectWorkbook: "Desproteger libro de trabajo",
      UnprotectPasswordAlert: "La contraseña que proporcionó no es correcta.",
      IncorrectPassword:
        "No se puede abrir el archivo o la hoja de trabajo con la contraseña dada",
      PasswordAlertMsg: "Por favor introduzca la contraseña",
      ConfirmPasswordAlertMsg:
        "Por favor ingrese la contraseña de confirmación",
      IsProtected: "esta protegido",
      PDF: "Documento PDF",
      AutoFillMergeAlertMsg:
        "Para hacer esto, todas las celdas combinadas deben tener el mismo tamaño.",
      Fluent: "Fluido",
      FluentDark: "Oscuro fluido",
      Fluent2: "fluido 2",
      Fluent2Dark: "Fluido 2 Oscuro",
      Fluent2HighContrast: "Fluido 2 alto contraste",
      Custom: "Disfraz",
      WEEKDAY: "Devuelve el día de la semana correspondiente a una fecha.",
      FillSeries: "Serie de relleno",
      CopyCells: "Copiar celdas",
      FillFormattingOnly: "Solo formato de relleno",
      FillWithoutFormatting: "Rellenar sin formatear",
      CustomFormat: "Formatos de números personalizados",
      CustomFormatPlaceholder: "Escriba o seleccione un formato personalizado",
      CustomFormatTypeList: "Escribe",
      CellReferenceTypoError:
        "Encontramos un error tipográfico en la referencia de su celda. ¿Desea corregir esta referencia de la siguiente manera?",
      AddCurrentSelection: "Agregar selección actual al filtro",
      ExternalWorkbook:
        "Un archivo de Excel importado contiene una referencia de libro externo. ¿Quieres importar ese archivo?",
      Directional: "Direccional",
      Shapes: "formas",
      Indicators: "Indicadores",
      Ratings: "Calificaciones",
      InvalidFormulaError:
        "Descubrimos que usted escribió una fórmula que no es válida.",
      InvalidArguments:
        "Descubrimos que escribió una fórmula con argumentos no válidos.",
      EmptyExpression:
        "Descubrimos que escribiste una fórmula con una expresión vacía.",
      MismatchedParenthesis:
        "Descubrimos que escribió una fórmula a la que le faltaban uno o más paréntesis de apertura o cierre.",
      ImproperFormula: "Descubrimos que usted escribió una fórmula incorrecta.",
      WrongNumberOfArguments:
        "Descubrimos que escribiste una fórmula con una cantidad incorrecta de argumentos.",
      Requires3Arguments:
        "Descubrimos que escribiste una fórmula que requiere 3 argumentos.",
      MismatchedStringQuotes:
        "Descubrimos que escribiste una fórmula con comillas que no coinciden.",
      FormulaCircularRef:
        "Descubrimos que escribiste una fórmula con una referencia circular.",
    },
    filemanager: {
      NewFolder: "Nueva carpeta",
      Upload: "Subir",
      Delete: "Eliminar",
      Rename: "Renombrar",
      Download: "Descargar",
      Cut: "Cortar",
      Copy: "Copiar",
      Paste: "Pegar",
      SortBy: "Ordenar por",
      Refresh: "Actualizar",
      "Item-Selection": "elemento seleccionado",
      "Items-Selection": "items seleccionados",
      View: "Ver",
      Details: "Detalles",
      SelectAll: "Seleccionar todo",
      Open: "Abrir",
      "Tooltip-NewFolder": "Nueva carpeta",
      "Tooltip-Upload": "Subir",
      "Tooltip-Delete": "Eliminar",
      "Tooltip-Rename": "Renombrar",
      "Tooltip-Download": "Descargar",
      "Tooltip-Cut": "Cortar",
      "Tooltip-Copy": "Copiar",
      "Tooltip-Paste": "Pegar",
      "Tooltip-SortBy": "Ordenar por",
      "Tooltip-Refresh": "Actualizar",
      "Tooltip-Selection": "Selección clara",
      "Tooltip-View": "Ver",
      "Tooltip-Details": "Detalles",
      "Tooltip-SelectAll": "Seleccionar todo",
      Name: "Nombre",
      Size: "Tamaño",
      DateModified: "Modificado",
      DateCreated: "Fecha de creacion",
      Path: "Camino",
      Modified: "Modificado",
      Created: "Creado",
      Location: "Ubicación",
      Type: "Tipo",
      Permission: "Permiso",
      Ascending: "Ascendente",
      Descending: "Descendente",
      None: "Ninguna",
      "View-LargeIcons": "Iconos grandes",
      "View-Details": "Detalles",
      Search: "Buscar",
      "Button-Ok": "Okay",
      "Button-Cancel": "Cancelar",
      "Button-Yes": "si",
      "Button-No": "No",
      "Button-Create": "Crear",
      "Button-Save": "Guardar",
      "Header-NewFolder": "Carpeta",
      "Content-NewFolder": "Ingrese el nombre de su carpeta",
      "Header-Rename": "Renombrar",
      "Content-Rename": "Ingrese su nuevo nombre",
      "Header-Rename-Confirmation": "Cambiar nombre de confirmación",
      "Content-Rename-Confirmation":
        "Si cambia una extensión de nombre de archivo, el archivo podría volverse inestable. ¿Estás seguro de que quieres cambiarlo?",
      "Header-Delete": "Borrar archivo",
      "Content-Delete": "¿Seguro que quieres eliminar este archivo?",
      "Header-Folder-Delete": "Eliminar carpeta",
      "Content-Folder-Delete":
        "¿Está seguro de que desea eliminar esta carpeta?",
      "Header-Multiple-Delete": "Eliminar múltiples archivos",
      "Content-Multiple-Delete":
        "¿Está seguro de que desea eliminar estos {0} archivos?",
      "Header-Duplicate": "Archivo / Carpeta existe",
      "Content-Duplicate": "{0} ya existe. ¿Quieres renombrar y pegar?",
      "Header-Upload": "Subir archivos",
      Error: "Error",
      "Validation-Empty":
        "El nombre del archivo o carpeta no puede estar vacío.",
      "Validation-Invalid":
        'El nombre del archivo o carpeta {0} contiene caracteres no válidos. Por favor use un nombre diferente. Los nombres válidos de archivos o carpetas no pueden terminar con un punto o espacio, y no pueden contener ninguno de los siguientes caracteres: \\ /: *? "<> |',
      "Validation-NewFolder-Exists":
        "Ya existe un archivo o carpeta con el nombre {0}.",
      "Validation-Rename-Exists":
        "No se puede cambiar el nombre de {0} a {1}: el destino ya existe.",
      "Folder-Empty": "Esta carpeta está vacía",
      "File-Upload": "Arrastra los archivos aquí para subir",
      "Search-Empty": "No se han encontrado resultados",
      "Search-Key": "Pruebe con diferentes palabras clave",
      "Filter-Empty": "No se han encontrado resultados",
      "Filter-Key": "Probar con un filtro diferente",
      "Sub-Folder-Error":
        "La carpeta de destino es la subcarpeta de la carpeta de origen.",
      "Same-Folder-Error":
        "La carpeta de destino es la misma que la carpeta de origen.",
      "Access-Denied": "Acceso denegado",
      "Access-Details": "No tienes permiso para acceder a esta carpeta.",
      "Header-Retry": "El archivo ya existe",
      "Content-Retry":
        "Ya existe un archivo con este nombre en esta carpeta. Que te gustaría hacer?",
      "Button-Keep-Both": "Mantén ambos",
      "Button-Replace": "Reemplazar",
      "Button-Skip": "Omitir",
      "ApplyAll-Label": "Haz esto para todos los items actuales",
      KB: "KB",
      "Access-Message":
        "{0} no es accesible. Necesita permiso para realizar la acción {1}.",
      "Network-Error":
        "NetworkError: error al enviar en XMLHTTPRequest: error al cargar",
      "Server-Error": "ServerError: respuesta no válida de",
    },
    calendar: {
      today: "Hoy",
    },
    datepicker: {
      today: "Hoy",
      placeholder: "Elige una fecha",
    },
    daterangepicker: {
      placeholder: "Elige un rango de fechas",
      startLabel: "Fecha de inicio",
      endLabel: "Fecha final",
      applyText: "Aplicar",
      cancelText: "Cancelar",
      selectedDays: "Días seleccionados",
      days: "Dias",
      customRange: "Rango personalizado",
    },
    timepicker: {
      placeholder: "Elegir un fecha",
    },
    datetimepicker: {
      today: "Hoy",
      placeholder: "Elige una fecha y hora",
    },
    dialog: {
      close: "Cerrar",
    },
    toast: {
      close: "Cerrar",
    },
    tab: {
      closeButtonTitle: "Cerrar",
    },
    schedule: {
      day: "Día",
      week: "Semana",
      workWeek: "Semana laboral",
      month: "Mes",
      year: "Año",
      agenda: "Agenda",
      weekAgenda: "Agenda de la semana",
      workWeekAgenda: "Agenda de la semana laboral",
      monthAgenda: "Agenda del mes",
      today: "Hoy",
      noEvents: "No hay eventos",
      emptyContainer: "No hay eventos programados para este día.",
      allDay: "Todo el dia",
      start: "Inicio",
      end: "Fin",
      more: "más",
      close: "Cerrar",
      cancel: "Cancelar",
      noTitle: "(Sin título)",
      delete: "Borrar",
      deleteEvent: "Este evento",
      deleteMultipleEvent: "Eliminar Varios eventos",
      selectedItems: "Elementos seleccionados",
      deleteSeries: "Serie completa",
      edit: "Editar",
      editSeries: "Serie completa",
      editEvent: "Este evento",
      createEvent: "Crear",
      subject: "Asunto",
      addTitle: "Añadir título",
      moreDetails: "Más detalles",
      moreEvents: "Más eventos",
      save: "Guardar",
      editContent: "¿Cómo te gustaría cambiar la cita en la serie?",
      deleteContent: "¿Está seguro de que desea eliminar este evento?",
      deleteMultipleContent:
        "¿Estás seguro de que deseas eliminar los eventos seleccionados?",
      newEvent: "Nuevo evento",
      title: "Título",
      location: "Ubicación",
      description: "Descripción",
      timezone: "Zona horaria",
      startTimezone: "Iniciar zona horaria",
      endTimezone: "Fin de la zona horaria",
      repeat: "Repetir",
      saveButton: "Guardar",
      cancelButton: "Cancelar",
      deleteButton: "Eliminar",
      recurrence: "Repetición",
      wrongPattern: "El patrón de recurrencia no es válido.",
      seriesChangeAlert:
        "¿Desea cancelar los cambios realizados en instancias específicas de esta serie y volver a compararlos con toda la serie?",
      createError:
        "La duración del evento debe ser más corta que la frecuencia con la que ocurre. Acorte la duración o cambie el patrón de periodicidad en el editor de eventos de periodicidad.",
      sameDayAlert:
        "Dos ocurrencias del mismo evento no pueden ocurrir en el mismo día.",
      occurenceAlert:
        "No se puede reprogramar una aparición de la cita periódica si omite una aparición posterior de la misma cita.",
      editRecurrence: "Editar periodicidad",
      repeats: "Repite",
      alert: "Alerta",
      startEndError:
        "La fecha de finalización seleccionada se produce antes de la fecha de inicio.",
      invalidDateError: "El valor de la fecha ingresada no es válido.",
      blockAlert:
        "Los eventos no se pueden programar dentro del rango de tiempo bloqueado.",
      ok: "De acuerdo",
      yes: "si",
      no: "No",
      occurrence: "Ocurrencia",
      series: "Serie",
      previous: "Anterior",
      next: "próximo",
      timelineDay: "Día de la línea de tiempo",
      timelineWeek: "Semana de la línea de tiempo",
      timelineWorkWeek: "Cronograma Semana Laboral",
      timelineMonth: "Mes de la línea de tiempo",
      timelineYear: "Cronología Año",
      editFollowingEvent: "Siguientes eventos",
      deleteTitle: "Eliminar evento",
      editTitle: "Editar evento",
      beginFrom: "Comenzar desde",
      endAt: "Fin en",
      expandAllDaySection: "Expandir la sección de todo el día",
      collapseAllDaySection: "Contraer sección de todo el día",
      searchTimezone: "Zona horaria de búsqueda",
      noRecords: "No se encontraron registros",
      deleteRecurrenceContent:
        "¿Está seguro de que desea eliminar solo este evento o la serie completa?",
      recurrenceDateValidation:
        "Algunos meses tienen menos días a la fecha seleccionada. Para estos meses la ocurrencia de los eventos será en el ultimo día del mes.",
    },
    recurrenceeditor: {
      none: "Ninguna",
      daily: "Diario",
      weekly: "Semanal",
      monthly: "Mensual",
      month: "Mes",
      yearly: "Anual",
      never: "Nunca",
      until: "Hasta",
      count: "Contar",
      first: "Primero",
      second: "Segundo",
      third: "Tercero",
      fourth: "Cuarto",
      last: "Último",
      repeat: "Repetir",
      repeatEvery: "Repite cada",
      on: "Repetir en",
      end: "Final",
      onDay: "Día",
      days: "Día(s)",
      weeks: "Semana(s)",
      months: "Mes(es)",
      years: "Año(s)",
      every: "cada",
      summaryTimes: "vece(s)",
      summaryOn: "en",
      summaryUntil: "hasta",
      summaryRepeat: "Repite",
      summaryDay: "día(s)",
      summaryWeek: "semana(s)",
      summaryMonth: "mes(es)",
      summaryYear: "año(s)",
      monthWeek: "Mes Semana",
      monthPosition: "Posición del mes",
      monthExpander: "Expansor de mes",
      yearExpander: "Expansor de año",
      repeatInterval: "Intervalo de repetición",
    },
    gantt: {
      emptyRecord: "No hay registros que mostrar",
      id: "CARNÉ DE IDENTIDAD",
      name: "Nombre",
      startDate: "Fecha de inicio",
      endDate: "Fecha final",
      duration: "Duración",
      progress: "Progreso",
      dependency: "Dependencia",
      notes: "Notas",
      baselineStartDate: "Fecha de inicio de línea de base",
      baselineEndDate: "Fecha de finalización de línea de base",
      taskMode: "Modo de Tarea",
      changeScheduleMode: "Cambiar modo de programación",
      subTasksStartDate: "Fecha de inicio de subtareas",
      subTasksEndDate: "Fecha de finalización de subtareas",
      scheduleStartDate: "Fecha de inicio del horario",
      scheduleEndDate: "Fecha de finalización del horario",
      auto: "Auto",
      manual: "Manual",
      type: "Tipo",
      offset: "Compensar",
      resourceName: "Recursos",
      resourceID: "ID de recurso",
      day: "día",
      hour: "hora",
      minute: "minuto",
      days: "dias",
      hours: "horas",
      minutes: "minutos",
      generalTab: "General",
      customTab: "Columnas personalizadas",
      writeNotes: "Escribe notas",
      addDialogTitle: "Nueva tarea",
      editDialogTitle: "Información de tarea",
      saveButton: "Guardar",
      add: "Añadir",
      edit: "Editar",
      update: "Actualizar",
      delete: "Eliminar",
      cancel: "Cancelar",
      search: "Buscar",
      task: " tarea",
      tasks: " Tareas",
      zoomIn: "Acercarse",
      zoomOut: "Disminuir el zoom",
      zoomToFit: "Zoom para ajustar",
      excelExport: "Exportación Excel",
      csvExport: "Exportación CSV",
      expandAll: "Expandir todo",
      collapseAll: "Desplegar todo",
      nextTimeSpan: "Próximo intervalo de tiempo",
      prevTimeSpan: "Periodo de tiempo anterior",
      okText: "Okay",
      confirmDelete: "¿Estás seguro de que deseas eliminar el registro?",
      from: "Desde",
      to: "A",
      taskLink: "Enlace de tareas",
      lag: "Retraso",
      start: "comienzo",
      finish: "Terminar",
      enterValue: "Ingrese el valor",
      taskBeforePredecessor_FS:
        "Movió '{0}' para comenzar antes de que '{1}' finalice y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskAfterPredecessor_FS:
        "Se movió '{0}' lejos de '{1}' y las dos tareas están vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskBeforePredecessor_SS:
        "Movió '{0}' para comenzar antes de que comience '{1}' y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskAfterPredecessor_SS:
        "Movió '{0}' para comenzar después de que '{1}' comience y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskBeforePredecessor_FF:
        "Movió '{0}' para finalizar antes de que '{1}' finalice y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskAfterPredecessor_FF:
        "Movió '{0}' para finalizar después de que '{1}' finalice y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskBeforePredecessor_SF:
        "Se movió '{0}' lejos de '{1}' para comenzar y las dos tareas están vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskAfterPredecessor_SF:
        "Movió '{0}' para finalizar después de que '{1}' comience y las dos tareas estén vinculadas. Como resultado, los enlaces no pueden ser respetados. Seleccione una acción a continuación para realizar",
      taskInformation: "Información de tarea",
      deleteTask: "Eliminar tarea",
      deleteDependency: "Eliminar dependencia",
      convert: "Convertir",
      save: "Guardar",
      above: "Encima",
      below: "Abajo",
      child: "Niño",
      milestone: "Hito",
      toTask: "A la tarea",
      toMilestone: "Al hito",
      eventMarkers: "Marcadores de eventos",
      leftTaskLabel: "Etiqueta de tarea izquierda",
      rightTaskLabel: "Etiqueta de tarea correcta",
      timelineCell: "Celda de la línea de tiempo",
      confirmPredecessorDelete:
        "¿Seguro que quieres eliminar el enlace de dependencia?",
      unit: "Unidad",
      work: "Trabajo",
      taskType: "Tipo de tarea",
      unassignedTask: "Tarea no asignada",
      group: "Grupo",
      indent: "Sangría",
      outdent: "Eliminar sangría",
      segments: "Segmentos",
      splitTask: "Tarea dividida",
      mergeTask: "Combinar tarea",
      left: "Izquierda",
      right: "Correcto",
    },
    richtexteditor: {
      alignments: "Alineaciones",
      justifyLeft: "Alinear a la izquierda",
      justifyCenter: "Alinear al centro",
      justifyRight: "Alinear a la derecha",
      justifyFull: "Alinear Justificar",
      fontName: "Nombre de la fuente",
      fontSize: "Tamaño de fuente",
      fontColor: "Color de fuente",
      backgroundColor: "Color de fondo",
      bold: "Negrita",
      italic: "Itálico",
      underline: "Subrayar",
      strikethrough: "Tachado",
      clearFormat: "Formato claro",
      clearAll: "Limpiar todo",
      cut: "Cortar",
      copy: "Copiar",
      paste: "Pegar",
      unorderedList: "Lista con viñetas",
      orderedList: "Lista numerada",
      indent: "Aumentar sangría",
      outdent: "Disminuir sangría",
      undo: "Deshacer",
      redo: "Rehacer",
      superscript: "Sobrescrito",
      subscript: "Subíndice",
      createLink: "Insertar hipervínculo",
      openLink: "Enlace abierto",
      editLink: "Editar enlace",
      removeLink: "Remover enlace",
      image: "Insertar imagen",
      replace: "Reemplazar",
      numberFormatList: "Lista de formatos de números",
      bulletFormatList: "Lista de formatos de viñetas",
      audioReplace: "Reemplazar",
      videoReplace: "Reemplazar",
      videoAlign: "Alinear",
      videoDimension: "Dimensión",
      audioRemove: "Eliminar",
      videoRemove: "Eliminar",
      audioLayoutOption: "Opción de diseño",
      videoLayoutOption: "Opción de diseño",
      align: "Alinear",
      caption: "Captura de imagen",
      remove: "Eliminar",
      insertLink: "Insertar el link",
      display: "Monitor",
      altText: "Texto alternativo",
      dimension: "Cambiar tamaño",
      fullscreen: "Maximizar",
      maximize: "Maximizar",
      minimize: "Minimizar",
      lowerCase: "Minúscula",
      upperCase: "Mayúscula",
      print: "Impresión",
      formats: "Formatos",
      sourcecode: "Vista de código",
      preview: "Avance",
      viewside: "VerLado",
      insertCode: "Insertar codigo",
      linkText: "Mostrar texto",
      linkTooltipLabel: "Título",
      linkWebUrl: "Dirección web",
      linkTitle: "Ingrese un título",
      linkurl: "https://ejemplo.com",
      linkOpenInNewWindow: "Abrir enlace en una nueva ventana",
      linkHeader: "Insertar el link",
      dialogInsert: "Insertar",
      dialogCancel: "Cancelar",
      dialogUpdate: "Actualizar",
      imageHeader: "Insertar imagen",
      audioHeader: "Insertar Audio",
      videoHeader: "Insertar vídeo",
      imageLinkHeader: "También puede proporcionar un enlace desde la web",
      audioLinkHeader: "También puedes proporcionar un enlace desde la web.",
      videoLinkHeader: "URL web",
      embedVideoLinkHeader: "URL para insertar medios",
      mdimageLink: "Proporcione una URL para su imagen",
      imageUploadMessage: "Suelta la imagen aquí o navega para subir",
      audioUploadMessage: "Suelta un archivo de audio o busca para cargarlo",
      videoUploadMessage: "Suelta un archivo de vídeo o busca para subirlo",
      imageDeviceUploadMessage: "Haga clic aquí para subir",
      audioDeviceUploadMessage: "Haga clic aquí para cargar",
      videoDeviceUploadMessage: "Haga clic aquí para cargar",
      imageAlternateText: "Texto alternativo",
      alternateHeader: "Texto alternativo",
      browse: "Examinar",
      imageUrl: "https://ejemplo.com/imagen.png",
      audioUrl: "https://ejemplo.com/audio.mp3",
      videoUrl: "https://ejemplo.com/video.mp4",
      webUrl: "URL web",
      embedUrl: "Código de inserción",
      imageCaption: "Subtítulo",
      imageSizeHeader: "Tamaño de la imagen",
      imageHeight: "Altura",
      imageWidth: "Anchura",
      videoHeight: "Altura",
      videoWidth: "Ancho",
      textPlaceholder: "Ingrese texto",
      inserttablebtn: "Insertar tabla",
      tabledialogHeader: "Insertar tabla",
      tableWidth: "Anchura",
      cellpadding: "Relleno Celular",
      cellspacing: "Espaciamiento celular",
      columns: "Número de columnas",
      rows: "Número de filas",
      tableRows: "Fila",
      tableColumns: "Columna",
      tableCellHorizontalAlign: "Alineación horizontal de celda de tabla",
      tableCellVerticalAlign: "Alineación vertical de mesa",
      createTable: "Crear mesa",
      removeTable: "Eliminar tabla",
      tableHeader: "Fila de encabezado",
      tableRemove: "Eliminar tabla",
      tableCellBackground: "Fondo de celda de tabla",
      tableEditProperties: "Propiedades de edición de tabla",
      styles: "Estilos",
      insertColumnLeft: "Insertar columna a la izquierda",
      insertColumnRight: "Insertar columna a la derecha",
      deleteColumn: "Eliminar columna",
      insertRowBefore: "Insertar fila antes",
      insertRowAfter: "Insertar fila después",
      deleteRow: "Borrar fila",
      tableEditHeader: "Editar tabla",
      TableHeadingText: "Bóveda",
      TableColText: "Columna",
      imageInsertLinkHeader: "Insertar el link",
      editImageHeader: "Editar imagen",
      alignmentsDropDownLeft: "Alinear a la izquierda",
      alignmentsDropDownCenter: "Alinear al centro",
      alignmentsDropDownRight: "Alinear a la derecha",
      alignmentsDropDownJustify: "Alinear Justificar",
      imageDisplayDropDownInline: "En línea",
      imageDisplayDropDownBreak: "Descanso",
      audioLayoutOptionDropDownInline: "En línea",
      audioLayoutOptionDropDownBreak: "Romper",
      videoLayoutOptionDropDownInline: "En línea",
      videoLayoutOptionDropDownBreak: "Romper",
      tableInsertRowDropDownBefore: "Insertar fila antes",
      tableInsertRowDropDownAfter: "Insertar fila después",
      tableInsertRowDropDownDelete: "Borrar fila",
      tableInsertColumnDropDownLeft: "Insertar columna a la izquierda",
      tableInsertColumnDropDownRight: "Insertar columna a la derecha",
      tableInsertColumnDropDownDelete: "Eliminar columna",
      tableVerticalAlignDropDownTop: "Alinear la parte superior",
      tableVerticalAlignDropDownMiddle: "Alinear Medio",
      tableVerticalAlignDropDownBottom: "Alinear la parte inferior",
      tableStylesDropDownDashedBorder: "Bordes discontinuas",
      tableStylesDropDownAlternateRows: "Filas Alternas",
      pasteFormat: "Pegar formato",
      pasteFormatContent: "Elija la acción de formateo",
      plainText: "Texto sin formato",
      cleanFormat: "Limpiar",
      keepFormat: "Mantener",
      pasteDialogOk: "Okay",
      pasteDialogCancel: "Cancelar",
      fileManager: "Administrador de archivos",
      fileDialogHeader: "Explorador de archivos",
      formatsDropDownParagraph: "Párrafo",
      formatsDropDownCode: "Código",
      formatsDropDownQuotation: "Cotización",
      formatsDropDownHeading1: "Título 1",
      formatsDropDownHeading2: "Título 2",
      formatsDropDownHeading3: "Título 3",
      formatsDropDownHeading4: "Título 4",
      fontNameSegoeUI: "SegoeUI",
      fontNameArial: "Arial",
      fontNameGeorgia: "Georgia",
      fontNameImpact: "Impact",
      fontNameTahoma: "Tahoma",
      fontNameTimesNewRoman: "Times New Roman",
      fontNameVerdana: "Verdana",
      formatsOLListNumber: "Número",
      formatsOLListLowerAlpha: "InferiorAlfa",
      formatsOLListUpperAlpha: "Alfa superior",
      formatsOLListLowerRoman: "romano inferior",
      formatsOLListUpperRoman: "romano superior",
      formatsOLListLowerGreek: "bajogriego",
      formatsULListDisc: "Desct",
      formatsULListCircle: "Circulo",
      formatsULListSquare: "Cuadrado",
      formatsOLListNone: "Ninguno",
      formatsULListNone: "Ninguno",
      formatPainter: "Copiar formato",
      emojiPicker: "Selector de emojis",
      embeddedCode: "Código incrustado",
      pasteEmbeddedCodeHere: "Pegue el código incrustado aquí",
      emojiPickerTypeToFind: "Escriba para encontrar",
      emojiPickerNoResultFound: "No se han encontrado resultados",
      emojiPickerTrySomethingElse: "Prueba algo más",
      linkAriaLabel: "Abrir en Nueva ventana",
      imageLinkAriaLabel: "Abrir en Nueva ventana",
      unsupportedImage: "Formato de archivo no soportado",
      mergecells: "Combinar células",
      verticalsplit: "división vertical",
      horizontalsplit: "división horizontal",
      numberFormatListLowerAlpha: "Alfa inferior",
      numberFormatListUpperAlpha: "Alfa superior",
      numberFormatListLowerRoman: "Bajo romano",
      numberFormatListUpperRoman: "Alto Romano",
      numberFormatListLowerGreek: "griego bajo",
    },
    colorpicker: {
      Apply: "Aplicar",
      Cancel: "Cancelar",
      ModeSwitcher: "Modo interruptor",
    },
    uploader: {
      Browse: "Examinar...",
      Clear: "Claro",
      Upload: "Subir",
      dropFilesHint: "O suelta archivos aquí",
      invalidMaxFileSize: "El tamaño del archivo es demasiado grande.",
      invalidMinFileSize: "El tamaño del archivo es demasiado pequeño.",
      invalidFileType: "El tipo de archivo no está permitido",
      uploadFailedMessage: "El archivo no se pudo cargar",
      uploadSuccessMessage: "documento cargado exitosamente",
      removedSuccessMessage: "Archivo eliminado con éxito",
      removedFailedMessage: "No se puede eliminar el archivo",
      inProgress: "Cargando",
      readyToUploadMessage: "Listo para subir",
      abort: "Abortar",
      remove: "Eliminar",
      cancel: "Cancelar",
      delete: "Borrar archivo",
      pauseUpload: "Carga de archivo en pausa",
      pause: "Pausa",
      resume: "Currículum",
      retry: "Procesar de nuevo",
      fileUploadCancel: "Carga de archivo cancelada",
    },
    numerictextbox: {
      incrementTitle: "Valor de incremento",
      decrementTitle: "Valor de disminución",
    },
    slider: {
      incrementTitle: "Incrementar",
      decrementTitle: "Disminución",
    },
    formValidator: {
      required: "Este campo es requerido.",
      email: "Por favor, introduce una dirección de correo electrónico válida.",
      url: "Por favor introduzca un URL válido.",
      date: "Por favor introduzca una fecha valida.",
      dateIso: "Por favor, introduzca una fecha válida (ISO).",
      creditcard: "Por favor ingrese un número de tarjeta válido",
      number: "Por favor ingrese un número valido.",
      digits: "Por favor ingrese solo dígitos.",
      maxLength: "Ingrese no más de {0} caracteres.",
      minLength: "Ingrese al menos {0} caracteres.",
      rangeLength: "Ingrese un valor entre {0} y {1} caracteres de longitud.",
      range: "Ingrese un valor entre {0} y {1}.",
      max: "Ingrese un valor menor o igual a {0}.",
      min: "Ingrese un valor mayor o igual a {0}.",
      regex: "Por favor, introduzca un valor correcto.",
      tel: "Por favor ingrese un número de teléfono válido.",
      pattern: "Por favor, introduzca un valor de patrón correcto.",
      equalTo: "Por favor ingrese el texto válido del partido",
    },
    treegrid: {
      Above: "Encima",
      Below: "Abajo",
      AddRow: "Añadir fila",
      ExpandAll: "Expandir todo",
      CollapseAll: "Desplegar todo",
    },
    querybuilder: {
      StartsWith: "Comienza con",
      EndsWith: "Termina con",
      Contains: "Contiene",
      NotLike: "Contiene",
      Like: "No como",
      Equal: "Igual",
      NotEqual: "No es igual",
      LessThan: "Menos que",
      LessThanOrEqual: "Menor o igual",
      GreaterThan: "Mas grande que",
      GreaterThanOrEqual: "Mayor que o igual",
      Between: "Entre",
      NotBetween: "No entre",
      Empty: "Vacío",
      NotEmpty: "No vacío",
      In: "En",
      NotIn: "No en",
      NotContains: "No contiene",
      Remove: "ELIMINAR",
      SelectField: "Selecciona un campo",
      SelectOperator: "Seleccionar operador",
      DeleteRule: "Eliminar esta condición",
      DeleteGroup: "Eliminar grupo",
      AddGroup: "Añadir grupo",
      AddCondition: "Agregar condición",
      Edit: "EDITAR",
      ValidationMessage: "este campo es requerido",
      SummaryViewTitle: "Vista de resumen",
      OtherFields: "Otros campos",
      AND: "Y",
      OR: "O",
      SelectValue: "Ingrese valor",
      IsEmpty: "Esta vacio",
      IsNotEmpty: "No está vacío",
      IsNull: "Es nulo",
      IsNotNull: "No es nulo",
      True: "cierto",
      False: "falso",
      DoesNotStartWith: "No comienza con",
      DoesNotEndWith: "no termina con",
      DoesNotContain: "No contiene",
      AddButton: "Agregar grupo/condición",
      CloneGroup: "Grupo clon",
      LockGroup: "Grupo de bloqueo",
      CloneRule: "Regla de clonación",
      LockRule: "Regla de bloqueo",
      UnlockRule: "Regla de desbloqueo",
      UnlockGroup: "Desbloquear grupo",
    },
    barcode: {},
    datamatrix: {},
    qrcode: {},
    pivotview: {
      grandTotal: "Gran total",
      total: "Total",
      value: "Valor",
      noValue: "Sin valor",
      row: "Fila",
      column: "Columna",
      collapse: "Colapso",
      expand: "Expandir",
      rowAxisPrompt: "Suelta la fila aquí",
      columnAxisPrompt: "Suelta la columna aquí",
      valueAxisPrompt: "Suelta el valor aquí",
      filterAxisPrompt: "Suelta el filtro aquí",
      filter: "Filtrar",
      filtered: "Filtrado",
      sort: "Clasificar",
      filters: "Filtros",
      rows: "Filas",
      columns: "Columnas",
      values: "Valores",
      close: "Cerrar",
      cancel: "Cancelar",
      delete: "Borrar",
      CalculatedField: "Campo calculado",
      createCalculatedField: "Crear campo calculado",
      fieldName: "Ingrese el nombre del campo",
      error: "Error",
      invalidFormula: "Fórmula no válida.",
      dropText: "Ejemplo: ('Sum (Order_Count)' + 'Sum (In_Stock)') * 250",
      dropTextMobile: "Agregue campos y edite la fórmula aquí.",
      dropAction:
        "El campo calculado no se puede colocar en ninguna otra región excepto en el eje de valores.",
      alert: "Alerta",
      warning: "Advertencia",
      ok: "DE ACUERDO",
      search: "Buscar",
      drag: "Arrastrar",
      remove: "Eliminar",
      allFields: "Todos los campos",
      formula: "Fórmula",
      addToRow: "Agregar a la fila",
      addToColumn: "Agregar a columna",
      addToValue: "Agregar valor",
      addToFilter: "Agregar al filtro",
      emptyData: "No hay registros que mostrar",
      fieldExist:
        "Ya existe un campo con este nombre. Ingrese un nombre diferente.",
      confirmText:
        "Ya existe un campo de cálculo con este nombre. ¿Quieres cambiarlo?",
      noMatches: "No hay coincidencias",
      format: "Valores de resúmenes por",
      edit: "Editar",
      clear: "Claro",
      formulaField: "Arrastre y suelte campos a la fórmula",
      dragField: "Arrastra el campo a la fórmula",
      clearFilter: "Claro",
      by: "por",
      all: "Todos",
      multipleItems: "Varios elementos",
      member: "Miembro",
      label: "Etiqueta",
      date: "Fecha",
      enterValue: "Ingrese valor",
      chooseDate: "Ingrese la fecha",
      Before: "Antes",
      BeforeOrEqualTo: "Antes o igual a",
      After: "Después",
      AfterOrEqualTo: "Después o igual a",
      labelTextContent: "Muestre los items para los que la etiqueta",
      dateTextContent: "Muestre los elementos para los que la fecha",
      valueTextContent: "Muestre los elementos para los que",
      Equals: "Igual",
      DoesNotEquals: "No es igual",
      BeginWith: "Empieza con",
      DoesNotBeginWith: "No comienza con",
      EndsWith: "Termina con",
      DoesNotEndsWith: "No termina con",
      Contains: "Contiene",
      DoesNotContains: "No contiene",
      GreaterThan: "Mas grande que",
      GreaterThanOrEqualTo: "Mayor qué o igual a",
      LessThan: "Menos que",
      LessThanOrEqualTo: "Menos que o igual a",
      Between: "Entre",
      NotBetween: "No entre",
      And: "y",
      Sum: "Suma",
      Count: "Contar",
      DistinctCount: "Recuento distinto",
      Product: "Producto",
      Avg: "Promedio",
      Min: "mínimo",
      SampleVar: "Var de muestra",
      PopulationVar: "Var de población",
      RunningTotals: "Totales acumulados",
      Max: "máx.",
      Index: "Índice",
      SampleStDev: "Muestra StDev",
      PopulationStDev: "Desv.población",
      PercentageOfRowTotal: "% del total de la fila",
      PercentageOfParentTotal: "% del total de padres",
      PercentageOfParentColumnTotal: "% del total de la columna principal",
      PercentageOfParentRowTotal: "% del total de la fila principal",
      DifferenceFrom: "Diferencia de",
      PercentageOfDifferenceFrom: "% de diferencia de",
      PercentageOfGrandTotal: "% del total general",
      PercentageOfColumnTotal: "% del total de la columna",
      NotEquals: "No es igual",
      AllValues: "Todos los valores",
      conditionalFormatting: "Formato condicional",
      apply: "SOLICITAR",
      condition: "Agregar condición",
      formatLabel: "Formato",
      valueFieldSettings: "Configuración del campo de valor",
      baseField: "Campo base:",
      baseItem: "Elemento base:",
      summarizeValuesBy: "Resuma los valores por:",
      sourceName: "Nombre del campo :",
      sourceCaption: "Título de campo:",
      example: "p.ej:",
      editorDataLimitMsg: " mas cosas. Busque para refinar aún más.",
      details: "Detalles",
      manageRecords: "Administrar registros",
      Years: "Años",
      Quarters: "Cuarteles",
      Months: "Meses",
      Days: "Dias",
      Hours: "Horas",
      Minutes: "Minutos",
      Seconds: "Segundos",
      save: "Guardar un informe",
      new: "Crear un informe nuevo",
      load: "Carga",
      saveAs: "Guardar como informe actual",
      rename: "Cambiar el nombre de un informe actual",
      deleteReport: "Eliminar un informe actual",
      export: "Exportar",
      subTotals: "Sub totales",
      grandTotals: "Grandes totales",
      reportName: "Reportar nombre :",
      pdf: "PDF",
      excel: "Sobresalir",
      csv: "CSV",
      png: "PNG",
      jpeg: "JPEG",
      svg: "SVG",
      mdxQuery: "Consulta MDX",
      showSubTotals: "Mostrar subtotales",
      doNotShowSubTotals: "No mostrar subtotales",
      showSubTotalsRowsOnly: "Mostrar subtotales solo en filas",
      showSubTotalsColumnsOnly: "Mostrar solo columnas de subtotales",
      showGrandTotals: "Mostrar totales generales",
      doNotShowGrandTotals: "No mostrar totales generales",
      showGrandTotalsRowsOnly: "Mostrar solo filas de totales generales",
      showGrandTotalsColumnsOnly: "Mostrar solo columnas de totales generales",
      fieldList: "Mostrar lista de campos",
      grid: "Mostrar tabla",
      toolbarFormatting: "Formato condicional",
      chart: "Gráfico",
      reportMsg: "Introduzca un nombre de informe válido.",
      reportList: "Lista de informes",
      removeConfirm: "¿Está seguro de que desea eliminar este informe?",
      emptyReport: "¡No se encontraron informes!",
      bar: "Bar",
      line: "Línea",
      area: "Zona",
      scatter: "Dispersión",
      polar: "Polar",
      of: "de",
      emptyFormat: "¡No se ha encontrado ningún formato!",
      emptyInput: "Ingrese un valor",
      newReportConfirm: "¿Quiere guardar los cambios para informar?",
      emptyReportName: "Ingrese un nombre de informe",
      qtr: "Trimestre",
      null: "nulo",
      undefined: "indefinido",
      groupOutOfRange: "Fuera de rango",
      fieldDropErrorAction:
        "El campo que está moviendo no se puede colocar en esa área del informe",
      MoreOption: "Más...",
      aggregate: "Agregar",
      drillThrough: "Perforar a través",
      ascending: "Ascendente",
      descending: "Descendiendo",
      number: "Número",
      currency: "Divisa",
      percentage: "Porcentaje",
      formatType: "Tipo de formato",
      customText: "Símbolo de moneda",
      symbolPosition: "Posición del símbolo",
      left: "Izquierda",
      right: "Derecha",
      grouping: "Agrupamiento",
      true: "Cierto",
      false: "Falso",
      decimalPlaces: "Lugares decimales",
      numberFormat: "Formato de número",
      memberType: "Tipo de campo",
      formatString: "Cadena de formato",
      expressionField: "Expresión",
      customFormat: "Ingrese una cadena de formato personalizado",
      selectedHierarchy: "Jerarquía de padres",
      olapDropText:
        "Ejemplo: [Medidas]. [Cantidad de pedido] + ([Medidas]. [Cantidad de pedido] * 0.10)",
      Percent: "Por ciento",
      Custom: "Personalizado",
      Measure: "La medida",
      Dimension: "Dimensión",
      Standard: "Estándar",
      blank: "(Blanco)",
      fieldTooltip:
        "Arrastre y suelte campos para crear una expresión. ¡Y, si desea editar los campos calculados existentes! Entonces puede lograrlo simplemente seleccionando el campo debajo de 'Miembros calculados'.",
      QuarterYear: "Trimestre del año",
      fieldTitle: "Nombre del campo",
      drillError:
        "No se pueden mostrar los elementos sin procesar de los campos calculados.",
      caption: "Título de campo",
      copy: "Dupdo",
      defaultReport: "Informe predeterminado",
      customFormatString: "Formato personalizado",
      invalidFormat: "Formato inválido.",
      group: "Grupo",
      unGroup: "Desagrupar",
      invalidSelection: "No se puede agrupar esa selección.",
      groupName: "Ingrese el título para mostrar en el encabezado",
      captionName: "Ingrese el título para el campo del grupo",
      selectedItems: "Elementos seleccionados",
      groupFieldCaption: "Título de campo",
      groupTitle: "Nombre del grupo",
      startAt: "A partir de",
      endAt: "Terminando en",
      groupBy: "Intervalo por",
      selectGroup: "Seleccionar grupos",
      numberFormatString: "Ejemplo: C, P, 0000%, ### 0. ## 0 #, etc.",
      stackingcolumn: "Columna apilada",
      stackingbar: "Barra apilada",
      stackingarea: "Área apilada",
      stepline: "Línea de paso",
      steparea: "Área de paso",
      splinearea: "Área de spline",
      spline: "Ranura",
      stackingcolumn100: "Columna 100% apilada",
      stackingbar100: "Barra 100% apilada",
      stackingarea100: "Área 100% apilada",
      bubble: "burbuja",
      pareto: "Pareto",
      radar: "Radar",
      chartTypeSettings: "Configuración de tipo de gráfico",
      multipleAxes: "Múltiples ejes",
      sortAscending: "Ordenar en orden ascendente",
      sortDescending: "Ordenar en orden descendente",
      sortNone: "Ordenar los datos",
      clearCalculatedField: "Borrar información de campo editada",
      editCalculatedField: "Editar campo calculado",
      ChartType: "Tipo de gráfico",
      yes: "sí",
      no: "No",
      numberFormatMenu: "Formato de números ...",
      conditionalFormattingMenu: "Formato condicional...",
      removeCalculatedField:
        "¿Está seguro de que desea eliminar este campo calculado?",
      replaceConfirmBefore: "Un informe llamado",
      replaceConfirmAfter: " ya existe. ¿Quieres cambiarlo?",
      pie: "Tarta",
      funnel: "Embudo",
      doughnut: "Rosquilla",
      pyramid: "Pirámide",
      showLegend: "Mostrar leyenda",
      exit: "Salida",
      invalidJSON: "Datos JSON no válidos",
      invalidCSV: "Datos CSV no válidos",
      stacked: "Apilado",
      single: "Soltero",
      multipleAxisMode: "Modo de múltiples ejes",
      grandTotalPosition: "Posición de los totales generales",
      top: "Cima",
      bottom: "Abajo",
      None: "Ninguna",
      stackingline: "línea apilada",
      stackingline100: "100% línea apilada",
      rowPage: "Localizador de filas",
      rowPerPage: "Filas por página",
      columnPage: "Localizador de columnas",
      columnPerPage: "Columnas por página",
      goToFirstPage: "Ir a la primera página",
      goToPreviousPage: "Regresar a la pagina anterior",
      goToNextPage: "Ir a la página siguiente",
      goToLastPage: "Ir a la última página",
      combined: "Conjunto",
      subTotalPosition: "Posición de subtotales",
      auto: "Auto",
      loading: "Cargando...",
      add: "Agregar",
    },
    pivotfieldlist: {
      staticFieldList: "Lista de campos dinámicos",
      fieldList: "Lista de campo",
      dropFilterPrompt: "Suelta el filtro aquí",
      dropColPrompt: "Suelta la columna aquí",
      dropRowPrompt: "Suelta la fila aquí",
      dropValPrompt: "Suelta el valor aquí",
      addPrompt: "Agregar campo aquí",
      adaptiveFieldHeader: "Elegir campo",
      centerHeader: "Arrastre los campos entre los ejes a continuación:",
      add: "agregar",
      drag: "Arrastrar",
      filter: "Filtrar",
      filtered: "Filtrado",
      sort: "Clasificar",
      remove: "Eliminar",
      filters: "Filtros",
      rows: "Filas",
      columns: "Columnas",
      values: "Valores",
      CalculatedField: "Campo calculado",
      createCalculatedField: "Crear campo calculado",
      fieldName: "Ingrese el nombre del campo",
      error: "Error",
      invalidFormula: "Fórmula no válida.",
      dropText: "Ejemplo: ('Sum (Order_Count)' + 'Sum (In_Stock)' ') * 250",
      dropTextMobile: "Agregue campos y edite la fórmula aquí.",
      dropAction:
        "El campo calculado no se puede colocar en ninguna otra región excepto en el eje de valores.",
      search: "Buscar",
      close: "Cerrar",
      cancel: "Cancelar",
      delete: "Borrar",
      alert: "Alerta",
      warning: "Advertencia",
      ok: "DE ACUERDO",
      allFields: "Todos los campos",
      formula: "Fórmula",
      fieldExist:
        "Ya existe un campo con este nombre. Ingrese un nombre diferente.",
      confirmText:
        "Ya existe un campo de cálculo con este nombre. ¿Quieres cambiarlo?",
      noMatches: "No hay coincidencias",
      format: "Valores de resúmenes por",
      edit: "Editar",
      clear: "Claro",
      formulaField: "Arrastre y suelte campos a la fórmula",
      dragField: "Arrastra el campo a la fórmula",
      clearFilter: "Claro",
      by: "por",
      enterValue: "Ingrese valor",
      chooseDate: "Ingrese la fecha",
      all: "Todos",
      multipleItems: "Varios elementos",
      Equals: "Igual",
      DoesNotEquals: "No es igual",
      BeginWith: "Empieza con",
      DoesNotBeginWith: "No comienza con",
      EndsWith: "Termina con",
      DoesNotEndsWith: "No termina con",
      Contains: "Contiene",
      DoesNotContains: "No contiene",
      GreaterThan: "Mas grande que",
      GreaterThanOrEqualTo: "Mayor qué o igual a",
      LessThan: "Menos que",
      LessThanOrEqualTo: "Menos que o igual a",
      Between: "Entre",
      NotBetween: "No entre",
      Before: "Antes",
      BeforeOrEqualTo: "Antes o igual a",
      After: "Después",
      AfterOrEqualTo: "Después o igual a",
      member: "Miembro",
      label: "Etiqueta",
      date: "Fecha",
      value: "Valor",
      labelTextContent: "Muestre los items para los que la etiqueta",
      dateTextContent: "Muestre los elementos para los que la fecha",
      valueTextContent: "Muestre los elementos para los que",
      And: "y",
      Sum: "Suma",
      Count: "Contar",
      DistinctCount: "Recuento distinto",
      Product: "Producto",
      Avg: "Promedio",
      Min: "mínimo",
      Max: "máx.",
      Index: "Índice",
      SampleStDev: "Muestra StDev",
      PopulationStDev: "Desv.población",
      SampleVar: "Var de muestra",
      PopulationVar: "Var de población",
      RunningTotals: "Totales acumulados",
      DifferenceFrom: "Diferencia de",
      PercentageOfDifferenceFrom: "% de diferencia de",
      PercentageOfGrandTotal: "% del total general",
      PercentageOfColumnTotal: "% del total de la columna",
      PercentageOfRowTotal: "% del total de la fila",
      PercentageOfParentTotal: "% del total de padres",
      PercentageOfParentColumnTotal: "% del total de la columna principal",
      PercentageOfParentRowTotal: "% del total de la fila principal",
      Years: "Años",
      Quarters: "Cuarteles",
      Months: "Meses",
      Days: "Dias",
      Hours: "Horas",
      Minutes: "Minutos",
      Seconds: "Segundos",
      apply: "SOLICITAR",
      valueFieldSettings: "Configuración del campo de valor",
      sourceName: "Nombre del campo :",
      sourceCaption: "Título de campo:",
      summarizeValuesBy: "Resuma los valores por:",
      baseField: "Campo base:",
      baseItem: "Elemento base:",
      example: "p.ej:",
      editorDataLimitMsg: " mas cosas. Busque para refinar aún más.",
      deferLayoutUpdate: "Aplazar la actualización del diseño",
      null: "nulo",
      undefined: "indefinido",
      groupOutOfRange: "Fuera de rango",
      fieldDropErrorAction:
        "El campo que está moviendo no se puede colocar en esa área del informe",
      MoreOption: "Más...",
      memberType: "Tipo de campo",
      selectedHierarchy: "Jerarquía de padres",
      formatString: "Cadena de formato",
      expressionField: "Expresión",
      olapDropText:
        "Ejemplo: [Medidas]. [Cantidad de pedido] + ([Medidas]. [Cantidad de pedido] * 0.10)",
      customFormat: "Ingrese una cadena de formato personalizado",
      Measure: "La medida",
      Dimension: "Dimensión",
      Standard: "Estándar",
      Currency: "Divisa",
      Percent: "Por ciento",
      Custom: "Personalizado",
      blank: "(Blanco)",
      fieldTooltip:
        "Arrastre y suelte campos para crear una expresión. ¡Y, si desea editar los campos calculados existentes! Puede lograrlo simplemente seleccionando el campo bajo 'Miembros calculados'.",
      fieldTitle: "Nombre del campo",
      QuarterYear: "Trimestre del año",
      caption: "Título de campo",
      copy: "Dupdo",
      group: "Grupo",
      numberFormatString: "Ejemplo: C, P, 0000%, ### 0. ## 0 #, etc.",
      sortAscending: "Ordenar en orden ascendente",
      sortDescending: "Ordenar en orden descendente",
      sortNone: "Ordenar los datos",
      clearCalculatedField: "Borrar información de campo editada",
      editCalculatedField: "Editar campo calculado",
      selectGroup: "Seleccionar grupos",
      of: "de",
      removeCalculatedField:
        "¿Está seguro de que desea eliminar este campo calculado?",
      yes: "si",
      no: "No",
      None: "Ninguna",
    },
    pdfviewer: {
      PdfViewer: "Visor de PDF",
      Cancel: "Cancelar",
      "Download file": "Descargar archivo",
      Download: "Descargar",
      "Enter Password":
        "Este documento está protegido por contraseña. Porfavor ingrese una contraseña.",
      "File Corrupted": "Archivo corrupto",
      "File Corrupted Content": "El archivo está dañado y no se puede abrir.",
      "Fit Page": "La página de ajuste",
      "Fit Width": "Ajuste ancho",
      Automatic: "Automático",
      "Go To First Page": "Mostrar primera página",
      "Invalid Password": "Contraseña incorrecta. Inténtalo de nuevo.",
      "Next Page": "Mostrar página siguiente",
      OK: "DE ACUERDO",
      Open: "Abrir documento",
      "Page Number": "Número de página actual",
      "Previous Page": "Mostrar página anterior",
      "Go To Last Page": "Mostrar la última página",
      Zoom: "Enfocar",
      "Zoom In": "Acercarse",
      "Zoom Out": "Disminuir el zoom",
      "Page Thumbnails": "Miniaturas de página",
      Bookmarks: "Marcadores",
      Print: "Imprimir archivo",
      "Password Protected": "Se requiere contraseña",
      Copy: "Copiar",
      "Text Selection": "Herramienta de selección de texto",
      Panning: "Modo panorámico",
      "Text Search": "Buscar texto",
      "Find in document": "Encuentra en el documento",
      "Match case": "Caso de partido",
      Apply: "Aplicar",
      GoToPage: "Ir a la página",
      "No matches":
        "El visor ha terminado de buscar el documento. No se encontraron más coincidencias.",
      "No Text Found": "No se encontró texto",
      Undo: "Deshacer",
      Redo: "Rehacer",
      Annotation: "Agregar o editar anotaciones",
      Highlight: "Subrayar el texto",
      Underline: "Subrayar texto",
      Strikethrough: "Texto tachado",
      Delete: "Eliminar anotación",
      Opacity: "Opacidad",
      "Color edit": "Cambiar el color",
      "Opacity edit": "Cambiar opacidad",
      "Highlight context": "Realce",
      "Underline context": "Subrayar",
      "Strikethrough context": "Penetrar",
      "Server error":
        "El servicio web no está escuchando. Visor de PDFr depende del servicio web para todas sus funciones. Inicie el servicio web para continuar.",
      "Open text": "Abrir",
      "First text": "Primera página",
      "Previous text": "Pagina anterior",
      "Next text": "Siguiente página",
      "Last text": "Última página",
      "Zoom in text": "Acercarse",
      "Zoom out text": "Disminuir el zoom",
      "Selection text": "Selección",
      "Pan text": "Cacerola",
      "Print text": "Impresión",
      "Search text": "Buscar",
      "Annotation Edit text": "Editar anotación",
      "Line Thickness": "Grosor de la línea",
      "Line Properties": "Propiedades de linea",
      "Start Arrow": "Flecha de inicio",
      "End Arrow": "Flecha final",
      "Line Style": "Estilo de línea",
      "Fill Color": "Color de relleno",
      "Line Color": "Color de linea",
      None: "Ninguna",
      "Open Arrow": "Abrir",
      "Closed Arrow": "Cerrado",
      "Round Arrow": "Redondo",
      "Square Arrow": "Cuadrado",
      "Diamond Arrow": "Diamante",
      Butt: "Extremo",
      Cut: "Cortar",
      Paste: "Pegar",
      "Delete Context": "Eliminar",
      Properties: "Propiedades",
      "Add Stamp": "Agregar sello",
      "Add Shapes": "Agregar formas",
      "Stroke edit": "Cambiar color de trazo",
      "Change thickness": "Cambiar grosor del borde",
      "Add line": "Añadir línea",
      "Add arrow": "Agregar flecha",
      "Add rectangle": "Añadir rectángulo",
      "Add circle": "Agregar círculo",
      "Add polygon": "Agregar polígono",
      "Add Comments": "Añadir comentarios",
      Comments: "Comentarios",
      "No Comments Yet": "Sin comentarios aún",
      Accepted: "Aceptado",
      Completed: "Terminado",
      Cancelled: "Cancelado",
      Rejected: "Rechazado",
      "Leader Length": "Longitud del líder",
      "Scale Ratio": "Ratio de escala",
      Calibrate: "Calibrar",
      "Calibrate Distance": "Calibrar distancia",
      "Calibrate Perimeter": "Calibrar perímetro",
      "Calibrate Area": "Área de calibración",
      "Calibrate Radius": "Calibrar radio",
      "Calibrate Volume": "Calibrar volumen",
      highlight: "Destacar",
      underline: "Subrayar",
      strikethrough: "Penetrar",
      "Seach text": "Búsqueda",
      FormDesigner: "Agregar y editar campos de formulario",
      "Client error":
        "Se encuentra un error del lado del cliente. Verifique los encabezados personalizados proporcionados en la propiedad AjaxRequestSettings y los métodos de acción web en la propiedad ServerActionSettings.",
      "FormDesigner Edit text": "Agregar y editar campos de formulario",
      SubmitForm: "Enviar formulario",
      Depth: "Profundidad",
      Closed: "Cerrada",
      Round: "Ronda",
      Square: "Cuadrado",
      Diamond: "Diamante",
      Edit: "Editar",
      Comment: "Comentario",
      "Comment Panel": "Panel de comentarios",
      "Set Status": "Establecer estado",
      Post: "Correo",
      Page: "Página",
      "Add a comment": "Añadir un comentario",
      "Add a reply": "Agregar una respuesta",
      "Import Annotations": "Importar anotaciones desde un archivo JSON",
      "Export Annotations": "Exportar anotación a archivo JSON",
      "Export XFDF": "Exportar anotación a archivo XFDF",
      "Import XFDF": "Importar anotaciones desde un archivo XFDF",
      Add: "Agregar",
      Clear: "Claro",
      Bold: "Negrito",
      Italic: "Itálico",
      Strikethroughs: "Tachado",
      Underlines: "Subrayar",
      Superscript: "Sobrescrito",
      Subscript: "Subíndice",
      "Align left": "Alinear a la izquierda",
      "Align right": "Alinear a la derecha",
      Center: "Centrar",
      Justify: "Justificar",
      "Font color": "Color de fuente",
      "Text Align": "Texto alineado",
      "Text Properties": "Estilo de fuente",
      SignatureFieldDialogHeaderText: "Agregar firma",
      HandwrittenSignatureDialogHeaderText: "Agregar firma",
      InitialFieldDialogHeaderText: "Agregar inicial",
      HandwrittenInitialDialogHeaderText: "Agregar inicial",
      "Draw Ink": "Dibujar tinta",
      Create: "Crear",
      "Font family": "Familia tipográfica",
      "Font size": "Tamaño de fuente",
      "Free Text": "Texto libre",
      "Import Failed":
        "Tipo de archivo JSON o nombre de archivo no válido; seleccione un archivo JSON válido",
      "Import PDF Failed":
        "Tipo de archivo PDF invalido o archivo PDF no encontrado. Por favor, selecciona un archivo PDF valido.",
      "File not found":
        "El archivo JSON importado no se encuentra en la ubicación deseada",
      "Export Failed":
        "La acción de exportación de anotaciones ha fallado; asegúrese de que las anotaciones se agreguen correctamente",
      of: "de ",
      Dynamic: "Dinámico",
      "Standard Business": "Negocio estándar",
      "Sign Here": "Firma aqui",
      "Custom Stamp": "Sello personalizado",
      "Enter Signature as Name": "Introduzca su nombre",
      "Draw-hand Signature": "DIBUJAR",
      "Type Signature": "TIPO",
      "Upload Signature": "SUBIR",
      "Browse Signature Image": "NAVEGAR",
      "Save Signature": "Guardar firma",
      "Save Initial": "Guardar inicial",
      Textbox: "Caja de texto",
      Password: "Clave",
      "Check Box": "Caja",
      "Radio Button": "Boton de radio",
      Dropdown: "Desplegable",
      "List Box": "Cuadro de lista",
      Signature: "Firma",
      "Delete FormField": "Eliminar campo de formulario",
      "Textbox Properties": "Propiedades del cuadro de texto",
      Name: "Nombre",
      Tooltip: "Información sobre herramientas",
      Value: "Valor",
      "Form Field Visibility": "Visibilidad de campo de formulario",
      "Read Only": "Solo lectura",
      Required: "Requerida",
      Checked: "Comprobada",
      "Show Printing": "Mostrar impresión",
      Formatting: "Formato",
      Fill: "Llenar",
      Border: "Borde",
      "Border Color": "Color del borde",
      Thickness: "Espesor",
      "Max Length": "Longitud máxima",
      "List Item": "Nombre del árticulo",
      "Export Value": "Valor del ítem",
      "Dropdown Item List": "Lista desplegable de elementos",
      "List Box Item List": "Lista de elementos del cuadro de lista",
      General: "GENERAL",
      Appearance: "APARIENCIA",
      Options: "OPCIONES",
      "Delete Item": "Borrar",
      Up: "Arriba",
      Down: "Abajo",
      Multiline: "multilínea",
      Revised: "Revisada",
      Reviewed: "Revisadas",
      Received: "Recibida",
      Confidential: "Confidencial",
      Approved: "Aprobada",
      "Not Approved": "No aprovado",
      Witness: "Testigo",
      "Initial Here": "Iniciales aqui",
      Draft: "Reclutar",
      Final: "Final",
      "For Public Release": "Para publicación pública",
      "Not For Public Release": "No para publicación pública",
      "For Comment": "Para comentarios",
      Void: "Vacía",
      "Preliminary Results": "Resultados preliminares",
      "Information Only": "Sólo información",
      in: "en",
      m: "metro",
      ft_in: "ft_in",
      ft: "pie",
      p: "pags",
      cm: "cm",
      mm: "milímetro",
      pt: "punto",
      cu: "cu",
      sq: "cuadrados",
      Initial: "Inicial",
    },
    diagram: {
      Copy: "Copiar",
      Cut: "Cortar",
      Paste: "Pegar",
      Undo: "Deshacer",
      Redo: "Rehacer",
      SelectAll: "Seleccionar todo",
      Grouping: "Agrupamiento",
      Group: "Grupo",
      UnGroup: "Desagrupar",
      Order: "Orden",
      BringToFront: "Traer al frente",
      MoveForward: "Avanzar",
      SendToBack: "Enviar al fondo",
      SendBackward: "Enviar atrás",
    },
    "inplace-editor": {
      save: "Guardar",
      cancel: "Cancelar",
      loadingText: "Cargando...",
      editIcon: "Haz click para editar",
      editAreaClick: "Haz click para editar",
      editAreaDoubleClick: "Doble click para editar",
    },
    drawing: {},
    "drop-down-base": {
      noRecordsTemplate: "No se encontraron registros",
      actionFailureTemplate: "La solicitud falló",
    },
    chart: {
      Zoom: "Enfocar",
      ZoomIn: "Acercarse",
      ZoomOut: "Disminuir el zoom",
      Reset: "Reiniciar",
      Pan: "Cacerola",
      ResetZoom: "Restablecer zoom",
    },
    maps: {
      Zoom: "Enfocar",
      ZoomIn: "Acercarse",
      ZoomOut: "Disminuir el zoom",
      Reset: "Reiniciar",
      Pan: "Cacerola",
      ResetZoom: "Restablecer zoom",
      ImageNotFound: "Imagen no encontrada",
    },
    "drop-down-tree": {
      noRecordsTemplate: "No se encontraron registros",
      actionFailureTemplate: "Solicitud fallida",
      overflowCountTemplate: "+ ${count} más ...",
      totalCountTemplate: "${count} seleccionados",
    },
    documenteditor: {
      Table: "Mesa",
      Row: "Fila",
      Cell: "Célula",
      Ok: "De acuerdo",
      Apply: "Progresivo",
      Cancel: "Cancelar",
      Size: "Tamaño",
      "Preferred Width": "Ancho preferido",
      Points: "Puntos",
      Percent: "Por ciento",
      "Measure in": "Medir en",
      Alignment: "Alineación",
      Left: "Izquierda",
      Center: "Centrar",
      Right: "Derecho",
      Decimal: "Decimal",
      Bar: "Barricada",
      Leader: "Gerente",
      Tab: "Facturas sin pagar",
      BookMarkList: "Una lista de marcadores en un documento.",
      TabMarkList: "Una lista de tabulaciones en un párrafo.",
      "Tab stop position": "Posición de tabulación",
      "Default tab stops": "Mapa estándar",
      "Tab stops to be cleared": "Tarjetas para borrar",
      Tabs: "ventana de pestaña",
      Set: "reclutamiento",
      Clear: "Transparente",
      "Clear All": "Elimina todo",
      Justify: "Justificar",
      "Indent from left": "Sangría desde la izquierda",
      "Borders and Shading": "Bordes y sombreado",
      Options: "Opciones",
      "Specify height": "Especificar altura",
      "At least": "Al menos",
      Exactly: "Exactamente",
      "Row height is": "La altura de la fila es",
      "Allow row to break across pages":
        "Permitir que la fila se rompa entre páginas",
      "Repeat as header row at the top of each page":
        "Repita como fila de encabezado en la parte superior de cada página",
      "Vertical alignment": "Alineamiento vertical",
      Top: "Parte superior",
      Bottom: "Fondo",
      "Default cell margins": "Márgenes de celda predeterminados",
      "Default cell spacing": "Espaciado de celda predeterminado",
      "Allow spacing between cells": "Permitir espacio entre celdas",
      "Cell margins": "Márgenes celulares",
      "Same as the whole table": "Igual que toda la mesa",
      Borders: "Bordes",
      None: "Ninguna",
      Style: "Estilo",
      Width: "Anchura",
      Height: "Altura",
      Letter: "Letra",
      Tabloid: "Tabloide",
      Legal: "Legal",
      Statement: "Declaración",
      Executive: "Ejecutivo",
      A3: "A3",
      A4: "A4",
      A5: "A5",
      B4: "B4",
      B5: "B5",
      "Custom Size": "Tamaño personalizado",
      "Different odd and even": "Diferentes pares e impares",
      "Different first page": "Primera página diferente",
      "From edge": "Desde el borde",
      Header: "Encabezamiento",
      Footer: "Pie de página",
      "First Page Header": "Encabezado de la primera página",
      "First Page Footer": "Pie de página de la primera página",
      "Even Page Header": "Encabezado de página par",
      "Even Page Footer": "Pie de página par",
      "Odd Page Header": "Encabezado de página impar",
      "Odd Page Footer": "Pie de página impar",
      "Same as Previous": "Igual que el anterior",
      Section: "Sección",
      Margin: "Márgenes",
      Paper: "Papel",
      Layout: "Diseño",
      Orientation: "Orientación",
      Landscape: "Paisaje",
      Portrait: "Retrato",
      "Show page numbers": "Mostrar números de página",
      "Right align page numbers": "Alinear a la derecha los números de página",
      Nothing: "Nada",
      "Tab leader": "Tabulador",
      "Show levels": "Mostrar niveles",
      "Use hyperlinks instead of page numbers":
        "Use hipervínculos en lugar de números de página",
      "Build table of contents from": "Crear tabla de contenido a partir de",
      Styles: "Estilos",
      "Available styles": "Estilos disponibles",
      "TOC level": "Nivel de TOC",
      Heading: "Título",
      "Heading 1": "Título 1",
      "Heading 2": "Título 2",
      "Heading 3": "Título 3",
      "Heading 4": "Título 4",
      "Heading 5": "Título 5",
      "Heading 6": "Título 6",
      "List Paragraph": "Párrafo de lista",
      Normal: "Normal",
      "Outline levels": "Niveles de esquema",
      "Table entry fields": "Campos de entrada de tabla",
      Modify: "Modificar",
      Color: "Color",
      Setting: "Ajuste",
      Box: "Caja",
      All: "Todos",
      Custom: "Personalizado",
      Preview: "Avance",
      Shading: "Sombreado",
      Fill: "Llenar",
      "Apply To": "Aplicar para",
      "Table Properties": "Propiedades de tabla",
      "Cell Options": "Opciones de celda",
      "Table Options": "Opciones de tabla",
      "Insert Table": "Insertar tabla",
      "Number of columns": "Número de columnas",
      "Number of rows": "Número de filas",
      "Text to display": "Texto a mostrar",
      "ScreenTip text": "Texto de información en pantalla",
      Address: "Habla a",
      "Insert Hyperlink": "Insertar hipervínculo",
      "Edit Hyperlink": "Editar hipervínculo",
      Insert: "Insertar",
      General: "General",
      Indentation: "Sangría",
      "Before text": "Antes del texto",
      Special: "Especial",
      "First line": "Primera linea",
      Hanging: "Colgando",
      "After text": "Después del texto",
      By: "Por",
      Before: "antes de",
      "Line Spacing": "Espaciado entre líneas",
      After: "Después",
      At: "A",
      Multiple: "Múltiple",
      Spacing: "Espaciado",
      "Define new Multilevel list": "Definir nueva lista multinivel",
      "List level": "Nivel de lista",
      "Choose level to modify": "Elija el nivel para modificar",
      Level: "Nivel",
      "Number format": "Formato numérico",
      "Number style for this level": "Estilo numérico para este nivel",
      "Enter formatting for number": "Ingrese el formato para el número",
      "Start at": "Empieza en",
      "Restart list after": "Reiniciar lista después",
      Position: "Posición",
      "Text indent at": "Sangría de texto en",
      "Aligned at": "Alineado a las",
      "Follow number with": "Seguir número con",
      "Tab character": "Carácter de tabulación",
      Space: "Espacio",
      Arabic: "Arábica",
      UpRoman: "UpRoman",
      LowRoman: "bajoromano",
      UpLetter: "UpLetter",
      LowLetter: "Letra baja",
      Number: "Número",
      "Leading zero": "Cero a la izquierda",
      Bullet: "Bala",
      Ordinal: "Ordinal",
      "Ordinal Text": "Texto ordinal",
      "For East": "Para el este",
      "No Restart": "No reiniciar",
      Font: "Fuente",
      "Font style": "Estilo de fuente",
      "Underline style": "Estilo subrayado",
      "Font color": "Color de fuente",
      Effects: "Efectos",
      Strikethrough: "Tachado",
      Superscript: "Sobrescrito",
      Subscript: "Subíndice",
      "Double strikethrough": "Tachado doble",
      Regular: "Regular",
      Bold: "Negrita",
      Italic: "Itálico",
      Cut: "Cortar",
      Copy: "Copiar",
      Paste: "Pegar",
      Hyperlink: "Hipervínculo",
      "Open Hyperlink": "Abrir hipervínculo",
      "Copy Hyperlink": "Copiar hipervínculo",
      "Remove Hyperlink": "Eliminar hipervínculo",
      Paragraph: "Párrafo",
      "Linked Style": "Vinculado (párrafo y personaje)",
      Character: "Personaje",
      "Merge Cells": "Combinar células",
      "Insert Above": "Insertar arriba",
      "Insert Below": "Insertar a continuación",
      "Insert Left": "Insertar a la izquierda",
      "Insert Right": "Insertar a la derecha",
      Delete: "Eliminar",
      "Delete Table": "Eliminar tabla",
      "Delete Row": "Borrar fila",
      "Delete Column": "Eliminar columna",
      "File Name": "Nombre del archivo",
      "Format Type": "Tipo de formato",
      Save: "Guardar",
      Navigation: "Navegación",
      Results: "Resultados",
      Replace: "Reemplazar",
      "Replace All": "Reemplaza todo",
      "We replaced all": "Reemplazamos todo",
      Find: "Encontrar",
      "No matches": "No hay coincidencias",
      "All Done": "Todo listo",
      Result: "Resultado",
      of: "de",
      instances: "instancias",
      with: "con",
      "Click to follow link": "Haga clic para seguir el enlace",
      "Continue Numbering": "Continuar numerando",
      "Bookmark name": "Nombre del marcador",
      Close: "Cerrar",
      "Restart At": "Reiniciar en",
      Properties: "Propiedades",
      Name: "Nombre",
      "Style type": "Tipo de estilo",
      "Style based on": "Estilo basado en",
      "Style for following paragraph": "Estilo para el siguiente párrafo",
      Formatting: "Formateo",
      "Numbering and Bullets": "Numeración y viñetas",
      Numbering: "Numeración",
      "Update Field": "Campo de actualización",
      "Edit Field": "Editar campo",
      Bookmark: "Marcador",
      "Page Setup": "Configurar página",
      "No bookmarks found": "No se encontraron marcadores",
      "Number format tooltip information":
        "Formato de número de nivel único: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Por ejemplo, 'Capítulo% 1'. mostrará numeración como </br> Capítulo 1. Elemento </br> Capítulo 2. Elemento </br>… </br> Capítulo N. Elemento </br> </br> Formato de número multinivel: </br> [ PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Por ejemplo, '% 1.% 2.' mostrará numeración como </br> 1.1. item </br> 1.2. item </br> ... </br> 1.N. item",
      Format: "Formato",
      "Create New Style": "Crear nuevo estilo",
      "Modify Style": "Modificar estilo",
      New: "Nuevo",
      Bullets: "Balas",
      "Use bookmarks": "Usar marcadores",
      "Table of Contents": "Tabla de contenido",
      AutoFit: "Autoajuste",
      "AutoFit to Contents": "Autoajuste al contenido",
      "AutoFit to Window": "Autoajustar a la ventana",
      "Fixed Column Width": "Ancho de columna fijo",
      Reset: "Reiniciar",
      "Match case": "Caso de partido",
      "Whole words": "Palabras completas",
      Add: "Añadir",
      "Go To": "Ir",
      "Search for": "Buscar",
      "Replace with": "Reemplazar con",
      "TOC 1": "TOC 1",
      "TOC 2": "TOC 2",
      "TOC 3": "TOC 3",
      "TOC 4": "TOC 4",
      "TOC 5": "TOC 5",
      "TOC 6": "TOC 6",
      "TOC 7": "TOC 7",
      "TOC 8": "TOC 8",
      "TOC 9": "TOC 9",
      "Right-to-left": "De derecha a izquierda",
      "Left-to-right": "De izquierda a derecha",
      Direction: "Dirección",
      "Table direction": "Dirección de la mesa",
      "Indent from right": "Sangría desde la derecha",
      "Contextual Spacing":
        "No agregue espacio entre los párrafos de los mismos estilos.",
      "Password Mismatch": "La contraseña no coincide",
      "Restrict Editing": "Restringir edición",
      "Formatting restrictions": "Restricciones de formato",
      "Allow formatting": "Permitir formateo",
      "Editing restrictions": "Edición de restricciones",
      "Read only": "Solo lectura",
      "Exceptions Optional": "Excepciones (opcional)",
      "Select Part Of Document And User":
        "Seleccione partes del documento y elija los usuarios que pueden editarlos libremente.",
      Everyone: "Todo el mundo",
      "More users": "Más usuarios",
      "Add Users": "Agregar usuarios",
      "Enforcing Protection": "Sí, comience a aplicar la protección",
      "Start Enforcing Protection": "Comience a hacer cumplir la protección",
      "Enter User": "Ingresar usuario",
      Users: "Los usuarios",
      "Enter new password": "Ingrese nueva clave",
      "Reenter new password to confirm":
        "Vuelva a ingresar la nueva contraseña para confirmar",
      "Your permissions": "Sus permisos",
      "Protected Document":
        "Este documento está protegido contra la edición no intencional. Puede editar en esta región.",
      FormFieldsOnly: "Solo puede completar formularios en esta región.",
      CommentsOnly: "Solo puede insertar comentarios en esta región.",
      ReadOnlyProtection: "Puede editar en esta región.",
      "Stop Protection": "Detener la protección",
      Password: "Contraseña",
      "Spelling Editor": "Editor de ortografía",
      Spelling: "Ortografía",
      "Spell Check": "Corrector ortográfico",
      "Underline errors": "Subrayar errores",
      Ignore: "Ignorar",
      "Ignore All": "Ignora todo",
      "Add to Dictionary": "Añadir al diccionario",
      Change: "Cambio",
      "Change All": "Cambia todo",
      Suggestions: "Sugerencias",
      "The password is incorrect": "La contraseña es incorrecta",
      "Error in establishing connection with web server":
        "Error al establecer conexión con el servidor web",
      "Highlight the regions I can edit":
        "Destacar las regiones que puedo editar",
      "Show All Regions I Can Edit":
        "Mostrar todas las regiones que puedo editar",
      "Find Next Region I Can Edit":
        "Buscar la siguiente región que puedo editar",
      "Keep source formatting": "Mantener el formato de origen",
      "Match destination formatting": "Hacer coincidir el formato de destino",
      "Text only": "Solo texto",
      Comments: "Comentarios",
      "Type your comment": "Escribe tu comentario",
      Post: "Enviar",
      Reply: "Respuesta",
      "New Comment": "Nuevo comentario",
      Edit: "Editar",
      Resolve: "Resolver",
      Reopen: "Reabrir",
      "No comments in this document": "No hay comentarios en este documento.",
      more: "más",
      "Type your comment here": "escribe tu comentario aquí",
      "Next Comment": "Siguiente comentario",
      "Previous Comment": "Comentario anterior",
      "Un-posted comments": "Comentarios no publicados",
      "Discard Comment":
        "Comentarios agregados no publicados. Si continúa, ese comentario será descartado.",
      Discard: "Desechar",
      "No Headings": "¡No se encontró rumbo!",
      "Add Headings":
        "Este documento no tiene encabezados. Agregue encabezados e intente nuevamente.",
      "More Options": "Mas opciones",
      "Click to see this comment": "Haga clic para ver este comentario",
      "Drop Down Form Field": "Campo de formulario desplegable",
      "Drop-down items": "items desplegables",
      "Items in drop-down list": "items en la lista desplegable",
      ADD: "AÑADIR",
      REMOVE: "ELIMINAR",
      "Field settings": "Configuraciones de campo",
      Tooltip: "Información sobre herramientas",
      "Drop-down enabled": "Desplegable habilitado",
      "Check Box Form Field": "Campo de formulario de casilla de verificación",
      "Check box size": "Casilla de verificación",
      Auto: "Auto",
      "Default value": "Valor por defecto",
      "Not checked": "Sin revisar",
      Checked: "Comprobado",
      "Check box enabled": "Casilla de verificación habilitada",
      "Text Form Field": "Campo de formulario de texto",
      Type: "Tipo",
      "Default text": "Texto predeterminado",
      "Maximum length": "Longitud máxima",
      "Text format": "Formato de texto",
      "Fill-in enabled": "Relleno habilitado",
      "Default number": "Número predeterminado",
      "Default date": "Fecha predeterminada",
      "Date format": "Formato de fecha",
      "Merge Track":
        "Esta acción no se marcará como cambio. ¿Quieres continuar?",
      UnTrack: "No se puede rastrear",
      Accept: "Aceptar",
      Reject: "Rechazar",
      "Previous Changes": "Cambios previos",
      "Next Changes": "Próximos cambios",
      Inserted: "Insertado",
      Deleted: "Eliminado",
      "Move From": "Ir desde",
      "Move To": "Transferido a",
      Changes: "Cambios",
      "Accept all": "Aceptar todo",
      "Reject all": "Rechazar todo",
      "No changes": "Sin cambios",
      "Accept Changes": "Aceptar cambios",
      "Reject Changes": "Rechazar cambios",
      User: "Usuario",
      View: "Ver",
      Insertion: "Inserción",
      Deletion: "Supresión",
      "All caps": "Todas mayúsculas",
      "This region is locked by": "Esta región está bloqueada por",
      Lock: "Bloquear",
      Unlock: "desbloquear",
      "Already locked":
        "Otro usuario ya ha bloqueado la región seleccionada o parte de ella",
      "1, 2, 3, ...": "1, 2, 3, ...",
      "a, b, c, ...": "a B C, ...",
      "A, B, C, ...": "A B C, ...",
      "I, II, III, ...": "Yo, II, III, ...",
      "i, ii, iii, ...": "yo, ii, iii, ...",
      "Text Form": "Formulario de texto",
      "Check Box": "Casilla de verificación",
      DropDown: "Desplegable",
      "Form Fields": "Campos de formulario",
      "Fillin enabled": "Completar habilitado",
      "Multiple Comment": "Por favor publique su comentario",
      InsertFootnote: "Insertar nota al pie",
      InsertEndnote: "Insertar nota final",
      Footnote: "Nota",
      Endnote: "Nota final",
      "Notes Options": "Opciones de nota",
      "Click to View/Edit Footnote": "Haga clic para ver / editar nota al pie",
      "Click to View/Edit Endnote": "Haga clic para ver / editar nota final",
      InsertAsRows: "Insertar como nuevas filas",
      InsertAsColumns: "Insertar como nuevas columnas",
      OverwriteCells: "Sobrescribir celdas",
      NestTable: "Mesa nido",
      "No suggestions": "No hay sugerencias",
      "More Suggestion": "Más sugerencia",
      "Ignore Once": "Ignorar una vez",
      "Keep With Next": "Seguir con el siguiente",
      "Keep Lines Together": "Mantener líneas juntas",
      WidowControl: "Control de viudas/huérfanos",
      "Indents and Spacing": "Sangrías y espaciado",
      "Line and Page Breaks": "Saltos de línea y de página",
      Pagination: "Paginación",
      Single: "Soltero",
      DashSmallGap: "GuiónPequeñoGap",
      DashDot: "Guion punto",
      Double: "Doble",
      ThinThickSmallGap: "DelgadoGruesoPequeñoGap",
      ThickThinSmallGap: "GruesoDelgadoPequeñoGap",
      ThickThinMediumGap: "GruesoDelgadoMedioEspacio",
      ThickThinLargeGap: "GruesoDelgadoGrandeBrecha",
      SingleWavy: "Solo ondulado",
      DoubleWavy: "doble ondulado",
      Inset: "Recuadro",
      DashLargeGap: "GuiónLargeGap",
      Dot: "Punto",
      DashDotDot: "guiónpuntopunto",
      Triple: "Triple",
      ThinThickThinSmallGap: "DelgadoGruesoDelgadoPequeñoGap",
      ThinThickThinMediumGap: "DelgadoGruesoDelgadoMedioBrecha",
      ThinThickThinLargeGap: "DelgadoGruesoDelgadoGrandeBrecha",
      DashDotStroked: "GuiónPuntoAcariciado",
      Engrave3D: "Grabar3D",
      Thick: "Grueso",
      Outset: "Comienzo",
      Emboss3D: "Relieve 3D",
      ThinThickLargeGap: "DelgadoGesorGrandeEspacio",
      ThinThickMediumGap: "DelgadoGruesoMedioEspacio",
      "Number of rows must be between": "El número de filas debe estar entre",
      "Number of columns must be between":
        "El número de columnas debe estar entre",
      and: "y",
      Unlimited: "Ilimitado",
      "Regular text": "Texto normal",
      Date: "Fecha",
      Uppercase: "Mayúsculas",
      Lowercase: "Minúsculas",
      FirstCapital: "PrimeraCapital",
      TitleCase: "Titulo del caso",
      "Filling in forms": "Rellenar formularios",
      px: "píxeles",
      "Tracked changes": "Cambios registrados",
      TrackChangesOnly:
        "Puede editar en esta región, pero se realizará un seguimiento de todos los cambios.",
      RemovedIgnore: "¿Desea eliminar las excepciones ignoradas?",
      RemovedIgnoreExceptions:
        "Si realiza este cambio en la protección de documentos, Word ignorará todas las excepciones de este documento.",
      Information: "Información",
      Yes: "Sí",
      No: "No",
      "Page Break": "Salto de página",
      "Column Break": "Salto de columna",
      "Section Break Next Page": "Salto de sección (Página siguiente)",
      "Section Break Continuous": "Salto de sección (continuo)",
      "Unsupported format":
        "El formato de archivo que ha seleccionado no es compatible. Elija un formato válido.",
      One: "Una",
      Two: "Dos",
      Three: "Tres",
      Presets: "Preajustes",
      Columns: "columnas",
      "Split your text into two or more columns":
        "Divide tu texto en dos o más columnas",
      "Line between column": "Línea entre columna",
      "Width and Spacing": "Ancho y espaciado",
      "Equal column width": "Ancho de columna igual",
      Column: "Columna",
      "Paste Content Dialog":
        "Debido a la política de seguridad del navegador, la función de pegar desde el portapapeles del sistema está restringida. ",
      "Paste Content CheckBox": "No vuelvas a aparecer",
      "The top/bottom margins are too large for the page height in some sections.":
        "Los márgenes superior/inferior son demasiado grandes para la altura de la página en algunas secciones.",
      "Column width cannot be less than 36 pt.":
        "El ancho de columna no puede ser inferior a 36 pt.",
      "Left and right margins.":
        "Las configuraciones que eligió para los márgenes izquierdo y derecho, el espaciado de columnas o las sangrías de párrafos son demasiado grandes para el ancho de la página en las mismas secciones.",
      "The address of this site is 'not valid. Check the address and try again.":
        "La dirección de este sitio no es válida. Comprueba la dirección y vuelve a intentarlo.",
      "Alt Text": "Texto alternativo",
      Title: "Título",
      Description: "Descripción",
      "Left Indent": "Bola enlazadora",
      "Right Indent": "Periodo correcto",
      "Hanging Indent": "Desplazamiento colgante",
      "First Line Indent": "La primera es la sangría de línea.",
      "Left Margin": "Esquina izquierda",
      "Right Margin": "esquina derecha",
      "Top Margin": "borde superior",
      "Bottom Margin": "Borde inferior",
      "Left Tab": "Pestaña izquierda",
      "Right Tab": "pestaña derecha",
      "Center Tab": "marcador central",
      "Decimal Tab": "corte de animales",
      "Bar Tab": "placa de goma",
      "Move Table Column": "Mover una columna de la tabla",
      external: "Eliminar control de contenido",
      "Content Control Properties": "Propiedades de control de contenido",
      "Remove content control when contents are edited":
        "Eliminar el control de contenido cuando se editan contenidos",
      "Content control cannot be deleted":
        "El control de contenido no se puede eliminar",
      "Contents cannot be edited": "Los contenidos no se pueden editar.",
      "Plain Text properties": "Propiedades de texto sin formato",
      "Allow carriage returns": "Permitir retornos de carro",
      "Drop_Down List properties": "Propiedades de la lista desplegable",
      "Display Text": "Mostrar texto",
      Locking: "Cierre",
      Tag: "Etiqueta",
      Value: "Valor",
      PictureContentControl: "Control de contenido de imagen",
      "Upload from computer": "Subir desde la computadora",
      Picture: "Imagen",
      "Choose an item": "Elige un item",
      "Paste CheckBox Content Dialog": "no volver a mostrar",
      "The address of this site is not valid. Check the address and try again.":
        "La dirección de este sitio no es válida. ",
      "Left and right margins":
        "Las configuraciones que eligió para los márgenes izquierdo y derecho, el espaciado de columnas o las sangrías de párrafos son demasiado grandes para el ancho de la página en las mismas secciones.",
      "Number of rows must be between 1 and 32767.":
        "El número de filas debe estar entre 1 y 32767.",
      "Number of columns must be between 1 and 63.":
        "El número de columnas debe estar entre 1 y 63.",
      "You may format text only with certain styles":
        "Puede formatear el texto sólo con ciertos estilos.",
    },
    documenteditorcontainer: {
      New: "Nuevo",
      Open: "Abrir",
      Undo: "Deshacer",
      Redo: "Rehacer",
      Image: "Imagen",
      Table: "Mesa",
      Link: "Enlace",
      Bookmark: "Marcador",
      "Table of Contents": "Tabla de contenido",
      "HEADING - - - - 1": "TÍTULO - - - - 1",
      "HEADING - - - - 2": "TÍTULO - - - - 2",
      "HEADING - - - - 3": "TÍTULO - - - - 3",
      Header: "Encabezado",
      Footer: "Pie de página",
      "Page Setup": "Configuración de página",
      "Page Number": "Número de página",
      Break: "Descanso",
      Find: "Encontrar",
      "Local Clipboard": "Portapapeles local",
      "Restrict Editing": "Restringir edición",
      "Content Control": "Control de contenido",
      "Rich Text Content Control": "Control de contenido de texto enriquecido",
      "Plain Text Content Control": "Control de contenido de texto sin formato",
      "Picture Content Control": "Control de contenido de imagen",
      "Combo Box Content Control": "Control de contenido del cuadro combinado",
      "Drop-Down List Content Control":
        "Control de contenido de lista desplegable",
      "Date Picker Content Control":
        "Control de contenido del selector de fecha",
      "Check Box Content Control":
        "Control de contenido de casilla de verificación",
      "Upload from computer": "Subir desde la computadora",
      "By URL": "Por URL",
      "Page Break": "Salto de página",
      "Section Break": "Salto de sección",
      "Header And Footer": "Encabezado y pie de página",
      Options: "Opciones",
      Levels: "Niveles",
      "Different First Page": "Primera página diferente",
      "Different header and footer for odd and even pages":
        "Encabezado y pie de página diferentes para páginas pares e impares.",
      "Different Odd And Even Pages": "Diferentes páginas pares e impares",
      "Different header and footer for first page":
        "Encabezado y pie de página diferentes para la primera página.",
      Position: "Posición",
      "Header from Top": "Encabezado desde arriba",
      "Footer from Bottom": "Pie de página desde abajo",
      "Distance from top of the page to top of the header":
        "Distancia desde la parte superior de la página hasta la parte superior del encabezado.",
      "Distance from bottom of the page to bottom of the footer":
        "Distancia desde la parte inferior de la página hasta la parte inferior del pie de página.",
      "Aspect ratio": "Relación de aspecto",
      W: "W",
      H: "H",
      Width: "Ancho",
      Height: "Altura",
      Text: "Texto",
      Paragraph: "Párrafo",
      Fill: "Llenar",
      "Fill color": "Color de relleno",
      "Border Style": "Estilo de borde",
      "Outside borders": "Bordes exteriores",
      "All borders": "Todas las fronteras",
      "Inside borders": "Bordes interiores",
      "Left border": "Borde izquierdo",
      "Inside vertical border": "Borde vertical interior",
      "Right border": "Borde derecho",
      "Top border": "Borde superior",
      "Inside horizontal border": "Borde horizontal interior",
      "Bottom border": "Borde inferior",
      "Border color": "Color del borde",
      "Border width": "Ancho del borde",
      Cell: "Célula",
      "Merge cells": "Combinar células",
      "Insert Or Delete": "Insertar / Eliminar",
      "Insert columns to the left": "Insertar columnas a la izquierda",
      "Insert columns to the right": "Insertar columnas a la derecha",
      "Insert rows above": "Insertar filas arriba",
      "Insert rows below": "Insertar filas debajo",
      "Delete rows": "Eliminar filas",
      "Delete columns": "Eliminar columnas",
      "Cell Margin": "Margen de celda",
      Top: "Parte superior",
      Bottom: "Fondo",
      Left: "Izquierda",
      Right: "Derecho",
      "Align Text": "Texto alineado",
      "Align top": "Alinear la parte superior",
      "Align bottom": "Alinear el fondo",
      "Align center": "Alinear al centro",
      "Number of heading or outline levels to be shown in table of contents":
        "Número de niveles de encabezado o esquema que se mostrarán en la tabla de contenido.",
      "Show page numbers": "Mostrar números de página",
      "Show page numbers in table of contents":
        "Mostrar números de página en la tabla de contenido.",
      "Right align page numbers": "Alinear a la derecha los números de página",
      "Right align page numbers in table of contents":
        "Alinee a la derecha los números de página en la tabla de contenido.",
      "Use hyperlinks": "Usar hipervínculos",
      "Use hyperlinks instead of page numbers":
        "Use hipervínculos en lugar de números de página.",
      Font: "Fuente",
      "Font Size": "Tamaño de fuente",
      "Font color": "Color de fuente",
      "Text highlight color": "Color de resaltado de texto",
      "Clear all formatting": "Borrar todo el formato",
      "Bold Tooltip": "Negrita (Ctrl + B)",
      "Italic Tooltip": "Cursiva (Ctrl + I)",
      "Underline Tooltip": "Subrayado (Ctrl + U)",
      Strikethrough: "Tachado",
      "Superscript Tooltip": "Superíndice (Ctrl + Shift ++)",
      "Subscript Tooltip": "Subíndice (Ctrl + =)",
      "Align left Tooltip": "Alinear a la izquierda (Ctrl + L)",
      "Center Tooltip": "Centro (Ctrl + E)",
      "Align right Tooltip": "Alinear a la derecha (Ctrl + R)",
      "Justify Tooltip": "Justificar (Ctrl + J)",
      "Decrease indent": "Disminuir sangría",
      "Increase indent": "Aumentar sangría",
      "Line spacing": "Espaciado entre líneas",
      Bullets: "Balas",
      Numbering: "Numeración",
      Styles: "Estilos",
      "Manage Styles": "Administrar estilos",
      Page: "Página",
      of: "de",
      "Fit one page": "Ajustar una página",
      "Spell Check": "Corrector ortográfico",
      Spelling: "ortografía, ortografía",
      "Underline errors": "Subrayar errores",
      "Fit page width": "Ajustar ancho de página",
      Update: "Actualizar",
      Cancel: "Cancelar",
      Insert: "Insertar",
      "No Border": "Sin bordes",
      "Create a new document": "Crea un nuevo documento.",
      "Open a document": "Abrir un documento",
      "Undo Tooltip": "Deshacer la última operación (Ctrl + Z).",
      "Redo Tooltip": "Rehaga la última operación (Ctrl + Y).",
      "Insert inline picture from a file":
        "Insertar imagen en línea de un archivo.",
      "Insert a table into the document": "Insertar una tabla en el documento",
      "Create Hyperlink":
        "Cree un enlace en su documento para acceder rápidamente a páginas web y archivos (Ctrl + K).",
      "Insert a bookmark in a specific place in this document":
        "Inserte un marcador en un lugar específico en este documento.",
      "Provide an overview of your document by adding a table of contents":
        "Proporcione una descripción general de su documento agregando una tabla de contenido.",
      "Add or edit the header": "Agregar o editar el encabezado.",
      "Add or edit the footer": "Agregar o editar el pie de página.",
      "Open the page setup dialog":
        "Abra el cuadro de diálogo de configuración de página.",
      "Add page numbers": "Agregar números de página.",
      "Find Text": "Buscar texto en el documento (Ctrl + F).",
      "Toggle between the internal clipboard and system clipboard":
        "Alterne entre el portapapeles interno y el portapapeles del sistema. </br> El acceso al portapapeles del sistema a través del script está denegado debido a la política de seguridad de los navegadores. En su lugar, </br> 1. Puede habilitar el portapapeles interno para cortar, copiar y pegar dentro del componente. </br> 2. Puede usar los atajos de teclado (Ctrl + X, Ctrl + C y Ctrl + V) para cortar , copie y pegue con el portapapeles del sistema.",
      "Current Page Number":
        "El número de página actual en el documento. Haga clic o toque para navegar por una página específica.",
      "Read only": "Solo lectura",
      Protections: "Protecciones",
      "Error in establishing connection with web server":
        "Error al establecer conexión con el servidor web",
      Single: "Soltero",
      Double: "Doble",
      "New comment": "Nuevo comentario",
      Comments: "Comentarios",
      "Print layout": "Diseño de impresión",
      "Web layout": "diseño web",
      "Text Form": "Formulario de texto",
      "Check Box": "Casilla de verificación",
      DropDown: "Desplegable",
      "Update Fields": "Actualizar campos",
      "Update cross reference fields":
        "Actualizar campos de referencia cruzada",
      "Hide properties pane": "Ocultar panel de propiedades",
      "Show properties pane": "Mostrar panel de propiedades",
      "Form Fields": "Campos de formulario",
      "Track Changes":
        "Realizar un seguimiento de los cambios realizados en el documento",
      TrackChanges: "Cambio de camino",
      AllCaps: "Todo en mayúsculas",
      "Change case Tooltip": "Cambiar caso",
      "Insert Footnote": "Insertar nota al pie",
      "Insert Endnote": "Insertar nota al final",
      "Footnote Tooltip": "Insertar nota al pie (Alt + Ctrl + F).",
      "Endnote Tooltip": "Insertar nota al final (Alt + Ctrl + D).",
      UPPERCASE: "MAYÚSCULAS",
      "No color": "Sin color",
      "Top margin": "Margen superior",
      "Bottom margin": "Margen inferior",
      "Left margin": "Margen izquierdo",
      "Right margin": "Margen derecho",
      Normal: "Normal",
      Heading: "Título",
      "Heading 1": "Título 1",
      "Heading 2": "Título 2",
      "Heading 3": "Título 3",
      "Heading 4": "Título 4",
      "Heading 5": "Título 5",
      "Heading 6": "Título 6",
      ZoomLevelTooltip:
        "Nivel de zoom. Haga clic o toque para abrir las opciones de Zoom.",
      None: "Ninguna",
      Borders: "Fronteras",
      "ShowHiddenMarks Tooltip":
        "Muestra los caracteres ocultos como espacios, tabulaciones, marcas de párrafo y saltos. (Ctrl + *)",
      Columns: "columnas",
      "Column Break": "Salto de columna",
      "Link to Previous": "Enlace a antes",
      "Link to PreviousTooltip":
        "Vincular esta sección al encabezado o pie de página de la sección anterior",
      "Alternate Text": "Texto alternativo",
      "The address of this site is not valid. Check the address and try again.":
        "La dirección de este sitio no es válida. ",
      OK: "DE ACUERDO",
      Information: "Información",
      "Page Breaks": "Fin de paginas",
      "Section Breaks": "La división se derrumba",
      Column: "Dividir",
      "Next Page": "Siguiente página",
      Continuous: "coherente",
    },
    carousel: {
      nextSlide: "Siguiente diapositiva",
      of: "de",
      pauseSlideTransition: "Pausar transición de diapositiva",
      playSlideTransition: "Reproducir transición de diapositiva",
      previousSlide: "Diapositiva anterior",
      slide: "Deslizar",
      slideShow: "Diapositivas",
    },
    message: {
      close: "Cerrar",
    },
    "image-editor": {
      0: "0%",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      20: "20%",
      40: "40%",
      60: "60%",
      80: "80%",
      100: "100%",
      Crop: "Cultivo",
      CropAndTransform: "Recortar y transformar",
      ZoomIn: "Acercarse",
      ZoomOut: "Disminuir el zoom",
      Undo: "Deshacer",
      Redo: "Rehacer",
      Transform: "Transformar",
      Annotation: "Anotación",
      Finetune: "Afinar",
      Brightness: "Brillo",
      Contrast: "Contraste",
      Hue: "Matiz",
      Saturation: "Saturación",
      Opacity: "Opacidad",
      Blur: "Difuminar",
      Sharpen: "Afilar",
      Exposure: "Exposición",
      Filter: "Filtrar",
      Default: "Por defecto",
      Chrome: "Cromo",
      Cold: "Frío",
      Warm: "Cálido",
      Grayscale: "Escala de grises",
      BlackAndWhite: "En blanco y negro",
      Sepia: "Sepia",
      Invert: "Invertir",
      Text: "Añadir texto",
      Pen: "Bolígrafo",
      Reset: "Reiniciar",
      Save: "Ahorrar",
      Select: "Seleccionar",
      RotateLeft: "Girar a la izquierda",
      RotateRight: "Gira a la derecha",
      HorizontalFlip: "Voltear horizontalmente",
      VerticalFlip: "Voltear verticalmente",
      OK: "DE ACUERDO",
      Cancel: "Cancelar",
      DlgOK: "DE ACUERDO",
      FillColor: "Color de relleno",
      StrokeColor: "Color del trazo",
      StrokeWidth: "Anchura del trazo",
      FontFamily: "Familia tipográfica",
      FontStyle: "Estilo de fuente",
      FontSize: "Tamaño de fuente",
      FontColor: "Color de fuente",
      Pan: "Cacerola",
      Move: "Mover",
      Load: "Carga",
      Custom: "Costumbre",
      Square: "Cuadrado",
      Circle: "Círculo",
      Ellipse: "Elipse",
      Rectangle: "Rectángulo",
      Line: "Línea",
      Arrow: "Flecha",
      Path: "Camino",
      Bold: "Atrevido",
      Italic: "Itálico",
      BoldItalic: "Negrita cursiva",
      XSmall: "XS",
      Small: "Pequeño",
      Medium: "Medio",
      Large: "Grande",
      XLarge: "XL",
      ABC: "A B C",
      Browse: "Navegar",
      Duplicate: "Duplicar",
      Remove: "Eliminar",
      EditText: "Editar texto",
      Start: "Comenzar",
      End: "Fin",
      Bar: "Bar",
      ArrowSolid: "Flecha Sólida",
      CircleSolid: "Círculo Sólido",
      SquareSolid: "Sólido cuadrado",
      None: "Ninguno",
      CropSelection: "Selección de cultivos",
      Image: "Añadir imagen",
      Transparency: "Transparencia",
      Height: "Altura",
      Width: "Ancho",
      AspectRatio: "Mantener la relación de aspecto",
      W: "W.",
      H: "h",
      DragText: "Arrastra y suelta tu imagen aquí o",
      DropText: "Deja tu imagen aquí o",
      BrowseText: "Navega aquí...",
      SupportText: "Soporta:",
      Frame: "Marco",
      Mat: "Estera",
      Bevel: "Bisel",
      Inset: "Recuadro",
      Hook: "Gancho",
      Color: "Color",
      Size: "Tamaño",
      Offset: "Compensar",
      Radius: "Radio",
      Amount: "Cantidad",
      Resize: "Cambiar tamaño",
      Border: "Borde",
      Solid: "Sólido",
      Dashed: "discontinuo",
      Dotted: "Punteado",
      GradientColor: "Degradado de color",
      ConfirmDialogHeader: "Confirmar guardar cambios",
      ConfirmDialogContent:
        "¿Quieres guardar los cambios que hiciste en la imagen?",
      AlertDialogHeader: "Archivo no soportado",
      AlertDialogContent: "El archivo eliminado no es compatible.",
      Yes: "Sí",
      No: "No",
      ImageErrorDialogHeader: "Error al seleccionar la imagen",
      ImageErrorDialogContent: "Seleccione solo una imagen para abrir.",
      Straighten: "Fecha límite",
      NoOutline: "Sin contornos",
      Clone: "Clon",
      Delete: "exterminar",
      SaveAs: "Guardar como",
      ImageName: "Nombre de la imágen",
      Format: "Formato",
      Quality: "Calidad",
      Download: "Descargar",
      Close: "Cerca",
      ImageSize: "Tamaño de la imagen",
      QualityInfo:
        "La opción de calidad de imagen solo está disponible para el formato JPEG",
      Good: "Bien",
      Great: "Excelente",
      Highest: "más alto",
      BringForward: "Adelantar",
      SendBackward: "Enviar atrás",
      SendToBack: "Enviar al fondo",
      BringToFront: "Traer al frente",
      ZOrder: "Orden Z",
    },
    mention: {
      noRecordsTemplate: "No se encontraron registros",
    },
    inplaceeditor: {
      save: "Ahorrar",
      cancel: "Cancelar",
      loadingText: "Cargando...",
      editIcon: "Haz click para editar",
      editAreaClick: "Haz click para editar",
      editAreaDoubleClick: "Doble click para editar",
    },
    PdfViewer: {
      PdfViewer: "Visor de PDF",
      Cancel: "Cancelar",
      "Download file": "Descargar archivo",
      Download: "Descargar",
      "Enter Password": "Este documento está protegido con contraseña. ",
      "File Corrupted": "Archivo corrupto",
      "File Corrupted Content": "El archivo está dañado y no se puede abrir.",
      "Fit Page": "La página de ajuste",
      "Fit Width": "Ajuste ancho",
      Automatic: "Automático",
      "Go To First Page": "Mostrar primera página",
      "Invalid Password": "Contraseña incorrecta. ",
      "Next Page": "Mostrar la página siguiente",
      OK: "DE ACUERDO",
      Open: "Abrir documento",
      "Page Number": "Número de página actual",
      "Previous Page": "Mostrar página anterior",
      "Go To Last Page": "Mostrar última página",
      Zoom: "Zoom",
      "Zoom In": "Acercarse",
      "Zoom Out": "Disminuir el zoom",
      "Page Thumbnails": "Miniaturas de página",
      Bookmarks: "Marcadores",
      Print: "Imprimir archivo",
      "Organize Pages": "Organizar páginas",
      "Insert Right": "Insertar a la derecha",
      "Insert Left": "Insertar a la izquierda",
      Total: "Total",
      Pages: "paginas",
      "Rotate Right": "Gira a la derecha",
      "Rotate Left": "Girar a la izquierda",
      "Delete Page": "Eliminar página",
      "Delete Pages": "Eliminar páginas",
      "Copy Page": "Copiar página",
      "Copy Pages": "Copiar páginas",
      Save: "Ahorrar",
      "Save As": "Guardar como",
      "Select All": "Seleccionar todo",
      "Password Protected": "Se requiere contraseña",
      Copy: "Copiar",
      "Text Selection": "Herramienta de selección de texto",
      Panning: "Modo panorámico",
      "Text Search": "Buscar texto",
      "Find in document": "Buscar en el documento",
      "Match case": "caso de partido",
      Apply: "Aplicar",
      GoToPage: "Ir a la página",
      "No matches": "El espectador ha terminado de buscar en el documento. ",
      "No Text Found": "No se encontró texto",
      Undo: "Deshacer",
      Redo: "Rehacer",
      Annotation: "Agregar o editar anotaciones",
      FormDesigner: "Agregar y editar campos de formulario",
      Highlight: "Subrayar el texto",
      Underline: "Texto subrayado",
      Strikethrough: "Texto tachado",
      Delete: "Eliminar anotación",
      Opacity: "Opacidad",
      "Color edit": "Cambiar el color",
      "Opacity edit": "Cambiar opacidad",
      "Highlight context": "Destacar",
      "Underline context": "Subrayar",
      "Strikethrough context": "Penetrar",
      "Server error": "El servicio web no escucha. ",
      "Client error":
        "Se encuentra un error del lado del cliente. Verifique los encabezados personalizados proporcionados en la propiedad AjaxRequestSettings y los métodos de acción web en la propiedad ServerActionettings.",
      "Open text": "Abierto",
      "First text": "Primera página",
      "Previous text": "Pagina anterior",
      "Next text": "Siguiente página",
      "Last text": "Última página",
      "Zoom in text": "Acercarse",
      "Zoom out text": "Disminuir el zoom",
      "Selection text": "Selección",
      "Pan text": "Cacerola",
      "Print text": "Imprimir",
      "Search text": "Buscar",
      "Annotation Edit text": "Editar anotación",
      "FormDesigner Edit text": "Agregar y editar campos de formulario",
      "Line Thickness": "Grosor de la línea",
      "Line Properties": "Propiedades de línea",
      "Start Arrow": "Flecha de inicio",
      "End Arrow": "Flecha final",
      "Line Style": "Estilo de línea",
      "Fill Color": "Color de relleno",
      "Line Color": "Color de linea",
      None: "Ninguno",
      "Open Arrow": "Abierto",
      "Closed Arrow": "Cerrado",
      "Round Arrow": "Redondo",
      "Square Arrow": "Cuadrado",
      "Diamond Arrow": "Diamante",
      Butt: "Culata",
      Cut: "Cortar",
      Paste: "Pegar",
      "Delete Context": "Borrar",
      Properties: "Propiedades",
      "Add Stamp": "Agregar sello",
      "Add Shapes": "Agregar formas",
      "Stroke edit": "Cambiar color de trazo",
      "Change thickness": "Cambiar el grosor del borde",
      "Add line": "Añadir línea",
      "Add arrow": "Agregar flecha",
      "Add rectangle": "Agregar rectángulo",
      "Add circle": "Agregar círculo",
      "Add polygon": "Agregar polígono",
      "Add Comments": "Añadir comentarios",
      Comments: "Comentarios",
      SubmitForm: "Enviar formulario",
      "No Comments Yet": "Sin comentarios aún",
      Accepted: "Aceptado",
      Completed: "Terminado",
      Cancelled: "Cancelado",
      Rejected: "Rechazado",
      "Leader Length": "Longitud del líder",
      "Scale Ratio": "Relación de escala",
      Calibrate: "Calibrar",
      "Calibrate Distance": "Calibrar distancia",
      "Calibrate Perimeter": "Calibrar perímetro",
      "Calibrate Area": "Calibrar área",
      "Calibrate Radius": "Calibrar radio",
      "Calibrate Volume": "Calibrar volumen",
      Depth: "Profundidad",
      Closed: "Cerrado",
      Round: "Redondo",
      Square: "Cuadrado",
      Diamond: "Diamante",
      Edit: "Editar",
      Comment: "Comentario",
      "Comment Panel": "Panel de comentarios",
      "Set Status": "Establecer estado",
      Post: "Correo",
      Page: "Página",
      "Add a comment": "Añadir un comentario",
      "Add a reply": "Agregar una respuesta",
      "Import Annotations": "Annotaciones de importación desde el archivo JSON",
      "Export Annotations": "Anotación de exportación al archivo JSON",
      "Export XFDF": "Anotación de exportación al archivo XFDF",
      "Import XFDF": "Importar anotaciones desde el archivo XFDF",
      Add: "Agregar",
      Clear: "Claro",
      Bold: "Atrevido",
      Italic: "Itálico",
      Strikethroughs: "Tachado",
      Underlines: "Subrayar",
      Superscript: "Sobrescrito",
      Subscript: "Subíndice",
      "Align left": "Alinear a la izquierda",
      "Align right": "Alinear a la derecha",
      Center: "Centro",
      Justify: "Justificar",
      "Font color": "Color de fuente",
      "Text Align": "Texto alineado",
      "Text Properties": "Estilo de fuente",
      SignatureFieldDialogHeaderText: "Agregar firma",
      HandwrittenSignatureDialogHeaderText: "Agregar firma",
      InitialFieldDialogHeaderText: "Agregar inicial",
      HandwrittenInitialDialogHeaderText: "Agregar inicial",
      "Draw Ink": "Tinta de dibujo",
      Create: "Crear",
      "Font family": "Familia tipográfica",
      "Font size": "Tamaño de fuente",
      "Free Text": "Texto libre",
      "Import Failed":
        "Tipo de archivo JSON inválido o nombre de archivo; Seleccione un archivo JSON válido",
      "Import PDF Failed":
        "Tipo de archivo PDF no válido o archivo PDF no encontrado. Seleccione un archivo PDF válido",
      "File not found":
        "El archivo JSON importado no se encuentra en la ubicación deseada",
      "Export Failed":
        "La acción de las anotaciones de exportación ha fallado; Asegúrese de que las anotaciones se agregan correctamente",
      of: "de",
      Dynamic: "Dinámica",
      "Standard Business": "Negocio estándar",
      "Sign Here": "Firma aqui",
      "Custom Stamp": "Sello personalizado",
      "Enter Signature as Name": "Introduzca su nombre",
      "Draw-hand Signature": "DIBUJAR",
      "Type Signature": "TIPO",
      "Upload Signature": "SUBIR",
      "Browse Signature Image": "NAVEGAR",
      "Save Signature": "Guardar firma",
      "Save Initial": "Guardar inicial",
      Textbox: "Caja de texto",
      Password: "Contraseña",
      "Check Box": "Caja",
      "Radio Button": "Boton de radio",
      Dropdown: "Desplegable",
      "List Box": "Cuadro de lista",
      Signature: "Firma",
      "Delete FormField": "Eliminar campo de formulario",
      "Textbox Properties": "Propiedades del cuadro de texto",
      Name: "Nombre",
      Tooltip: "Información sobre herramientas",
      Value: "Valor",
      "Form Field Visibility": "Formulario de visibilidad de campo",
      "Read Only": "Solo lectura",
      Required: "Requerido",
      Checked: "Comprobado",
      "Show Printing": "Impresión del programa",
      Formatting: "Formato",
      Fill: "Llenar",
      Border: "Borde",
      "Border Color": "Color del borde",
      Thickness: "Espesor",
      "Max Length": "Longitud máxima",
      "List Item": "Nombre del árticulo",
      "Export Value": "Valor del ítem",
      "Dropdown Item List": "Lista de elementos desplegables",
      "List Box Item List": "Lista de elementos del cuadro Lista",
      General: "GENERAL",
      Appearance: "APARIENCIA",
      Options: "OPCIONES",
      "Delete Item": "Borrar",
      Up: "Arriba",
      Down: "Abajo",
      Multiline: "multilínea",
      Revised: "Revisado",
      Reviewed: "Revisado",
      Received: "Recibió",
      Confidential: "Confidencial",
      Approved: "Aprobado",
      "Not Approved": "No aprovado",
      Witness: "Testigo",
      "Initial Here": "Iniciales aqui",
      Draft: "Borrador",
      Final: "Final",
      "For Public Release": "Para liberación pública",
      "Not For Public Release": "No para el lanzamiento público",
      "For Comment": "Para comentarios",
      Void: "Vacío",
      "Preliminary Results": "Resultados preliminares",
      "Information Only": "Sólo información",
      in: "en",
      m: "metro",
      ft_in: "ft_in",
      ft: "pie",
      p: "pag",
      cm: "cm",
      mm: "milímetros",
      pt: "pt",
      cu: "Cu",
      sq: "cuadrado",
      Initial: "Inicial",
    },
    stepper: {
      optional: "Opcional",
    },
  },
};
