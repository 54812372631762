import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import ApiClient from "../../api/ApiClient"; 
import EndPoint from "../../api/Endpoints"; 
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {  find,getCookie  } from '../../utils/Functions';
import {
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";

var listDialogTemplates;

class DialogTemplate extends React.Component{
	
	constructor(props) {
		 
    super(  props);
    this.animationSettings = { effect: 'Zoom' };
    this.state = {
      templates:[],
      categories:[],
      categorySelected:"",
      selectImage:""
		}
  }
    

  componentWillUnmount() {
    
    document.removeEventListener('click', this.checkClick);
  }
  async componentDidMount () { 
    document.addEventListener("click",this.checkClick );

    var token=getCookie(process.env.REACT_APP_NAME+'_usertoken')
    if (token){
      try{
        await this.reloadData();
      }catch(Ex){
        console.log("Session closed")
      }
    }
  }

  reloadData = async (event) => {       

    //let response = await ApiClient.doPost(REACT_APP_MASTER_API_CONFIG+"/public/components/get-templates", {type: this.props.templateType });
    let response = await ApiClient.doPost(REACT_APP_API_CONFIG+"/components/get-templates", {type: this.props.templateType });

    let categories=[]
    let templates=[]
    for (let i in response.result)   {
      let key  = response.result[i]

      let config = key.CONFIG

      try{
        config = JSON.parse( key.CONFIG)
        key["CONFIG"] = config
      }catch(ex){

      }

      try{
        let data = JSON.parse( key.DATA)
        key["DATA"] = data
      }catch(ex){
        
      }
     
     
      if ( categories.indexOf( config.category)<0 ){
        categories.push(config.category)
      }
      try{
        let images = config.images.split(",")        
        key["images"] = images
      }catch(ex){         
        key["images"] = config.images
      }

 
      if (key.images.length===0){
        key.images.push( "/images/users/defTemplate.jpg" )
      }
      templates.push(key)
    }

    let response2 = await ApiClient.doPost( EndPoint.getPrivateTemplates, {type : this.props.templateType, componenttypecode: "templates" } );

    for (let i in response2.result)   {
      let key  = response2.result[i]
      
        let images =[]
        images.push( "/images/users/defTemplate.jpg" )        
        key["images"] = images

        let config = key.CONFIG

        try{
          config = JSON.parse( key.CONFIG)
          key["CONFIG"] = config
        }catch(ex){
          
        }
 
        try{
          let data = JSON.parse( key.DATA)
          key["DATA"] = data
        }catch(ex){
          
        }

        key["CONFIG"] = {
          autor: key.INSERT_USER,
          description: config,
          category: "Private"
        }
      templates.push(key)
    }
    
    this.setState({  ...this.state, templates:templates, categories:categories, categorySelected: categories[0] }) 

  }
  
  checkClick =  (event) => {           
        if (event.target.id.indexOf("LOADtemplate_")>=0){
            this.getTemplate(event.target.id)
        }else if (event.target.id.indexOf("LOADtemplateP_")>=0){
            this.getTemplateP(event.target.id)
        }else if (event.target.id.indexOf("SHOWtemplate_")>=0){

          let comp = event.target.id.replace("SHOWtemplate_","")
          this.setState({  ...this.state, showMore: true, selectTemplate :comp, selectImage:"",readMore:false }) 
        
        }else if (event.target.id.indexOf("SHOWtemplateP_")>=0){
          
          this.setState({  ...this.state, showMore: true, selectTemplate : ""}) 

        }else if (event.target.id.indexOf("go-back")>=0){
          
          this.setState({  ...this.state, showMore: false, selectTemplate : ""}) 

        }else if (event.target.id.indexOf("read-more")>=0){
         
          this.setState({  ...this.state, readMore: !this.state.readMore}) 

        }else if (event.target.id.indexOf("selectImage_")>=0){

          let comp = event.target.id.replace("selectImage_","")
  	 
          this.setState({  ...this.state, showMore: true, selectImage :comp }) 
        
        }else if (event.target.id.indexOf("selectCategory_")>=0){

          let comp = event.target.id.replace("selectCategory_","")
          this.setState({  ...this.state,  categorySelected :comp }) 
        
        }else{
          if (this.props.callBackAction){
            this.props.callBackAction(event)
          }
        }
    }

  getTemplate =  (event) => {	  
		let comp = event.replace("LOADtemplate_","")
		let pos = find( this.state.templates, comp, "COMPONENTNAME" ) 
		let response = this.state.templates[pos] 
    this.props.setTemplate(response)
    console.log("response",response)
  }

  getTemplateP =  (event) => {	  
		let comp = event.replace("LOADtemplateP_","")
		let pos = find( this.props.privateTemplates, comp, "COMPONENTNAME" ) 
		let response = this.props.privateTemplates[pos] 
    console.log("response",response)
    this.props.setTemplate(response)
  }


  render() {

    return (
      <> 
        <DialogComponent
          id={"listDialogTemplates"+ this.props.componentName}
          showCloseIcon={true}
          animationSettings={this.props.animationSettings}
          closeOnEscape={false}
          ref={(dialog) => (listDialogTemplates = dialog)}
          isModal={true}
          open={this.props.open} 
          close={this.props.close}
          cssClass="full-dialog no-padding"
          header="Template Center"
          buttons={ [
            
            {
              click: () => {
                  this.reloadData();
                  //listDialogTemplates.hide();
              },
              buttonModel: {
                  content: 'Refresh',
                  isPrimary: true,
              },
              },
              
            {
            click: () => {
                this.props.close();
                document.removeEventListener('click', this.checkClick);
                //listDialogTemplates.hide();
            },
            buttonModel: {
                content: 'Close',
                isSecondary: true,
            },
            }
          ]
          }
          width= {window.innerWidth>1650?"75%":window.innerWidth>1240?"100%":"100%"}
          height="100%"
          position={ { X: 'left', Y: 'top' }}
          visible={this.props.status} 
        >               
         
              <div className="template-container">    

                  <div className="row">   
                    <div className="pt-1 col-lg-3 col-sm-3 left-col">   

                      <div className="item">                    
                        <div>Explore </div>                    
                      </div>

                      <div className="item">                    
                        <div id={"selectCategory_"+"ALLTEMPLATES"} >All Templates </div>                    
                      </div>

                      <div className="item">                    
                        <div  id={"selectCategory_"+"Private"} >Private Templates </div>                    
                      </div>


                      <div className="item-header">                    
                        <div >Browse by category</div>                    
                      </div>

                        {this.state.categories.map( (item,i ) => {  return(
                          <div style={{fontSize:"1.35rem"}} id={"selectCategory_"+item} className="item" key={"cat"+i}>
                            {item}
                          </div>
                        )
                          }) 
                        }
                    </div>
                    <div className="col-lg-9 col-sm-9 right-col">  
                       
                      {!this.state.showMore ?

                        <div className="row">  
                          <div className="text-bold mt-2 col-sm-12 col-lg-12">                    
                            <div >{this.state.categorySelected}</div>                    
                          </div>                      
                          {this.state.templates.map( (item, index) => {  return( (item.CONFIG.category ===this.state.categorySelected || this.state.categorySelected ==="ALLTEMPLATES"  ) &&
                            <div className="col-lg-4 col-sm-6 mt-2" key={"col_"+item.COMPONENTNAME}>
                              <div className="item-template">                              
                                <img src={item.images[0]}  key={"i"+index}/> 

                                <div className="item-title" key={"t"+index}>
                                  {item.COMPONENTNAME}
                                </div>
                                <div className="item-autor" key={"a"+index}>
                                  {item.CONFIG.autor}
                                </div> 
                                <div className="item-description" key={"d"+item}>
                                  {item.CONFIG.description.length>60 ?  item.CONFIG.description.substr(0,57)+"...":item.CONFIG.description   }
                                </div> 

                                <div className="row  flex-middle" key={"r"+item}>
                                  <div style={ {fontSize: "1.2rem"  }} className="col-lg-6 col-sm-6 no-padding cursor-pointer" id =  {"SHOWtemplate_"+item.COMPONENTNAME}  >
                                    Show more(+)
                                  </div> 
                                  <div className="col-lg-6 col-sm-6 no-padding text-right"   >

                                    <ButtonComponent
                                      key={"btn"+index}
                                      className="e-success"
                                      id =  {"LOADtemplate_"+item.COMPONENTNAME}
                                      name= "Use_Template"
                                      style={ { textTransform:"capitalize", fontWeight:  "500", fontSize: "1.2rem"  }}
                                    >Use
                                    </ButtonComponent>
                                  </div> 
                                </div> 


                              </div>
                            </div>
                          )
                            }) 
                          }
                        </div>  
                    :
                      <div className="row">  
                        <div className="text-bold my-2 col-sm-12 col-lg-12 no-padding">                    
                          <div style={{paddingLeft: 15}} className="cursor-pointer mb-2" id="go-back">{"<< Back"} </div>                    
 

                          {this.state.templates.map( (item, index) => {  return( item.COMPONENTNAME ===this.state.selectTemplate &&
                            <div className="col-lg-12 col-sm-12" key={"col_"+item.COMPONENTNAME}>
                              <div className="item-single">                              

                                <div className="row my-2 flex-middle" key={"r"+item}>
                                  <div className="col-lg-9 col-sm-6 no-padding  "   >
                                    <div  key={"t"+index}>
                                     <span className="item-title">  {item.COMPONENTNAME} </span> |  <span className="item-autor">  {item.CONFIG.autor} </span>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-sm-6 no-padding text-right"   >

                                    <ButtonComponent
                                      key={"btn"+index}
                                      className="e-success"
                                      id =  {"LOADtemplate_"+item.COMPONENTNAME}
                                      name= "Use_Template"
                                      style={ { textTransform:"capitalize", fontWeight:  "500", fontSize: "1.2rem"  }}
                                    >Use Template
                                    </ButtonComponent>
                                  </div> 
                                </div> 

                                
                                
                                <div className="item-description" key={"d"+item}>
                                  {/*item.CONFIG.description*/}
                                  {this.state.readMore ? item.CONFIG.description+ " " :
                                  item.CONFIG.description.length>120  ?  item.CONFIG.description.substr(0,117)+"...":item.CONFIG.description+ " " }

                                  {item.CONFIG.description.length>120 && !this.state.readMore ?
                                    <span className="cursor-pointer" id ="read-more" >
                                      Read more
                                    </span> 
                                    :item.CONFIG.description.length>120?
                                    
                                    <span className="cursor-pointer" id ="read-more" >
                                      Hide
                                    </span> :
                                    <span className="cursor-pointer" id ="read-more" ></span>
                                  }

                                </div> 


                                <div className="row  " key={"r"+item}>
                                  <div className="col-lg-10 col-sm-9 no-padding" style={{marginRight: 10}} >
                                    {this.state.selectImage=="" ?

                                      <img className="full-image" src={item.images[0]}  key={"i"+index}/> 
                                    :
                                      <>
                                          {item.images.map( (image, index2) => { return( image === this.state.selectImage &&
                                             <img className="full-image" src={image}  key={"i"+index}/> 
                                          )
                                          }) 
                                        }
                                      </>
                                    }

                                  </div>
                                  <div className="col-lg-2 col-sm-3 no-padding text-right tumb-container"  style={{marginRight: -10}}  >

                                      {item.images.map( (image, index2) => { return(
                                        <img className="tumb-image cursor-pointer" id={"selectImage_"+image } src={image}  key={"i2_"+index2}/> 
                                      )
                                      }) 
                                    }
                                  </div> 
                                </div> 
                              </div>
                            </div>
                          )
                            }) 
                          }
                        </div>  
                      </div>  
                    }
                    </div>
                </div>
                 

                 
                </div>  

             
        </DialogComponent> 
      </>
    );
  }
}
export default DialogTemplate; 
