import { createSpinner, showSpinner } from '@syncfusion/ej2-popups';
import * as React from 'react';
import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar';
import { getPalette } from '../../utils/Utils';
export default class App extends React.Component {
   
 progressLoad = (args) => {
        let color = getPalette().primary
        let div = document.getElementsByClassName('progress-text-align');
        let selectedTheme = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.progressBar.theme = (selectedTheme.charAt(0).toUpperCase() +
            selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast');
        if (args.progressBar.theme === 'HighContrast' || args.progressBar.theme === 'Bootstrap5Dark' || args.progressBar.theme === 'BootstrapDark' || args.progressBar.theme === 'FabricDark'
            || args.progressBar.theme === 'TailwindDark' || args.progressBar.theme === 'MaterialDark' || args.progressBar.theme === 'FluentDark') {
            for (let i = 0; i < div.length; i++) {
                div[i].setAttribute('style', 'color:white');
            }
        }
        args.progressBar.trackColor = getPalette().primary;
        args.progressBar.opacity = '1';
        args.progressBar.progressColor = '#fff';
    };
    render() {
      return (
        <div className="control-pane" style={{ 
		        	 
                    zIndex: 999998,
		        	height: this.props.contained?"100%": "100vh", 
                    width:"100%", 
                    position: this.props.contained?"absolute": "fixed",
		        	display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: this.props.transparent? "initial": "#fff",
                    opacity: this.props.transparent? "1": "0.75",
                    top: 0,
                    left: 0,
        	  }}  >
            <div className="spin-loader" style={{ zIndex: 999999 , top: this.props.customTop?this.props.customTop:"50%" }}>
    		 <ProgressBarComponent id="rounded-container" 
                                    type='Circular'
                                    width='75px'
                                    height='75px'
                                    cornerRadius='Round'
                                    trackThickness={8}
                                    isIndeterminate={true}
                                    value={20}
                                    animation={{
                                        enable: true,
                                        duration: 2000,
                                        delay: 0,
                                    }}
                                    load={this.progressLoad.bind(this)}
                                >
                                </ProgressBarComponent>
                </div>
  			</div>
  		);
    }
}