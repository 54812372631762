import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import ApiClient from "../../api/ApiClient"; 
import EndPoint from "../../api/Endpoints";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { findValueById, find } from '../../utils/Functions';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import Loading from "../customdesign/Loading";
import { ToastComponent } from '@syncfusion/ej2-react-notifications'; 

let name
let description
 
const DialogSaveTemplate  = (props) => {
	
  const { defConfig,  defLang, } = useSelector((state) => state.Login);
  const { componentConfig, processSuccess,  processError, requesting, processMessage,formFields, initialValues, validationSchema} = useSelector( (state) => state.Process );
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const [loading,setLoading]    = React.useState(false)
  const [error,setError]    = React.useState(false)
    
   
  const  saveComponent = async (event) => {	
  
      let formData = {
        data: props.template,
        componentname: name.value,
        tag :  props.type,
        config :  description.value,
        componenttypecode: "templates"
      }
      setLoading(true)
      setError(false)
      let response = await ApiClient.doPut( EndPoint.saveTemplate, formData );

      if(response.status){

        let response = await ApiClient.doPost( EndPoint.getPrivateTemplates, {type : props.type,componenttypecode: "templates" } );
        props.setPrivateTemplates(response.result)
       
        props.close()   
      }else{
        setError(true)
      }
      setLoading(false)
    }
 
    return (
      <> 
     
        <DialogComponent
          id={"listDialogSaveTemplates"}
          showCloseIcon={true}
          animationSettings={"Fade"}
          closeOnEscape={false}
          
          isModal={true}
          open={props.open} 
          close={props.close}
          width="335px" 
          header="Template Aditional Info"
          buttons={ [ {
            click: () => {
               saveComponent();
               
            },
            buttonModel: {
                content: 'Save',
                isPrimary: true,
                
            },
            }]
          }
          
          visible={props.status} 
        >               
           
           <div style={{ minHeight:100, paddingTop: 27 }}>
                 
           {loading &&
             <Loading/>
            }
                <div>
                <TextBoxComponent  
                    
                    placeholder="Name"
                    ref={(scope) => { name = scope; }} 
                    floatLabelType="Auto" />
				
                </div>

                <div className="mt-2">
                <TextBoxComponent  
                  
                  ref={(scope) => { description = scope; }} 
                  multiline={true} 
                  placeholder="Description"  
                  floatLabelType="Auto"  />
                </div>
                {error &&
                  <div className="mt-1 alert-message">

                    Template name is already used!

                  </div>
                }
            </div>
            
        </DialogComponent> 
      </>
    );
  };
  DialogSaveTemplate.propTypes = {};
  
  export default DialogSaveTemplate;
  
