import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation,useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GenericForm from "../../../components/forms/GenericForm";
import LoadingForm from "../../../components/forms/LoadingForm";
import Loading from "../../../components/customdesign/Loading";
import ConnectorTypes from "../../../components/forms/ConnectorTypes";
import CongratulationsConnector from "../../../components/forms/CongratulationsConnector";
import ProcessingRequest from "../../../components/forms/ProcessingRequest";
import Utils from "../../../utils/Utils";
import { validateForm , find, testJS,testRestApi, clearformData} from '../../../utils/Functions';
import * as ProcessActions from "../../../redux/actions/process.actions";
import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import ApiClient from "../../../api/ApiClient"; 
import EndPoint from "../../../api/Endpoints"; 

let linear;
let dialogInstanceField;

let animationSettings = { effect: 'Fade' };
const ManageConnector = (props) => {
	const { defConfig, params, defLang, activeForm, usertoken} = useSelector((state) => state.Login);
    const { componentConfig, processSuccess,  processError, requesting, processMessage,formFields, initialValues, validationSchema} = useSelector(
        (state) => state.Process
    );
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	const  progressLoad = (args) => {
        let selectedTheme = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.progressBar.theme = (selectedTheme.charAt(0).toUpperCase() +
            selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast');
            args.progressBar.trackColor = '#000';
			args.progressBar.progressColor = '#4c38c9';

    };

	const [linearValue,setLinearValue] = React.useState(1);

	const [step,setStep] = React.useState(0);
	const [activeType,setactiveType] = React.useState(0);
	const [action,setAction] = React.useState("add");
	
	const [compFormValidation, setCompFormValidation] = React.useState([]);
	const [connectorFormValidation, setConnectorFormValidation] = React.useState([]);
	const [connectorFormValidation2, setConnectorFormValidation2] = React.useState([]);
	const [payTypeFormValidation, setPayTypeFormValidation] = React.useState([]);
	
	const [values,setValues] = React.useState({});	
	const [valuesT,setValuesT] = React.useState({});	
	 
	const [headerTitle,setHeaderTitle] = React.useState("Component Type");	

	

	const [loading,setLoading] = React.useState(false);
	const [loadingT,setLoadingT] = React.useState(false);
	const [payTypeFormInputs,setpayTypeFormInputs] = React.useState([]);
    const [compFormInputs,setCompFormInputs] = React.useState([]);
	const [connectorFormInputs,setConnectorFormInputs] = React.useState([]);
    const [connectorFormInputs2,setConnectorFormInputs2] = React.useState([]);
	const [apiResponseMessage,setApiResponseMessage] = React.useState("init");
	const [apiResponseStatus,setApiResponseStatus] = React.useState("");

	const [testFormInputs,setTestFormInputs] = React.useState([]);
	const [testFormValidation, setTestFormValidation] = React.useState([]);

	const onChange = (event) => { 
		values[event.target.name] = event.target.value
		let pos = find(payTypeFormInputs,event.target.name, "name" )
		if (pos>=0){payTypeFormInputs[pos].value= event.target.value}
		pos = find(compFormInputs,event.target.name, "name" )
		if (pos>=0){compFormInputs[pos].value= event.target.value}
		pos = find(connectorFormInputs,event.target.name, "name" )
		if (pos>=0){connectorFormInputs[pos].value= event.target.value}
		pos = find(connectorFormInputs2,event.target.name, "name" )
		if (pos>=0){connectorFormInputs2[pos].value= event.target.value}

	}

	const onChangeT = (event) => { 
		valuesT[event.target.name] = event.target.value
		let pos = find(testFormInputs,event.target.name, "name" )
		if (pos>=0){testFormInputs[pos].value= event.target.value}
	}

	const testApiData = async() => {
		 
		let validResponse = await validateForm(testFormInputs,valuesT);
		setLoadingT(true) 
		setTimeout( async function() {				 
			setTestFormValidation(validResponse.messages)	
			if (validResponse.messages.length===0){
				

				let apidata=""
				for (let f in testFormInputs){
					let field = testFormInputs[f]
					console.log(field)
					apidata += "|"+field.name+"="+field.value
				}
				apidata = apidata.substring(1, apidata.length)

				let formData = {
					apidata  : apidata,
					api      : values.apiurl,
					apitoken : values.apitoken,
					apimethod: values.apimethod,
					preloadscript : values.scriptbody,
					postloadscript : values.scriptpostload
				}				 
				console.log("formData", formData)
				let response={}				 
				if (values.apiprocesstype==="JAVASCRIPT"){
					response = await testJS(formData)				
				}else if (values.apiprocesstype==="RESTAPI"){
					response = await testRestApi(formData)
				}else{
					response ={ status:false, message:"not implemented"};
				}
				setApiResponseMessage(JSON.stringify(response))
				setApiResponseStatus(response.status)
				setLoadingT(false)
				dialogInstanceField.hide()

			}else{
				setLoadingT(false)
			}
			
			
		}.bind(this),100)
 
	}

	const testServerApiData = async() => {
		 
		let validResponse = await validateForm(testFormInputs,valuesT);
		setLoadingT(true) 
		setTimeout( async function() {				 
			setTestFormValidation(validResponse.messages)	
			if (validResponse.messages.length===0){
				

				let apidata=""
				for (let f in testFormInputs){
					let field = testFormInputs[f]
					console.log(field)
					apidata += "|"+field.name+"="+field.value
				}
				apidata = apidata.substring(1, apidata.length)
 
				let formData = {
					apidata       : apidata,
					apiurl        : values.apiurl,
					apitoken      : values.apitoken,
					apimethod     : values.apimethod,
					apitypemethod : values.apitypemethod,
					scriptbody    : values.scriptbody,
					scriptpostload: values.scriptpostload,
					apiauthtoken  : values.apiauthtoken,
					apiprocesstype: values.apiprocesstype,
				}				 

				let response = await ApiClient.doPost( EndPoint.testApi, formData);
				
				setApiResponseMessage(JSON.stringify(response))
				setApiResponseStatus(response.status)
				setLoadingT(false)
				dialogInstanceField.hide()

			}else{
				setLoadingT(false)
			}
			
			
		}.bind(this),100)
 
	}


	const test = async() => {
		 
		setApiResponseMessage("")
		setApiResponseStatus(true)
		setLoadingT(true) 
		let formData = {
			apidata  : "",
			api      : values.apiurl,
			apitoken : values.apitoken,
			apimethod: values.apimethod,
			preloadscript : values.scriptbody,
			postloadscript : values.scriptpostload
		}				 

		if(!values.scriptinputs){
			values["scriptinputs"]=[]
		}
		let response={}
		if (values.scriptinputs.length===0){



			let apidata=""
				 
			let formData = {
				apidata  : apidata,
				api      : values.apiurl,
				apitoken : values.apitoken,
				apimethod: values.apimethod,
				preloadscript : values.scriptbody,
				postloadscript : values.scriptpostload
			}				 
			//console.log("formData", formData)
			//console.log("formData", formData)
			response = await ApiClient.doPost( EndPoint.testApi, formData);

			setApiResponseMessage(JSON.stringify(response))
			setApiResponseStatus(response.status)
			setLoadingT(false) 
			
		}else{		
			setLoadingT(false) 
			dialogInstanceField.show()
			let testFields =[]
			for (let i in values.scriptinputs){
				let field = values.scriptinputs[i]
				let textField= {
					name           : field.INPUTNAME,
					label          : field.INPUTLABEL,
					placeholder    : field.INPUTLABEL,
					type           : "textarea",
					size           : {xs      : 12,
									  lg      : 12},
					values         : [],
					originalvalues : [],
					value          : valuesT[field.INPUTNAME]?valuesT[field.INPUTNAME]:"",	 
					is_required    : field.IS_REQUIRED==="YES",
					customType     : field.TYPE
				}
				testFields.push(textField)
			}
			setTestFormInputs([])
			setTimeout( async function() {		 
				setTestFormInputs(testFields)
				setValuesT({})
			}.bind(this),50)

		}
		
		
	}
	const changePlan = (plan) => {
		setactiveType(plan)
	}
	const optionButton = async(option) => {
		console.log("optionButton", option)
		let newStep = option==="next"?step+1: step-1
		if (newStep ===-1){
			navigate("/manage/connectors");
		}
		if (newStep===0 ){
            console.log("0")
			setLinearValue(1)
			setStep(newStep)
			setHeaderTitle("Component Type")
		 
		}else if(newStep===1 ){
			console.log("step1", activeType)
			setHeaderTitle("General info")
			setLoading(true)
			setTimeout( async function() {				
				setLoading(false)				 
				setStep(newStep)

			}.bind(this),50)
		}else if(newStep===2 ){
			console.log("step2", activeType)
			
			let validResponse = await validateForm(compFormInputs,values);
			setLoading(true)
			setTimeout( async function() {				 
				setCompFormValidation(validResponse.messages)				 
				if (validResponse.messages.length===0){
					setHeaderTitle("Configuration")			
					setStep(newStep)
				}
				setLoading(false)
			}.bind(this),50)
		}else if(newStep===3 ){
			console.log("step3", activeType, connectorFormInputs)			
			let validResponse = await validateForm(connectorFormInputs,values);
			setLoading(true)
			console.log(validResponse)
			setTimeout( async function() {				 
				setConnectorFormValidation(validResponse.messages)	
				if (validResponse.messages.length===0){
					setStep(newStep)
					setHeaderTitle("Body")
				}
				setLoading(false)
				
			}.bind(this),50)
		}else if(newStep===4 ){
			console.log("step4", activeType)
			setLoading(true)
			let validResponse = await validateForm(connectorFormInputs2,values);
			
			console.log(validResponse)
			setTimeout( async function() {				 
				setConnectorFormValidation2(validResponse.messages)	
				if (validResponse.messages.length===0){
					console.log(activeType)
					if(activeType===1 && option==="next"){

						console.log("_compFormInputs",compFormInputs)
						console.log("_payTypeFormInputs",payTypeFormInputs)
						console.log("_connector1FormInputs",connectorFormInputs)
						console.log("_connector1FormInputs2",connectorFormInputs2)
						console.log("activeType",activeType)
						console.log("values",values)
						let data = values

						data["type"] = "PRIVATE"
						data["componenttypecode"] = "connectors"
						let response
						if  (action==="add"){
							//response = await ApiClient.doPut(process.env.REACT_APP_MASTER_API_CONFIG+"/components/create", data);
							response = await ApiClient.doPut(process.env.REACT_APP_API_CONFIG+"/components/create-connector", data);
							dispatch(ProcessActions.setMessage(response))
						}else{
							data["id"] = id
							//response = await ApiClient.doPut(process.env.REACT_APP_MASTER_API_CONFIG+"/components/update", data);
							response = await ApiClient.doPut(process.env.REACT_APP_API_CONFIG+"/components/update-connector", data);
							dispatch(ProcessActions.setMessage(response))
						}
						
						
						if (response.status){
							setHeaderTitle("Paymnent Type")
							newStep++
							setStep(newStep)
						}else{
							
						}
						

					}else{
						setStep(newStep)
					}
					
				}
				setLoading(false)
				
			}.bind(this),50)
		}else if(newStep===5 ){
			console.log("step5", activeType, payTypeFormValidation)
 
			setHeaderTitle("Finish")
			let validResponse = await validateForm(payTypeFormInputs,values);
			setLoading(true)
			console.log(validResponse)
			setTimeout( async function() {				 
				setPayTypeFormValidation(validResponse.messages)	
				if (validResponse.messages.length===0){
					setStep(newStep)
				}
				setLoading(false)
				
			}.bind(this),50)

		}
    };
    let  { id } = useParams();	
	React.useEffect(()=>{
		 

        if(formFields.length<=0){
			//dispatch(ProcessActions.getFormData( { code :"connectors", domainid:6, defLang:defLang, id: parseInt(id) } , process.env.REACT_APP_MASTER_API_CONFIG+"/public/components/get-connector-by-org" ))	
			dispatch(ProcessActions.getFormData( { code :"connectors", domainid:6, defLang:defLang, id: parseInt(id) } , process.env.REACT_APP_API_CONFIG+"/components/get-connector-by-org" ))	
		}else{
            
            if(!requesting){
                console.log(formFields)
				let _payTypeFormInputs= []
				let _connectorFormInputs= []
                let _connectorFormInputs2= []
                let _compFormInputs=[]
				formFields.map(function (key, item) {
					values[key["name"]] = key["value"]
					if ( key["name"].indexOf("api") === 0 ){
						_connectorFormInputs.push(key)
					}else if ( key["name"].indexOf("script") === 0 ){
						_connectorFormInputs2.push(key)
					}else if ( key["name"].indexOf("subscription") === 0 ){
                        _payTypeFormInputs.push(key)
                    }else if ( key["name"].indexOf("connector") === 0 ){
                        _compFormInputs.push(key)
                    }
				});  
				setpayTypeFormInputs(_payTypeFormInputs)
				setConnectorFormInputs(_connectorFormInputs) 
                setConnectorFormInputs2(_connectorFormInputs2) 
                setCompFormInputs(_compFormInputs)
				
                console.log("_compFormInputs",_compFormInputs)
                console.log("_payTypeFormInputs",_payTypeFormInputs)
				console.log("_connector1FormInputs",_connectorFormInputs)
				console.log("_connector1FormInputs2",_connectorFormInputs2)
				console.log("values",values)
	    
				console.log("values.subscriptionpaymenttype",values.subscriptionpaymenttype)
				if (values.type==="PRIVATE"){
					setactiveType(1)
				}else{
					setactiveType(2)
				}

				if (parseInt(id)>0){	
					setAction("update")
				} else{				
						
				}
			}

        }

	},[componentConfig, usertoken, requesting])
    
  
  return (  	
	 
                <div className='control-section card-control-section basic_card_layout'>
 
					<DialogComponent
                        id="fieldvalues"
                        closeOnEscape={true}                                        
                        isModal={true} showCloseIcon={true} 
                        ref={dialog => (dialogInstanceField = dialog)}  
                        buttons={[
                            {
                                click: () => {
                                    testServerApiData();
                                  },
                                buttonModel: {
                                    content   : 'Test Api',
                                    isPrimary : true,
                                    type      : 'button'
                                }           
                            },
                            {
                                click: () => { 
                                    dialogInstanceField.hide();
                                },
                                buttonModel: {
                                    content   : 'Cancel',
                                    isPrimary : false,
                                    type      : 'button'
                                }           
                            }]
                        }
                        header={"Configure Fields"}
                        width="435px" 
                        visible={false}
                        animationSettings={animationSettings}
                    >   
                        <div style={{ minHeight:180, paddingTop: 5 }}>
							{testFormInputs.length>0 &&
								<>
									{!loadingT ?
										<GenericForm 		
											
											data={testFormInputs} 
											formValidation={testFormValidation}
											buttonposition="center"
											onChange={onChangeT}
											buttons={[]}
										/> 
									:
										<LoadingForm 		
											
											data={testFormInputs} 
											formValidation={testFormValidation}
											buttonposition="center" 
										/> 
									}
								</> 
							}               
                        </div>

                    </DialogComponent>

                    <div className="e-card-resize-container">
                        <div className='row'>
                            <div className="row ">
                                <div className="no-padding col-xs-12 col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                    <div className="e-card" id="basic_card">
                                        <div className="e-card-header">
                                            <div className="e-card-header-caption">
                                                <div className={step===5 && "text-center"}>
													<img className="logo-mini" src="/images/logo_mini.png"/>
													{step!==5 && <span className="h3"> Developer connectors </span>}
												</div>
                                            </div>
                                        </div>
                                        <div className="e-card-content">
											 
											<div className="row">
												<div className=" col-xs-12">

											 
													<h4 className="ml-1" style={{marginBottom:-10}}>
														<span className="step-item" >Step {step+1}.</span>
														<span className="" style={{fontSize:"2.5rem"}}> {headerTitle}</span>
													</h4>
												 
												{step===0 &&

													
													<ProgressBarComponent id="success"  type='Linear' height='40' width='100%' 
													value={1}   role='Secondary'   load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}
												{step===1 &&
													<ProgressBarComponent id="success" type='Linear' height='40' width='100%' 
													value={20}   role='Secondary'  load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}
												{step===2 &&
													<ProgressBarComponent id="success" type='Linear' height='40' width='100%' 
													value={40}    role='Secondary'  load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}

												{step===3 &&
													<ProgressBarComponent id="success" type='Linear' height='40' width='100%' 
													value={60}    role='Secondary'  load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}
												{step===4 &&
													<ProgressBarComponent id="success" type='Linear' height='40' width='100%' 
													value={80}    role='Secondary'  load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}
												{step===5 &&
													<ProgressBarComponent id="success" type='Linear' height='40' width='100%' 
													value={95}    role='Secondary'  load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}
												</div>
                                        	</div>
											{step===0 &&
												<ConnectorTypes changePlan={changePlan} activeType={activeType}/>
											}											
											{step===1 &&
												<>
													 
													<div className="row " style={{minHeight:window.innerHeight-370}}>
														<div className="flex-center pt-3">
															<div className=" col-xs-12 col-md-10 col-lg-8 col-xl-6 flex-center">
																<div   className=" connector-form-wrapper wow fadeIn animated hover-up">
																	<div className="connector-form">
																		{!loading ?
																			<GenericForm 		
																				
																				data={compFormInputs} 
																				formValidation={compFormValidation}
																				buttonposition="center"
																				onChange={onChange}
																				buttons={[]}
																			/> 
																		:
																			<LoadingForm 		
																				
																				data={compFormInputs} 
																				formValidation={compFormValidation}
																				buttonposition="center" 
																			/> 
																		}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</>
											}
											{step===2 &&
                                            	<>
													 
													<div className="row " style={{minHeight:window.innerHeight-370}}>
														<div className="flex-center pt-3">
															<div className=" col-xs-12 col-md-10 col-lg-8 col-xl-6 flex-center">
																<div   className=" connector-form-wrapper wow fadeIn animated hover-up">
																	<div className="connector-form">
																		{!loading ?
																			<GenericForm 		
																				
																				data={connectorFormInputs} 
																				formValidation={connectorFormValidation}
																				buttonposition="center"
																				onChange={onChange}
																				buttons={[]}
																			/> 
																		:
																			<LoadingForm 		
																				
																				data={connectorFormInputs} 
																				formValidation={connectorFormValidation}
																				buttonposition="center" 
																			/> 
																		}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</>
											}											
											{step===3 &&
                                                <>
													
													<div className="row " style={{minHeight:window.innerHeight-370}}>
													{loadingT && <Loading contained={true}/>}
														<div className="flex-center pt-3">
															<div className=" col-xs-12 flex-center">
																<div   className=" connector-form-wrapper wow fadeIn animated hover-up">
																	<div className="connector-form">
																		{!loading ?
																			<GenericForm 		
																				
																				data={connectorFormInputs2} 
																				formValidation={connectorFormValidation2}
																				buttonposition="center"
																				onChange={onChange}
																				buttons={[]}
																			/> 
																		:
																			<LoadingForm 																						
																				data={connectorFormInputs2} 
																				formValidation={connectorFormValidation2}
																				buttonposition="center" 
																			/> 
																		}
																	<div className="connector-form text-center">
																		<div className="">
																			{apiResponseMessage ==="init" ?
																			"You can test your connect before publish"
																			:
																				<span style={{minHeight:24, display:"block" }} className={ apiResponseStatus?"text-success":"text-danger" }>
																					{apiResponseMessage}
																				</span>
																			}
																		</div>
																		<button onClick={()=> {test() }} style={{minWidth:120}} className="e-btn e-primary mt-30">
																			Test your Api
																		</button>
																	</div>

																	</div>
																</div>
															</div>
														</div>
													</div>

													 

												</>
											}
											{step===4 &&
                                                <>
                                                    
                                                    <div className="row " style={{minHeight:window.innerHeight-370}}>
                                                        <div className="flex-center pt-3">
                                                            <div className=" col-xs-12 col-md-10 col-lg-8 col-xl-6 flex-center">
                                                                <div   className=" connector-form-wrapper wow fadeIn animated hover-up">
                                                                    <div className="connector-form">
                                                                        {!loading ?
                                                                            <GenericForm 		
                                                                                
                                                                                data={payTypeFormInputs} 
                                                                                formValidation={payTypeFormValidation}
                                                                                buttonposition="center"
                                                                                onChange={onChange}
                                                                                buttons={[]}
                                                                            /> 
                                                                        :
                                                                            <LoadingForm 		
                                                                                
                                                                                data={payTypeFormInputs} 
                                                                                formValidation={payTypeFormValidation}
                                                                                buttonposition="center" 
                                                                            /> 
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
											}

											{step===5 &&
												<CongratulationsConnector />
											}

                                        </div>
										
											<div className="e-card-actions">
											{step<5 &&
                                                <>
                                                    <div className="mb-1 col-xs-6 text-left">
                                                        <button style={{minWidth:120}} onClick={()=> { optionButton("back");  }} className="e-btn e-light">
                                                            Back
                                                        </button>
                                                    </div>
                                                    <div className="mb-1 col-xs-6 text-right">
                                                        <button onClick={()=> {optionButton("next")}} style={{minWidth:120}} className="e-btn e-primary">
                                                        {(step===4 && activeType ===2) || (step===3 && activeType === 1) ? (( action==="add"?"Create":"Update")+  " my connector") :"Next"}
                                                        </button>
                                                    </div>
                                                </>
											}
											</div>
										
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
           
  );
};

ManageConnector.propTypes = {};

export default ManageConnector;
