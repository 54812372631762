import * as React from 'react';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import './properties.component.css';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DataManager, Query, WebApiAdaptor, WebMethodAdaptor } from '@syncfusion/ej2-data';
import { getCookie } from '../../utils/Functions';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Page, Sort, RowDD, Selection, Inject } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import Loading from "../customdesign/Loading";

var gridOptInstance
var apimethodObj
var tabInstanceOpt
class CompFormCompValues extends React.Component {


    constructor(props) {
        super(props);
        //this.onAdd = this.onAdd.bind(this); 
        this.state = {
            // isExecuting:true ,
            // dataSource : [],
            option: "",
            value: "",
            headertext: [{ text: "Static Data" }, { text: "Dynamic Data" }],
            options: [],
            optionText: "",
            optionValue: "",
            apiurl: "",
            apidata: "",
            text: "",
            name: this.props.name,
            type: this.props.type,
            value: "",
            masterLoading: true,
        }
    }

    componentDidMount = () => {

        setTimeout(async function () {
            if (Array.isArray(this.props.value)) {
                this.setState({ ...this.state, options: this.props.value, masterLoading: false })
            } else {
                this.setState({ ...this.state, masterLoading: false })
            }


        }.bind(this), 250)


    }

    dialogTemplate = (props) => {

        return (<DialogFormTemplate {...props} />);
    }

    editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog', newRowPosition: 'Bottom', template: this.dialogTemplate };
    pageSettings = { pageCount: 5 };
    validationRule = { required: true };
    toolbarOptions = ['Add', 'Delete', 'Edit', 'Cancel', 'Search'];
    onChange = async (event) => {
        try {
            if (event.target) {
                this.setState({ ...this.state, [event.target.name]: event.target.value });
            } else {
                this.setState({ ...this.state, apimethod: apimethodObj.value });
            }
            if (!event.target?.temporal) {
                this.props.onChange({ target: this.state })
            }

        } catch (Exc) {
            console.log(">>>>Err", Exc)
            if (action != "") {
                console.log("action", action)
            }
        }
    };


    loadingData = () => {
        return (
            <div style={{ height: 330 }}>
                <Loading />
            </div>
        )
    }


    onRowDrop = (args) => {
        this.props.onChange({ target: { name: this.props.name, value: gridOptInstance.dataSource } })
    }

    actionComplete = (args) => {

        if (!args.item) {

            if (args.requestType === "save") {
                let delItem = -1
                for (let k = 0; k < gridOptInstance.dataSource.length; k++) {
                    let key = gridOptInstance.dataSource[k]
                    //console.log("comparando",  args.data.NAME == key.NAME)
                    if (args.data.NAME == key.NAME) {
                        delItem++
                    }
                }
                if (delItem > 0) {
                    let items = []
                    var selectedRows = gridOptInstance.dataSource.filter((it, index) => {

                        if (it.NAME == args.data.NAME) {
                            items.push(index);
                            gridOptInstance.deleteRecord("NAME", it);

                        }/*&& it.SORTNAME!= gridOptInstance.dataSource[delItem].SORTNAME*/
                        gridOptInstance.selectRows(items);
                    });

                    selectedRows = gridOptInstance.getSelectedRecords().filter(it => it.NAME == args.data.NAME /*&& it.SORTNAME!= gridOptInstance.dataSource[delItem].SORTNAME*/);
                    selectedRows.forEach((element) => {
                        //gridOptInstance.deleteRecord("NAME", element);  
                    });


                }

                this.props.onChange({ target: { name: this.props.name, value: gridOptInstance.dataSource } })

            }


        }

    }


    render() {
        return (this.state.masterLoading ? <Loading /> :

            <div className='control-pane'>
                <div className='control-section'>
                    <div className='col-12'>

                        <GridComponent
                            height='180'
                            actionComplete={this.actionComplete.bind(this)}
                            ref={(dialog) => (gridOptInstance = dialog)}
                            dataSource={this.state.options}
                            pageSettings={this.pageSettings}
                            toolbar={this.toolbarOptions}
                            allowPaging={false}
                            allowRowDragAndDrop={true}
                            allowSorting={true}
                            rowDrop={this.onRowDrop}
                            /*sortSettings={sortingOptions}*/
                            editSettings={this.editSettings}>
                            <ColumnsDirective>
                                {/** <ColumnDirective field='SORTORDER' headerText='Sort Order' width='120' validationRules={numberRules}  textAlign='Right' editType='numericedit' ></ColumnDirective> */}
                                <ColumnDirective field='NAME' headerText='Field Name' width='230' isPrimaryKey={true} validationRules={this.validationRule} ></ColumnDirective>
                                <ColumnDirective field='LABEL' headerText='Label' width='130'></ColumnDirective>
                                <ColumnDirective field='ISPK' headerText='Primary Key' width='130'></ColumnDirective>
                                <ColumnDirective field='TYPE' headerText='Field Type' width='130' ></ColumnDirective>
                                <ColumnDirective field='LENGTH' headerText='Length' width='80'></ColumnDirective>
                                <ColumnDirective field='ISNULL' headerText='Null' width='130'></ColumnDirective>
                                <ColumnDirective field='DEFAULT' headerText='Default Value' width='130'></ColumnDirective>
                                

                            </ColumnsDirective>
                            <Inject services={[Page, Toolbar, Edit, Sort, RowDD, Selection]} />
                        </GridComponent>
                    </div>
                </div>
            </div>

        );
    }
}

function DialogFormTemplate(props) {

    let NAME, LENGTH, TYPE, ISNULL, DEFAULT, ISPK, LABEL

    const [val, setval] = React.useState(props);
    


    function onChange(args) {
        
        let key = args.target.name;
        let value = args.target.value;
        setval(prevVal => ({ ...prevVal, [key]: value }));
        //setval(prevVal => ({ ...prevVal, ["ISPK"]: "YES" }));

    }

    let data = val;
 

    {/*
<ColumnDirective field='NAME' headerText='Field Name' width='230' isPrimaryKey={true} validationRules={this.validationRule} ></ColumnDirective>
<ColumnDirective field='TYPE' headerText='Field Type' width='130' ></ColumnDirective>
<ColumnDirective field='LENGTH' headerText='Length' width='80'></ColumnDirective>
<ColumnDirective field='ISPK' headerText='Primary Key' width='130'></ColumnDirective>
<ColumnDirective field='ISNULL' headerText='Null' width='130'></ColumnDirective>
<ColumnDirective field='DEFAULT' headerText='Desfault Vlue' width='130'></ColumnDirective>

*/}
    return (<div>
        <div className="form-row mt-2">
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <input ref={input => NAME = input} id="NAME" name="NAME" type="text" disabled={!data.isAdd} value={data.NAME} onChange={onChange.bind(this)} />
                    <span className="e-float-line"></span>
                    <label className="e-float-text e-label-top">Field Name</label>
                </div>
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <input  value={data.LABEL} id="LABEL" name="LABEL" type="text" onChange={onChange.bind(this)} />
                    <span className="e-float-line"></span>
                    <label className="e-float-text e-label-top">Label</label>
                </div>
            </div>
        </div>

        <div className="form-row mt-2">
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        height: 31,
                        borderBottom: "1px solid #c2c2c2",
                        marginBottom: 13
                    }}>
                        <label htmlFor={"ISPK"} style={{ padding: "0px 0px 0px 0", marginBottom: 0, fontWeight: "normal" }}> Primary key?</label>
                        <SwitchComponent cssClass='bar-color' onChange={() => {  onChange({ target: { name: "ISPK", value: data.ISPK ==="YES" ? "" : "YES", type: "switch" } }) }} checked={data.ISPK === "YES"} ></SwitchComponent>
                        <input  value={data.ISPK} id="ISPK" name="ISPK" type="hidden"  />

                    </div>

                </div>
            </div>
        </div>

        <div className="form-row mt-2">
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        height: 31,
                        borderBottom: "1px solid #c2c2c2",
                        marginBottom: 13
                    }}>
                        <label htmlFor={"ISNULL"} style={{ padding: "0px 0px 0px 0", marginBottom: 0, fontWeight: "normal" }}> Support null?</label>
                        <SwitchComponent cssClass='bar-color' onChange={() => {  onChange({ target: { name: "ISNULL", value: data.ISNULL ==="YES" ? "" : "YES", type: "switch" } }) }} checked={data.ISNULL === "YES"} ></SwitchComponent>
                        <input  value={data.ISNULL} id="ISNULL" name="ISNULL" type="hidden"  />
                    </div>

                </div>
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-12">
                <DropDownListComponent name="TYPE" onChange={onChange.bind(this)} id="TYPE" value={data.TYPE ? data.TYPE : "VARCHAR"}
                    dataSource={[
                        { text: "VARCHAR", value: "VARCHAR" },
                        { text: "INT", value: "INT" },
                        { text: "DOUBLE", value: "DOUBLE" },
                        { text: "DATETIME", value: "DATETIME" },
                        { text: "BLOB", value: "BLOB" },
                        { text: "LONGTEXT", value: "LONGTEXT" },
                    ]}
                    fields={{ text: 'text', value: 'value' }}
                    placeholder="Field Type" popupHeight='300px' floatLabelType='Always'></DropDownListComponent>
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <NumericTextBoxComponent
                        name={"LENGTH"}
                        format={'###'}
                        value={LENGTH}
                        floatLabelType="Auto"
                        onChange={({ value }) => onChange({ target: { name: "LENGTH", value } })}
                        placeholder={"Length"} />

                </div>
            </div>
        </div>

      

        <div className="form-row">
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <input ref={input => DEFAULT = input} value={data.DEFAULT} id="DEFAULT" name="DEFAULT" type="text" onChange={onChange.bind(this)} />
                    <span className="e-float-line"></span>
                    <label className="e-float-text e-label-top">Default Value*</label>
                </div>
            </div>
        </div>

        <div className="form-row">
            <div className="col-md-12">
                <span className='text-muted'>*Use [] for reserved words</span>
            </div>
        </div>



    </div>);
}

CompFormCompValues.propTypes = {};

export default CompFormCompValues;

