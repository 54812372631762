import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";

import { findValueById } from '../../utils/Functions'; 
var versionDialogInstance;
var versionsListInstance;
const DialogFlowVasTemplate = (props) => {

  const { defConfig,  defLang, } = useSelector((state) => state.Login);
  const { componentConfig, processSuccess,  processError, requesting, fetchSuccess, initialValues, validationSchema, rows} = useSelector( (state) => state.Process );
  const fields = { value: 'version', text: 'versionlabel' };
          
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const [noVersions,setNoVersions] = React.useState(false); 
  const [versions,setVersions] = React.useState([]); 
  const [version,setVersion] = React.useState(0); 
  const [definition,setDefinition] = React.useState(""); 
  
 
   
  React.useEffect(() => {
   
    //console.log("datos",props,props.id, rows)
    if (props.status && props.id>0){
      //console.log("cargamos");
      if (!requesting && fetchSuccess){
        

        var _versions=[]
        rows.map(( key) => {
				  _versions.push ({version: key.VERSION, versionlabel: "Version "+key.VERSION, definition: key.FLOWDEFINITION } )
        });
        setVersions(_versions)
        if (rows.length>0){
          setVersion(rows[0].VERSION)
          setDefinition(rows[0].FLOWDEFINITION)
          setNoVersions(false)
        }else{        
          setNoVersions(true)          
        }        
      }       
    }

  }, [props, rows, requesting, fetchSuccess]);
  
  const handleOnChange = async(event) => {
    console.log(event.definition)
    setVersion(event.version)
    setDefinition(event.definition)

  }
  const submitForm = async (event) => {
    
    await dispatch(ProcessActions.processData( "flows", "set-flow-version",{ id: props.id, version: version, flowdefinition:definition }, props.header ) )
    //props.submitForm(values)
    versionDialogInstance.hide()
  }; 
  return (
    <DialogComponent
			id="variablesDialogInstance"
      animationSettings={ { effect: 'FlipXUp' }}
			showCloseIcon={true}
			closeOnEscape={false}     
			ref={(dialog) => (versionDialogInstance = dialog)}
			isModal={true}
			open={props.open} 
      close={props.close}
      width="280px"
			header={"Select version"}
      buttons={noVersions?
                [{
                  click: () => {
                      //props.close();
                      versionDialogInstance.hide()
                  },
                  buttonModel: {
                      content: 'CANCEL',
                      isSecondary: true,
                  },
              }]
              :
              [
                {
                    click: () => {
                      submitForm();
                    },
                    buttonModel: {
                        content: 'SAVE',
                        isPrimary: true,
                    },
                },
                {
                    click: () => {
                        //props.close();
                        versionDialogInstance.hide()
                    },
                    buttonModel: {
                        content: 'CANCEL',
                        isSecondary: true,
                    },
                },
              ]}
			 
			position={ { X: 'center', Y: 'center' }}
			visible={props.status} 
		>               
    
    <div className='control-pane my-20'>
      <div className='control-section'>
        
          <div className='col-12' style={{ display: versions.length>0? "block":"none", visibility: versions.length>0?"initial":"hidden" }}>
            <DropDownListComponent
              dataSource={versions}
              name={"versions"} 
              fields={fields}
              select={({ itemData }) => {
              handleOnChange( itemData);
            }} 
              value={version}
            />
          </div>

          <div className='col-12' style={{ display: noVersions? "block":"none", visibility: noVersions?"initial":"hidden" }}>
            Sorry No versions founded
          </div>
         
      </div>
    </div>
    </DialogComponent>
  );
};
DialogFlowVasTemplate.propTypes = {};

export default DialogFlowVasTemplate;
