import actionTypes from "../action.types";
import * as ProcessService from "../../services/process.service";

export const clearMessages = () => {

  return (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_MESSAGE });
  };
};

export const processing = (proccessname ) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESSING, payload: {proccessname:proccessname}  });
    
  };
};

export const processed = (proccessname ) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESSED, payload: {proccessname:proccessname}  });
    
  };
};

export const executed = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_SUCCESS, payload: {message:data} });
  };
};


export const error = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_FAILED, payload: {message:data} });
  };
};

export const processData = (componentName, action, data, header) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST, payload: {proccessname:componentName} });
    ProcessService.processData(dispatch, componentName, action, data, header);
  };
};

export const fetchData = (componentName, action, data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST, payload: {proccessname:componentName} });
    ProcessService.fetchData(dispatch, componentName, action, data);
  };
};

export const getFormData = (data,apiName) => {
    return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST,payload: {proccessname:"getFormData"}   });
    //dispatch({ type: actionTypes.CLEAR_FORM });
    ProcessService.getFormData(dispatch, data, apiName);
  };
};

export const refreshFormData = (data) => {
  return (dispatch) => {

    dispatch({ type: actionTypes.PROCESS_REQUEST,payload: {proccessname:"resetFormData" }   });
     
    setTimeout( async function() {
		//console.log("call clear")
      dispatch({ type: actionTypes.CLEAR_FORM, payload:  data.importData }); 
    }.bind(this),500)
            
    
  };
};

export const getComponentConfig = (componentName,defLang) => {
	
  return (dispatch) => {
    let data = { code :componentName, domainid:6, defLang:defLang, id:0 }
    dispatch({ type: actionTypes.PROCESS_REQUEST,payload: {proccessname:"getComponentConfig"} });
    ProcessService.getComponentConfig(dispatch, data);
  };
};

export const getCustomPageConfig = (pageid,defLang) => {
	
  return (dispatch) => {
    
    dispatch({ type: actionTypes.PROCESS_REQUEST,payload: {proccessname:"getCustomPageConfig"} });
    let data = { pageid :pageid }

    ProcessService.getCustomPageConfig(dispatch, data);
  };
};

export const getTasks = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST,payload: {proccessname:"getTasks"} });
    ProcessService.getTasks(dispatch, data);
  };
};

export const executeTask = ( data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST, payload: {proccessname:"executingTask"} });
    ProcessService.executeTask(dispatch, data);
  };
};


export const getHistoryFlows = (data) => {
	
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST,payload: {proccessname:"getTasks"} });
    ProcessService.getHistoryFlows(dispatch, data);
  };
};


export const setMessage = (data ) => {
  return (dispatch) => {
    if(data.status){
      dispatch({ type: actionTypes.PROCESS_MESSAGE_SUCC, payload: data  });
    }else{
      dispatch({ type: actionTypes.PROCESS_FAILED, payload: data  });
    }
    
  };
};

export const setProcessed = (data ) => {
  return (dispatch) => {
      dispatch({ type: actionTypes.PROCESSED2, payload: data  });
 
  };
};

export const setProcessing = (data ) => {
  return (dispatch) => {
      dispatch({ type: actionTypes.PROCESSING2, payload: data  });
 
  };
};