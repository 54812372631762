import { createSpinner, showSpinner } from '@syncfusion/ej2-popups';
import * as React from 'react';

import { SkeletonComponent } from '@syncfusion/ej2-react-notifications';

export default class LoadingForm extends React.Component {
    
 
    render() {
      return (
        <div className="control-pane pl-1 pt-1" >
   
                    <div>
                        <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "left" , marginBottom:10 }} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                        <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "right", marginBottom:10 }} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                    </div>
                    <div>
                        <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "left" , marginBottom:10 }} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                        <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "right", marginBottom:10 }} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                    </div>

                    {this.props.showMoreRows &&
                        <div>
                            <div>
                                <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "left" , marginBottom:10 }} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                                <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "right", marginBottom:10 }} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                            </div>
                            <div>
                                <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "left" , marginBottom:10 }} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                                <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "right", marginBottom:10 }} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                            </div>
                            <div>
                                <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "left", marginBottom:10}} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                                <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "right" , marginBottom:10}} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                            </div>
                            <div>
                                <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "left", marginBottom:10}} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                                <SkeletonComponent shape='Rectangle' width='49%' style={{ float: "right" , marginBottom:10}} height='45px' shimmerEffect='Fade'></SkeletonComponent>
                            </div>
                        </div>
                    }

                                 
                    <div>
                        <SkeletonComponent shape='Rectangle' width='100%' style={{  marginBottom:10 }} height='65px' shimmerEffect='Fade'></SkeletonComponent>
                    </div>

                    <div>
                        <SkeletonComponent shape='Circle' width='45px'  style={{  marginBottom:10}} shimmerEffect='Pulse'></SkeletonComponent>
                        <SkeletonComponent shape='Rectangle' width='40%' style={{  marginBottom:10}} height='45px' shimmerEffect='Fade'></SkeletonComponent>                        
                    </div>
                   
                
  			</div>
  		);
    }
}