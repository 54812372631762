export default {
  INIT_APP: "INIT_APP",
  ACTIVE_FORM: "ACTIVE_FORM",
  USER_LOADED: "USER_LOADED",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  SHOW_DASHBOARD: "SHOW_DASHBOARD",
  HIDE_DASHBOARD: "HIDE_DASHBOARD",
  LOGOUT: "LOGOUT",
  PROCESS_REQUEST: "PROCESS_REQUEST",
  PROCESS_RESET: "PROCESS_RESET",
  PROCESS_SUCCESS: "PROCESS_SUCCESS",
  PROCESS_FAILED: "PROCESS_FAILED",
  PROCESS_ERROR: "PROCESS_ERROR",
  PROCESS_MESSAGE_SUCC: "PROCESS_MESSAGE_SUCC",

  FETCH_REQUEST: "FETCH_REQUEST",
  FETCH_RESET: "FETCH_RESET",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_FAILED: "FETCH_FAILED",
  FETCH_ERROR: "FETCH_ERROR",

  CLEAR_MESSAGE: "CLEAR_MESSAGE",

  CPAGE_LOADED: "CPAGE_LOADED",
  CPAGE_ERROR: "CPAGE_ERROR",
  CPAGE_RESET: "CPAGE_RESET",

  FORM_LOADED: "FORM_LOADED",
  FORM_ERROR: "FORM_ERROR",
  CLEAR_FORM: "CLEAR_FORM",
  COMPONENT_LOADED: "COMPONENT_LOADED",
  COMPONENT_ERROR: "COMPONENT_ERROR",
  PROCESSING: "PROCESSING",
  PROCESSED: "PROCESSED",
  PROCESSING2: "PROCESSING2",
  PROCESSED2: "PROCESSED2",
  RESUME_TASKS_REQUEST: "RESUME_TASKS_REQUEST",
  RESUME_TASKS_LOADED: "RESUME_TASKS_LOADED",
  RESUME_TASKS_FAILED: "RESUME_TASKS_FAILED",
  RESUME_TASKS_S_REQUEST: "RESUME_TASKS_S_REQUEST",
  TASKS_LOADED: "TASKS_LOADED",
  TASKS_FAILED: "TASKS_FAILED",
  HISTORY_LOADED: "HISTORY_LOADED",
  HISTORY_FAILED: "HISTORY_FAILED",
  RECOVERY_SUCCESS: "RECOVERY_SUCCESS",
  RECOVERY_FAILED: "RECOVERY_FAILED",
};
