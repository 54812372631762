 
import * as React from 'react';
import { ProgressBarComponent, ProgressBarAnnotationsDirective, ProgressBarAnnotationDirective, Inject, ProgressAnnotation } from '@syncfusion/ej2-react-progressbar';
import { getPalette } from '../../utils/Utils';
export default class App extends React.Component {
    circularSeg;
    
    content1 = '<div id="point1" style="font-size:18px;font-weight:bold;color:#fff;fill:#000"><span>100%</span></div>';
    progressLoad = (args) => {
      
        let selectedTheme = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.progressBar.progressColor = '#FFFFFF';
        args.progressBar.theme = (selectedTheme.charAt(0).toUpperCase() +
            selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast');
        if (args.progressBar.element.id === 'full-background') {
            //console.log("selectedTheme",selectedTheme)
            args.progressBar.trackColor = getPalette().primary;
            args.progressBar.opacity = '1';
            args.progressBar.progressColor = '#fff';

        }
    };
    render() {
      return (
        <div className="control-pane" style={{ 		        	 
                    zIndex: 1000,
		        	height: "100%", 
                    width:"100%", 
                    position: "fixed",
		        	display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#fff",
                    opacity: "0.85",
                    top: 0,
                    left: 0,
                    paddingLeft: 25,
                    paddingRight: 25
            }}>
            <div  style={{ zIndex: 1001, width: "100%", display: "flex",   justifyContent: "center", }}>
                <ProgressBarComponent id="full-background" ref={progress1 => this.fullBackground = progress1}
                                type='Circular'
                                width='120px'
                                height='120px'
                                cornerRadius='Round'
                                enableRtl={false}
                                radius='100%'
                                innerRadius='350%'
                                progressThickness={10}
                                trackThickness={80}
                                value={100}
                                animation={{
                                    enable: true,
                                    duration: this.props.timer?this.props.timer:2000 ,
                                    delay: 0,
                                }}
                                load={this.progressLoad.bind(this)}
                            >
                                <Inject services={[ProgressAnnotation]} />
                                <ProgressBarAnnotationsDirective>
                                    <ProgressBarAnnotationDirective content={this.content1}>
                                    </ProgressBarAnnotationDirective>
                                </ProgressBarAnnotationsDirective>
                                   </ProgressBarComponent>
            </div>
        </div>
  		);
    }
}