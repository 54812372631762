import { FormValidator } from '@syncfusion/ej2-inputs';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {useNavigate} from 'react-router-dom';
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { DataManager, Query, WebApiAdaptor,WebMethodAdaptor } from '@syncfusion/ej2-data';
import { getCookie,convertObjToArray, convertArrayToObj,testRestApi,testJS,getToken } from '../../utils/Functions';
import ApiClient from "../../api/ApiClient";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  CheckBoxComponent,
  RadioButtonComponent,
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import Typography from "../customdesign/Typography";
import CompFormDesinger from "./CompFormDesinger";
import CompDashboardDesinger from "./CompDashboardDesinger";
import CompFormAction from "./CompFormAction";
import CompFormInput from "./CompFormInput";
import CompFormOption from "./CompFormOption";
import CompFormOptionChart from "./CompFormOptionChart";
import Loading from "../customdesign/Loading";

var specialComponents={};
var dialogInstanceMessage;
const customerData={}

const Input = ({ label, name, value, floatBlur, floatFocus,onChange, type,   ..._rest }) => {
	
	const navigate = useNavigate();

	var values=[]
	var text=""
	var checkFields = { text: 'Name', value: 'Code' };
	var groupFields=  { text: 'Name', value: 'Code', groupBy: "Groupcondition" };
	let remoteFields, query


	let rest= _rest
	if ( _rest.values?.datatype){
		if ( rest.values.datatype=="static"){
			rest["values"]  = rest.values.options
		}else{
			rest["apiurl"] = rest.values.apiurl
			rest["apidata"] = rest.values.apidata
			rest["apimethod"] = rest.values.apimethod
			rest["apitoken"] = rest.values.apitoken 
			rest["fields"] =  { text: rest.values.text , value: rest.values.value }
		 
		}
	}

	if(rest.apiurl){
		var token=getCookie(process.env.REACT_APP_NAME+'_usertoken')
		if(rest.apitoken){
			if(rest.apitoken!=="" && rest.apitoken!=="NOTOKEN"){
				token = rest.apitoken
			}
		}
		let url = rest.apiurl
		if(rest.apiurl.indexOf("http")<0 ){
			url = process.env.REACT_APP_API_CONFIG+ rest.apiurl
		}		
		customerData[name] = new DataManager({
			url: url,
			adaptor: rest.apimethod==="post"? new WebMethodAdaptor : new WebApiAdaptor,
			headers:[ {'Authorization': 'Bearer ' + token}, {"getInfo": "simplified"} ] ,
			crossDomain: true
		});
		 remoteFields = rest.fields	// Example{ text: 'FLOWGROUP', value: 'FLOWGROUPID' };
		 query = new Query().addParams( '$apidata' , rest.apidata).select([rest.fields.text,rest.fields.value]).take(1000).requiresCount();
	}

	try{
		if(type!== "space"){ 
			
			if(rest.values){
				if(rest.values[0]){
					let keys = Object.keys(rest.values[0])	
					rest.values.map(( key, index) => {
						if (key["GROUPCONDITION"]){
							values.push({Code: (key[ keys[0]]).toString() , Name: key[ keys[1]]  , Groupcondition: key["GROUPCONDITION"] })
						}else{
							values.push({Code: (key[ keys[0]]).toString() , Name: key[ keys[1]] })
						}
						
						if ( key[ keys[0] ]==value){
							text=  key[ keys[1] ]
						}
					});
					if (value=="" && rest.values[0] ){
						value = rest.values[0][keys[0]]
					}
				}
			}
		}
	}catch(e){
		console.log(">>>>err:",  name, value,type, e)
	}
	
  	switch (type) {
		
		case "title":
			
			return (
			
				<Typography variant={rest?.variant}>
					{label}
				</Typography>
				
			);

		case "daterange":

		let startValue = new Date(new Date().setDate(1));
		let endValue = new Date();

			return (
			
				<DateRangePickerComponent   startDate={startValue} endDate={endValue} format='dd/MMM/yy hh:mm a' ref={(scope) => { specialComponents[name] = scope; }} >

				</DateRangePickerComponent>
				
			);

		case "options":
				return (
					<CompFormOption value={value} ref={(scope) => { specialComponents[name] = scope; }}  type={type} name={name} label={label} onChange={onChange}  />
				);
		case "actions":
				return (
					<div className='my-1 e-card e-custom-card'>
						<span className="comp-label"> {label} </span>
						<CompFormAction value={value} ref={(scope) => { specialComponents[name] = scope; }}  type={type} name={name} label={label} onChange={onChange}  />
					</div>
				);
		case "inputs":				 
				return (
					<div className='my-1 e-card e-custom-card'>
						<span className="comp-label"> {label+"loadi"} </span>
						<CompFormInput value={value} ref={(scope) => { specialComponents[name] = scope; }}  type={type} name={name} label={label} onChange={onChange}  />
					</div>
				);
		case "optionsChart":
			return (
				<CompFormOptionChart value={value} ref={(scope) => { specialComponents[name] = scope; }}  type={type} name={name} label={label} onChange={onChange}  />
			);
		case "text":
			return (
			
				<TextBoxComponent id={"empty_"+name}					 
					name={"empty_"+name} 
					disabled={true}					
					placeholder={label} 
					value={value} 
					cssClass= { (rest?.className? rest.className: "e-outline") +(rest.invalid?" e-error":"")}  
					floatLabelType="Auto" />
				
			);
		case "select":		
		return (
			<TextBoxComponent id={"empty_"+name}					 
					name={"empty_"+name} 
					disabled={true}					
					placeholder={label} 
					value={value} 
					cssClass= { (rest?.className? rest.className: "e-outline") +(rest.invalid?" e-error":"")}  
					floatLabelType="Auto" />
			
		)
		case "scriptarea":
				return (
				
					<TextBoxComponent id={"empty_"+name}
						onFocus={floatFocus} onBlur={floatBlur} 
						data-msg-containerid={name+"Error"}
						disabled={true}
						name={"empty_"+name} 
						multiline={true} 
						placeholder={label} 
						value={value}
						onChange={({  value }) => onChange({target:{ name, value} })}
						cssClass= {rest?.className? rest.className: "e-outline"}  
						floatLabelType="Auto" />
					
				);
		case "switch":
			return (
				<div disabled={true} style={{display: "flex",
							  alignItems: "center",
					 		  height: 31}}>
					<label htmlFor={name} style={{ padding: "0px 0px 0px 0", marginBottom:0, fontWeight: "normal" }}> {label}</label>
					<SwitchComponent disabled={true} cssClass='bar-color' id={name} name={"empty_"+name}  onChange={({  value }) => onChange({target:{ name, value, type:"switch"} })}  checked={value!==""} ></SwitchComponent>
				</div>
			);

			

		case "numeric":
			return (
				<>
					<NumericTextBoxComponent
						id={"empty"+name} 
						name={"empty_"+name} 
						disabled={true}
						format= {rest.format?rest.format:'###'}
						value={value}
						floatLabelType="Auto" 
						onChange={({  value }) => onChange({target:{ name, value} })}
						cssClass= {rest?.className? rest.className: "e-outline"}  
						placeholder={label} />
					
				</>	
			);
		case "textarea":
			return(
				<TextBoxComponent  
					id={"empty_"+name} 
					disabled={true}
					data-msg-containerid={name+"Error"}
					multiline={true} 
					placeholder={label} 
					value={value}					
					cssClass= {rest?.className? rest.className: "e-outline"}  
					onChange={({  value }) => onChange({target:{ name, value} })}	
					floatLabelType="Auto"  />
			)

		case "password":
		return (
		
			<TextBoxComponent id={name}
				disabled={true}
				onFocus={floatFocus} onBlur={floatBlur} 
				data-msg-containerid={name+"Error"}
				name={name} 
				type="password"
				placeholder={label} 
				value={value}
				onChange={({  value }) => onChange({target:{ name, value} })}
				cssClass= {rest?.className? rest.className: "e-outline"}  
				floatLabelType="Auto" />
			
		);
		
		case "link":
		return (
		
			<div
				style={  rest.style?rest.style:{} }
				className="item-link"
				onClick={() => {
				navigate(value);
			}}>
				{label}
			</div>
			
		);
		
		case "radio":
		return rest?.values.map((e) => (
			<RadioButtonComponent
			key={e}
			disabled={true}
			label={e}
			value={e}
			name={name} 
			onChange={({ value }) => onChange({target:{ name, value} })}
			checked={value === e}
			/>
		));
	 

		case "multiple":
			console.log("values", values)
			let fixedValues =[]
			if(value && value.length>0){
				value.map((key) => {
					fixedValues.push(key.toString())
				});
			}
		return (

				 
			<MultiSelectComponent  ref={(scope) => { specialComponents[name] = scope; }} dataSource={values}
				fields={groupFields} placeholder={label} mode="CheckBox" 
				showSelectAll={true}
				value={fixedValues}
				showDropDownIcon={true} 
				cssClass= {rest?.className? rest.className: "e-outline"}  
				onChange={({ value }) => onChange({target:{ name, value} })}
				filterBarPlaceholder={label} popupHeight="350px">
				<Inject services={[CheckBoxSelection]} />
			</MultiSelectComponent>
		);

		case "dropdown":
		return (
			<DropDownListComponent
				dataSource={rest?.options}
				name={"empty_"+name} 
				disabled={true}
				select={({ itemData }) => {
					onChange(  {target:{ name, value:itemData.value} });
				}}
			
			
			value={value}
			/>
		);
		case "componentformcontainer":
			return (
				<CompFormDesinger type={type} name={name} label={label} onChange={onChange} value={value} />
			);

		case "componentdashboardcontainer":
				return (
					<CompDashboardDesinger type={type} name={name} label={label} onChange={onChange} value={value} />
				);
	
		case "checkbox":
		return (
			<CheckBoxComponent
				label={rest?.checkboxLabel}
				disabled={true}
				name={"empty_"+name}  
				onChange={(e) => onChange({target:{ name, value: e.target.checked } })}
				checked={value}
			/>
		);
		case "space":
			 
			/*return(
				<div class="form-group">
					<div class="e-float-input e-control-wrapper">
						<div style={{  height: "0px" }}></div>
						
					</div>
				</div>
			)*/
			return(
				 
					 
						<div className="" style={{  height: rest.size.lg==12?0:50 }}></div>						
				 
				 
			)

		case "autocomplete":
			return(
				<ComboBoxComponent ref={(scope) => { specialComponents[name] = scope; }} 
					ignoreAccent={true} allowFiltering={true}
					name={"empty_"+name} 
					cssClass={"e-custom-combobox"}
					floatLabelType="auto"
					value={value.toString()}
					fields={checkFields}
				 	dataSource={values} placeholder={label}/>		
/**
				<MultiSelectComponent  ref={(scope) => { specialComponents[name] = scope; }} dataSource={values}
					fields={checkFields} placeholder={label} mode="CheckBox" 
					showSelectAll={true}
					value={value.toString()}
					showDropDownIcon={true} 
					filterBarPlaceholder={label} popupHeight="350px">
					<Inject services={[CheckBoxSelection]} />
				</MultiSelectComponent>
				 */
			);
		
		case "image":
			
			return(
				<input
					type="hidden"
					name={"empty_"+name} 
					value={value.toString()}
				/>
			)
		default:
      		return ("no:"+type);
  }
};



class GenericForm extends React.Component{
	
	constructor(props) {
		 
    super(  props);
    this.animationSettings = { effect: 'Fade' };
    this.state = {
	 		formValues:{},
	 		buttons:[],
			originalformInputs:[],
			events:[],
			loading:false,
			apiResponseMessage:"init",
			//showResponse:false,
			expandStatus: false,
			wWidth:window.innerWidth,
			formValidation:[]

		}
	}

	

	componentWillUnmount() { 
		this.setState({  ...this.state, events:[] }) 
    } 
	async componentDidMount () { 
		// initialize the form rules
		if (this.props.wfpflowid && this.props.wfptaskid && this.props.officecode ){
			//console.log("w" , window.innerWidth )
			this.setState({  ...this.state, wWidth: window.innerWidth, wfpflowid : this.props.wfpflowid,  wfptaskid : this.props.wfptaskid, officecode: this.props.officecode }) 
		}
		
		if (this.props.formValidation ){
			this.setState({  ...this.state, formValidation: this.props.formValidation }) 
		}
		await this.prepareForm( this.props.data, this.props.buttons, this.props.wfpflowid , this.props.wfptaskid, this.props.officecode, this.props.datapostload)
   
	} 

	
	 


	prepareForm = async (preFormData, buttons, wfpflowid, wfptaskid, officecode,  datapostload ) => {

		let options = {
			rules: {
			}
		}; 
		let formValues={}  

		this.setState({  ...this.state, loading:true, originalformInputs: [], formInputs:[] }) 

		let formButtons =[]
		if(buttons){
			formButtons = (this.props.buttons).map((b, index) => {
				
				return (
					<div className={(b.size?b.size:"col-xs-12 col-md-6 col-lg-4 col-xl-3")+" min-padding "}>
				 
			
						<ButtonComponent
							key={"btn"+index}
							className={  (this.props.buttons.length>1?"mr-10":"")+ " "+ (b.className?b.className:"e-success")}
							id =  {b.name}
							name= {b.name}
							onClick={this.onSubmitClick.bind(this)} 
							style={   b.style?b.style: { textTransform:"uppercase", fontWeight:  "500"  }}
						>{b.label?b.label:defConfig[defLang].savedatalabel}
						</ButtonComponent>
					</div>
				);
			});
		}	       
		preFormData.sort(function (a, b){
			return ((b.layout_row > a.layout_row) ? -1 : ((b.layout_row < a.layout_row) ? 1 : 0));
		})	 
		let formData = convertArrayToObj(preFormData)		  
		

		

		formData = convertObjToArray(formData,"layout_row")

//		console.log("FINAL", formData)

		let formInputs = (formData).map((_e, index) => {

			//let _e = formData[key]
			let e  
			if (_e.layout_id ){
				e = {
					rules  : {},
					label  : _e.label,
					name   : _e.name ,
					action : _e.action,
					value  : _e.value?_e.value:"", 
					values : _e.values?_e.values:[],
					api    : _e.api,
					type   : _e.type ,
					size   : { xs: 12, lg: _e.layout_sizeX } ,
					variant : _e.variant,
					disabled : false,
					format:'###',
					checkboxLabel: "",
					className: "",
					addbutton   : _e.addbutton ,
					path        : _e.path,
					iframetype  : _e.iframetype,
					formValidation: this.state.formValidation

				}
			}else{
				e = _e
				e["formValidation"] = this.state.formValidation
			}

			let { rules, label, name, value, type, addbutton } = e;			
			//define rules for input field
			let formattedRules = {
				required: [true, '* Field Required']
			};
			if (e.rules){
				options.rules[name] = formattedRules;			
			}
			formValues[name] = value?value:"";			
			let xs = 12
			let lg = 12
			if (e.size?.xs){
				xs =e.size.xs
			}	 		
			if (e.size?.lg){
				lg =e.size.lg
			}

			let addPath=""
			if (type === "iframe"){ 
				if(wfpflowid && wfptaskid && officecode){ 
					if (e.iframetype==="EXTERNAL"){
						if (this.props.flowactions.length>0){
							addPath = "?wfpflowid="+wfpflowid+"&wfptaskid="+wfptaskid + "&officecode="+officecode + "&options="+this.props.flowactions.toString()+"&token="+getToken()
						}else{
							addPath = "?wfpflowid="+wfpflowid+"&wfptaskid="+wfptaskid + "&officecode="+officecode +"&token="+getToken()
						}
						
					}else{
						addPath = "?wfpflowid="+wfpflowid+"&wfptaskid="+wfptaskid+ "&officecode="+officecode + "&token="+getToken()
					}
				}else{
					addPath = "?wfpflowid="+wfpflowid+"&wfptaskid="+wfptaskid+ "&officecode="+officecode + "&token="+getToken()
				}
 
			}

			return (type === "iframe" ?
					<div style={{textAlign:"right"}}>
						{e.iframetype &&
							<ButtonComponent 
								id="openiFrame"
								name={e.path+addPath+"&popup=true"}
								style={{margin:"0 10px 10px 0"}}
								className={"e-btn e-primary"}
							>Open external Form
							</ButtonComponent>
						}
						<iframe width="100%" style={{border:"0"}} height="640px" src={ e.path+addPath} />
					</div>
				:
				type === "hidden" ?
				<input
					type="hidden"
					name={name} 
					value={value?value.toString():""}
				/>
			:
				<div key={"field"+index} className={"padding-1 col-xs-"+xs + " col-lg-"+lg  } >

					<div key={"div"+index}  className={(type=="space" && lg==12 ?"":
					( !e.disabled && e.editOnline ? "form-group-edit":"form-group" )  
					)+ ( addbutton?"  ": ""  ) +  ( type==="scriptarea" || name==="postloadscript" || name==="preloadscript" || name==="apidata" || name==="apidata"? " source-code":"" ) }>  

						<Input
							key={"input"+index}
							name={name}
							value={value}
							type={type}
							label={label}
							floatFocus={this.floatFocus}
							floatBlur={this.floatBlur}
							onChange={this.onChange}
							{...e}
						/>
						
					</div>  
				</div>
			);
		});   		
		this.setState({  ...this.state, loading:false, formButtons:formButtons, originalformInputs: formData, formInputs:formInputs,formValues:formValues , buttons: buttons }) 
	}

  
  render() {
    return (
	<>
		{<Loading  contained={true} customTop={"10%"} />}
    	<div className='control-panel mt-1'>		   
			<div className="row no-padding">
				<div className={"no-padding col-sm-1"+(this.props.expandaction?1:2)+" col-lg-1"+(this.props.expandaction?1:2)+" col-1"+(this.props.expandaction?1:2)}>
					{this.props.datatitle &&
						<Typography variant="h3" mt={2} style={{borderBottom: "3px solid #4c38c9", lineHeight: "5rem"}}>
							{this.props.datatitle && this.props.datatitle}
						</Typography>
					}
				</div>				
			</div>

			<div className="row no-padding">
				<div className={"no-padding col-sm-1"+(this.props.expandaction && !this.props.datatitle ?1:2)+" col-lg-1"+(this.props.expandaction && !this.props.datatitle ?1:2)+" col-1"+(this.props.expandaction && !this.props.datatitle ?1:2)}>
					{this.props.datasubtitle &&
						<Typography variant="h5" style={{borderBottom: "1px solid #acacac"}} mt={  this.props.datatitle?1:0} mb={3}>
							{this.props.datasubtitle} 
						</Typography>
					}
				</div>
				{/*this.props.expandaction && !this.props.datatitle && this.state.wWidth>799 &&
					<div className={"col-sm-1 col-lg-1 col-1 expand-btn"} id="expandOption" >					 
						<span class={"e-icons e-zoom-"+(this.state.expandStatus?"out":"in") }></span>
					</div>
  				*/}
			</div>
		    <div className='validation_wrapper'>
				<div className={"control_wrapper"+(this.props.datatitle?" mt-2":"") } id="formContainer">					 
					<div className="row no-padding">
						{this.state.formInputs?this.state.formInputs:"NO_DATA"}

				
					</div>		
	          </div>
	        </div>	      
	    </div>
		<div className="row no-padding">			
				{this.state.formButtons}			
		</div>		
  				 
	</>
    );
  }
}
export default GenericForm;
	 	