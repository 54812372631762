import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
 	ButtonComponent,
  } from "@syncfusion/ej2-react-buttons";

import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Filter, Sort, Edit, Toolbar, Aggregate, Page,DetailRow } from '@syncfusion/ej2-react-grids';
import { AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import Utils from "../../utils/Utils";
import { prepareForm, prepareGetHistoryFlows, prepareGetFlowVars} from '../../utils/Functions';
import GenericForm from "../../components/forms/GenericForm";
import * as ProcessActions from "../../redux/actions/process.actions";
import { Browser } from "@syncfusion/ej2-base";
import Loading from "../../components/customdesign/Loading";

import DialogFlowDiagram from "../../components/dialog/DialogFlowDiagram";

import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar';
let gridHistoryFlows;
const SearchWF = (props) => {
      
	const { defConfig, params, defLang, activeForm } = useSelector((state) => state.Login);
    
    const dispatch = useDispatch();
    const [formFields,setFormFields]    = React.useState([] )
    const [values,setValues]    = React.useState({})
    const [historyTasks,setHistoryTasks]    = React.useState({})
    const [historyFlows,setHistoryFlows]    = React.useState({})
    const [currentParams,setCurrentParams]    = React.useState({initial:true})
    const [showDiagram, setShowDiagram]    = React.useState(false)
    const [loading, setLoading]    = React.useState(false)
    const [flowId, setFlowId]    = React.useState(0)
    const [id, setId]    = React.useState(0)
    const [version, setVersion]    = React.useState(0)
    const [executedTasks, setExecutedTasks]    = React.useState([])
    const [header,setHeader]    = React.useState([] )
    const [flowVars,setFlowVars]    = React.useState([] )
    
    const filterOptions = { type: 'Excel' };
    const renderingMode = 'Vertical';

    const toolbarOptions = [  
         { text: Browser.isDevice?'':'Refresh', prefixIcon:'e-icons e-refresh',  tooltipText: 'Refresh', id: 'refresh'},
         { text: Browser.isDevice?'':'Show Diagram', prefixIcon:'e-icons e-group-1',  tooltipText: 'Show Diagram', id: 'ShowDiagram'},    
         //{ text: Browser.isDevice?'':'Show Vars', prefixIcon:'e-icons e-list-unordered',  tooltipText: 'Show Vars', id: 'ShowVars'},    
        'Search'];

    function onChangeRender(e) {
        if (e.checked) {
            gridHistoryFlows.rowRenderingMode = 'Horizontal';
        }else {
            gridHistoryFlows.rowRenderingMode = 'Vertical';
        }
    }
    const onChange = async (event) => {
		let _values = values
        console.log(event)
		_values[event.target.name] = event.target.value
		setValues(_values)
	};

    const loadForm = async (event) => {
		if (formFields.length===0){
            let _formFields= await prepareForm (Utils.formSearchWF)
            console.log("load search form",_formFields)
            setFormFields(_formFields)
        }
	};

    const loadHeader = async (_flowid) => {
        var flowid = _flowid
        var vars = [
            {VARNAME: "WFPFLOWID", VARLABEL:"Flow Id"},
            {VARNAME: "flowname", VARLABEL:"Flow Name"},
            {VARNAME: "version", VARLABEL:"Version"},
            {VARNAME: "office", VARLABEL:"Office"},
            {VARNAME: "startDate", VARLABEL:"Start Date"},
            {VARNAME: "endDate", VARLABEL:"End Date"},
            
        ]
		if (flowVars.length===0){
            let data = {id:0}
            setLoading(true)            
            let _flowVars= await prepareGetFlowVars (data)                    
            _flowVars.result.map(function (key) {
                flowVars.push(key)
                if (flowid ===0){
                    flowid = key.FLOWID
                }
                if (flowid === key.FLOWID){ 
                    vars.push(key)
                }
            })			
            setHeader(vars)
            setLoading(false)
        }else{
            flowVars.map(function (key) {
                if (flowid ===0){
                    flowid = key.FLOWID
                }
                if (flowid === key.FLOWID){ 
                    vars.push(key)
                }
            })	
            setHeader(vars)
        }        
	};

 	React.useEffect(()=>{
        loadForm();
        loadHeader(0);        
    },[])

    const searchFlows = async (params) => {	 
        let data ={}
        if (!params){
            data = currentParams
        }else{
            data ={
                from   : params.daterange[0],
                to     :  params.daterange[1],
                flowid : params.flowid
            }
        }
        if (!data.flowid || data.flowid===""){         
            let resp = {status:false, message:"Flow is empty"}
            dispatch(ProcessActions.setMessage(resp ))	
        }else{           
            setCurrentParams(data)
            setLoading(true)
            await loadHeader( data.flowid); 
            let response = await prepareGetHistoryFlows(data)
            setHistoryTasks(response.tasks)
            setHistoryFlows(response.flows)
            setLoading(false)
        }
	};

    const contextToolbarMenuTaskClick = async (args)=> {  

        if (args.item.id =="refresh"){
            searchFlows()
        }else if (args.item.id =="ShowDiagram"){
            const selectedrecords = gridHistoryFlows.getSelectedRecords();
            console.log(selectedrecords[0], "flowrow")
            if (selectedrecords.length ===1){
                setShowDiagram(true)
                setFlowId(selectedrecords[0].flowid)
                setId(selectedrecords[0].WFPFLOWID)
                setVersion(selectedrecords[0].version)
                let _executedTasks =[]
                console.log(selectedrecords[0],historyTasks, selectedrecords[0].ID)

                historyTasks.map(function (t){ 
                    if (t.WFPFLOWID === selectedrecords[0].ID){
                        _executedTasks.push(t.TASKID)
                    }
                    })
                setExecutedTasks(_executedTasks)                
            }else{
                alert("No records selected for selected operation");
            }
        }
    }
    
    const formDialogClose= () => {
        setShowDiagram(false)
    }

    const childGrid = {
        dataSource: historyTasks,
        queryString: 'WFPFLOWID',
        allowPaging: true,
        pageSettings: { pageSize: 10, pageCount: 5 },
        columns: [
            
            { field: 'TASK', headerText: 'Task', width: 100 },
            { field: 'STARTDATE', headerText: 'Start date', width: 100 },
            { field: 'ENDDATE', headerText: 'End date', width: 100 },
            { field: 'USERNAME', headerText: 'Username', width: 100 },
            { field: 'ADDCOMMENTARY', headerText: 'Response', width: 100 },
            { field: 'OFFICE', headerText: 'Office', width: 100 },
            { field: 'ACTOR', headerText: 'Actor', width: 100 },
            
        ]
    };
    
  
  return (
    
	<div style={{  overflow: "auto" }}>
        {loading && <Loading timer={4000} contained={true}/>}    
        {showDiagram &&
			<DialogFlowDiagram status={showDiagram} close={formDialogClose} id={id} version={version} flowId={flowId} executedTasks={executedTasks}/>
		}
		<div className="row no-padding mb-1">
    		<div className="col-sm-12 col-md-12 col-md-6 col-lg-4 min-padding">
                {formFields.length>0 &&
                    <GenericForm 
                        data={formFields} 
                        onChange={onChange}
                        buttons={[{label: "search", name:"Search", action:searchFlows}]}
                    /> 
                } 
    	    </div>            
    	</div>
        {!loading &&
            <div style={{padding : Browser.isDevice? "5px":"0"  }}>
                {Browser.isDevice ? (
                    <div className="-e-mobile-layout">
                        <div className="+e-mobile-content">
                            <GridComponent id="adaptivebrowser" toolbarClick={contextToolbarMenuTaskClick} 
                            toolbar={toolbarOptions} 
                            dataSource={historyFlows} 
                            height='100%'  
                            ref={(g) => { gridHistoryFlows = g }} 
                            enableAdaptiveUI={true} 
                            rowRenderingMode={renderingMode} 
                            allowFiltering={true} 
                            allowSorting={true} allowPaging={true} filterSettings={filterOptions} pageSettings={{ pageCount: 3, pageSize: 5, pageSizes: true }} >
                             <ColumnsDirective>                        
                                {header && header.map((b, index) => {            
                                    return (  index===0?
                                        <ColumnDirective field={b.VARNAME} headerText={b.VARLABEL} width='120' />
                                        :
                                        <ColumnDirective field={b.VARNAME} headerText={b.VARLABEL} width='150' />
                                    );
                                })}
                            </ColumnsDirective>
                            <Inject services={[Filter, Sort, Toolbar, Page]} />
                            </GridComponent>
                        </div>
                    </div>
                ) : (
                    <GridComponent id="adaptivedevice" 
                        allowTextWrap={true}
                        childGrid={childGrid} 
                        filterSettings={filterOptions} 
                        toolbarClick={contextToolbarMenuTaskClick} 
                        toolbar={toolbarOptions} 
                        dataSource={historyFlows}  ref={(g) => { gridHistoryFlows = g }}  
                        /*height='100%'*/
                        allowFiltering={true} allowSorting={true} allowPaging={true} 
                        pageSettings={{ pageCount: 3, pageSize: 10, pageSizes: true }} >
                        <ColumnsDirective>                        
                            {header && header.map((b, index) => {            
                                return (  index===0?
                                    <ColumnDirective field={b.VARNAME} headerText={b.VARLABEL} width='120' />
                                    :
                                    <ColumnDirective field={b.VARNAME} headerText={b.VARLABEL} width='150' />
                                );
                            })}
                        </ColumnsDirective>                    
                    <Inject services={[Filter, Sort, DetailRow, Toolbar, Page]} />
                    </GridComponent>
                )}
            </div>
        }   
    </div>
  );
}

SearchWF.propTypes = {
//  window: ListData.func,
};

export default SearchWF;
