import React, { useState } from "react";
import PropTypes from "prop-types";
//import Header from "../components/header/Header";
import { Outlet } from "react-router-dom";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Browser } from "@syncfusion/ej2-base";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { useNavigate } from "react-router-dom";
import * as Actions from "../redux/actions/user.actions";
import * as ProcessActions from "../redux/actions/process.actions";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import {
  AppBarComponent,
  TreeViewComponent,
  SidebarComponent,
} from "@syncfusion/ej2-react-navigations";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";

import Utils from "../utils/Utils";
import Typography from "../components/customdesign/Typography";
import { useSelector, useDispatch } from "react-redux";

import { findValueById, find } from "../utils/Functions";

import CompTreeview from "../components/treeview/CompTreeview";

let dialogObj;
let dialogObj2;
let old_password;
let password;
let password2;
let toastUpdPass;
const MainLayout = ({ openPanel, setOpenPanel }) => {
  let WFListInstance;
  let MListInstance;
  let CListInstance;

  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const {
    defConfig,
    params,
    defLang,
    activeForm,
    usertoken,
    username,
    userid,
    menu,
    menuWFL,
    customMenu,
    useracronym,
    tasks,
    role,
    isNew,
    gettingResumeTasks,
  } = useSelector((state) => state.Login);
  const { refreshResumeTasks } = useSelector((state) => state.Process);

  const [menuFields, setMenuFields] = React.useState({});

  const [dataSourceWFL, setDataSourceWFL] = React.useState([]);

  const [totalAssigned, setTotalAssigned] = React.useState(0);
  const [totalActives, setTotalActives] = React.useState(0);
  const [totalOuts, setTotalOuts] = React.useState(0);

  const [dataSource, setDataSource] = React.useState([]);
  const [dataSourceC, setDataSourceC] = React.useState([]);
  const [dataSourceD, setDataSourceD] = React.useState([]);
  const [path, setPath] = React.useState("");

  const width = "280px";
  const target = ".main-sidebar-content";
  const mediaQuery = "(min-width: 600px)";
  const windowWidth = window.innerWidth;
  const loadPage = (event) => {
    let _link = findValueById(dataSource, event, "id", "link");
    if (_link) {
      setPath(_link);
      if (_link.indexOf("manage") >= 0) {
        dispatch(
          ProcessActions.getComponentConfig(_link.replace("/manage/", ""))
        );
      }
      navigate(_link);
    }
    if (windowWidth <= 600) {
      sidebarobj.toggle();
    }
  };

  const loadPageC = (event) => {
    let _link = findValueById(dataSourceC, event, "id", "link");
    if (_link) {
      setPath(_link);
      if (_link.indexOf("manage-page") >= 0) {
        dispatch(
          ProcessActions.getCustomPageConfig(_link.replace("/manage-page/", ""))
        );
      }
      navigate(_link);
    }
    if (windowWidth <= 600) {
      sidebarobj.toggle();
    }
  };

  const loadPageD = (event) => {
    let _link = findValueById(dataSourceD, event, "id", "link");
    if (_link) {
      setPath(_link);
      navigate(_link);
    }
    if (windowWidth <= 600) {
      sidebarobj.toggle();
    }
  };

  const loadPageWFL = (event) => {
    if (event.indexOf("TASK") >= 0) {
      let _link = event.split("_");
      let flowId = _link[1];
      let taskid = _link[2];
      setPath(_link);
      dispatch(ProcessActions.getTasks({ taskId: taskid, flowId: flowId }));
      navigate("/getTasks/" + taskid + "/" + flowId);
    } else if (event.indexOf("SEARCH") >= 0) {
      navigate("/searchWF/");
    }
    if (windowWidth <= 600) {
      sidebarobj.toggle();
    }
  };
  const navigate = useNavigate();
  React.useEffect(() => {
    setPath(location.pathname);
    if (!usertoken) {
      navigate("/login");

      //window.location.reload();
    } else {
      let data = [];

      if (isNew) {
        setTimeout(
          function () {
            dialogObj2.show();
          }.bind(this),
          250
        );
      }
      let goToCustomMenu = [];
      menu.map(function (key) {
        let id = key.id;
        let item = {
          id: id,
          pid: 999999,
          name: key.title,
          label: defConfig[defLang][key.code],
          hasChild: key.items ? true : false,
        };
        if (key.link) {
          item["link"] = key.link;
        }

        data.push(item);
        if (key.items) {
          key.items.map(function (key2) {
            let item = {
              id: key2.id,
              name: key2.title,
              label: defConfig[defLang][key2.code],
              hasChild: key2.items ? true : false,
              pid: id,
              link: key2.link,
            };
            if (key2.isPage) {
              //console.log("is page!!!")
              goToCustomMenu.push(key2);
            } else {
              data.push(item);
            }

            if (key2.items) {
              let id = key2.id;
              key2.items.map(function (key3) {
                console.log("-", key3.code);
                let item = {
                  id: key3.id,
                  name: key3.title,
                  label: defConfig[defLang][key3.code],
                  hasChild: key3.items ? true : false,
                  pid: id,
                  link: key3.link,
                };
                data.push(item);
              });
            }
          });
        }
      });

      let item = {
        id: 999999,
        name: "Administration",
        label: defConfig[defLang]["administrationlabel"],
        hasChild: true,
        type: "root",
        link: "#",
      };
      data.push(item);
      console.log("data", data);
      let fields = {
        dataSource: data,
        id: "id",
        parentID: "pid",
        text: "name",
        hasChildren: "hasChild",
      };
      console.log("fields", fields);

      setMenuFields(fields);
      if (data.length > 2) {
        setDataSource(data);
      } else {
        setDataSource([]);
      }

      let tmpGroups = [];
      let cdata = [];
      let contMenu = 1;
      /*      console.log("m", menu);
      console.log("cm", customMenu);
      console.log("pages", goToCustomMenu);*/
      customMenu.map(function (key) {
        if (tmpGroups.indexOf(key.group) < 0) {
          tmpGroups.push(key.group);
          // console.log("entr", key.group)
          let item = {
            id: contMenu,
            pid: 999997,
            name: key.group,
            hasChild: true,
          };

          cdata.push(item);

          customMenu.map(function (key2) {
            if (key.group === key2.group) {
              let item = {
                id: key2.id,
                name: key2.title,
                hasChild: key2.items ? true : false,
                pid: contMenu,
                link: key2.link,
              };
              cdata.push(item);
            }
          });
          contMenu++;
        }
      });

      goToCustomMenu.map(function (key) {
        let item = {
          id: key.id,
          name: key.title,
          hasChild: key.items ? true : false,
          pid: 999997,
          link: key.link,
        };
        cdata.push(item);
      });
      let itemC = {
        id: 999997,
        name: "Pages",
        label: defConfig[defLang]["pageslabel"],
        hasChild: true,
        type: "root",
        link: "#",
      };
      cdata.push(itemC);
      setDataSourceC(cdata);

      let ddata = [
        /*
        {
          id: 999987,
          name: "Developer Center",
          label: defConfig[defLang]["developercenterlabel"],
          hasChild: true,
          type: "root",
          link: "#",
        },*/
        {
          id: 999985,
          name: "My Connectors",
          label: defConfig[defLang]["myapislabel"],
          pid: 999987,
          link: "/manage/connectors",
        },
        {
          id: 999986,
          name: "Community Connectors",
          label: defConfig[defLang]["communityapislabel"],
          pid: 999987,
          link: "/public/connectors",
        },
      ];

      if (userid === "0") {
        let newElement = {
          id: 999987,
          name: "Developer Center",
          label: defConfig[defLang]["developercenterlabel"],
          hasChild: true,
          type: "root",
          link: "#",
        };
        ddata = [newElement].concat(ddata);
      }

      //console.log(ddata);
      setDataSourceD(ddata);

      if (tasks === undefined) {
        //console.log("GETTING RES TASKs", tasks)
        dispatch(Actions.getResumeTasksByUser());
      }

      if (menuWFL && menuWFL.length > 0) {
        let groupAssingned = 0;
        let groupActives = 0;
        let groupOuts = 0;

        let groups = [];
        let flows = [];

        //    console.log(menuWFL)
        menuWFL.map(function (g) {
          if (groups.indexOf(g.FLOWGROUPID) < 0) {
            let hasChildG = false;
            menuWFL.map((f) => {
              let flowActives = 0;
              let flowAssigned = 0;
              let flowOuts = 0;
              if (f.FLOWGROUP === g.FLOWGROUP) {
                hasChildG = true;
                if (flows.indexOf(f.FLOWID) < 0) {
                  let hasChildF = false;
                  let fid = f.FLOWID;
                  let _tasks = [];
                  menuWFL.map((t) => {
                    if (f.FLOWID === t.FLOWID) {
                      if (_tasks.indexOf(t.TASKID) < 0) {
                        let taskActives = 0;
                        let taskAssigned = 0;
                        let taskOuts = 0;
                        tasks.map(function (key) {
                          if (
                            key.FLOWID === t.FLOWID &&
                            key.TASKID === t.TASKID
                          ) {
                            (taskActives = key.TOTAL),
                              (taskAssigned = parseInt(key.TOTAL_ASSIGNED));
                            taskOuts = parseInt(key.TOTAL_OUT);
                          }
                        });
                        hasChildF = true;
                        flowActives = flowActives + taskActives;
                        flowAssigned = flowAssigned + taskAssigned;
                        flowOuts = flowOuts + taskOuts;
                        if (t.oldVersion) {
                          if (taskActives >= 0) {
                            _tasks.push(fid + "_T" + t.TASKID);
                          }
                        } else {
                          _tasks.push(fid + "_" + t.TASKID);
                        }
                      }
                    }
                  });

                  flows.push(f.FLOWID);
                }
                groupActives = groupActives + flowActives;
                groupAssingned = groupAssingned + flowAssigned;
                groupOuts = groupOuts + flowOuts;
              }
            });
          }
        });
        setTotalActives(groupActives);
        setTotalAssigned(groupAssingned);
        setTotalOuts(groupOuts);
      }
    }
  }, [usertoken, menu, customMenu, menuWFL, tasks, refreshResumeTasks, isNew]);

  let sidebarobj;

  const set_menuWFL = (_menuWFL) => {
    let dataWFL = [];
    let groups = [];
    let flows = [];
    _menuWFL.map(function (g) {
      if (groups.indexOf(g.FLOWGROUPID) < 0) {
        let gid = g.FLOWGROUPID;
        let groupActives = 0;
        let hasChildG = false;
        _menuWFL.map((f) => {
          let flowActives = 0;
          if (f.FLOWGROUP === g.FLOWGROUP) {
            hasChildG = true;
            if (flows.indexOf(f.FLOWID) < 0) {
              let hasChildF = false;
              let fid = f.FLOWID;
              let _tasks = [];
              _menuWFL.map((t) => {
                if (f.FLOWID === t.FLOWID) {
                  if (_tasks.indexOf(t.TASKID) < 0) {
                    let taskActives = 0;
                    if (tasks) {
                      tasks.map(function (key) {
                        if (
                          key.FLOWID === t.FLOWID &&
                          key.TASKID === t.TASKID
                        ) {
                          taskActives = key.TOTAL;
                        }
                      });
                    }

                    hasChildF = true;

                    flowActives = flowActives + taskActives;

                    if (t.oldVersion) {
                      if (taskActives > 0) {
                        let item = {
                          id: "TASK_" + fid + "_" + t.TASKID,
                          taskid: t.TASKID,
                          name: t.TASK.toLowerCase(),
                          hasChild: false,
                          pid: fid,
                          link: "/getTasks/" + t.TASKID + "/" + fid,
                          actives: taskActives,
                          sort: t.SORTORDER_F + "_" + t.SORTORDER_T,
                        };
                        dataWFL.push(item);
                        _tasks.push(fid + "_T" + t.TASKID);
                      }
                    } else {
                      let item = {
                        id: "TASK_" + fid + "_" + t.TASKID,
                        taskid: t.TASKID,
                        name: t.TASK.toLowerCase(),
                        hasChild: false,
                        pid: fid,
                        link: "/getTasks/" + t.TASKID + "/" + fid,
                        actives: taskActives,
                        sort: t.SORTORDER_F + "_T" + t.SORTORDER_T,
                      };
                      dataWFL.push(item);
                      _tasks.push(fid + "_" + t.TASKID);
                    }
                  }
                }
              });

              let item = {
                id: f.FLOWID,
                name: f.FLOW.toLowerCase(),
                hasChild: hasChildF,
                pid: gid,
                actives: flowActives,
                sort: f.SORTORDER_F + "",
              };
              dataWFL.push(item);
              flows.push(f.FLOWID);
            }
            groupActives = groupActives + flowActives;
          }
        });

        groups.push(g.FLOWGROUPID);
        let item = {
          id: gid,
          //  pid: 999999,
          name: g.FLOWGROUP.toLowerCase(),
          hasChild: hasChildG,
          type: "root",
          actives: groupActives,
          sort: "1", //change when use more flowgroups
        };
        dataWFL.push(item);
      }
    });

    //    console.log("dataWFL",dataWFL)
    let item = {
      id: "SEARCH",
      pid: 999999,
      cssicon: "",
      name: "Search Flows",
      label: defConfig[defLang]["searchflowslabel"],
      hasChild: false,
      link: "/searchWF/",
      sort: "999998",
    };
    dataWFL.push(item);
    let itemM = {
      id: 999999,
      cssicon: "",
      name: "Flows",
      label: defConfig[defLang]["flowslabel"],
      hasChild: true,
      type: "root",
      link: "#",
      sort: "1",
    };
    //dataWFL.push(itemM);

    dataWFL.sort(function (a, b) {
      return b.sort > a.sort ? -1 : b.sort < a.sort ? 1 : 0;
    });
    return dataWFL;
  };

  const toggle = () => {
    sidebarobj.toggle();
  };
  const dispatch = useDispatch();
  const logout = (event) => {
    if (dialogObj) {
      dialogObj.hide();
    }

    dispatch(Actions.logout());
  };

  const updatePassword = (event) => {
    let isInvalid = false;
    let message = "";
    if (password.value.length < 8) {
      message = defConfig[defLang].messagePasswordVal0;
      isInvalid = true;
    } else {
      if (password.value !== password2.value) {
        message = defConfig[defLang].messagePasswordVal1;
        isInvalid = true;
      } else {
        if (password.value === old_password.value) {
          message = defConfig[defLang].messagePasswordVal2;
          isInvalid = true;
        }
      }
    }
    if (isInvalid) {
      setTimeout(
        function () {
          toastUpdPass.show({
            content: message,
            cssClass: "e-toast-danger",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    } else {
      let data = {
        password: password.value,
        old_password: old_password.value,
      };
      dispatch(Actions.updatePassword(data));
      dialogObj2.hide();
      old_password.value = "";
      password.value = "";
      password2.value = "";
    }
  };

  const items = [
    {
      text: defConfig[defLang].changepassword,
      code: "changePass",
      iconCss: "e-icons e-edit",
    },
    {
      text: defConfig[defLang].logoutlabel,
      code: "logout",
      iconCss: "e-icons e-export",
    },
  ];
  const actionComplete = (args) => {
    //console.log("args", args.item.code)
    if (args.item.code == "logout") {
      //console.log("quiere salir")
      dialogObj = DialogUtility.confirm({
        title: defConfig[defLang].logoutlabel,
        content:
          '<div class="new" style=" margin: 13px 0; display: flex;flex-direction: column;align-items: center;">' +
          '<p><span class="circle-border"><span class="e-icons e-export" style="position: relative; top: 3px; font-size: 20px; padding:5px 0 0 0; font-weight: 700;"></span></span> ' +
          ' <b style="font-size:18px; font-weight: 500 !important;">' +
          defConfig[defLang].confirmlogout +
          " </b></p></div>",
        okButton: {
          click: logout.bind(this),
          text: defConfig[defLang].logoutlabel,
          cssClass: "e-primary",
        },
        cancelButton: {
          click: cancelDialog,
          text: defConfig[defLang].cancellabel,
        },
        animationSettings: { effect: "Fade", delay: 0, duration: 400 },
        style: { width: 320 },
        position: { X: "center", Y: "center" },
      });
    } else if (args.item.code == "changePass") {
      dialogObj2.show();
    }
  };

  const cancelDialog = (args) => {
    //console.log("cancel")
    dialogObj.hide();
  };

  return Utils.excludeSideBar.indexOf(location.pathname) >= 0 ? (
    <Outlet />
  ) : (
    <div className="control-container" id="responsive-wrapper">
      <ToastComponent
        ref={(toast) => {
          toastUpdPass = toast;
        }}
        position={{ X: "Right", Y: "Bottom" }}
        showCloseButton={true}
      ></ToastComponent>
      <DialogComponent
        ref={(scope) => {
          dialogObj2 = scope;
        }}
        width="320px"
        height="360px"
        id="dialogObj2"
        header={defConfig[defLang].changepassword}
        showCloseIcon={isNew ? false : true}
        isModal={true}
        buttons={
          isNew
            ? [
                {
                  buttonModel: {
                    content: defConfig[defLang].changelabel,
                    cssClass: "e-primary",
                    isPrimary: true,
                  },
                  click: () => {
                    updatePassword();
                  },
                },
              ]
            : [
                {
                  buttonModel: {
                    content: defConfig[defLang].changelabel,
                    cssClass: "e-primary",
                    isPrimary: true,
                  },
                  click: () => {
                    updatePassword();
                  },
                },
                {
                  buttonModel: {
                    content: defConfig[defLang].cancellabel,
                    cssClass: "e-flat",
                  },
                  click: () => {
                    dialogObj2.hide();
                  },
                },
              ]
        }
        visible={false}
      >
        <div className="mt-30">
          <TextBoxComponent
            type="password"
            floatLabelType="Auto"
            placeholder={defConfig[defLang].oldpasswordlabel}
            ref={(scope) => {
              old_password = scope;
            }}
          ></TextBoxComponent>
          <div className="mt-20">
            <TextBoxComponent
              type="password"
              floatLabelType="Auto"
              placeholder={defConfig[defLang].newpasswordlabel}
              ref={(scope) => {
                password = scope;
              }}
            ></TextBoxComponent>
          </div>
          <div className="mt-20"></div>
          <TextBoxComponent
            type="password"
            floatLabelType="Auto"
            placeholder={defConfig[defLang].repeatpasswordlabel}
            ref={(scope) => {
              password2 = scope;
            }}
          ></TextBoxComponent>
        </div>
      </DialogComponent>

      <div id="reswrapper">
        <div>
          <span id="statusText"></span>
          <AppBarComponent className="appBarHeader" style={{ padding: 0 }}>
            <ButtonComponent
              cssClass="e-inherit"
              iconCss="e-icons e-menu"
              onClick={toggle}
            ></ButtonComponent>
            <div className="e-folder">
              <div className="e-folder-name">
                <img
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ height: "34px", cursor: "pointer" }}
                  src={
                    process.env.REACT_APP_CUSTOM_LOGO_IMAGE_MINI ||
                    "/images/logo_mini_w.png"
                  }
                />
              </div>
            </div>
            <div className="e-appbar-spacer e-appbar-item"></div>
            {/*
            <ButtonComponent cssClass="e-inherit" iconCss="e-icons e-comments">
              {!Browser.isDevice ? defConfig[defLang].support : ""}
            </ButtonComponent>
            */}
            {totalOuts > 0 && (
              <ButtonComponent
                cssClass="e-inherit"
                iconCss="e-icons e-hand-gestures"
              >
                {!Browser.isDevice ? defConfig[defLang].taken : ""}
                {totalAssigned > 0 && (
                  <span className="e-badge e-badge-success e-badge-notification e-badge-overlap">
                    {totalAssigned}{" "}
                  </span>
                )}
              </ButtonComponent>
            )}
            {totalOuts > 0 && (
              <ButtonComponent cssClass="e-inherit" iconCss="e-icons e-eye">
                <>
                  {!Browser.isDevice ? defConfig[defLang].expired : ""}
                  {totalAssigned > 0 && (
                    <span className="e-badge e-badge-danger e-badge-notification e-badge-overlap">
                      {totalOuts}{" "}
                    </span>
                  )}
                </>
              </ButtonComponent>
            )}
            <DropDownButtonComponent
              select={actionComplete}
              items={items}
              iconCss="e-ddb-icons e-profile"
            >
              <span className="e-avatar e-avatar-small  e-avatar-circle ">
                {useracronym}
              </span>
            </DropDownButtonComponent>
          </AppBarComponent>
        </div>
        <SidebarComponent
          id="sideTree"
          className="sidebar-treeview"
          ref={(Sidebar) => (sidebarobj = Sidebar)}
          width={width}
          target={target}
          closeOnDocumentClick={false}
          mediaQuery={mediaQuery}
          isOpen={windowWidth > 600}
        >
          <div className="res-main-menu">
            <div className="table-content primary">
              <p className="main-menu-header mt-4">
                {/* <!-- Card Component --> */}

                <div className="e-card-content e-avatar-content">
                  {/* <!-- XLarge Circle Avatar Component --> */}
                  <div className="e-avatar e-avatar-xxlarge e-avatar-circle">
                    <img
                      onClick={() => {
                        navigate("/");
                      }}
                      style={{ cursor: "pointer" }}
                      className="image"
                      src="/images/users/user.png"
                      alt="avatar"
                    />
                  </div>
                </div>

                <Typography variant="h4" mb="0" style={{ textAlign: "center" }}>
                  {defConfig[defLang].welcomeh1}
                </Typography>
                <Typography
                  variant="h4"
                  mb="30"
                  style={{ fontWeight: 700, textAlign: "center" }}
                >
                  <p
                    className={
                      (!Browser.isDevice ? "h4" : "h2") +
                      " text-bold primary-color mb-1"
                    }
                  >
                    {username}
                  </p>
                  <ButtonComponent
                    id="refresh-wfl-menu"
                    cssClass="e-flat e-primary e-menu-icon-refresh"
                    iconCss="e-btn-icon e-icons e-refresh "
                    onClick={() => {
                      dispatch(Actions.getSimpleResumeTasksByUser());
                    }}
                  ></ButtonComponent>
                </Typography>

                {!gettingResumeTasks && tasks.length >= 0 ? (
                  <CompTreeview
                    dataSource={set_menuWFL(menuWFL)}
                    name={"menuWFL"}
                    nodeClicked={loadPageWFL}
                    cssClass="template-tree"
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      marginLeft: 40,
                      position: "relative",
                      top: 3,
                      marginBottom: 8,
                    }}
                  >
                    <span className={"e-flows-gray"}> </span>
                    <span
                      style={{ fontWeight: 500, fontSize: 16, paddingTop: 3 }}
                      className="treeName"
                    >
                      {gettingResumeTasks
                        ? defConfig[defLang].refreshingtasks
                        : defConfig[defLang].flowslabel}
                    </span>
                  </div>
                )}

                {dataSource.length > 1 && (
                  <>
                    <CompTreeview
                      dataSource={dataSource}
                      name={"menu"}
                      nodeClicked={loadPage}
                      cssClass="template-tree"
                    />
                  </>
                )}

                {dataSourceC.length > 1 && (
                  <>
                    <CompTreeview
                      dataSource={dataSourceC}
                      name={"customMenu"}
                      nodeClicked={loadPageC}
                      cssClass="template-tree"
                    />
                  </>
                )}
                {dataSourceD.length > 0 && role == 1 && (
                  <>
                    <CompTreeview
                      dataSource={dataSourceD}
                      name={"devMenu"}
                      nodeClicked={loadPageD}
                      cssClass="template-tree"
                    />
                  </>
                )}
              </p>
            </div>
            <div></div>
          </div>
        </SidebarComponent>
        <div className="main-sidebar-content" id="main-text">
          <div
            className="sidebar-content"
            style={{ height: "100%", padding: Browser.isDevice ? 5 : 20 }}
          >
            <div
              className="border-bottom-style-1 e-card e-custom-card"
              style={{
                minHeight: windowHeight - 100,
                background: path === "/public/connectors" ? "#fafafa" : "#fff",
              }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {};

export default MainLayout;
