import React from "react";
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../../redux/actions/process.actions";
import ApiClient from "../../../api/ApiClient"; 
import EndPoint from "../../../api/Endpoints"; 
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {  find,getCookie  } from '../../../utils/Functions';
import {
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";

class ListConnectors extends React.Component{
	
	constructor(props) {
		 
    super(  props);
    this.animationSettings = { effect: 'Zoom' };
    this.state = {
      templates:[],
      categories:[],
      categorySelected:"",
      selectImage:"",
	  value:""
		}
  }

  onChange =(event) =>{
	console.log(event.target.name, event.target.value)
	this.setState({  ...this.state, [event.target.name] : event.target.value }) 
  }

  search =(event) =>{
	console.log("search")
	 
  }
  
  componentWillUnmount() {    
    document.removeEventListener('click', this.checkClick);
  }

  async componentDidMount () { 
	document.removeEventListener('click', this.checkClick);
    document.addEventListener("click",this.checkClick );
    var token=getCookie(process.env.REACT_APP_NAME+'_usertoken')

	
    if (token){
      try{
        await this.reloadData();
      }catch(Ex){
        console.log("Session closed")
      }
    }
  }

  reloadData = async (event) => {       

    //let response = await ApiClient.doPost(process.env.REACT_APP_MASTER_API_CONFIG+"/public/components/get-templates", {type: "task" });
	let response = await ApiClient.doPost(process.env.REACT_APP_API_CONFIG+"/components/get-templates", {type: "task" });

    let categories=[]
    let templates=[]
    for (let i in response.result)   {
      let key  = response.result[i]

      let config = key.CONFIG

      try{
        config = JSON.parse( key.CONFIG)
        key["CONFIG"] = config
      }catch(ex){

      }

      try{
        let data = JSON.parse( key.DATA)
        key["DATA"] = data
      }catch(ex){
        
      }
     
     
      if ( categories.indexOf( config.category)<0 ){
        categories.push(config.category)
      }
      try{
        let images = config.images.split(",")        
        key["images"] = images
      }catch(ex){         
        key["images"] = config.images
      }

 
      if (key.images.length===0){
        key.images.push( "/images/users/defTemplate.jpg" )
      }
      templates.push(key)
    }

    let response2 = await ApiClient.doPost( EndPoint.getPrivateTemplates, {type : "task", componenttypecode: "templates" } );

    for (let i in response2.result)   {
      let key  = response2.result[i]
      
        let images =[]
        images.push( "/images/users/defTemplate.jpg" )        
        key["images"] = images

        let config = key.CONFIG

        try{
          config = JSON.parse( key.CONFIG)
          key["CONFIG"] = config
        }catch(ex){
          
        }
 
        try{
          let data = JSON.parse( key.DATA)
          key["DATA"] = data
        }catch(ex){
          
        }

        key["CONFIG"] = {
          autor: key.INSERT_USER,
          description: config,
          category: "Private"
        }
      templates.push(key)
    }
    console.log("templates",templates)
    this.setState({  ...this.state, templates:templates, categories:categories, categorySelected: categories[0] }) 

  }
  
  checkClick =  (event) => {       

		console.log("ch", event.target.id)
        if (event.target.id.indexOf("LOADtemplate_")>=0){
            this.getTemplate(event.target.id)
        }else if (event.target.id.indexOf("LOADtemplateP_")>=0){
            this.getTemplateP(event.target.id)
        }else if (event.target.id.indexOf("SHOWtemplate_")>=0){

          let comp = event.target.id.replace("SHOWtemplate_","")
  		    console.log("c", comp)
          this.setState({  ...this.state, showMore: true, selectTemplate :comp, selectImage:"",readMore:false }) 
        
        }else if (event.target.id.indexOf("SHOWtemplateP_")>=0){
          
          this.setState({  ...this.state, showMore: true, selectTemplate : ""}) 

        }else if (event.target.id.indexOf("go-back")>=0){
          
          this.setState({  ...this.state, showMore: false, selectTemplate : ""}) 

        }else if (event.target.id.indexOf("read-more")>=0){
         
          this.setState({  ...this.state, readMore: !this.state.readMore}) 

        }else if (event.target.id.indexOf("selectImage_")>=0){

          let comp = event.target.id.replace("selectImage_","")
  	 
          this.setState({  ...this.state, showMore: true, selectImage :comp }) 
        
        }else if (event.target.id.indexOf("selectCategory_")>=0){

          let comp = event.target.id.replace("selectCategory_","")
   console.log("cat",comp)
          this.setState({  ...this.state,  categorySelected :comp }) 
        
        }
    }

  getTemplate =  (event) => {	  
		let comp = event.replace("LOADtemplate_","")
		let pos = find( this.state.templates, comp, "COMPONENTNAME" ) 
		let response = this.state.templates[pos] 
    this.props.setTemplate(response)
    console.log("response",response)
  }

  getTemplateP =  (event) => {	  
		let comp = event.replace("LOADtemplateP_","")
		let pos = find( this.props.privateTemplates, comp, "COMPONENTNAME" ) 
		let response = this.props.privateTemplates[pos] 
    console.log("response",response)
    this.props.setTemplate(response)
  }


  render() {
	return (
		<div className="template-container">    

			<div className="row mt-1">   
				<div className="pt-1 col-lg-2 col-md-3  col-sm-12 left-col">   
					<div className="e-input-group">
						<input className="e-input" name="search" onChange={this.onChange} type="text" placeholder="Find your connector" />
						<span className="e-input-group-icon e-search-icon e-icons e-search" onClick={this.search} onMouseDown={this.search} onMouseUp={this.search}></span>
					</div>
				</div>
			</div>
			<div className="row">   
				<div className="pt-1 col-lg-2 col-md-3  col-sm-12 left-col">   

					<div className="">                    
					 <h5 className="text-bold">Connector type</h5>                  
					</div>

					<div className="item">                    
					<div id={"selectCategory_"+"ALLTEMPLATES"} >Connectors </div>                    
					</div>

					<div className="item">                    
					<div  id={"selectCategory_"+"Private"} >Forms</div>
					</div>

					<div className="item">                    
					<div  id={"selectCategory_"+"Private"} >Entire Flows</div>
					</div>

					<div className="item-connector-header">                    
					<h5 className="text-bold">Browse by category</h5>                    
					</div>

					{this.state.categories.map( (item,i ) => {  return(
						<div style={{fontSize:"1.20rem"}} id={"selectCategory_"+item} className="item" key={"cat"+i}>
						{item}
						</div>
					)
						}) 
					}
				</div>
				<div className="col-lg-10 col-md-9 col-sm-12 right-col">  
					
					{!this.state.showMore ?

					<div className="row">  
						<div className="text-bold mt-1 col-sm-12 col-lg-12">                    
						<div > <h5 className="text-bold"> {this.state.categorySelected} </h5> </div>
						</div>                      
						{this.state.templates.map( (item, index) => {  return( (item.CONFIG.category ===this.state.categorySelected || this.state.categorySelected ==="ALLTEMPLATES"  ) &&
						<div className="col-lg-4 col-sm-6 mt-1" key={"col1_"+item.COMPONENTNAME}>
							<div className="card-connector">    
								<div className="row item-connector">
									<div className="col-3 flex-center flex-middle" key={"col2_"+item.COMPONENTNAME}>
										<img onClick={this.checkClick} className="icon-connector" style={{cursor:"pointer"}} src={item.images[0]} id =  {"SHOWtemplate_"+item.COMPONENTNAME}  key={"i"+index}/> 
									</div>
									<div className="col-9 pl-1 " key={"col3_"+item.COMPONENTNAME}>
										<div className="title">
											{item.COMPONENTNAME.length>20 ?  item.COMPONENTNAME.substr(0,17)+"...":item.COMPONENTNAME   }
										</div>
										<ButtonComponent
											key={"btn"+index}
											className="e-secondary-mini"
											id =  {"LOADtemplate_"+item.COMPONENTNAME}
											name= "Use_Template"
										 
										>Install<span className="e-icons e-download"></span>  
										</ButtonComponent>
									</div>
								</div>
								<div className="row mt-1">
									<div className="col-12 body" key={"col_"+item.COMPONENTNAME}>
										{item.CONFIG.description.length>60 ?  item.CONFIG.description.substr(0,57)+"...":item.CONFIG.description   }
									</div>									 
								</div>

								{/*
								<div className="row" key={"r"+item}>
									<div  className="col-xs-12 no-padding  flex-right flex-middle e-connector-footer"  >
										<span style={{textDecoration:"underline"}}> More </span>
										<span style={{marginLeft:3}} className=" e-icons e-zoom-in"></span>  
									</div> 
								</div> 
								*/}
							</div>
						</div>
						)
						}) 
						}
					</div>  
				:
					<div className="row">  
					<div className="text-bold my-2 col-sm-12 col-lg-12 no-padding">                    
						<div style={{paddingLeft: 15}} className="cursor-pointer mb-2" onClick={this.checkClick} id="go-back">{"<< Back"} </div>                    


						{this.state.templates.map( (item, index) => {  return( item.COMPONENTNAME ===this.state.selectTemplate &&
						<div className="col-lg-12 col-sm-12" key={"col_"+item.COMPONENTNAME}>
							<div className="item-single">                              

							<div className="row my-2 flex-middle" key={"r"+item}>
								<div className="col-lg-9 col-sm-6 no-padding  "   >
								<div  key={"t"+index}>
								<span className="item-title">  {item.COMPONENTNAME} </span> |  <span className="item-autor">  {item.CONFIG.autor} </span>
								</div>
								</div>
								<div className="col-lg-3 col-sm-6 no-padding text-right"   >

								<ButtonComponent
									key={"btn"+index}
									onClick={this.checkClick}
									className="e-secondary"
									id =  {"LOADtemplate_"+item.COMPONENTNAME}
									name= "Use_Template"
									style={ { textTransform:"capitalize", fontWeight:  "500", fontSize: "1.2rem"  }}
								>Install<span className="e-icons e-download"></span>  
								</ButtonComponent>
								</div> 
							</div> 

							
							
							<div className="item-description" key={"d"+item}>
								{/*item.CONFIG.description*/}
								{this.state.readMore ? item.CONFIG.description+ " " :
								item.CONFIG.description.length>120  ?  item.CONFIG.description.substr(0,117)+"...":item.CONFIG.description+ " " }

								{item.CONFIG.description.length>120 && !this.state.readMore ?
								<span className="cursor-pointer" id ="read-more" >
									Read more
								</span> 
								:item.CONFIG.description.length>120?
								
								<span className="cursor-pointer" id ="read-more" >
									Hide
								</span> :
								<span className="cursor-pointer" id ="read-more" ></span>
								}

							</div> 


							<div className="row  " key={"r"+item}>
								<div className="col-lg-10 col-sm-9 no-padding" style={{marginRight: 10}} >
								{this.state.selectImage=="" ?

									<img className="full-image" src={item.images[0]}  key={"i"+index}/> 
								:
									<>
										{item.images.map( (image, index2) => { return( image === this.state.selectImage &&
										<img className="full-image" src={image}  key={"i"+index}/> 
										)
										}) 
									}
									</>
								}

								</div>
								<div className="col-lg-2 col-sm-3 no-padding text-right tumb-container"  style={{marginRight: -10}}  >

									{item.images.map( (image, index2) => { return(
									<img onClick={this.checkClick}  className="tumb-image cursor-pointer" id={"selectImage_"+image } src={image}  key={"i2_"+index2}/> 
									)
									}) 
								}
								</div> 
							</div> 
							</div>
						</div>
						)
						}) 
						}
					</div>  
					</div>  
				}
				</div>
			</div>
	  	</div>  
	);
	}
}
export default ListConnectors; 
