import ApiClient from "../api/ApiClient";
import actionTypes from "../redux/action.types";
import * as Actions from "../redux/actions/user.actions";
import * as ActionsP from "../redux/actions/process.actions";
import EndPoint from "../api/Endpoints";
import { getCookie} from '../utils/Functions';
export const Login = async (dispatch, data) => {
  try {
    const response = await ApiClient.doPost(EndPoint.login, data);
    if (response.token) {
    	dispatch(Actions.loginSuccess(response));
    } else {
    	dispatch(Actions.loginFailed({ error: response.message }));
    }
  } catch (error) {
  	console.log(">>>>", error)
		dispatch(Actions.loginFailed({ error: "error"+error }));
    
  }
};
export const CreateUser = async (dispatch, data) => {
  try {
    const response = await ApiClient.doPost(EndPoint.CreateUser, data);
    if (response.success) {
    	return {
		    type: actionTypes.LOGIN_SUCCESS,
		    payload: response,
		  };
    } else {
    	return {
		    type: actionTypes.LOGIN_FAILED,
		    payload: { error: response.message },
		  };
    }
  } catch (error) {
  	console.log(">>>>", error)
		return {
		    type: actionTypes.LOGIN_FAILED,
		    payload: { error: "error"+error },
		  };    
  }
};

export const RecoveryPass = async (dispatch, data) => {
	try {
			
			var email = getCookie(process.env.REACT_APP_NAME+'_recoverymail_'+data.email)
			
			if (email == "SEND"){
				dispatch(Actions.recoveryFailed({ error: "<WAIT TO SEND RECOVERY EMAIL AGAIN>" }));
			}else{
				
				var response = await ApiClient.doPost(EndPoint.RecoveryPass, data);
			  if (response.status) {
				  response["email"] = data.email
				  dispatch(Actions.recoverySuccess(response));
			  }else{
				  dispatch(Actions.recoveryFailed({ error: response.message }));
			  }
			 }
	   
	} catch (error) {
		//console.log(">>>>", error)
		  return {
			  type: actionTypes.LOGIN_FAILED,
			  payload: { error: "error"+error },
			};    
	}
  };
  
export const updatePassword = async (dispatch, data) => {
	try {
	  const response = await ApiClient.doPut(EndPoint.updatePassword, data);
  
	  if (response.status) {
		  dispatch({ type: actionTypes.PROCESS_SUCCESS, payload: response });
	  } else {
		  dispatch({ type: actionTypes.PROCESS_FAILED, payload: response });
	  }
	
	} catch (error) {
		console.log(">>>>", error)
		dispatch({ type: actionTypes.PROCESS_ERROR, payload: {error : "ERROR:"+error} });
	}
  };

export const getResumeTasksByUser = async (dispatch, data) => {
	try {

		var token=getCookie(process.env.REACT_APP_NAME+'_usertoken')
		if(!token){
			dispatch(Actions.getTasksFailed({ error: "NO TOKEN" }));
		}else{
			const response = await ApiClient.doPost(EndPoint.getResumeTasksByUser, data);
			if (response.status) {
				dispatch(Actions.getTasksSuccess(response));
			} else {
				dispatch(Actions.getTasksFailed({ error: response.message }));
			}
		}
	} catch (error) {
		dispatch(Actions.getTasksFailed({ error: "error"+error }));
	  
	}
  };

  export const getSimpleResumeTasksByUser = async (dispatch, data) => {
	try {

		var token=getCookie(process.env.REACT_APP_NAME+'_usertoken')
		if(token){			 
			const response = await ApiClient.doPost(EndPoint.getResumeTasksByUser, data);
			if (response.status) {
				dispatch(Actions.getTasksSuccess(response));
			}
		}
	} catch (error) {
		 
	}
  };