import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import GenericForm from "../forms/GenericForm";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import Typography from "../customdesign/Typography";
import LoadingForm from "../customdesign/LoadingForm";
import Endpoints from "../../api/Endpoints.js";

import { saveCommentary } from "../../utils/Functions";
var listDialogTaskForm;
var comnent;
const wHeight = window.innerHeight;
const DialogExecuteTask = (props) => {
  const { defConfig, defLang } = useSelector((state) => state.Login);
  const {
    componentConfig,
    processSuccess,
    processError,
    requesting,
    processMessage,
    initialValues,
    validationSchema,
  } = useSelector((state) => state.Process);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params, setParams] = React.useState({});
  // const [comment,setComment]    = React.useState("")
  const [sizeCol, setSizeCol] = React.useState(6);

  let { componentName } = useParams();
  const [isExternalFrame, setIsExternalFrame] = React.useState(false);

  const [isFrame, setIsFrame] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [listening, setListening] = React.useState(false);
  const [facts, setFacts] = React.useState([]);

  /*
  React.destroy =  (action) => { 
    console.log("byr")
  }
  */
  React.useEffect(() => {
    //console.log("PROPS", props);
    if (listDialogTaskForm) {
      if (props.status) {
        listDialogTaskForm.show();
      } else {
        listDialogTaskForm.hide();
      }
    }
    if (props.data.length > 0) {
      props.data.map(function (key, item) {
        //console.log("init", props.wfptaskid)

        if (key.type === "iframe") {
          setIsFrame(true);
        }

        if (key.type === "iframe" && key.iframetype === "EXTERNAL") {
          console.log("oculta");
          setIsExternalFrame(true);

          if (!listening) {
            const events = new EventSource(
              process.env.REACT_APP_API_CONFIG + Endpoints["initSocket"]
            );
            console.log("listen");
            events.onmessage = (event) => {
              //	  const parsedData = JSON.parse(event.data);

              console.log("EVENT", event.data, props.wfptaskid);

              let data = JSON.parse(event.data);

              //console.log(data)
              let wasExecuted = false;
              if (data.isParent) {
                let _parentid = props.wfptaskid.split("___");
                let parentid = _parentid[0];
                if (data.wfptaskid == parentid) {
                  console.log("S M");
                  wasExecuted = true;
                }
              } else {
                if (data.wfptaskid == props.wfptaskid) {
                  console.log("S S");
                  wasExecuted = true;
                }
              }

              if (wasExecuted) {
                console.log("F5");
                setTimeout(
                  async function () {
                    listDialogTaskForm.hide();
                    props.refreshData();
                    //dispatch(ProcessActions.getTasks({taskId:props.taskId, flowId: props.flowId }))
                    //dispatch(ProcessActions.executeTask( data  ) )
                  }.bind(this),
                  750
                );
              }

              // setFacts((facts) => facts.concat(parsedData));
            };

            setListening(true);
          }
        } else {
          console.log("sordos!");
          setListening(false);
        }

        if (key["value"]) {
          params[key["name"]] = key["value"] ? key["value"] : "";
        } else {
          if (props.data) {
            let pos = props.data.findIndex((item) => item.name === key.name);
            if (pos >= 0) {
              if (props.data[pos].defaultvalue) {
                params[key["name"]] = props.data[pos].defaultvalue;
              }
            }
          }
        }
      });
    } else {
      if (comnent) {
        comnent.value = "";
      }
      setParams({});
    }

    if (props.id) {
      setTimeout(
        function () {
          setIsLoading(false);
        }.bind(this),
        50
      );
    }
  }, [props]);

  const submitForm = async (action) => {
    if (action === "NO___OPTION") {
      let data = {
        id: props.id,
        commentary: comnent.value,
      };
      let response = await saveCommentary(data);
      if (response.status) {
        listDialogTaskForm.hide();
      }
      dispatch(ProcessActions.setMessage(response));
    } else {
      if (comnent.value) {
        if (comnent.value.toString() != "") {
          params["comment_" + props.taskName] = comnent.value;
        }
      }

      let data = {
        id: props.id,
        isParent: props.isParent,
        params: params,
        optionValue: action,
      };

      if (props.isParent) {
        data = {
          parentid: props.id,
          isParent: props.isParent,
          params: params,
          optionValue: action,
        };
      }

      listDialogTaskForm.hide(); //check!!!
      dispatch(ProcessActions.executeTask(data));
    }
  };

  const onChange = (event) => {
    console.log("----", event);
    let _values = params;
    _values[event.target.name] = event.target.value;
    setParams(_values);
  };

  const close = (event) => {
    listDialogTaskForm.hide();
  };

  const setExpandAction = (event) => {
    setSizeCol(9);
  };

  const collapseaction = (event) => {
    setSizeCol(6);
  };

  return (!props.status && props.actions.length > 0) || isLoading ? (
    ""
  ) : (
    <DialogComponent
      id={"listDialogTaskForm_" + (isExternalFrame ? "EXTERNAL" : "TASK")}
      showCloseIcon={true}
      animationSettings={props.animationSettings}
      closeOnEscape={false}
      ref={(dialog) => (listDialogTaskForm = dialog)}
      isModal={true}
      close={close}
      cssClass="full-dialog"
      header={props.header}
      footerTemplate={() => {
        if (!isExternalFrame) {
          return (
            <div>
              {props.actions.map((opt) => {
                return (
                  <button
                    key={"sendButton" + opt}
                    onClick={() => {
                      submitForm(opt);
                    }}
                    className="e-control e-btn e-primary"
                    data-ripple="true"
                  >
                    {opt === "NO___OPTION"
                      ? defConfig[defLang].acceptlabel
                      : opt}
                  </button>
                );
              })}
            </div>
          );
        } else {
          return "";
        }
      }}
      width={props.data.length === 0 ? "50%" : "75%"}
      height="100%"
      position={{ X: "right", Y: "top" }}
    >
      <div className="control-pane">
        <div className="row  mt-10 no-padding">
          <div
            style={{
              display: isFrame ? "none" : "initial",
              visibility: isFrame ? "hidden" : "initial",
            }}
            className={
              "col-xs-12 col-md-12 col-lg-" +
              (props.data.length === 0 ? 12 : 12 - sizeCol) +
              " padding-memory xxx1"
            }
          >
            <div
              className="e-card e-custom-card-2"
              style={{
                height: wHeight - (isExternalFrame ? 200 : 300),
                overflowY: "auto",
              }}
            >
              {props.flowData.length > 0 ? (
                !props.isParent ? (
                  <GenericForm
                    defConfig={defConfig}
                    defLang={defLang}
                    data={props.flowData}
                    wfpflowid={props.wfpflowid}
                    wfptaskid={props.wfptaskid}
                    varSchema={props.varSchema}
                    onChangeForm={props.onChangeForm}
                    buttons={[]}
                    datasubtitle={
                      defConfig[defLang].memoryheader + props.wfpflowid + ")"
                    }
                  />
                ) : (
                  <AccordionComponent>
                    <AccordionItemsDirective>
                      {props.flowData.map((flow, index) => {
                        return (
                          <AccordionItemDirective
                            expanded={index == 0 ? true : false}
                            header={flow.header}
                            content={() => {
                              return (
                                <GenericForm
                                  defConfig={defConfig}
                                  defLang={defLang}
                                  data={flow.data}
                                  wfpflowid={flow.wfpflowid}
                                  wfptaskid={flow.wfptaskid}
                                  varSchema={props.varSchema}
                                  onChangeForm={props.onChangeForm}
                                  buttons={[]}
                                />
                              );
                            }}
                          />
                        );
                      })}
                    </AccordionItemsDirective>
                  </AccordionComponent>
                )
              ) : (
                <>
                  {props.loading ? (
                    <LoadingForm showMoreRows={true} />
                  ) : (
                    <div className="control-panel mt-1">
                      <div className="row">
                        <div className="col-sm-12 col-lg-12 col-12">
                          <Typography
                            variant="h5"
                            style={{ borderBottom: "1px solid #212121" }}
                            mb={3}
                          >
                            {defConfig[defLang].memorylabel}
                          </Typography>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-lg-12 col-12">
                          [{defConfig[defLang].nomemorymessage}]
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {props.data.length > 0 && (
            <div
              className={
                "col-xs-12 col-md-12 col-lg-" +
                (isFrame ? 12 : sizeCol) +
                " mt-10-b  padding-memory xxx2"
              }
            >
              <div
                className="e-card e-custom-card-2 mb-1"
                style={{
                  height: isExternalFrame ? "auto" : wHeight - 300,
                  overflowY: "auto",
                }}
              >
                <GenericForm
                  data={props.data}
                  defConfig={defConfig}
                  defLang={defLang}
                  wfpflowid={props.wfpflowid}
                  wfptaskid={props.wfptaskid}
                  officecode={props.officecode}
                  datasubtitle={defConfig[defLang].taskformtitle}
                  datapostload={props.datapostload}
                  flowactions={props.actions}
                  onChange={onChange}
                  buttons={[]}
                  expandaction={!isExternalFrame ? setExpandAction : null}
                  collapseaction={collapseaction}
                />
              </div>
            </div>
          )}

          <div
            style={{
              display: !isFrame ? "none" : "initial",
              visibility: !isFrame ? "hidden" : "initial",
            }}
            className={"col-xs-12 col-md-12 col-lg-12 padding-memory xxx3"}
          >
            <div
              className="e-card e-custom-card-2"
              style={{
                height: wHeight - (isExternalFrame ? 200 : 300),
                overflowY: "auto",
              }}
            >
              {props.flowData.length > 0 ? (
                !props.isParent ? (
                  <GenericForm
                    defConfig={defConfig}
                    defLang={defLang}
                    wfpflowid={props.wfpflowid}
                    wfptaskid={props.wfptaskid}
                    data={props.flowData}
                    varSchema={props.varSchema}
                    onChangeForm={props.onChangeForm}
                    buttons={[]}
                    datasubtitle={
                      defConfig[defLang].memoryheader + props.wfpflowid + ")"
                    }
                  />
                ) : (
                  <AccordionComponent>
                    <AccordionItemsDirective>
                      {props.flowData.map((flow, index) => {
                        return (
                          <AccordionItemDirective
                            expanded={index == 0 ? true : false}
                            header={
                              defConfig[defLang].memorylabel +
                              " - " +
                              (flow.label && flow.key
                                ? flow.label +
                                  " " +
                                  flow.key +
                                  " (Flow id:" +
                                  flow.wfpflowid +
                                  ")"
                                : flow.header)
                            }
                            content={() => {
                              return (
                                <GenericForm
                                  defConfig={defConfig}
                                  defLang={defLang}
                                  data={flow.data}
                                  wfpflowid={flow.wfpflowid}
                                  wfptaskid={flow.wfptaskid}
                                  varSchema={props.varSchema}
                                  onChangeForm={props.onChangeForm}
                                  buttons={[]}
                                />
                              );
                            }}
                          />
                        );
                      })}
                    </AccordionItemsDirective>
                  </AccordionComponent>
                )
              ) : (
                <>
                  {props.loading ? (
                    <LoadingForm showMoreRows={true} />
                  ) : (
                    <div className="control-panel mt-1">
                      <div className="row">
                        <div className="col-sm-12 col-lg-12 col-12">
                          <Typography
                            variant="h5"
                            style={{ borderBottom: "1px solid #212121" }}
                            mb={3}
                          >
                            {defConfig[defLang].memorylabel}
                          </Typography>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-lg-12 col-12">
                          [{defConfig[defLang].nomemorymessage}]
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {!isExternalFrame && (
          <div className="row no-padding">
            <div className="col-xs-12 col-md-12 col-lg-12  padding-memory">
              {" "}
              {/*no-padding*/}
              <div className="e-card e-custom-card-2">
                <div className="pt-1">
                  <TextBoxComponent
                    multiline={true}
                    cssClass={"e-outline"}
                    placeholder={defConfig[defLang].aditionalcommentary}
                    ref={(scope) => {
                      comnent = scope;
                    }}
                    floatLabelType="Auto"
                  />
                </div>

                {props.commentary?.length > 0 && (
                  <div className="comment-history">
                    <h4> {defConfig[defLang].commenthistory}</h4>
                    {props.commentary.map((c) => {
                      return c !== "" && <p>{c}</p>;
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </DialogComponent>
  );
};
DialogExecuteTask.propTypes = {};

export default DialogExecuteTask;
