import { FormValidator } from "@syncfusion/ej2-inputs";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject,
} from "@syncfusion/ej2-react-dropdowns";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import {
  DataManager,
  Query,
  WebApiAdaptor,
  WebMethodAdaptor,
} from "@syncfusion/ej2-data";
import {
  getCookie,
  convertObjToArray,
  convertArrayToObj,
  testRestApi,
  testJS,
  getToken,
  getUserInfo,
  saveFlowField,
  ExportToExcel,
  getTranslateValue,
} from "../../utils/Functions";
import ApiClient from "../../api/ApiClient";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import {
  CheckBoxComponent,
  RadioButtonComponent,
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import Typography from "../customdesign/Typography";
import CompFormFileManager from "./CompFormFileManager";
import CompFormDesinger from "./CompFormDesinger";
import CompFilterDesinger from "./CompFilterDesinger";

import CompDashboardDesinger from "./CompDashboardDesinger";

import CompFormOption from "./CompFormOption";
import CompFormAction from "./CompFormAction";
import CompFormCompValues from "./CompFormCompValues";

import CompFormValue from "./CompFormValue";

import CompFormInput from "./CompFormInput";
import CompFormOptionChart from "./CompFormOptionChart";
import Loading from "../customdesign/Loading";

var specialComponents = {};
var dialogInstanceMessage;
const customerData = {};
var textObj;
const Input = ({
  label,
  name,
  value,
  floatBlur,
  floatFocus,
  onChange,
  type,
  saveFlowFieldLocal,
  defConfig,
  defLang,
  ..._rest
}) => {
  //console.log(name, _rest)
  const navigate = useNavigate();
  var values = [];
  var text = "";
  var checkFields = { text: "Name", value: "Code" };
  var groupFields = { text: "Name", value: "Code", groupBy: "Groupcondition" };
  let remoteFields, query;

  let rest = _rest;
  if (_rest.values?.datatype) {
    if (rest.values.datatype == "static") {
      rest["values"] = rest.values.options;
    } else {
      rest["apiurl"] = rest.values.apiurl;
      rest["apidata"] = rest.values.apidata;
      rest["apimethod"] = rest.values.apimethod;
      rest["apitoken"] = rest.values.apitoken;
      rest["fields"] = { text: rest.values.text, value: rest.values.value };
    }
  }
  if (rest.apiurl) {
    var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
    if (rest.apitoken) {
      if (rest.apitoken !== "" && rest.apitoken !== "NOTOKEN") {
        token = rest.apitoken;
      }
    }
    let url = rest.apiurl;
    if (rest.apiurl.indexOf("http") < 0) {
      url = process.env.REACT_APP_API_CONFIG + rest.apiurl;
    }
    customerData[name] = new DataManager({
      url: url,
      adaptor:
        rest.apimethod === "post"
          ? new WebMethodAdaptor()
          : new WebApiAdaptor(),
      headers: [
        { Authorization: "Bearer " + token },
        { getInfo: "simplified" },
      ],
      crossDomain: true,
    });
    remoteFields = rest.fields; // Example{ text: 'FLOWGROUP', value: 'FLOWGROUPID' };
    query = new Query()
      .addParams("$apidata", rest.apidata)
      .select([rest.fields.text, rest.fields.value])
      .take(1000)
      .requiresCount();
  }

  try {
    if (type !== "space") {
      if (
        rest.values &&
        (type === "select" ||
          type === "options" ||
          type === "radio" ||
          type === "multiple" ||
          type === "autocomplete" ||
          type === "multiple")
      ) {
        if (rest.values[0]) {
          let keys = Object.keys(rest.values[0]);
          rest.values.map((key, index) => {
            if (key["GROUPCONDITION"]) {
              values.push({
                Code: key[keys[0]], //.toString(),
                Name: getTranslateValue(key[keys[1]]) || key[keys[1]],
                Groupcondition: key["GROUPCONDITION"],
              });
            } else {
              values.push({
                Code: key[keys[0]], //.toString(),
                Name: getTranslateValue(key[keys[1]]) || key[keys[1]],
              });
            }

            if (key[keys[0]] == value) {
              text = key[keys[1]];
            }
          });

          if (!Array.isArray(value)) {
            if (value == "" && rest.values[0]) {
              value = rest.values[0][keys[0]];
            }
          }
        }
      }
    }
  } catch (e) {
    console.log(">>>>err:", name, value, type, e);
  }

  switch (type) {
    case "title":
      return <Typography variant={rest?.variant}>{label}</Typography>;

    case "text":
      if (!rest.disabled && rest.editOnline && rest.wfpflowid) {
        return (
          <>
            <span
              className={
                "e-float-text-edit" +
                (rest?.priority && rest.priority === "YES"
                  ? " input-highlight"
                  : "")
              }
            >
              {label}{" "}
            </span>
            <InPlaceEditorComponent
              actionSuccess={(e) => saveFlowFieldLocal(name, e.value)}
              id={name}
              mode="Popup"
              type="Text"
              value={value === undefined ? "" : value}
              model={{ placeholder: "Update " + label }}
              popupSettings={{ title: "Update " + label }}
              cssClass={
                rest?.priority && rest.priority === "YES"
                  ? " input-highlight"
                  : ""
              }
            ></InPlaceEditorComponent>
          </>
        );
      } else {
        return (
          <>
            <TextBoxComponent
              id={name}
              onFocus={floatFocus}
              onBlur={floatBlur}
              data-msg-containerid={name + "Error"}
              disabled={rest?.disabled}
              name={name}
              placeholder={label}
              value={value == "undefined" ? "" : value}
              onChange={({ value }) => onChange({ target: { name, value } })}
              cssClass={
                (rest?.className ? rest.className : "e-outline") +
                (rest.invalid ? " e-error" : "") +
                (rest?.priority && rest.priority === "YES"
                  ? " input-highlight"
                  : "")
              }
              floatLabelType="Auto"
            />
            {rest.invalid &&
              rest.invalid_message &&
              rest.invalid_message !== "" && (
                <span className="validation-message e-error">
                  {" "}
                  {rest.invalid_message}{" "}
                </span>
              )}
          </>
        );
      }
    case "date":
      return (
        <>
          <DateTimePickerComponent
            name={name}
            onChange={(event) => {
              onChange({
                target: { name: name, value: event.value, type: "date" },
              });
            }}
            format={"dd/MM/yyyy HH:mm"}
            value={value}
            cssClass={" e-error custom-datepicker"}
          />
          {rest.formValidation?.findIndex((item) => item.name === name) >=
            0 && (
            <label
              style={{ position: "relative", top: -10 }}
              className="e-error"
            >
              {
                rest.formValidation[
                  rest.formValidation.findIndex((item) => item.name === name)
                ].message
              }
            </label>
          )}
        </>
      );

    case "button":
      return (
        <ButtonComponent
          key={"btn" + name}
          className="e-success"
          id={"formBTN___" + name}
          name={"formBTN___" + name}
        >
          {label}
        </ButtonComponent>
      );

    case "daterange":
      return (
        <DateRangePickerComponent
          onChange={(event) => {
            onChange({
              target: { name: name, value: event.value, type: "date" },
            });
          }}
          startDate={value[0]}
          endDate={value[1]}
          format="dd/MMM/yy hh:mm a"
          ref={(scope) => {
            specialComponents[name] = scope;
          }}
        ></DateRangePickerComponent>
      );
    case "values":
      return (
        <div className="my-1 e-card e-custom-card">
          <span className="comp-label"> {label} </span>
          <CompFormValue
            value={value}
            ref={(scope) => {
              specialComponents[name] = scope;
            }}
            type={type}
            name={name}
            label={label}
            onChange={onChange}
          />
        </div>
      );
    case "options":
      return (
        <CompFormOption
          value={value}
          ref={(scope) => {
            specialComponents[name] = scope;
          }}
          type={type}
          name={name}
          label={label}
          onChange={onChange}
        />
      );
    case "actions":
      return (
        <div className="my-1 e-card e-custom-card">
          <span className="comp-label"> {label} </span>
          <CompFormAction
            value={value}
            ref={(scope) => {
              specialComponents[name] = scope;
            }}
            type={type}
            name={name}
            label={label}
            onChange={onChange}
          />
        </div>
      );
    case "componentvalues":
      return (
        <div className="my-1 e-card e-custom-card">
          <span className="comp-label"> {label} </span>
          <CompFormCompValues
            value={value}
            ref={(scope) => {
              specialComponents[name] = scope;
            }}
            type={type}
            name={name}
            label={label}
            onChange={onChange}
          />
        </div>
      );
    case "inputs":
      return (
        <div className="my-1 e-card e-custom-card">
          <span className="comp-label"> {label} </span>
          <CompFormInput
            value={value}
            ref={(scope) => {
              specialComponents[name] = scope;
            }}
            type={type}
            name={name}
            label={label}
            onChange={onChange}
          />
        </div>
      );
    case "optionsChart":
      return (
        <CompFormOptionChart
          value={value}
          ref={(scope) => {
            specialComponents[name] = scope;
          }}
          type={type}
          name={name}
          label={label}
          onChange={onChange}
        />
      );

    case "select":
      if (rest.apiurl) {
        if (value != "") {
          return (
            <>
              <DropDownListComponent
                dataSource={customerData[name]}
                ref={(scope) => {
                  specialComponents[name] = scope;
                }}
                query={query}
                fields={remoteFields}
                name={name}
                value={value}
                cssClass={
                  (rest?.className ? rest.className : "e-outline") +
                  (rest.formValidation?.findIndex(
                    (item) => item.name === name
                  ) >= 0
                    ? " e-error"
                    : "")
                }
                placeholder={label}
                floatLabelType="Auto"
                enabled={rest.enabled}
                select={({ itemData }) => {
                  let value = itemData.Code;
                  if (!itemData.Code) {
                    value = itemData[remoteFields.value];
                  }
                  onChange({ target: { name, value: value, rest: itemData } });
                }}
              />
              {rest.formValidation?.findIndex((item) => item.name === name) >=
                0 && (
                <label
                  style={{ position: "relative", top: -10 }}
                  className="e-error"
                >
                  {
                    rest.formValidation[
                      rest.formValidation.findIndex(
                        (item) => item.name === name
                      )
                    ].message
                  }
                </label>
              )}
            </>
          );
        } else {
          return (
            <>
              <DropDownListComponent
                dataSource={customerData[name]}
                ref={(scope) => {
                  specialComponents[name] = scope;
                }}
                query={query}
                fields={remoteFields}
                name={name}
                cssClass={
                  (rest?.className ? rest.className : "e-outline") +
                  (rest.formValidation?.findIndex(
                    (item) => item.name === name
                  ) >= 0
                    ? " e-error"
                    : "")
                }
                placeholder={label}
                floatLabelType="Auto"
                enabled={rest.enabled}
                select={({ itemData }) => {
                  let value = itemData.Code;
                  if (!itemData.Code) {
                    value = itemData[remoteFields.value];
                  }
                  onChange({ target: { name, value: value, rest: itemData } });
                }}
              />
              {rest.formValidation?.findIndex((item) => item.name === name) >=
                0 && (
                <label
                  style={{ position: "relative", top: -10 }}
                  className="e-error"
                >
                  {
                    rest.formValidation[
                      rest.formValidation.findIndex(
                        (item) => item.name === name
                      )
                    ].message
                  }
                </label>
              )}
            </>
          );
        }
      } else {
        return (
          <>
            <DropDownListComponent
              dataSource={values}
              ref={(scope) => {
                specialComponents[name] = scope;
              }}
              name={name}
              value={value}
              fields={checkFields}
              enabled={rest.enabled}
              cssClass={
                (rest?.className ? rest.className : "e-outline") +
                (rest.formValidation?.findIndex((item) => item.name === name) >=
                0
                  ? " e-error"
                  : "")
              }
              placeholder={label}
              floatLabelType="Auto"
              select={({ itemData }) => {
                onChange({ target: { name, value: itemData.Code } });
              }}
            />
            {rest.formValidation?.findIndex((item) => item.name === name) >=
              0 && (
              <label
                style={{ position: "relative", top: -10 }}
                className="e-error"
              >
                {
                  rest.formValidation[
                    rest.formValidation.findIndex((item) => item.name === name)
                  ].message
                }
              </label>
            )}
          </>
        );
      }

    case "scriptarea":
      return (
        <TextBoxComponent
          id={name}
          onFocus={floatFocus}
          onBlur={floatBlur}
          data-msg-containerid={name + "Error"}
          disabled={rest?.disabled}
          name={name}
          multiline={true}
          placeholder={label}
          value={value}
          onChange={({ value }) => onChange({ target: { name, value } })}
          cssClass={
            (rest?.className ? rest.className : "e-outline") +
            (rest.invalid ? " e-error" : "") +
            (rest?.priority && rest.priority === "YES"
              ? " input-highlight"
              : "")
          }
          floatLabelType="Auto"
        />
      );
    case "switch":
      return (
        <div
          disabled={rest?.disabled}
          style={{
            display: "flex",
            alignItems: "center",
            height: 31,
          }}
        >
          <label
            htmlFor={name}
            style={{
              padding: "0px 0px 0px 0",
              marginBottom: 0,
              fontWeight: "normal",
            }}
          >
            {" "}
            {label}
          </label>
          <SwitchComponent
            cssClass="bar-color"
            id={name}
            name={name}
            onChange={({ value }) =>
              onChange({
                target: { name, value: value ? value : "", type: "switch" },
              })
            }
            checked={value === "YES"}
          ></SwitchComponent>
        </div>
      );

    case "numeric":
      let min = rest.min || 0;
      let max = rest.max || 9999999999;
      return (
        <>
          <NumericTextBoxComponent
            name={name}
            min={min}
            max={max}
            disabled={rest?.disabled}
            format={rest.format ? rest.format : "n2"}
            value={value}
            floatLabelType="Auto"
            onChange={({ value }) => onChange({ target: { name, value } })}
            cssClass={
              (rest?.className ? rest.className : "e-outline") +
              (rest.formValidation?.findIndex((item) => item.name === name) >= 0
                ? " e-error"
                : "")
            }
            placeholder={label}
          />
          {rest.formValidation?.findIndex((item) => item.name === name) >=
            0 && (
            <label
              style={{ position: "relative", top: -10 }}
              className="e-error"
            >
              {
                rest.formValidation[
                  rest.formValidation.findIndex((item) => item.name === name)
                ].message
              }
            </label>
          )}
        </>
      );
    case "textarea":
      return (
        <>
          <TextBoxComponent
            onFocus={floatFocus}
            onBlur={floatBlur}
            disabled={rest?.disabled}
            data-msg-containerid={name + "Error"}
            multiline={true}
            placeholder={label}
            value={value}
            cssClass={
              (rest?.className ? rest.className : "e-outline") +
              (rest.invalid ? " e-error" : "")
            }
            onChange={({ value }) => onChange({ target: { name, value } })}
            floatLabelType="Auto"
          />
          {rest.invalid &&
            rest.invalid_message &&
            rest.invalid_message !== "" && (
              <span className="validation-message-extended e-error">
                {" "}
                {rest.invalid_message}{" "}
              </span>
            )}
        </>
      );

    case "password":
      return (
        <>
          <TextBoxComponent
            id={name}
            disabled={rest?.disabled}
            onFocus={floatFocus}
            onBlur={floatBlur}
            data-msg-containerid={name + "Error"}
            name={name}
            type="password"
            placeholder={label}
            value={value}
            onChange={({ value }) => onChange({ target: { name, value } })}
            cssClass={
              (rest?.className ? rest.className : "e-outline") +
              (rest.invalid ? " e-error" : "")
            }
            floatLabelType="Auto"
          />
          {rest.invalid &&
            rest.invalid_message &&
            rest.invalid_message !== "" && (
              <span className="validation-message e-error">
                {" "}
                {rest.invalid_message}{" "}
              </span>
            )}
        </>
      );

    case "link":
      return (
        <div
          style={rest.style ? rest.style : {}}
          className="item-link"
          onClick={() => {
            navigate(value);
          }}
        >
          {label}
        </div>
      );

    case "radio":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 41,
            borderBottom: "1px solid #c2c2c2",
          }}
        >
          <span className="field-form-label"> {label} </span>
          {rest?.values.map((e) => (
            <span className="ml-1">
              <RadioButtonComponent
                key={e}
                disabled={rest?.disabled}
                label={e.text}
                value={e.value}
                className="mr-1"
                name={name}
                onChange={({ value }) =>
                  onChange({ target: { name, value: e.value } })
                }
                checked={value === e.value}
              />
            </span>
          ))}
        </div>
      );

    case "multiple":
      //      console.log("values", values);
      //      console.log("value", value);
      let fixedValues = [];
      if (value) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            value.map((key) => {
              fixedValues.push(key.toString());
            });
          }
        }
      }
      return (
        <MultiSelectComponent
          ref={(scope) => {
            specialComponents[name] = scope;
          }}
          dataSource={values}
          fields={groupFields}
          placeholder={label}
          mode="CheckBox"
          showSelectAll={true}
          value={fixedValues}
          showDropDownIcon={true}
          cssClass={rest?.className ? rest.className : "e-outline"}
          onChange={({ value }) => onChange({ target: { name, value } })}
          filterBarPlaceholder={label}
          popupHeight="350px"
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      );

    case "dropdown":
      return (
        <DropDownListComponent
          dataSource={rest?.options}
          name={name}
          disabled={rest?.disabled}
          select={({ itemData }) => {
            onChange({ target: { name, value: itemData.value } });
          }}
          value={value}
        />
      );
    case "componentformcontainer":
      return (
        <CompFormDesinger
          type={type}
          name={name}
          label={label}
          onChange={onChange}
          value={value}
          defConfig={defConfig}
          defLang={defLang}
        />
      );
    case "file":
      return (
        <CompFormFileManager
          type={type}
          name={name}
          label={label}
          onChange={onChange}
          value={value}
          defConfig={defConfig}
          defLang={defLang}
          rest={_rest}
        />
      );
    case "componentfiltercontainer":
      return (
        <CompFilterDesinger
          type={type}
          name={name}
          label={label}
          onChange={onChange}
          value={value}
        />
      );

    case "componentdashboardcontainer":
      return (
        <CompDashboardDesinger
          type={type}
          name={name}
          label={label}
          onChange={onChange}
          value={value}
        />
      );

    case "checkbox":
      return (
        <CheckBoxComponent
          label={rest?.checkboxLabel}
          disabled={rest?.disabled}
          name={name}
          onChange={(e) =>
            onChange({ target: { name, value: e.target.checked } })
          }
          checked={value}
        />
      );
    case "space":
      /*return(
				<div class="form-group">
					<div class="e-float-input e-control-wrapper">
						<div style={{  height: "0px" }}></div>
						
					</div>
				</div>
			)*/
      return (
        <div className="" style={{ height: rest.size.lg == 12 ? 0 : 50 }}></div>
      );

    case "autocomplete":
      return (
        <ComboBoxComponent
          ref={(scope) => {
            specialComponents[name] = scope;
          }}
          ignoreAccent={true}
          allowFiltering={true}
          name={name}
          cssClass={"e-custom-combobox"}
          floatLabelType="auto"
          value={value.toString()}
          fields={checkFields}
          dataSource={values}
          placeholder={label}
        />
        /**
								<MultiSelectComponent  ref={(scope) => { specialComponents[name] = scope; }} dataSource={values}
									fields={checkFields} placeholder={label} mode="CheckBox" 
									showSelectAll={true}
									value={value.toString()}
									showDropDownIcon={true} 
									filterBarPlaceholder={label} popupHeight="350px">
									<Inject services={[CheckBoxSelection]} />
								</MultiSelectComponent>
								 */
      );

    case "image":
      return <input type="hidden" name={name} value={value.toString()} />;
    default:
      return "no:" + type;
  }
};

class GenericForm extends React.Component {
  constructor(props) {
    super(props);
    this.animationSettings = { effect: "Fade" };
    this.state = {
      formValues: {},
      buttons: [],
      originalformInputs: [],
      events: [],
      loading: false,
      apiResponseMessage: "init",
      //showResponse:false,
      expandStatus: false,
      wWidth: window.innerWidth,
      varSchema: [],
    };
  }

  floatFocus(args) {
    args.target.parentElement.classList.add("e-input-focus");
  }
  floatBlur(args) {
    args.target.parentElement.classList.remove("e-input-focus");
  }

  onOverlayClick() {
    this.dialogInstance.hide();
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.checkEnter);
    document.removeEventListener("click", this.checkClick);
    this.setState({ ...this.state, events: [] });
  }
  async componentDidMount() {
    // initialize the form rules
   // console.log("GEN", this.props)
    if (this.props.wfpflowid && this.props.wfptaskid && this.props.officecode) {
      //console.log("w" , window.innerWidth )
      this.setState({
        ...this.state,
        wWidth: window.innerWidth,
        wfpflowid: this.props.wfpflowid,
        wfptaskid: this.props.wfptaskid,
        officecode: this.props.officecode,
      });
    }

    if (this.props.varSchema) {
      this.setState({ ...this.state, varSchema: this.props.varSchema });
    }

    await this.prepareForm(
      this.props.data,
      this.props.buttons,
      this.props.wfpflowid,
      this.props.wfptaskid,
      this.props.officecode,
      this.props.datapostload
    );

    document.removeEventListener("click", this.checkClick);
    document.removeEventListener("keyup", this.checkEnter);
    document.addEventListener("click", this.checkClick);
    document.addEventListener("keyup", this.checkEnter);

    let events = [];
    this.props.data.map((key, index) => {
      if (key.addbutton) {
        if (Array.isArray(key.addbutton)) {
          key.addbutton.map((keyB) => {
            events.push(keyB);
          });
        } else {
          events.push(key.addbutton);
        }
      }
    });
    if (this.props.expandaction) {
      events.push({
        id: "expandOption",
        open: this.props.expandaction,
        close: this.props.collapseaction,
      });
    }
    //console.log("events", events)
    this.setState({ ...this.state, events: events });
  }

  checkEnter = async (event) => {
    if (event.key === "Enter" && event.target.id) {
      if (this.state.formValues[event.target.id]) {
        if (this.state.buttons.length === 1) {
          let formValues = this.state.formValues;
          try {
            let keys = Object.keys(specialComponents);
            Object.keys(specialComponents).map((key, index) => {
              let field = specialComponents[key];
              formValues[key] = field.value;
            });
          } catch (e) {
            //console.log("no custom data", specialComponents, e)
          }

          this.state.buttons[0].action(formValues);
        }
      }
    }
  };
  checkClick = async (event) => {
    let elem = event.target;

    if (elem.className.indexOf("e-open-link") >= 0) {
      elem["id"] = "openiFrame";
    }

    if (elem.className && elem.id) {
      let id = elem.id;

      if (id.indexOf("formBTN___") >= 0) {
        try {
          let formData = this.state.originalformInputs;
          let pos = formData.findIndex(
            (item) => item.name === id.replace("formBTN___", "")
          );
          if (pos >= 0) {
            let formValues = this.state.formValues;
            let action = formData[pos].action;
            const { ExportToExcel } = require("../../utils/Functions");
            await eval(action);

            //ExportToExcel( formData, formValues , "taskForm")
          }
        } catch (Ex) {
          console.log(">>>>Error:", Ex);
        }
      } else {
        if (id === "" && elem.className.indexOf("e-icons") >= 0) {
          id = elem.offsetParent.id;
        }

        let name = elem.name;
        if (id === "openiFrame" && elem.className.indexOf("e-icons") >= 0) {
          name = elem.offsetParent.name;
        }

        if (elem.className.indexOf("e-open-link") >= 0) {
          id = "openiFrame";
        }

        var appUrl = process.env.REACT_APP_URL_CONFIG;
        const pos = this.state.events.findIndex((item) => item.id === id);
        let expandStatus = this.state.expandStatus;

        if (id === "openiFrame") {
          window.open(
            elem.name,
            "Execute Task",
            "width=760, height=550, scrollbars=NO"
          );
          this.setState({ ...this.state, loading: false });
        } else {
          if (pos >= 0) {
            let eventField = this.state.events[pos];
            //predefined functions
            let fieldName = event.target.name;

            this.setState({
              ...this.state,
              loading: true /*showResponse:false*/,
            });
            if (id == "refresh") {
              let formData = convertArrayToObj(this.state.originalformInputs);

              if (formData[fieldName].api) {
                var apidata = {};
                var _apidata = formData[fieldName].apidefdata.split(",");
                for (let d in _apidata) {
                  let info = _apidata[d].split("=");
                  apidata[info[0]] = info[1];
                }
                let value = "";
                let values = await ApiClient.getCustomData(
                  formData[fieldName].api,
                  apidata
                );
                if (values.length > 0) {
                  var fields = Object.keys(values[0]);
                  values.map(function (key, item) {
                    key["value"] = key[fields[0]];
                    key["text"] = key[fields[1]];
                  });
                  if (formData[fieldName].type.indexOf("autocomplete") < 0) {
                    value = values[0][fields[0]];
                  }
                }

                formData[fieldName].values = values;
                formData[fieldName].originalvalues = values;
                formData[fieldName].value = value;
                //console.log(formData[fieldName])
              }
              let formInputs = convertObjToArray(formData, "layout_row");

              this.setState({ ...this.state, formInputs: [], loading: false });

              setTimeout(
                async function () {
                  await this.prepareForm(
                    formInputs,
                    this.state.buttons,
                    this.state.wfpflowid,
                    this.state.wfptaskid,
                    this.state.officecode
                  );
                }.bind(this),
                50
              );

              //	this.setState({  ...this.state })
            } else if (id == "testapi") {
              let formData = convertArrayToObj(this.state.formValues);
              let response = {};
              if (formData.processtypeid == "54") {
                response = await testRestApi(formData);
              } else if (formData.processtypeid == "55") {
                response = await testJS(formData);
              } else {
                response = { status: false, message: "not implemented" };
              }

              dialogInstanceMessage.show();
              this.setState({
                ...this.state,
                loading: false,
                expandStatus: expandStatus,
                apiResponseMessage: JSON.stringify(response),
              });
            } else if (id == "expandOption") {
              //				console.log("expandimos", this.state.events[pos])

              if (!this.state.expandStatus) {
                this.state.events[pos].open();
              } else {
                this.state.events[pos].close();
              }
              expandStatus = !this.state.expandStatus;
              this.setState({
                ...this.state,
                loading: false,
                expandStatus: expandStatus,
              });
            } else {
              if (eventField.action) {
                try {
                  const { simpleGetData } = require("../../utils/Functions");
                  const { simplePostData } = require("../../utils/Functions");
                  const utils = require("../../utils/Utils");
                  if (eventField.action.indexOf("async") >= 0) {
                    setRefreshingData(true);
                    await eval(eventField.action);
                    setRefreshingData(false);
                  } else {
                    //console.log("sync",  eventField.action )
                    eval(eventField.action);
                  }
                } catch (Exc) {
                  window.alert("error exec custom code");
                  //console.log("ERROR", Exc, eventField.action)
                }
              }
              this.setState({ ...this.state, loading: false });
            }
          }
        }
      }
    }
  };

  saveFlowFieldLocal = (name, value) => {
    saveFlowField(this.props.wfpflowid, this.props.wfptaskid, name, value);
    if (this.props.onChangeForm) {
      this.props.onChangeForm(
        this.props.wfpflowid,
        this.props.wfptaskid,
        name,
        value
      );
    }
  };

  prepareForm = async (
    preFormData,
    buttons,
    wfpflowid,
    wfptaskid,
    officecode,
    datapostload
  ) => {
    let options = {
      rules: {},
    };
    let formValues = {};
    this.setState({
      ...this.state,
      loading: true,
      originalformInputs: [],
      formInputs: [],
    });
    let formButtons = [];
    if (buttons) {
      formButtons = this.props.buttons.map((b, index) => {
        return (
          <div
            className={
              (b.size ? b.size : "col-xs-12 col-md-6 col-lg-4 col-xl-3") +
              " min-padding "
            }
          >
            <ButtonComponent
              key={"btn" + index}
              className={
                (this.props.buttons.length > 1 ? "mr-10" : "") +
                " " +
                (b.className ? b.className : "e-success")
              }
              id={b.name}
              name={b.name}
              onClick={this.onSubmitClick.bind(this)}
              style={
                b.style
                  ? b.style
                  : { textTransform: "uppercase", fontWeight: "500" }
              }
            >
              {b.label ? b.label : defConfig[defLang].savedatalabel}
            </ButtonComponent>
          </div>
        );
      });
    }
    preFormData.sort(function (a, b) {
      return b.layout_row > a.layout_row
        ? -1
        : b.layout_row < a.layout_row
        ? 1
        : 0;
    });
    let formData = convertArrayToObj(preFormData);

    if (datapostload) {
      //console.log(datapostload)
      //console.log("********************INJECTED CODE*********************")

      try {
        const { simpleGetData } = require("../../utils/Functions");
        const { simplePostData } = require("../../utils/Functions");
        const utils = require("../../utils/Utils");

        if (datapostload.indexOf("async") >= 0) {
          //console.log("async",  )
          setRefreshingData(true);
          await eval(datapostload);
          //console.log("fin")
          setRefreshingData(false);
        } else {
          console.log("sync");
          
          eval(datapostload);
        }
      } catch (Exc) {
        window.alert("error compiling field action");
        console.log("ERROR", Exc, datapostload);
      }
      //console.log("********************INJECTED CODE*********************")
    }

    formData = convertObjToArray(formData, "layout_row");

    if (this.props.varSchema) {
      let varSchema = this.props.varSchema;
      formData.map(function (e, item) {
        let pos = varSchema.findIndex((item) => item.VARNAME === e.name);
        if (pos >= 0) {
          e["priority"] = varSchema[pos].REMARKABLE;
        } else {
          e["priority"] = "NO";
        }
      });
    }

    let formInputs = formData.map((_e, index) => {
      //let _e = formData[key]

      let editOnline = false;
      let disabled = false;
      if (this.props.varSchema) {
        if (this.props.varSchema.length > 0) {
          disabled = true;
          editOnline = true;
        }
      }
      if (this.props.varSchema) {
        let pos = this.props.varSchema.findIndex(
          (item) => item.VARNAME === _e.name
        );
        if (pos > 0) {
          let option = this.props.varSchema[pos].EDITABLE;
          if (option === "NO") {
            disabled = true;
          } else if (option === "ALL") {
            disabled = false;
          } else {
            if (
              option === getUserInfo().USERGROUP ||
              getUserInfo().USERGROUPID === 1
            ) {
              disabled = false;
            } else {
              disabled = true;
            }
          }
        }
      }
      let enabled = !disabled;
      let e;
      if (_e.forceDiabled) {
        disabled = true;
        enabled = false;
      }
      if (_e.layout_id) {
        e = _e;
        e["rules"] = {};
        e["value"] = _e.value ? _e.value : "";
        e["values"] = _e.values ? _e.values : [];
        e["size"] = { xs: 12, lg: _e.layout_sizeX };
        e["enabled"] = enabled;
        e["disabled"] = disabled;
        e["editOnline"] = editOnline;
        e["format"] = _e.format || "###";
        e["checkboxLabel"] = "";
        e["className"] = "";
        e["formValidation"] = this.props.formValidation;
        e["varSchema"] = this.props.varSchema;
        e["wfpflowid"] = wfpflowid;
        e["wfptaskid"] = wfptaskid;
        e["priority"] = _e.priority;
      } else {
        e = _e;
        e["formValidation"] = this.props.formValidation;
        e["varSchema"] = this.props.varSchema;
        e["disabled"] = disabled;
        e["enabled"] = enabled;
        e["editOnline"] = editOnline;
        e["wfpflowid"] = wfpflowid;
        e["wfptaskid"] = wfptaskid;
        e["priority"] = _e.priority;
      }

      let { rules, label, name, value, type, addbutton } = e;
      //define rules for input field
      let formattedRules = {
        required: [true, "* Field Required"],
      };
      if (e.rules) {
        options.rules[name] = formattedRules;
      }
      formValues[name] = value ? value : "";
      let xs = 12;
      let lg = 12;
      if (e.size?.xs) {
        xs = e.size.xs;
      }
      if (e.size?.lg) {
        lg = e.size.lg;
      }

      let addPath = "";
      if (type === "iframe") {
        if (wfpflowid && wfptaskid && officecode) {
          if (e.iframetype === "EXTERNAL") {
            if (this.props.flowactions.length > 0) {
              addPath =
                "?wfpflowid=" +
                wfpflowid +
                "&wfptaskid=" +
                wfptaskid +
                "&officecode=" +
                officecode +
                "&options=" +
                this.props.flowactions.toString() +
                "&token=" +
                getToken();
            } else {
              addPath =
                "?wfpflowid=" +
                wfpflowid +
                "&wfptaskid=" +
                wfptaskid +
                "&officecode=" +
                officecode +
                "&token=" +
                getToken();
            }
          } else {
            addPath =
              "?wfpflowid=" +
              wfpflowid +
              "&wfptaskid=" +
              wfptaskid +
              "&officecode=" +
              officecode +
              "&token=" +
              getToken();
          }
        } else {
          addPath =
            "?wfpflowid=" +
            wfpflowid +
            "&wfptaskid=" +
            wfptaskid +
            "&officecode=" +
            officecode +
            "&token=" +
            getToken();
        }
      }

      if (type === "hidden") {
        if (this.props.onChange) {
          if (typeof value !== "object") {
            this.props.onChange({
              target: {
                name: name,
                value: value ? value.toString() : _e.defaultvalue || "",
              },
            });
          }
        }
      }
      //      console.log("e",_e,e)
      return type === "iframe" ? (
        <div style={{ textAlign: "right" }}>
          {e.iframetype && !this.props.noExternalButon && (
            <ButtonComponent
              id="openiFrame"
              name={e.path + addPath + "&popup=true"}
              style={{
                margin: 0,
                zIndex: 999,
                position: "relative",
                right: 6,
                width: 38,
                top: 40,
              }}
              className={"e-btn"}
              iconCss="  e-icons e-open-link "
            ></ButtonComponent>
          )}
          <iframe
            width="100%"
            style={{ border: "0" }}
            height={this.props.iFrameHeight ? this.props.iFrameHeight : "640px"}
            src={e.path + addPath}
          />
        </div>
      ) : type === "hidden" ? (
        <input
          type="hidden"
          name={name}
          value={value ? value.toString() : _e.defaultvalue || ""}
        />
      ) : (
        <div
          key={"field" + index}
          className={"padding-1 col-form col-xs-" + xs + " col-lg-" + lg}
        >
          <div
            key={"div" + index}
            className={
              (type == "space" && lg == 12
                ? ""
                : !e.disabled && e.editOnline
                ? "form-group-edit"
                : type === "radio"
                ? ""
                : "form-group") +
              (addbutton ? "  " : "") +
              (type === "scriptarea" ||
              name === "postloadscript" ||
              name === "preloadscript" ||
              name === "apidata" ||
              name === "apidata"
                ? " source-code"
                : "")
            }
          >
            {!addbutton ? (
              <Input
                key={"input" + index}
                name={name}
                value={value}
                type={type}
                label={label}
                floatFocus={this.floatFocus}
                floatBlur={this.floatBlur}
                onChange={this.onChange}
                saveFlowFieldLocal={this.saveFlowFieldLocal}
                defConfig={this.props.defConfig}
                defLang={this.props.defLang}
                {...e}
              />
            ) : (
              <>
                <Input
                  key={"input" + index}
                  name={name}
                  value={value}
                  type={type}
                  label={label}
                  floatFocus={this.floatFocus}
                  floatBlur={this.floatBlur}
                  onChange={this.onChange}
                  saveFlowFieldLocal={this.saveFlowFieldLocal}
                  {...e}
                />
                {type !== "space" && (
                  <>
                    {Array.isArray(addbutton) ? (
                      <div style={{ textAlign: "right" }}>
                        {addbutton.map((keyB, indexB) => {
                          return (
                            <ButtonComponent
                              style={{ textTransform: "none", marginLeft: 2 }}
                              key={"btnadd" + index + "_" + indexB}
                              iconCss={
                                keyB.id === "refresh" ? "e-icons e-refresh" : ""
                              }
                              className={
                                "e-btn " +
                                (keyB.id === "refresh"
                                  ? "  e-primary"
                                  : " e-success")
                              }
                              id={keyB.id}
                              name={name}
                            >
                              {keyB.id === "refresh" ? "" : keyB.label}
                            </ButtonComponent>
                          );
                        })}
                      </div>
                    ) : (
                      <ButtonComponent
                        key={"btnadd" + index}
                        className="ml-10 e-success"
                        id={addbutton.id}
                        name={name}
                      >
                        {addbutton.label}
                      </ButtonComponent>
                    )}
                  </>
                )}
              </>
            )}
            <div key={"error" + index} id={name + "Error"} />
          </div>
        </div>
      );
    });

    this.formObject = new FormValidator("#form1", options);
    this.formObject.customPlacement = function (element, errorElement) {
      element.parentNode.parentNode.appendChild(errorElement);
    };

    this.setState({
      ...this.state,
      loading: false,
      formButtons: formButtons,
      originalformInputs: formData,
      formInputs: formInputs,
      formValues: formValues,
      buttons: buttons,
    });
  };

  onChange = async (event) => {
    //console.log("eve", event)
    var action = "";
    const formValues = this.state.formValues;
    var formInputs = this.state.originalformInputs;
    var field = {};

    if (event.target.type) {
      if (event.target.type === "switch") {
        if (
          formValues[event.target.name] === "" ||
          formValues[event.target.name] === "NO"
        ) {
          formValues[event.target.name] = "YES";
          event["target"]["value"] = "YES";
        } else {
          formValues[event.target.name] = "";
          event["target"]["value"] = "";
        }
      } else if (event.target.type === "options") {
        let data = event.target;
        let value = {};
        if (data.datatype == "static") {
          value = {
            datatype: data.datatype,
            options: data.options,
          };
        } else {
          value = {
            datatype: data.datatype,
            apidata: data.apidata,
            apimethod: data.apimethod,
            apitoken: data.apitoken,
            apiurl: data.apiurl,
            text: data.text,
            value: data.value,
          };
        }
        //console.log( "formValues:",formValues, value)
        formValues[event.target.name] = value;
      }
    } else {
      formValues[event.target.name] = event.target.value;
    }

    try {
      const index = formInputs.findIndex(
        (item) => item.name === event.target.name
      );

      if (formInputs[index].action) {
        //console.log("action", formInputs[index].action)
        if (formInputs[index].action != "") {
          const formData = convertArrayToObj(this.state.originalformInputs);
          var appUrl = process.env.REACT_APP_URL_CONFIG;
          field = formInputs[index];
          field["value"] = event.target.value;
          //console.log("formInputs", formInputs )
          //console.log("field", field )
          action = formInputs[index].action;
          const { simpleGetData } = require("../../utils/Functions");
          const { simplePostData } = require("../../utils/Functions");
          const utils = require("../../utils/Utils");
          if (action.indexOf("async") >= 0) {
            //console.log("async")
            setRefreshingData(true);
            await eval(action);
            //console.log("fin")
            setRefreshingData(false);
          } else {
            //console.log("sync",action)
            eval(action);
          }
          //console.log("formData", formData)
          formInputs = convertObjToArray(formData, "layout_row");
          //console.log("formInputs", formInputs)
          await this.prepareForm(
            formInputs,
            this.state.buttons,
            this.state.wfpflowid,
            this.state.wfptaskid,
            this.state.officecode
          );
        }
        if (this.props.setformValues) {
          this.props.setformValues(this.state.formValues);
        }
      }

      if (this.props.onChange) {
     //   console.log("PR", event)
        this.props.onChange(event);
      }
    } catch (Exc) {
      console.log(">>>>Err", Exc);
      console.log("event", event);
      if (action != "") {
        console.log("action", action);
      }
    }
  };

  onSubmitClick(event) {
    //event.preventDefault();

    let formValues = this.state.formValues;
    const index = this.state.buttons.findIndex(
      (item) => item.name === event.target.name
    );

    //console.log(this.state.buttons, index)

    try {
      let keys = Object.keys(specialComponents);
      Object.keys(specialComponents).map((key, index) => {
        let field = specialComponents[key];
        formValues[key] = field.value;
      });
    } catch (e) {
      //console.log("no custom data", specialComponents, e)
    }

    this.state.buttons[index].action(formValues);

    /*
		  const index = this.state.buttons.findIndex((item) => item.name === event.target.name);
		  //if (this.formObject.validate()) {
			const resp =	(values)=> this.state.buttons[index].action(values) 
	  	
		  try{
			  let keys = Object.keys(specialComponents)
			  let formValues = this.state.formValues
			  Object.keys(specialComponents).map(( key, index) => {
			  	
				  let field = specialComponents[ key]
				  formValues[key] = field.value
			  	
			  });
		  }catch(e){
			  console.log("no custom data", specialComponents, e)
		  }
  
			resp( this.state.formValues);*/
  }

  render() {
    return (
      <>
        {this.state.loading && <Loading />}
        <div className="control-panel mt-1">
          <div className="row no-padding">
            <div
              className={
                "no-padding col-sm-1" +
                (this.props.expandaction ? 1 : 2) +
                " col-lg-1" +
                (this.props.expandaction ? 1 : 2) +
                " col-1" +
                (this.props.expandaction ? 1 : 2)
              }
            >
              {this.props.datatitle && (
                <Typography
                  variant="h3"
                  mt={2}
                  style={{
                    borderBottom: "3px solid #4c38c9",
                    lineHeight: "5rem",
                  }}
                >
                  {this.props.datatitle && this.props.datatitle}
                </Typography>
              )}
            </div>
            {/*this.props.datatitle && this.props.expandaction && this.state.wWidth>799 &&
					<div className={"col-sm-1 col-lg-1 col-1 expand-btn"} id="expandOption" >
						<span class={"e-icons e-zoom-"+(this.state.expandStatus?"out":"in") }></span>
					</div>
				*/}
          </div>

          <div className="row no-padding">
            <div
              className={
                "no-padding col-sm-1" +
                (this.props.expandaction && !this.props.datatitle ? 1 : 2) +
                " col-lg-1" +
                (this.props.expandaction && !this.props.datatitle ? 1 : 2) +
                " col-1" +
                (this.props.expandaction && !this.props.datatitle ? 1 : 2)
              }
            >
              {this.props.datasubtitle && (
                <Typography
                  variant="h5"
                  style={{ borderBottom: "1px solid #acacac" }}
                  mt={this.props.datatitle ? 1 : 0}
                  mb={3}
                >
                  {this.props.datasubtitle}
                </Typography>
              )}
            </div>
            {/*this.props.expandaction && !this.props.datatitle && this.state.wWidth>799 &&
					<div className={"col-sm-1 col-lg-1 col-1 expand-btn"} id="expandOption" >					 
						<span class={"e-icons e-zoom-"+(this.state.expandStatus?"out":"in") }></span>
					</div>
  				*/}
          </div>
          <div className="validation_wrapper">
            <div
              className={
                "control_wrapper" + (this.props.datatitle ? " mt-2" : "")
              }
              id="formContainer"
            >
              <div className="row no-padding">
                {this.state.formInputs ? this.state.formInputs : "NO_DATA"}

                {/**
						<MultiSelectComponent  ref={(scope) => { specialComponents[name] = scope; }} 
							dataSource={values}
							fields={checkFields} 
							placeholder={label} 
							mode="CheckBox" 
							showSelectAll={true}
							value={null}
							showDropDownIcon={true} 
							enableSelectionOrder={true}
							

							onChange={({  value }) => {  onChange({target:{ name, value} }) } }
							filterBarPlaceholder={label} popupHeight="350px">
							<Inject services={[CheckBoxSelection]} />
						</MultiSelectComponent>
						 */}
              </div>
            </div>
          </div>
        </div>

        <div className="row no-padding">{this.state.formButtons}</div>
        <DialogComponent
          id="editFieldDialog"
          animationSettings={this.animationSettings}
          header="Api response"
          ref={(dialog) => (dialogInstanceMessage = dialog)}
          visible={false}
          width="40%"
          isModal={true}
          showCloseIcon={true}
        >
          <div className="my-30">{this.state.apiResponseMessage}</div>
        </DialogComponent>
      </>
    );
  }
}
export default GenericForm;
