import React, { useState } from "react";

import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";

import { useSelector, useDispatch } from "react-redux";
//import "react-toastify/dist/ReactToastify.css";
//import { ToastContainer } from "react-toastify";
import Loading from "./components/customdesign/Loading";
import { getCookie, getInitialConf } from "./utils/Functions";
import Utils from "./utils/Utils";


import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import actionTypes from "./redux/action.types";
import { setCulture } from "@syncfusion/ej2-base";
var toastInstance;
var position = { X: "Right", Y: "Bottom" };
let isExternalLoading = false;
import { L10n } from "@syncfusion/ej2-base";
L10n.load({
  es: Utils.languageEs,
});

const Route = (props) => {
  const {
    user,
    showDashboard,
    defLang,
    defConfig,
    requestingLogin,
    failed,
    error,
    success,
    message,
  } = useSelector((state) => state.Login);
  const {
    requesting,
    requesting2,
    processFailed,
    processMessage,
    processSuccess,
    processError,
    reloadPage,
  } = useSelector((state) => state.Process);
  const [isFatching, setIsFatching] = useState(true);

  React.useEffect(() => {
    setCulture("es");

    const fetchFn = async () => {
      setIsFatching(true);
      const formStructure = [];
      var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
      if (token) {
        var response = await getInitialConf();
      }

      setIsFatching(false);
    };
    fetchFn();
    if (reloadPage) {
      window.location.reload();
    }
  }, [reloadPage]);

  if (failed && error) {
    //console.log("error",error )
    setTimeout(
      function () {
        toastInstance.show({
          title: "Error",
          content: error,
          cssClass: "e-toast-danger",
          icon: "e-error toast-icons",
        });
      }.bind(this),
      200
    );
  }

  if (success && message) {
    //console.log("succ",message )
    setTimeout(
      function () {
        toastInstance.show({
          content: message,
          cssClass: "e-toast-success",
          icon: "e-success toast-icons",
        });
      }.bind(this),
      200
    );
  }

  if (processSuccess && processMessage) {
    console.log("succP", processMessage);

    let message = processMessage;
    if (processMessage === "<Process Succesfull>") {
      message = defConfig[defLang].processSuccMessage;
    }

    if (processMessage === "<Successfully deleted>") {
      message = defConfig[defLang].processSuccdelMessage;
    }

    setTimeout(
      function () {
        toastInstance.show({
          title: "Exito",
          content: message,
          cssClass: "e-toast-success",
          icon: "e-success toast-icons",
        });
      }.bind(this),
      200
    );
  }

  if (processFailed && processError) {
    console.log("errorP", processError);

    setTimeout(
      function () {
        toastInstance.show({
          title: "Error",
          content: processError,
          cssClass: "e-toast-danger",
          icon: "e-error toast-icons",
        });
      }.bind(this),
      200
    );
  }

  return isFatching ? (
    ""
  ) : (
    <>
      <ToastComponent
        ref={(toast) => {
          toastInstance = toast;
        }}
        position={position}
        showCloseButton={true}
      ></ToastComponent>
      {requestingLogin && <Loading />}
      {requesting && <Loading />}
      {requesting2 && <Loading />}
      {/*
			<ToastContainer
			 			closeButton={true}
	          autoClose={2500}
	          hideProgressBar={true}
	          position={"bottom-right"}
	           />
	    */}

      <BrowserRouter>
        <Routes
          defLang={defLang}
          defConfig={defConfig}
          reloadPage={reloadPage}
        />
      </BrowserRouter>
    </>
  );
};

export default Route;
