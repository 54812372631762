import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as UserActions from "../../redux/actions/user.actions";
import GenericForm from "../../components/forms/GenericForm";
import Typography from "../../components/customdesign/Typography";

const Forgot = (props) => {
	const { defConfig, params, defLang, activeForm, usertoken} = useSelector((state) => state.Login);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
   
	React.useEffect(()=>{
		if (usertoken){
			navigate("/dashboard");
		}
	},[usertoken])

	const submitForm = (values) => {   	
		let domain= (window.location.hostname.replace("-", "")).split(".")


	//	values["domain"] = domain[0].replace("-", "")
		//console.log("submitForm",values)
		dispatch(UserActions.RecoveryPass(values))
	};
	const loginFormInputs = [
	 
   {
  	name:"email",
    label: "Email",
    type: "text",
    placeholder: "Enter Email",
    value:  process.env.REACT_APP_DEF_USER?process.env.REACT_APP_DEF_USER:"" ,
	values:[],
    rules: {
      required: true,
    },
    size: {
    	xs:12,
    	lg:12
    }
  },
 
  
  {
  	name:"Login",
    label: "Login",
	values:[],
    type: "link",
    value: "/login",
    style:{textAlign: "center"},
    size: {
    	xs:12,
    	lg:12
    }
  }
  
];
  return (  	
	<div>
		<div  style={{ height: "100vh", display:"flex", alignItems:"center", justifyContent:"center" , 
					background: "linear-gradient( #F0EEFF, #fff)"
					}}>
		
			<div className="col-xs-12 col-md-12 col-lg-6 " >
				<div className="col-xs-12">  				
					<div className="row image"> 
						<div className="col-xs-12 mt-50-web mt-30-mobile flex-center">
							<img style={{width: 218}}  src="/images/logo.png"/>
						</div>
					</div>

					<div className="row form"> 
						<div className="col-xs-12  flex-center">
							<Typography variant="h4" mt={2}>
								Recovery password
							</Typography>
						</div>
					</div>

					<div className="row form flex-center"> 
						<div className="col-xs-12 col-lg-12">		
							<GenericForm 				    	 
								data={loginFormInputs} 
								buttonposition="center"
								buttons={
								[{label     : defConfig[defLang].recoverypassword,
									style: {textTransform:"initial"  , width:"25%" },
									name      : "Forgot",
									size      : "col-xs-12 col-md-12 col-lg-12  flex-center text-center",
									action    : submitForm ,
									className : "e-primary"
								}
								]}
							/> 
						</div>
					</div>		  
				</div>
			</div>	
			
			

		</div>
		<div className="row text-center copyright" >
		    <div className="col-xs-12">  
			2023 © Copyright
			</div>
		</div>	
	</div>	
  );
};

Forgot.propTypes = {};

export default Forgot;
