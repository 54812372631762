import React from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import * as ProcessActions from "../../redux/actions/process.actions";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Filter,
  Sort,
  Edit,
  Toolbar,
  Aggregate,
  Page,
  DetailRow,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Browser } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import ApiClient from "../../api/ApiClient";
import EndPoint from "../../api/Endpoints";
import Utils from "../../utils/Utils";
import { prepareForm, prepareGetHistory } from "../../utils/Functions";
import Loading from "../../components/customdesign/Loading";
import GenericForm from "../../components/forms/GenericForm";
let gridHistoryFlows;
let variablesDialogInstance;
const DialogReports = (props) => {
  const { defConfig, params, defLang, activeForm } = useSelector(
    (state) => state.Login
  );

  const [historyJobs, setHistoryJobs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [header, setHeader] = React.useState([]);
  const [values, setValues] = React.useState({});
  const [formFields, setFormFields] = React.useState([]);
  const filterOptions = { type: "Excel" };
  const toolbarOptions = [
    {
      text: Browser.isDevice ? "" : "Refresh",
      prefixIcon: "e-icons e-refresh",
      tooltipText: "Refresh",
      id: "refresh",
    },
    "Search",
  ];

  const loadForm = async (event) => {
    if (formFields.length === 0) {
      let _formFields = await prepareForm(
        props.formFields || Utils.formSearchJobs
      );
      setFormFields(_formFields);
      let _values = {};
      for (let i in _formFields) {
        let field = _formFields[i];
        _values[field.name] = field.value;
        onChange({ target: { name: field.name, value: field.value } });
      }
      //setValues(_values);

      setTimeout(
        function () {
          search(_values);
        }.bind(this),
        100
      );
    }
  };

  React.useEffect(() => { 
    var vars = props.reportInfo.header;
    setHeader(vars);
    loadForm();
  }, []);

  const handleClick = () => {
    search(values);
  };

  const onChange = async (event) => {
    document
      .getElementById("searchlogs")
      .removeEventListener("click", handleClick);
    let _values = values;
    _values[event.target.name] = event.target.value;
    setValues(_values);

    document
      .getElementById("searchlogs")
      .addEventListener("click", handleClick);
  };

  var search = async (values) => {
    let data = {};
    if (props.filterValue && props.filterValue !== "") {
      data = {
        filtervalue: props.filterValue,
        ...values,
      };
      if (values.daterange) {
        data["from"] = values.daterange[0];
        data["to"] = values.daterange[1];
      }
      //console.log(data);
      setLoading(true);
      let response = await prepareGetHistory(data, props.reportInfo.apiName);
      if (response.status) {
        setHistoryJobs(response.jobs);
      } else {
        setHistoryJobs([]);
      }
      setLoading(false);
    }
  };
  const contextToolbarMenuTaskClick = async (args) => {
    if (args.item.id == "refresh") {
      search(values);
    }
  };

  const footerDialogFlow = () => {
    return (
      <div>
        <ButtonComponent
          className={"ml-10 e-btn   e-flat"}
          onClick={props.close}
          style={{ textTransform: "uppercase", fontWeight: "500" }}
        >
          {defConfig[defLang].closelabel}
        </ButtonComponent>
      </div>
    );
  };

  return (
    <DialogComponent
      id="variablesDialogInstance"
      showCloseIcon={true}
      closeOnEscape={false}
      animationSettings={props.animationSettings}
      ref={(dialog) => (variablesDialogInstance = dialog)}
      isModal={true}
      open={props.open}
      close={props.close}
      header={props.header || defConfig[defLang].viewlogstitle}
      footerTemplate={footerDialogFlow.bind(this)}
      width="85%"
      height="100vh"
      position={{ X: "right", Y: "top" }}
      visible={props.status}
    >
      <div className="control-pane">
        <div style={{ overflow: "auto" }}>
          {loading && <Loading timer={4000} contained={true} />}
          <div className="row no-padding">
            <div className="col-sm-12 col-md-12 col-md-6 col-lg-4 min-padding">
              {formFields.length > 0 && (
                <GenericForm
                  data={formFields}
                  onChange={onChange}
                  buttons={[]}
                />
              )}
            </div>
          </div>
          <div className="row no-padding mb-2">
            <div className="col-sm-12 col-md-12 col-md-6 col-lg-4 items-right min-padding">
              <ButtonComponent
                className={"e-success"}
                id="searchlogs"
                style={{
                  fontWeight: "500",
                  position: "relative",
                  top: 8,
                  marginRight: 2,
                  textTransform: "capitalize",
                }}
              >
                {defConfig[defLang].searchlabel}
              </ButtonComponent>
            </div>
          </div>
          {!loading && (
            <div style={{ padding: Browser.isDevice ? "5px" : "0" }}>
              {Browser.isDevice ? (
                <div className="-e-mobile-layout">
                  <div className="+e-mobile-content">
                    <GridComponent
                      id="adaptivebrowser"
                      toolbarClick={contextToolbarMenuTaskClick}
                      toolbar={toolbarOptions}
                      dataSource={historyJobs}
                      height="100%"
                      ref={(g) => {
                        gridHistoryFlows = g;
                      }}
                      enableAdaptiveUI={true}
                      rowRenderingMode={"Vertical"}
                      allowFiltering={true}
                      allowSorting={true}
                      allowPaging={true}
                      filterSettings={filterOptions}
                      pageSettings={{
                        pageCount: 3,
                        pageSize: 5,
                        pageSizes: true,
                      }}
                    >
                      <ColumnsDirective>
                        {header &&
                          header.map((b, index) => {
                            return index === 0 ? (
                              <ColumnDirective
                                field={b.field}
                                headerText={b.label}
                                width="120"
                              />
                            ) : (
                              <ColumnDirective
                                field={b.field}
                                headerText={b.label}
                                width="150"
                              />
                            );
                          })}
                      </ColumnsDirective>
                      <Inject services={[Filter, Sort, Toolbar, Page]} />
                    </GridComponent>
                  </div>
                </div>
              ) : (
                <GridComponent
                  id="adaptivedevice"
                  allowTextWrap={true}
                  filterSettings={filterOptions}
                  toolbarClick={contextToolbarMenuTaskClick}
                  toolbar={toolbarOptions}
                  dataSource={historyJobs}
                  ref={(g) => {
                    gridHistoryFlows = g;
                  }}
                  /*height='100%'*/
                  allowFiltering={true}
                  allowSorting={true}
                  allowPaging={true}
                  pageSettings={{ pageCount: 3, pageSize: 10, pageSizes: true }}
                >
                  <ColumnsDirective>
                    {header &&
                      header.map((b, index) => {
                        return index === 0 ? (
                          <ColumnDirective
                            field={b.field}
                            headerText={b.label}
                            width="120"
                          />
                        ) : (
                          <ColumnDirective
                            field={b.field}
                            headerText={b.label}
                            width="150"
                          />
                        );
                      })}
                  </ColumnsDirective>
                  <Inject services={[Filter, Sort, Toolbar, Page]} />
                </GridComponent>
              )}
            </div>
          )}
        </div>
      </div>
    </DialogComponent>
  );
};

DialogReports.propTypes = {};

export default DialogReports;
