import actionTypes from "../action.types";
import {
  getUserRol,
  getCookie,
  setCookie,
  getUserInfo,
} from "../../utils/Functions";
//import { toast } from "react-toastify";
import Lngs from "./languages.js";

var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
var name = getCookie(process.env.REACT_APP_NAME + "_username");
var id = getCookie(process.env.REACT_APP_NAME + "_userid");
var org = getCookie(process.env.REACT_APP_NAME + "_organization");

var usertoken;
var username;
var userorg;
var userid
var defLang = process.env.REACT_APP_DEF_LANGUAGE;
if (token) {
  if (token != "undefined") {
    usertoken = token;
  }
}

if (token) {
  if (token != "undefined") {
    userorg = org;
  }
}

if (name) {
  if (name.length > 0) {
    username = name;
  }
}

if(id){
  if (id.length > 0) {
    userid = id;
  }
}
var userInfo = getUserInfo();
var useracronym = "";
var filespath = "";
if (userInfo.USERID) {
  useracronym = (
    userInfo.FIRSTNAME.substr(0, 1) + userInfo.LASTNAME.substr(0, 1)
  ).toUpperCase();
  if (userInfo.SUPERADMIN == "NO") {
    filespath = "store_" + userInfo.STORECODE + "/";
  }
}
var menu = [];
let dataMenu = localStorage.getItem(process.env.REACT_APP_NAME + "_menu");
if (dataMenu) {
  if (dataMenu.length > 0) {
    menu = JSON.parse(dataMenu);
  }
}

var menuWFL = [];
let dataMenuWFL = localStorage.getItem(process.env.REACT_APP_NAME + "_menuWFL");
if (dataMenuWFL) {
  if (dataMenuWFL.length > 0) {
    menuWFL = JSON.parse(dataMenuWFL);
  }
}

var customMenu = [];
let dataCustomMenu = localStorage.getItem(
  process.env.REACT_APP_NAME + "_customMenu"
);
if (dataCustomMenu) {
  if (dataCustomMenu.length > 0) {
    customMenu = JSON.parse(dataCustomMenu);
  }
}

var orgGlobalVars = [];
let _orgGlobalVars = localStorage.getItem(
  process.env.REACT_APP_NAME + "_orgGlobalVars"
);
if (_orgGlobalVars) {
  if (_orgGlobalVars.length > 0) {
    orgGlobalVars = JSON.parse(_orgGlobalVars);
  }
}

const initialState = {
  requestingLogin: false,
  success: usertoken ? true : false,
  failed: false,
  error: null,
  showDashboard: usertoken ? true : false,
  defConfig: Lngs.defConfig,
  params: Lngs,
  activeForm: "login",
  defLang: defLang ? defLang : process.env.REACT_APP_DEF_LANGUAGE,
  role: getUserRol(),
  usertoken: usertoken,
  username: username,
  userid: userid,
  userorg: userorg,
  useracronym: useracronym,
  filespath: filespath,
  menu: menu,
  menuWFL: menuWFL,
  customMenu: customMenu,
  orgGlobalVars: orgGlobalVars,
  message: "",
  isNew: false,
  //tasks:[],
  requestingResumenTasks: true,
};

const LoginReducer = (state = initialState, action) => {
  const { type, payload } = action;
  //console.log("type USR", Lngs.defConfig);

  switch (type) {
    case actionTypes.ACTIVE_FORM:
      return {
        ...state,
        requestingLogin: false,
        success: true,
        activeForm: payload,
      };

    case actionTypes.LOGIN_SUCCESS:
      setCookie(
        process.env.REACT_APP_NAME + "_usertoken",
        action.payload.token,
        process.env.REACT_APP_SESSION_DURATION
      );
      setCookie(
        process.env.REACT_APP_NAME + "_username",
        action.payload.data.USERNAME,
        process.env.REACT_APP_SESSION_DURATION
      );
      setCookie(
        process.env.REACT_APP_NAME + "_userid",
        action.payload.data.USERID,
        process.env.REACT_APP_SESSION_DURATION
      );
      setCookie(
        process.env.REACT_APP_NAME + "_userorg",
        action.payload.data.ORGANIZATIONCODE,
        process.env.REACT_APP_SESSION_DURATION
      );

      
      localStorage.setItem(process.env.REACT_APP_NAME + "_session", new Date());

      
      localStorage.setItem(
        process.env.REACT_APP_NAME + "_menu",
        JSON.stringify(action.payload.menu)
      );
      localStorage.setItem(
        process.env.REACT_APP_NAME + "_menuWFL",
        JSON.stringify(action.payload.menuWFL)
      );
      localStorage.setItem(
        process.env.REACT_APP_NAME + "_customMenu",
        JSON.stringify(action.payload.customMenu)
      );
      localStorage.setItem(
        process.env.REACT_APP_NAME + "_orgGlobalVars",
        JSON.stringify(action.payload.orgGlobalVars)
      );

      var useracronym = "";
      var filespath = "";
      useracronym = (
        action.payload.data.FIRSTNAME.substr(0, 1) +
        action.payload.data.LASTNAME.substr(0, 1)
      ).toUpperCase();
      if (action.payload.data.SUPERADMIN == "NO") {
        filespath = "store_" + action.payload.data.STORECODE + "/";
      }
      return {
        ...state,
        requestingLogin: false,
        isNew: action.payload.isNew,
        success: true,
        failed: false,
        username: action.payload.data.USERNAME,
        userorg: action.payload.data.ORGANIZATIONCODE,
        usertoken: action.payload.token,
        role: action.payload.data.role,
        useracronym: useracronym,
        filespath: filespath,
        menu: action.payload.menu,
        orgGlobalVars: action.payload.orgGlobalVars,
        customMenu: action.payload.customMenu,
        menuWFL: action.payload.menuWFL,
        error: null,
        message:
          Lngs.defConfig[
            defLang ? defLang : process.env.REACT_APP_DEF_LANGUAGE
          ]["welcomeTo"] + process.env.REACT_APP_NAME,
        requestingResumenTasks: false,
        tasks: action.payload.activeTasks,
      };
    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        requestingLogin: false,
        failed: true,
        success: false,
        error: payload.error,
      };

    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        requestingLogin: true,
        error: null,
        failed: false,
        success: false,
      };
    case actionTypes.USER_LOADED:
      return {
        ...state,
        requestingLogin: false,
        success: true,
        user: payload,
      };

    case actionTypes.RECOVERY_SUCCESS:
      setCookie(
        process.env.REACT_APP_NAME + "_recoverymail_" + payload.email,
        "SEND",
        process.env.REACT_APP_SESSION_DURATION
      );

      return {
        ...state,
        requestingLogin: false,
        success: true,
        message: payload.message,
      };

    case actionTypes.RECOVERY_FAILED:
      return {
        ...state,
        requestingLogin: false,
        failed: true,
        success: false,
        error: payload.error,
      };
    case actionTypes.RESUME_TASKS_REQUEST:
      return {
        ...state,
        requestingResumenTasks: true,
      };

    case actionTypes.RESUME_TASKS_S_REQUEST:
      return {
        ...state,
        gettingResumeTasks: true,
      };
    case actionTypes.RESUME_TASKS_LOADED:
      return {
        ...state,
        tasks: action.payload.result,
        requestingResumenTasks: false,
        gettingResumeTasks: false,
      };
    case actionTypes.RESUME_TASKS_FAILED:
      return {
        ...state,
        tasks: [],
        requestingResumenTasks: false,
      };
    case actionTypes.LOGOUT:
      setCookie(process.env.REACT_APP_NAME + "_usertoken", null, 0.00001);
      setCookie(process.env.REACT_APP_NAME + "_username", null, 0.00001);
      setCookie(process.env.REACT_APP_NAME + "_userid", null, 0.00001);
      setCookie(process.env.REACT_APP_NAME + "_userorg", null, 0.00001);
      localStorage.removeItem(process.env.REACT_APP_NAME + "_session");
      localStorage.removeItem(process.env.REACT_APP_NAME + "_menu");
      localStorage.removeItem(process.env.REACT_APP_NAME + "_menuWFL");
      localStorage.removeItem(process.env.REACT_APP_NAME + "_customMenu");
      localStorage.removeItem(process.env.REACT_APP_NAME + "_orgGlobalVars");

      return {
        ...state,
        username: "",
        usertoken: "",
        role: "",
        message: "logout",
        showDashboard: false,
      };

    default:
      return {
        ...state,
        error: false,
        successs: false,
        message: null,
        error: null,
      };
      return state;
  }
};

export default LoginReducer;
