import * as React from 'react';
import { DashboardLayoutComponent, PanelsDirective, PanelDirective } from '@syncfusion/ej2-react-layouts';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import './properties.component.css';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import GenericForm from "./GenericForm";
import Utils from "../../utils/Utils";
import { prepareForm, parseFormData, clearformData, find, mergeFormData } from '../../utils/Functions';
let animationSettings = { effect: 'Fade' };
class CompFilterDesinger extends React.Component {

    constructor(props) {
        super(props);
        //this.onAdd = this.onAdd.bind(this); 
        this.state = {
            isExecuting: true,
            dataSource: [],
            dashboardObj: {},
            dialogInstanceFilter: {},
            dialogInstanceFilter1: {},
            count: 0,
            formFields: [],
            formVisible: false,
            completeFields: [],
            sizeCol: 12,
            fields: [],
            fieldId: "",
            filedOptions: [
                { text: "Full Size", value: 12 },
            ]
        }
    }

    componentDidMount = async () => {
        let formFields = await prepareForm(Utils.filterFiledEditor)


        document.removeEventListener('click', this.checkClick, true);
        document.addEventListener("click", this.checkClick);

        let fields = []
        let count = 0
        setTimeout(async function () {
            for (let i in this.props.value) {
                let col = this.props.value[i]
                let _id = parseInt(col.id)
                if (count < _id) {
                    count = _id
                }

                let showVal = false;
                if (col.is_required === "YES" || col.is_name === "YES" || col.is_number === "YES" || col.is_email === "YES" || col.is_phonenumber === "YES") {
                    showVal = true
                }

                let panel = [{
                    'id': col.filter_id,
                    sizeX: col.filter_sizeX,
                    sizeY: col.filter_sizeY,
                    'row': col.filter_row,
                    'col': col.filter_col,
                    minSizeY: col.filter_maxSizeY,
                    maxSizeY: col.filter_minSizeY,
                    header: '<span id="edit" class="e-btn-icon e-edit e-icons e-edit-icon "></span>  <span id="close" class="e-close-icon e-clear-icon"></span> ' +
                        ' <div>Filter: ' + col.name + ' </div>',
                    content: '<div class="row no-padding" style="line-height: 1.15; height:34px">' +
                        '<div  class="col-xs-12 col-md-6"> <p>Label: ' + col.label + '</p>  <p class="flex-left">Type : <span class="e-badge e-badge-success ml-1">' + col.type + '</span></p> </div>' +
                        '</div>'

                }];
                this.state.dashboardObj.addPanel(panel[0]);
                col["id"] = parseInt(col["id"])
                fields.push(col)

            }
            this.setState({ formFields: formFields, fields: fields, count: count });
        }.bind(this), 10)

    }

    checkClick = (event) => {
        try {
            if (event.target.parentNode.id) {
                if (event.target.parentNode.id.indexOf("filtertemplate") >= 0) {
                    if (event.target.id === "edit") {
                        this.onEditIconHandler(event)
                    } else if (event.target.id === "close") {
                        this.onCloseIconHandler(event)
                    }
                }
            }
        } catch (Ex) {

        }
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.checkClick, true);
    }
    onChange = (event) => {
        const formFields = this.state.formFields.map(function (a) {
            let val = "NO"
            if (a["name"] == event.target.name) {
                if (event.target.type) {
                    if (event.target.type === "switch") {
                        if (a["value"] === "YES") {
                            val = "NO"
                        } else {
                            val = "YES"
                        }
                        a["value"] = val
                    } else if (event.target.type === "options") {
                        let data = event.target
                        let value = {}
                        if (data.datatype == "static") {
                            value = {
                                datatype: data.datatype,
                                options: data.options
                            }
                            //   a["values"] = data.options
                            a["value"] = value
                        } else {
                            value = {
                                datatype: data.datatype,
                                apidata: data.apidata,
                                apimethod: data.apimethod,
                                apitoken: data.apitoken,
                                apiurl: data.apiurl,
                                text: data.text,
                                value: data.value,
                            }
                            val = value
                            // a["api"] = value
                            a["value"] = value
                        }
                    }
                } else {
                    val = event.target.value
                    a["value"] = val
                }


            }
            return a;
        });

        //console.log("formFields",formFields)
    }
    //deprecated
    onBeginAdd = (event) => {
        let formFields = clearformData(this.state.formFields)
        this.setState({ formFields: [], sizeCol: parseInt(event.target.id) });
        setTimeout(async function () {
            this.setState({ formFields: formFields, formVisible: true, action: "create" });
            //this.state.dialogInstanceFilter.show()
        }.bind(this), 10)
    }

    prepareField = (event) => {

        let formFields = clearformData(this.state.formFields)
        this.setState({ formFields: [] });
        setTimeout(async function () {
            this.setState({ formFields: formFields, formVisible: true, action: "create" });
            //this.state.dialogInstanceFilter1.hide()
        }.bind(this), 10)
    }

    onChangeS = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    saveField = (event) => {

        let formData = parseFormData(this.state.formFields)
        const proxy = this.state;
        let row = 0
        let col = 0
        let dataSource = proxy.dashboardObj.serialize()
        let maxRow = 0
        const fields = this.state.fields

        let showVal = false;
        if (formData.is_required === "YES" || formData.is_name === "YES" || formData.is_number === "YES" || formData.is_email === "YES" || formData.is_phonenumber === "YES") {
            showVal = true
        }

        if (this.state.action == "update") {

            let pos = find(dataSource, this.state.fieldId, "id")
            let pos2 = find(this.state.fields, parseInt(this.state.fieldId), "id")
            formData["id"] = fields[pos2].id
            fields[pos2] = formData
            let id = proxy.fieldId.replace("_filter", "")
            this.setState({ fields: fields });
            proxy.dashboardObj.panels[pos]["header"] =
                '<span id="edit" class="e-btn-icon e-edit e-icons e-edit-icon "></span>  <span id="close" class="e-close-icon e-clear-icon"></span> ' +
                ' <div>Filter: ' + formData.name + ' </div>',


                proxy.dashboardObj.panels[pos]["content"] =
                '<div class="row no-padding" style="line-height: 1.15; height:34px">' +
                '<div  class="col-xs-12 col-md-6"> <p>Label: ' + formData.label + '</p>  <p class="flex-left">Type : <span class="e-badge e-badge-success ml-1">' + formData.type + '</span></p> </div>' +
                '</div>'

            if (this.state.sizeCol > 4) {

                proxy.dashboardObj.panels[pos]["content"] = '<div class="row no-padding" style="line-height: 1.15; height:34px">' +
                    '<div  class="col-xs-12 col-md-6"> <p>Label: ' + formData.label + '</p>  <p class="flex-left">Type : <span class="e-badge e-badge-success ml-1">' + formData.type + '</span></p> </div>' +
                    '</div>'


            }


        } else {
            this.state.count = this.state.count + 1;
            for (let r in dataSource) {
                if (dataSource[r].row > maxRow) {
                    maxRow = dataSource[r].row
                }
            }
            let maxCol = 0
            let totalCol = 0
            for (let c in dataSource) {
                if (dataSource[c].row == maxRow) {
                    totalCol = totalCol + dataSource[c].sizeX;
                    if (dataSource[c].col > maxCol) {
                        maxCol = dataSource[c].col
                    }
                }
            }
            if (dataSource.length > 0) {
                if (totalCol + this.state.sizeCol > 12) {
                    row = maxRow + 4
                } else {
                    col = totalCol
                    row = maxRow
                }
            }
            let panel = [{
                'id': this.state.count.toString() + '_filter', 'sizeX': this.state.sizeCol, 'sizeY': 2, 'row': row, 'col': col, minSizeY: 2, maxSizeY: 2,
                header: '<span id="edit" class="e-btn-icon e-edit e-icons e-edit-icon "></span>  <span id="close" class="e-close-icon e-clear-icon"></span> ' +
                    ' <div>Filter: ' + formData.name + ' </div>',
                /*content: '<div class="row no-padding" style="line-height: 1.3"> <div  class="col-xs-6"> <p>Label: '+formData.label+'</p>  <p>Type : '+formData.type+'</p> </div>'+
                        (this.state.sizeCol>=4 ?  '  <div  class="col-md-6"></div>':'')+
                        '</div>'*/

                content: '<div class="row no-padding" style="line-height: 1.15; height:34px">' +
                    '<div  class="col-xs-12 col-md-6"> <p>Label: ' + formData.label + '</p>  <p class="flex-left">Type : <span class="e-badge e-badge-success ml-1">' + formData.type + '</span></p> </div>' +
                    '</div>'
            }];

            if (this.state.sizeCol > 4) {
                panel = [{
                    'id': this.state.count.toString() + '_filter', 'sizeX': this.state.sizeCol, 'sizeY': 2, 'row': row, 'col': col, minSizeY: 2, maxSizeY: 2,
                    header: '<span id="edit" class="e-btn-icon e-edit e-icons e-edit-icon "></span>  <span id="close" class="e-close-icon e-clear-icon"></span> ' +
                        ' <div>Filter: ' + formData.name + ' </div>',
                    /*content: '<div class="row no-padding" style="line-height: 1.3"> <div  class="col-xs-6"> <p>Label: '+formData.label+'</p>  <p>Type : '+formData.type+'</p> </div>'+
                            (this.state.sizeCol>=4 ?  '  <div  class="col-md-6"></div>':'')+
                            '</div>'*/

                    content: '<div class="row no-padding" style="line-height: 1.15; height:34px">' +
                        '<div  class="col-xs-12 col-md-6"> <p>Label: ' + formData.label + '</p>  <p class="flex-left">Type : <span class="e-badge e-badge-success ml-1">' + formData.type + '</span></p> </div>' +
                        '</div>'

                }];
            }

            formData["id"] = this.state.count.toString()

            fields.push(formData)
            proxy.dashboardObj.addPanel(panel[0]);
            /*
            let closeIcon = document.getElementById(this.state.count.toString() + '_filter').querySelector('.e-clear-icon');
            closeIcon.addEventListener('click', this.onCloseIconHandler.bind(this));
            let editIcon = document.getElementById(this.state.count.toString() + '_filter').querySelector('.e-edit-icon');
            editIcon.addEventListener('click', this.onEditIconHandler.bind(this));
            */
        }
        this.onChangeForm()
        this.state.dialogInstanceFilter.hide();
    }

    onChangeForm() {
        let dataSource = this.state.dashboardObj.serialize()
        const fields = this.state.fields
        var values = []
        fields.map(function (f) {
            let newObj = f
            dataSource.map(function (d) {
                if (f.id + "_filter" === d.id) {
                    newObj["filter_id"] = d.id
                    // newObj["filter_maxSizeX"] =  d.maxSizeX
                    newObj["filter_maxSizeY"] = d.maxSizeY
                    //newObj["filter_minSizeX"] =  d.minSizeX
                    newObj["filter_minSizeY"] = d.minSizeY
                    newObj["filter_row"] = d.row
                    newObj["filter_col"] = d.col
                    newObj["filter_sizeX"] = d.sizeX
                    newObj["filter_sizeY"] = d.sizeY
                    newObj["sortorder"] = (d.row * 100) + d.col

                }
            });
            values.push(newObj)
        });
        values.sort(function (a, b) {
            return a.sortorder - b.sortorder;
        });
        this.props.onChange({ target: { name: this.props.name, value: values } })
    }

    onCellChange(args) {
        //console.log("onCellChange",args)
        this.onChangeForm()
    }

    onEditIconHandler(event) {
        let proxy = this.state;
        let panel = event.target;
        const fields = this.state.fields
        let pos = find(fields, parseInt(panel.offsetParent.id), "id")
        let formFields = clearformData(this.state.formFields)
        this.setState({ formFields: [] });

        let dataSource = proxy.dashboardObj.serialize()
        let pos2 = find(dataSource, panel.offsetParent.id, "id")
        let sizeCol = dataSource[pos2].sizeX
        setTimeout(async function () {
            formFields = mergeFormData(formFields, this.state.fields[pos])
            this.setState({ formFields: formFields, formVisible: true, action: "update", fieldId: panel.offsetParent.id, sizeCol: sizeCol });

        }.bind(this), 10)


    }
    onCloseIconHandler(event) {
        let proxy = this.state;
        let panel = event.target;
        const fields = this.state.fields
        let pos = find(fields, parseInt(panel.offsetParent.id), "id")
        fields.splice(pos, 1)

        if (panel.offsetParent) {
            proxy.dashboardObj.removePanel(panel.offsetParent.id);
            this.onChangeForm()
        }
    }



    contentd = () => {
        return (
            <div className='mb-2'>
                <GenericForm
                    data={this.state.formFields.length > 0 ? this.state.formFields : []}
                    onChange={this.onChange}
                    buttons={[]}
                />
            </div>
        );
    }
    render() {
        return (
            <div className="row">
                <DialogComponent
                    id="dialogInstanceFilter1"
                    closeOnEscape={true}
                    isModal={true} showCloseIcon={true}
                    ref={dialog => (this.state.dialogInstanceFilter1 = dialog)}
                    buttons={[
                        {
                            click: () => {
                                this.prepareField();
                            },
                            buttonModel: {
                                content: 'Create',
                                isPrimary: true,
                                type: 'button'
                            }
                        },
                        {
                            click: () => {
                                this.state.dialogInstanceFilter1.hide();
                            },
                            buttonModel: {
                                content: 'Cancel',
                                isPrimary: false,
                                type: 'button'
                            }
                        }]
                    }
                    header={"Add New Filter"}
                    width="235px"
                    visible={false}
                    animationSettings={animationSettings}
                >
                    <div style={{ minHeight: 80, paddingTop: 25 }}>
                        <div>

                            <DropDownListComponent
                                dataSource={this.state.filedOptions}
                                fields={{ text: 'text', value: 'value' }}
                                name="sizeCol"
                                value={this.state.sizeCol}
                                cssClass="e-outline"
                                placeholder={"Field Size"}
                                floatLabelType="Auto"
                                select={({ itemData }) => {
                                    let value = itemData.value
                                    this.onChangeS({ target: { name: "sizeCol", value: value } });
                                }}
                            />


                        </div>
                    </div>

                </DialogComponent>

                <div className="col-lg-12 control-section no-padding" id="control_dash">

                    <div className="no-padding col-lg-12 property-section dashboard" id="api_property">
                        <div className='row field-list-header'>
                            <span>Filter List</span>
                        </div>
                    </div>
                    {/*
                        <div className="no-padding col-lg-12 property-section dashboard" id="api_property">
                            <div className='my-1  row'>
                                <div  className="col-md-6 flex-left no-padding text-center" >
                                    <ButtonComponent iconCss='e-icons e-plus' type="button" id="6" className="e-inherit widget-button" onClick={ this.onBeginAdd }>
                                        Input (6-col)
                                    </ButtonComponent>
                                </div>
                                <div  className="col-md-4  no-padding text-center " >
                                    <ButtonComponent iconCss='e-icons e-plus' type="button" id="4" className="e-inherit widget-button" onClick={ this.onBeginAdd }>
                                        Input (4-col)
                                    </ButtonComponent>
                                </div>
                                <div className="col-md-2 flex-right no-padding text-center " >
                                    <ButtonComponent iconCss='e-icons e-plus' type="button" id="2" className="e-inherit widget-button" onClick={ this.onBeginAdd }>
                                        Input (2-col)
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                        */}
                    <div style={{ padding: 10 }}>
                        <div className="content-wrapper mt-50-web mt-10-mobile" style={{ maxWidth: "100%", minHeight: "127px" }}>
                            <DashboardLayoutComponent id="default_dashboard" cssClass="custom-dashboard"
                                columns={12} cellSpacing={[5, 5]}
                                header={300}
                                change={this.onCellChange.bind(this)}
                                resizeStop={this.onCellChange.bind(this)}
                                ref={(scope) => { this.state.dashboardObj = scope; }} allowResizing={true}>
                                <PanelsDirective>
                                </PanelsDirective>
                            </DashboardLayoutComponent>
                        </div>
                    </div>
                    <div style={{ padding: "0 10px" }}>
                        <div className="col-lg-12 no-padding property-section dashboard" id="api_property">
                            <div className='mb-10 row'>
                                <div className="col-md-12 flex-right no-padding text-right" >
                                    <ButtonComponent iconCss='e-icons e-plus' type="button"
                                        className="e-btn-square e-secondary" onClick={() => { this.prepareField() }}>
                                        New Filter
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.formFields.length > 0 && this.state.formVisible &&
                    <DialogComponent id='editFilterDialog'
                        animationSettings={animationSettings}

                        width={"50%"} height={660}
                        header="Filter Info"
                        ref={dialog => (this.state.dialogInstanceFilter = dialog)}
                        visible={this.state.formVisible} isModal={true} showCloseIcon={true}
                        /*content={this.content.bind(this)}*/
                        buttons={
                            [
                                {
                                    click: () => {
                                        this.saveField();
                                    },
                                    buttonModel: {
                                        content: 'SAVE',
                                        isPrimary: true,
                                    },
                                },
                                {
                                    click: () => {
                                        //props.close();
                                        this.state.dialogInstanceFilter.hide();
                                    },
                                    buttonModel: {
                                        content: 'CANCEL',
                                        isSecondary: true,
                                    },
                                },
                            ]}
                    >

                        <div className='mb-2'>
                            {this.state.formVisible &&
                                <GenericForm
                                    data={this.state.formFields.length > 0 ? this.state.formFields : []}
                                    onChange={this.onChange}
                                    buttons={[]}
                                />
                            }
                        </div>

                    </DialogComponent>
                }
            </div>);
    }
}

export default CompFilterDesinger;