import * as React from 'react';
import Typography from "../customdesign/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../customdesign/Loading";
const ProcessingRequest = (props) => {
	const navigate = useNavigate();
	  
	return (
		<div className="row flex-center">
			<div className=" col-xs-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 flex-center ">
				<div className={"banner-processing-wrapper wow fadeIn animated hover-up"}>
					 
					<div className="banner-processing text-center">


						<Loading  contained={true}  customTop={"30%"} transparent={true} />
						<Typography variant={"h4"} style={{position:"relative", top: "50%", color: "#282c34"}}>
							Creating your account, please wait
						</Typography>
						 
					</div>
				</div>
			</div>
		</div>
	);
};

ProcessingRequest.propTypes = {};

export default ProcessingRequest;

