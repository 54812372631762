import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DialogUtility } from '@syncfusion/ej2-react-popups';
import {
	ButtonComponent,
} from "@syncfusion/ej2-react-buttons";

import * as ProcessActions from "../../../redux/actions/process.actions";
import GenericForm from "../../../components/forms/GenericForm";
import Typography from "../../../components/customdesign/Typography";
import DialogFormItem from "../../../components/dialog/DialogFormItem";
import DialogTemplates from "../../../components/dialog/DialogTemplates";
import DialogSaveTemplate from "../../../components/dialog/DialogSaveTemplate";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import ApiClient from "../../../api/ApiClient";
import EndPoint from "../../../api/Endpoints";
import { findValueById, find } from '../../../utils/Functions';
let wHeight = window.innerHeight
let dialogInstance
const animationSettings = { effect: 'Fade' };
const animationSettings2 = { effect: 'SlideLeft' };
const animationSettings3 = { effect: 'Fade' };
const ManageForm = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { defConfig, params, defLang } = useSelector((state) => state.Login);

	const [formDialog, setFormDialog] = React.useState(false);
	const [formTemplateDialog, setTemplateFormDialog] = React.useState(false);
	const [saveTemplateDialog, setSaveTemplateDialog] = React.useState(false);

	const [privateTemplates, setPrivateTemplates] = React.useState([])


	const [tempValue, setTempValue] = React.useState("");



	const { createdId, formFields, requesting } = useSelector((state) => state.Process);
	const [finalFormFields1, setFinalFormFields1] = React.useState([])
	const [finalFormFields2, setFinalFormFields2] = React.useState([])
	const [values, setValues] = React.useState({ data: [] })
	const [action, setAction] = React.useState("create")
	const [updatePreview, setUpdatePreview] = React.useState(true)


	let { id } = useParams();

	React.useEffect(() => {

		//getTemplates(); 
		//dispatch(ProcessActions.getFormData(  {  code: "forms", domainid:6, defLang :defLang , id: parseInt(id) } ))
		if (createdId > 0) {
			navigate("/manageForm/" + createdId)
			setAction("update")

		}
		if (formFields.length <= 0) {
			dispatch(ProcessActions.getFormData({ code: "forms", domainid: 6, defLang: defLang, id: parseInt(id) }))

		} else {

			if (!requesting) {
				let _finalFormFields1 = []
				let _finalFormFields2 = []
				formFields.map(function (key, item) {
					values[key["name"]] = key["value"]
					if (key["type"] !== "componentformcontainer" && key["type"] !== "scriptarea") {
						//console.log("---",key.type)
						_finalFormFields1.push(key)
					} else {
						_finalFormFields2.push(key)
					}
				});
				setFinalFormFields1(_finalFormFields1)
				setFinalFormFields2(_finalFormFields2)
				getPrivateTemplates()
				if (parseInt(id) > 0) {
					setAction("update")
				} else {
					let defValues = [
						{
							"name": "field1",
							"label": "Field Label 1 (Click to edit)",
							"type": "text",
							"path": "",
							"iframetype": "",
							"action": "",
							"values": "",
							"style": "",
							"variant": "h1",
							"color": "primary",
							"validations": "",
							"is_required": "",
							"is_required_message": "",
							"id": "1",
							"layout_id": "1_layout",
							"layout_maxSizeY": 2,
							"layout_minSizeY": 2,
							"layout_row": 0,
							"layout_col": 0,
							"layout_sizeX": 6,
							"layout_sizeY": 2
						},
						{
							"name": "field2",
							"label": "Field Label 2 (Click to edit)",
							"type": "text",
							"path": "",
							"iframetype": "",
							"action": "",
							"values": "",
							"style": "",
							"variant": "h1",
							"color": "primary",
							"validations": "",
							"is_required": "",
							"is_required_message": "",
							"id": "2",
							"layout_id": "2_layout",
							"layout_maxSizeY": 2,
							"layout_minSizeY": 2,
							"layout_row": 0,
							"layout_col": 6,
							"layout_sizeX": 6,
							"layout_sizeY": 2
						}
					]
					_finalFormFields2[0]["value"] = defValues
					setValues({ data: defValues })
				}
			}

		}
		//console.log("formFields",formFields)
	}, [formFields, createdId])

	const getPrivateTemplates = async () => {
		let response = await ApiClient.doPost(EndPoint.getPrivateTemplates, { type: "forms", componenttypecode: "templates" });
		setPrivateTemplates(response.result)
	};

	const submitForm = async () => {
		values["id"] = id
		values["componenttypecode"] = "forms"
		dispatch(ProcessActions.processData("forms", action, values))
	};
	const exportForm = async () => {

		values["componenttypecode"] = "forms"
		const fileData = JSON.stringify(values);
		const blob = new Blob([fileData], { type: "text/plain" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.download = "template_form.json";
		link.href = url;
		link.click();
	};

	const saveTemplate = async () => {

		values["componenttypecode"] = "forms"
		//	const fileData = JSON.stringify(values);
		setTempValue(values)
		setSaveTemplateDialog(true)
	};

	const onChange = async (event) => {
		let _values = values
		_values[event.target.name] = event.target.value
		setValues(_values)
	};

	const formDialogOpen = () => {
		setFormDialog(true)
	}
	const formDialogClose = () => {
		setFormDialog(false)
	}

	const formTemplateDialogOpen = () => {
		setTemplateFormDialog(true)
	}
	const formTemplateDialogClose = () => {
		setTemplateFormDialog(false)
	}

	const onUploadSuccess = (args) => {
		let file1 = args.file;
		let file = file1.rawFile;
		let reader = new FileReader();
		reader.readAsText(file);
		reader.onloadend = loadDiagram;
	}
	//Load the diagraming object. 
	const loadDiagram = (event) => {

		let formData = JSON.parse(event.target.result)
		dispatch(ProcessActions.refreshFormData({ code: "forms", domainid: 6, defLang: defLang, id: 0, importData: formData }))

	}


	const setTemplate = (formData) => {
		dispatch(ProcessActions.refreshFormData({ code: "forms", domainid: 6, defLang: defLang, id: 0, importData: formData }))
		formTemplateDialogClose()

		console.log("formData", formData)
		let _finalFormFields2 = []

		formData.DATA.data.map(function (key, item) {
			values[key["name"]] = key["value"]
			_finalFormFields2.push(key)
		});
		values["data"] = formData.DATA.data

		console.log("_finalFormFields2", _finalFormFields2)
		setUpdatePreview(false)

		setTimeout(async function () {
			setUpdatePreview(true)
			setFinalFormFields2(_finalFormFields2)

		}.bind(this), 5000)


	}


	return (
		<div >

			<div style={{ display: "none" }}>
				<UploaderComponent type="file" id="fileupload" asyncSettings={{
					saveUrl: "https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save",
					removeUrl: "https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove"
				}} success={onUploadSuccess} />
			</div>


			<DialogSaveTemplate
				template={tempValue} status={saveTemplateDialog} componentName={"savetemplate"} animationSettings={animationSettings3}
				close={() => { setSaveTemplateDialog(false) }}
				open={() => { setSaveTemplateDialog(true) }}
				setPrivateTemplates={(result) => { console.log(result); setPrivateTemplates(result) }}
				type="form"
				hideButton={true} />

			<DialogTemplates
				setTemplate={setTemplate} status={formTemplateDialog} componentName={"template"} animationSettings={animationSettings2} templateType="form"
				close={formTemplateDialogClose} open={formTemplateDialogOpen} privateTemplates={privateTemplates} hideButton={true} />


			<DialogFormItem header={"Preview Mode"}
				datapostload={values["datapostload"]}
				updateForm={updatePreview}
				customFields={values["data"]}
				status={formDialog}
				action={"NO_ACTION"}
				componentName={"preview"} animationSettings={animationSettings}
				close={formDialogClose} open={formDialogOpen} hideButton={true} />

			<div
				className="border-bottom-style-0"
				style={{ width: '100%', height: '100%', height: wHeight - 168, overflowY: scroll, overflowX: "hidden" }}>
				<div className="row">
					<div className="col-sm-12 no-padding">
						<Typography variant="h4" mt={2} ml={1} color="primary">
							Form Builder
						</Typography>
					</div>
				</div>
				{!requesting &&

					<div className="row" style={{ maxWidth: 1280, height: wHeight - 352, }}>
						<div className="col-sm-12 col-md-12 col-lg-3 no-padding" style={{ marginTop: 12 }}>
							<div id="control_panel_dash">
								<div className="row">
									{finalFormFields1.length > 0 &&
										<div className='control-pane'>
											<div>
												<div className="form-container">
													<GenericForm
														defConfig={defConfig}
														defLang={defLang}
														data={finalFormFields1}
														onChange={onChange}
														buttons={[]}
													/>
												</div>
											</div>
										</div>
									}
									<div className="row no-padding">

										<div className="col-sm-12" style={{ paddingLeft: 3, paddingRight: 3 }}>
											<ButtonComponent
												className="e-btn-square e-success btn-block"
												onClick={() => {
													formDialogOpen();
													setUpdatePreview(false);
													setTimeout(async function () {
														setUpdatePreview(true)
													}.bind(this), 10)
												}}
											>Preview Form
											</ButtonComponent>
										</div>

										<div className="col-sm-12" style={{ paddingLeft: 3, paddingRight: 3, marginTop: 5 }}>
											<ButtonComponent
												className="e-btn-square e-light btn-block"
												onClick={() => {
													document
														.getElementsByClassName("e-file-select-wrap")[0]
														.querySelector("button")
														.click();
												}}
												style={{ textTransform: "uppercase", fontWeight: "500" }}
											>Import
											</ButtonComponent>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-12 col-lg-9 no-padding">

							{finalFormFields2.length > 0 &&
								<div className='control-pane'>
									<div>
										<div className="form-container">
											<GenericForm
												defConfig={defConfig}
												defLang={defLang}
												data={finalFormFields2}
												onChange={onChange}
												buttons={[]}
											/>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				}
			</div>

			<div className="row" >
				<div className={"col-xs-12 no-padding text-right"}>
					<ButtonComponent
						className={"ml-10 e-primary"}
						iconCss='e-icons e-save-2'
						onClick={submitForm}
						style={{ fontWeight: "500", position: "relative", top: 8, marginRight: 2 }}
					>{defConfig[defLang].savelabel}
					</ButtonComponent>
					<ButtonComponent
						className="ml-10 e-success e-btn"
						iconCss='e-icons e-export'
						onClick={() => {
							exportForm();
						}}
						style={{ fontWeight: "500", position: "relative", top: 8, marginRight: 2 }}
					>Export
					</ButtonComponent>
					<ButtonComponent
						className={"e-btn ml-10 e-outlined-secondary"}
						iconCss='e-icons e-save-as'
						onClick={() => { saveTemplate() }}
						style={{ fontWeight: "500", position: "relative", top: 8, marginRight: 2 }}
					>Save Template
					</ButtonComponent>
					<ButtonComponent
						className={"e-btn ml-10 e-outlined-secondary"}
						iconCss='e-icons e-back'
						onClick={() => { navigate("/manage/forms") }}
						style={{ fontWeight: "500", position: "relative", top: 8, marginRight: 2 }}
					>{defConfig[defLang].backlabel}
					</ButtonComponent>
				</div>
			</div>
		</div>
	);
}

ManageForm.propTypes = {
	//  window: ListData.func,
};

export default ManageForm;
