/*import "./assets/css/App.css";*/

import "./assets/sass/main.scss";

import "./assets/css/Bpmn.icons.css";
import "./assets/css/Bg.icons.css";
import "./assets/css/material.min.css";

import "../node_modules/@syncfusion/ej2-icons/styles/material.css";

import { Provider } from "react-redux";
import store from "../src/redux/store";
import Route from "./Route";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Route />
      </Provider>
    </div>
  );
}

export default App;
