import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Inject, Toolbar } from "@syncfusion/ej2-react-treegrid";
import CompTreeGridV2 from "../../components/treegrid/CompTreeGridV2";
import * as ProcessActions from "../../redux/actions/process.actions";
import * as Actions from "../../redux/actions/user.actions";
import Typography from "../../components/customdesign/Typography";
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-treegrid";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Browser } from "@syncfusion/ej2-base";
import {
  ToolbarComponent,
  ItemsDirective,
  ItemDirective,
} from "@syncfusion/ej2-react-navigations";
import {
  TabComponent,
  TabItemsDirective,
  TabItemDirective,
} from "@syncfusion/ej2-react-navigations";
const ListData = (props) => {
  //const { messages, socketId, totalUsers, sendMessage } = useSocketCtrl();
  const navigate = useNavigate();
  const { defConfig, params, defLang } = useSelector((state) => state.Login);
  const {
    componentConfig,
    requesting,
    activetasks,
    tasksheader,
    flowvars,
    refreshResumeTasks,
  } = useSelector((state) => state.Process);
  const { menuWFL } = useSelector((state) => state.Login);
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([]);
  const [vars, setVars] = React.useState([]);
  //	const [showAll,setShowAll]    = React.useState(false)
  //	const [id,setId]    = React.useState(0)
  const [taskData, setTaskData] = React.useState({});
  const [noData, setNoData] = React.useState(false);

  var { taskId, flowId } = useParams();

  const toolbarOptions = [
    {
      text: defConfig[defLang].refreshlabel,
      prefixIcon: "e-icons e-refresh",
      tooltipText: defConfig[defLang].refreshlabel,
      id: "refresh",
    },
    {
      text: defConfig[defLang].take,
      prefixIcon: "e-icons e-pan",
      tooltipText: defConfig[defLang].take,
      id: "take",
    },
    {
      text: defConfig[defLang].grouptasks,
      prefixIcon: "e-icons e-group-2",
      tooltipText: defConfig[defLang].grouptasks,
      id: "group",
    },
    "ExpandAll",
    "CollapseAll",
    "Print",
    "Search",
  ];

  const contextToolbarMenuTaskClick = async (args) => {
    if (args.item.id === "refresh") {
      dispatch(ProcessActions.getTasks({ taskId: taskId, flowId: flowId }));
    }
  };

  const setMessage = async (data) => {
    dispatch(ProcessActions.setMessage(data));
  };
  React.useEffect(() => {
    //console.log("init tasks", activetasks)

    menuWFL.map(function (key) {
      if (key.FLOWID === parseInt(flowId) && key.TASKID === parseInt(taskId)) {
        setTaskData({
          flowname: key.FLOW,
          taskname: key.TASK,
        });
      }
    });

    if (!requesting) {
      if (activetasks === undefined) {
        dispatch(ProcessActions.getTasks({ taskId: taskId, flowId: flowId }));
      } else {
        if (activetasks.length === 0) {
          setNoData(true);
          //console.log("NO DATA",menuWFL, taskId, flowId, tasksheader)
          setRows([]);
          setVars([]);
        } else {
          //console.log("llenando las nuevas tablas", activetasks)
          setRows(activetasks);
          setVars(flowvars);
        }
      }
    } else {
      setNoData(false);
      setRows([]);
      setVars([]);
    }

    if (refreshResumeTasks) {
      dispatch(Actions.getResumeTasksByUser());
    }
  }, [activetasks, flowvars, requesting, refreshResumeTasks]);

  const handleKeyDown = (e) => {
    const message = e.target.value;
    if (e.keyCode === 13 && message) {
      sendMessage(message);
      e.preventDefault();
      e.target.value = "";
    }
  };

  const refreshData = (e) => {
    dispatch(ProcessActions.getTasks({ taskId: taskId, flowId: flowId }));
  };

  const textBox = (args) => {
    return (
      <div>
        <span className="e-input-group e-control-wrapper">
          <input
            className="e-control e-textbox e-lib e-input"
            type="text"
            placeholder="Search..."
          />
          <span className="e-icons e-search e-input-group-icon"></span>
        </span>
      </div>
    );
  };

  const headerText = [
    { text: "assigned" },
    { text: "unassigned" },
    { text: "all" },
  ];
  return (
    <div style={{ marginLeft: -15, marginRight: -15 }}>
      <Typography
        variant="h3"
        mt={Browser.isDevice ? 0 : 1}
        ml={Browser.isDevice ? 1 : 0}
        mb="0"
        pl="1"
        style={{ textTransform: "capitalize" }}
      >
        <span className="e-component-title e-btn-icon e-icons e-group-2 e-icon-left"></span>
        <span className="e-component-title"> {taskData.flowname}</span>
      </Typography>
      {Browser.isDevice && (
        <ButtonComponent
          style={{
            position: "absolute",
            right: 12,
            top: 15,
          }}
          cssClass="e-flat e-primary e-menu-icon-refresh"
          iconCss="e-btn-icon e-icons e-refresh-2 "
          onClick={() => {
            console.log("REF");
            refreshData();
          }}
        ></ButtonComponent>
      )}
      <Typography
        variant="h5"
        mt={1}
        mb={"1"}
        pl="1"
        ml={Browser.isDevice ? 1 : 0}
        style={{ textTransform: "capitalize" }}
      >
        <span> {taskData.taskname}</span>
      </Typography>
      {activetasks && activetasks.length > 0 ? (
        <>
          <Typography
            variant="h6"
            mb={Browser.isDevice ? 0 : 0}
            ml={Browser.isDevice ? 1 : 0}
            pl="1"
          >
            {defConfig[defLang]["totaltaskslabel"]}: {activetasks.length}
          </Typography>

          {rows.length && tasksheader.length > 0 && (
            <>
              <CompTreeGridV2
                setMessage={setMessage}
                refreshData={refreshData}
                defConfig={defConfig}
                defLang={defLang}
                taskname={activetasks[0].taskname}
                tasktypeId={activetasks[0].tasktypeId}
                taskId={taskId}
                flowId={flowId}
                header={tasksheader}
                dataSource={rows}
                vars={vars}
                name={"activetasks"}
                cssClass="template-list"
              />
            </>
          )}
        </>
      ) : (
        ""
      )}
      {noData && (
        <>
          {Browser.isDevice ? (
            <div className="control-pane">
              <div className="control-section">
                <div className="no-padding">
                  <div className="px-15 mt-20">
                    <ToolbarComponent overflowMode="Popup" cssClass="template">
                      <ItemsDirective>
                        <ItemDirective
                          cssClass="find"
                          type="Input"
                          template={textBox}
                          overflow="Show"
                          align="Right"
                        />
                      </ItemsDirective>
                    </ToolbarComponent>
                    <div className="layout">
                      <div id="list-container">
                        <TabComponent id="tab">
                          <TabItemsDirective>
                            <TabItemDirective
                              header={headerText[0]}
                              content={""}
                            />
                            <TabItemDirective
                              header={headerText[1]}
                              content={""}
                            />
                            <TabItemDirective
                              header={headerText[2]}
                              content={""}
                            />
                          </TabItemsDirective>
                        </TabComponent>
                      </div>
                    </div>
                    <Typography
                      variant="h4"
                      mt={1}
                      mb={"1"}
                      pl="1"
                      ml={Browser.isDevice ? 1 : 0}
                      style={{ textTransform: "capitalize" }}
                    >
                      <span> {defConfig[defLang].nopendings}</span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="control-pane" style={{ padding: 10 }}>
              <div className="control-section">
                <TreeGridComponent
                  height="100"
                  toolbar={toolbarOptions}
                  toolbarClick={contextToolbarMenuTaskClick}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="id"
                      headerText="Action"
                      width="80"
                      textAlign="Right"
                    ></ColumnDirective>
                    {/*
						<ColumnDirective field='startDate' headerText='Start Date' width='160' format='yMd'  />      
						<ColumnDirective field='progress' headerText='Progress' width='90'  format='C2' />
						<ColumnDirective field='priority' headerText='Priority' width='90' />
					 */}
                    {tasksheader.map((b, index) => {
                      return index == 0 ? (
                        <ColumnDirective
                          field={"field" + index}
                          headerText={b.VARLABEL}
                          width="150"
                        />
                      ) : (
                        <ColumnDirective
                          field={"field" + index}
                          headerText={b.VARLABEL}
                          width="150"
                        />
                      );
                    })}
                  </ColumnsDirective>
                  <Inject services={[Toolbar]} />
                </TreeGridComponent>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

ListData.propTypes = {
  //  window: ListData.func,
};

export default ListData;
