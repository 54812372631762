import * as React from "react";
import { Link } from "react-router-dom";
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-treegrid";
import { Filter, Inject, Toolbar, Page } from "@syncfusion/ej2-react-treegrid";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import {
  findValueById,
  changeTasks,
  prepareGetTasks,
  getTemplateForm,
  find,
  preparTaskForm,
  saveCommentary,
  searchPhone,
  searchMail,
} from "../../utils/Functions";
import {
  ToolbarComponent,
  ItemsDirective,
  ItemDirective,
} from "@syncfusion/ej2-react-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import Loading from "../customdesign/Loading";
import DialogExecuteTask from "../dialog/DialogExecuteTask";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import {
  TabComponent,
  TabItemsDirective,
  TabItemDirective,
} from "@syncfusion/ej2-react-navigations";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { ChangeEventArgs } from "@syncfusion/ej2-buttons";
import { Browser } from "@syncfusion/ej2-base";

import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
let progressDialog;
let progressObj;
let progressValue = 0;
let maxItemperRow = 12;
let textareaObj;
let commentDialog;
let textboxObj;
let toolbarObj;
function onCreate() {
  textboxObj.addIcon("append", "e-icons e-search");
}

class CompTreeGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: { X: "center", Y: "center" },
      data: [],
      isParent: false,
      vars: [],
      flowVars: [],
      taskHeader: "",
      taskName: "",
      formDataTask: [],
      flowData: [],
      header: [],
      taskId: 0,
      wfptaskid: -1,
      wfpflowid: -1,
      officecode: "",
      selAll: false,
      animationSettings: { effect: "SlideRight" },
      loading: false,
      formDialogStatus: false,
      formDialogs: [],
      dialogProgressHeader: "Set Progress",
      dialogCommentHeader: this.props.defConfig[this.props.defLang].addcomment,
      progressValue: 20,
      taskActions: [
        {
          click: () => {
            this.formDialogClose();
            //listDialogForm.hide();
          },
          buttonModel: {
            content: "CLOSE",
            isSecondary: true,
          },
        },
      ],
      progressDlgButtons: [
        {
          buttonModel: {
            content: "Save",
            cssClass: "e-flat",
            isPrimary: true,
          },
          click: () => {
            this.saveProgress();
          },
        },
      ],
      commentDlgButtons: [
        {
          buttonModel: {
            content: "Save",
            cssClass: "e-flat",
            isPrimary: true,
          },
          click: () => {
            this.saveCommentary();
          },
        },
      ],
      actions: [
        {
          text: this.props.defConfig[this.props.defLang].executetasklabel,
          value: "RESOLVE",
          iconCss: "e-icons e-check-tick",
        },
        {
          text: this.props.defConfig[this.props.defLang].take,
          value: "TAKE",
          iconCss: "e-icons e-pan",
        },
        {
          text: this.props.defConfig[this.props.defLang].addcomment,
          value: "COMMENT",
          iconCss: "e-icons e-comment-2",
        },
      ],
      actions2: [
        {
          text: this.props.defConfig[this.props.defLang].executetasklabel,
          value: "RESOLVE",
          iconCss: "e-icons e-check-tick",
        },
        {
          text: this.props.defConfig[this.props.defLang].take,
          value: "TAKE",
          iconCss: "e-icons e-pan",
        },
        {
          text: this.props.defConfig[this.props.defLang].ungrouplabel,
          value: "UNGROUP",
          iconCss: "e-icons e-double-resize-1",
        },
        {
          text: this.props.defConfig[this.props.defLang].addcomment,
          value: "COMMENT",
          iconCss: "e-icons e-comment-2",
        },
      ],
      toolbarOptions: [],
      treegridTaskObj: null,
      isMobile: true,
      keySearch: "",
      selectedTasks: [],
      activeTabIndex: 0,
    };
  }

  asignedInstance;
  unassignedInstance;
  allInstance;
  tab;
  listObjects = [];
  headerText = [
    { id: "assigned", text: this.props.defConfig[this.props.defLang].assigned },
    {
      id: "unassigned",
      text: this.props.defConfig[this.props.defLang].unassigned,
    },
    { id: "all", text: this.props.defConfig[this.props.defLang].all },
  ];
  type = ["", "unassigned", "all"];
  // EventHandler to filter data while selecting tab
  filterData(dataSource, value) {
    let newData = [];
    dataSource.filter((data) => {
      if (data.id.indexOf(value) !== -1) {
        newData.push(data);
      }
    });
    return newData;
  }

  getAssigned(args) {
    let newData = [];

    let lat, lng;
    this.state.header.map((b, index) => {
      if (b.VARNAME === "latitude") {
        lat = index;
      }
      if (b.VARNAME === "longitude") {
        lng = index;
      }
    });

    this.state.data.filter((data) => {
      let haveCoords = false;
      let latitude = 0;
      let longitude = 0;
      if (lat >= 0 && lng >= 0) {
        latitude = parseFloat(data["field" + lat]);
        longitude = parseFloat(data["field" + lng]);
        if (
          !isNaN(latitude) &&
          !isNaN(longitude) &&
          latitude !== 0 &&
          longitude !== 0
        ) {
          haveCoords = true;
        }
      }

      if (haveCoords) {
        data["haveCoords"] = { latitude, longitude };
      }
      data["havePhone"] = searchPhone(data);
      data["haveMail"] = searchMail(data);
      if (data.assigned) {
        if (this.state.keySearch.length >= 3) {
          let isValid = false;
          for (let i = 0; i < maxItemperRow; i++) {
            if (
              data["field" + i] &&
              data["field" + i].indexOf(this.state.keySearch) >= 0
            ) {
              isValid = true;
              i = maxItemperRow;
            }
          }
          if (isValid) {
            newData.push(data);
          }
        } else {
          newData.push(data);
        }
      }
    });
    return newData;
  }

  getUnassigned(args) {
    let newData = [];

    let lat, lng;
    this.state.header.map((b, index) => {
      if (b.VARNAME === "latitude") {
        lat = index;
      }
      if (b.VARNAME === "longitude") {
        lng = index;
      }
    });
    this.state.data.filter((data) => {
      if (!data.assigned && !data.locked) {
        let haveCoords = false;
        let latitude = 0;
        let longitude = 0;
        if (lat >= 0 && lng >= 0) {
          latitude = parseFloat(data["field" + lat]);
          longitude = parseFloat(data["field" + lng]);
          if (
            !isNaN(latitude) &&
            !isNaN(longitude) &&
            latitude !== 0 &&
            longitude !== 0
          ) {
            haveCoords = true;
          }
        }
        if (haveCoords) {
          data["haveCoords"] = { latitude, longitude };
        }
        data["havePhone"] = searchPhone(data);
        data["haveMail"] = searchMail(data);
        if (this.state.keySearch.length >= 3) {
          let isValid = false;
          for (let i = 0; i < maxItemperRow; i++) {
            if (
              data["field" + i] &&
              data["field" + i].toLowerCase().indexOf(this.state.keySearch) >= 0
            ) {
              isValid = true;
              i = maxItemperRow;
            }
          }
          if (isValid) {
            newData.push(data);
          }
        } else {
          newData.push(data);
        }
      }
    });
    return newData;
  }

  getAll(args) {
    let newData = [];

    let lat, lng;
    this.state.header.map((b, index) => {
      if (b.VARNAME === "latitude") {
        lat = index;
      }
      if (b.VARNAME === "longitude") {
        lng = index;
      }
    });

    this.state.data.filter((data) => {
      let haveCoords = false;
      let latitude = 0;
      let longitude = 0;
      if (lat >= 0 && lng >= 0) {
        latitude = parseFloat(data["field" + lat]);
        longitude = parseFloat(data["field" + lng]);
        if (
          !isNaN(latitude) &&
          !isNaN(longitude) &&
          latitude !== 0 &&
          longitude !== 0
        ) {
          haveCoords = true;
        }
      }
      if (haveCoords) {
        data["haveCoords"] = { latitude, longitude };
      }
      data["havePhone"] = searchPhone(data);
      data["haveMail"] = searchMail(data);
      if (this.state.keySearch.length >= 3) {
        let isValid = false;
        for (let i = 0; i < maxItemperRow; i++) {
          if (
            data["field" + i] &&
            data["field" + i].indexOf(this.state.keySearch) >= 0
          ) {
            isValid = true;
            i = maxItemperRow;
          }
        }
        if (isValid) {
          newData.push(data);
        }
      } else {
        newData.push(data);
      }
    });

    return newData;
  }

  fields = { text: "field0", groupBy: "day" };
  styleNone = { display: "none" };
  // Set customized list template
  listTemplate(data) {
    let params = "";
    if (data.subtasks.length > 0) {
      let tasks = data.id.toString().replace(",", "_");
      params = tasks + "_" + data.parentid;
    } else {
      params = data.id + "_" + 0;
    }

    return (
      <div>
        <div className="e-list-wrapper e-list-multi-line">
          {this.props.header.map((b, index) => {
            if (index < maxItemperRow) {
              let priority = false;
              let pos = this.props.header.findIndex(
                (item) => item.VARNAME === b.VARNAME
              );
              if (pos >= 0) {
                if (this.props.header[pos].REMARKABLE === "YES") {
                  priority = true;
                }
              }
              if (!data["field" + index] && index > 0) {
                return "";
              } else {
                return index == 0 ? (
                  <>
                    {this.props.showTaskInfo && (
                      <Link to={"/getTasks/" + data.taskid + "/" + data.flowid}>
                        <p
                          className="e-list-item-body text-right"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {"Flow: "} <b> {data["flowname"]} </b>{" "}
                        </p>
                        <p
                          className="e-list-item-body text-right mb-1"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {"Task: "} <b> {data["taskname"]} </b>{" "}
                        </p>
                      </Link>
                    )}

                    <p
                      className={
                        "e-list-item-header" +
                        (data.haveCoord || data.havePhone || data.haveMail
                          ? " e-list-item-header-buttons-container"
                          : " ") +
                        (priority && data.field0 ? " input-highlight" : "")
                      }
                    >
                      {data.havePhone ? (
                        <a
                          href={"tel:" + data.havePhone}
                          className={
                            "e-component-title e-btn-icon e-icons e-symbols e-custom-icon-phone primary-color"
                          }
                        >
                          {" "}
                        </a>
                      ) : (
                        <a
                          href={"#"}
                          className={
                            "e-component-title e-btn-icon e-icons e-symbols e-custom-icon-phone disabled-color"
                          }
                        >
                          {" "}
                        </a>
                      )}
                      {data.haveMail ? (
                        <a
                          href={"mailto:" + data.haveMail}
                          className={
                            "e-component-title e-btn-icon e-icons e-changes-track e-custom-icon-mail secondary-color"
                          }
                        >
                          {" "}
                        </a>
                      ) : (
                        <a
                          href="#"
                          className={
                            "e-component-title e-btn-icon e-icons e-changes-track e-custom-icon-mail disabled-color"
                          }
                        >
                          {" "}
                        </a>
                      )}
                      {data.haveCoords ? (
                        <span
                          onClick={() => {
                            window.open(
                              "https://maps.google.com?q=" +
                                data.haveCoords.latitude +
                                "," +
                                data.haveCoords.longitude
                            );
                          }}
                          className={
                            "e-component-title e-btn-icon e-icons e-location e-custom-icon-location success-color"
                          }
                        ></span>
                      ) : (
                        <span
                          className={
                            "e-component-title e-btn-icon e-icons e-location e-custom-icon-location disabled-color"
                          }
                        ></span>
                      )}
                    </p>
                    <div class="flex-left flex-middle">
                      {!data.locked &&
                        !this.state.notGroupable &&
                        !this.props.noToolBar &&
                        !Array.isArray(data.id) && (
                          <input
                            className="task-check"
                            id={"2TASK_CHECK_" + data.id}
                            style={{ height: 22, width: 22 }}
                            type="checkbox"
                          />
                        )}
                      <p
                        className={
                          (data.field0 ? "e-list-item-header" : "") +
                          (data.haveCoord || data.havePhone || data.haveMail
                            ? " xxx-e-list-item-header-buttons-container"
                            : " ") +
                          (priority && data.field0 ? " input-highlight" : "")
                        }
                      >
                        {" "}
                        <span className="e-float-text">
                          {" "}
                          {b.VARLABEL + ": "}
                        </span>
                        <b>
                          <span className="e-float-text">
                            {data.field0 ? data.field0 : " - "}
                          </span>
                          {data.totalTasks && data.field0 && (
                            <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
                              {"+" + data.totalTasks}{" "}
                            </span>
                          )}
                        </b>
                      </p>
                      {Array.isArray(data.id) && data.isgroupable === "YES" && (
                        <p className="e-list-item-header">
                          <span
                            onClick={() => {
                              console.log("hi");
                              this.contextMenuClick({
                                item: { id: "ungrouptasks", ids: data.id },
                              });
                            }}
                            className="e-component-title e-btn-icon e-icons e-double-resize-1 e-custom-icon-group info-color"
                          ></span>
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <p
                    className={
                      "e-list-item-body" +
                      (priority && data["field" + index]
                        ? " input-highlight"
                        : "")
                    }
                    style={{
                      marginTop: data.totalTasks ? 10 : 0,
                    }}
                  >
                    <span className="e-float-text">
                      {" "}
                      {data["field" + index] && b.VARLABEL + ": "}
                    </span>
                    <b>
                      <span className="e-float-text">
                        {Array.isArray(data["field" + index] || "")
                          ? data["field" + index][0]
                            ? data["field" + index][1]
                            : "-"
                          : data["field" + index]
                          ? data["field" + index]
                          : "-"}{" "}
                      </span>
                    </b>
                    {Array.isArray(data["field" + index]) && (
                      <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
                        {"+" + data.totalTasks}{" "}
                      </span>
                    )}{" "}
                  </p>
                );
              }
            }
          })}
        </div>
        <div className="row no-padding mx-1">
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            {data.locked ? (
              <ButtonComponent
                cssClass={"e-flat mobile-button e-secondary"}
                iconCss="e-btn-sb-icons e-icons e-lock"
                iconPosition="Top"
              >
                {data.assigned ? (
                  <> {this.props.defConfig[this.props.defLang].release} </>
                ) : (
                  <> {this.props.defConfig[this.props.defLang].tasktaken} </>
                )}{" "}
              </ButtonComponent>
            ) : (
              <ButtonComponent
                cssClass={
                  "e-flat e-" +
                  (data.assigned ? "dark" : "secondary") +
                  " mobile-button"
                }
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: data.assigned ? "RELEASE" : "TAKE" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons  e-hand-gestures"
                iconPosition="Top"
              >
                {data.assigned ? (
                  <> {this.props.defConfig[this.props.defLang].release} </>
                ) : (
                  <> {this.props.defConfig[this.props.defLang].takelabel} </>
                )}{" "}
              </ButtonComponent>
            )}
          </div>
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            <ButtonComponent
              cssClass="e-flat e-info mobile-button"
              onClick={() => console.log(data)}
              iconCss="e-btn-sb-icons e-icons e-comments"
              iconPosition="Top"
            >
              {this.props.defConfig[this.props.defLang].comment}
            </ButtonComponent>
          </div>
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            {data.locked ? (
              <ButtonComponent
                style={{ color: "#000" }}
                cssClass="e-flat e-light mobile-button"
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: "VIEW" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons e-eye"
                iconPosition="Top"
              >
                {this.props.defConfig[this.props.defLang].view}{" "}
              </ButtonComponent>
            ) : (
              <ButtonComponent
                cssClass="e-flat e-success mobile-button"
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: "RESOLVE" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons e-check"
                iconPosition="Top"
              >
                {this.props.defConfig[this.props.defLang].executelabel}
              </ButtonComponent>
            )}
          </div>
        </div>
      </div>
    );
  }

  listTemplateA(data) {
    let params = "";
    if (data.subtasks.length > 0) {
      let tasks = data.id.toString().replace(",", "_");
      params = tasks + "_" + data.parentid;
    } else {
      params = data.id + "_" + 0;
    }

    return (
      <div>
        <div className="e-list-wrapper e-list-multi-line">
          {this.props.header.map((b, index) => {
            if (index < maxItemperRow) {
              let priority = false;
              let pos = this.props.header.findIndex(
                (item) => item.VARNAME === b.VARNAME
              );
              if (pos >= 0) {
                if (this.props.header[pos].REMARKABLE === "YES") {
                  priority = true;
                }
              }
              if (!data["field" + index] && index > 0) {
                return "";
              } else {
                return index == 0 ? (
                  <>
                    {this.props.showTaskInfo && (
                      <Link to={"/getTasks/" + data.taskid + "/" + data.flowid}>
                        <p
                          className="e-list-item-body text-right"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {"Flow: "} <b> {data["flowname"]} </b>{" "}
                        </p>
                        <p
                          className="e-list-item-body text-right mb-1"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {"Task: "} <b> {data["taskname"]} </b>{" "}
                        </p>
                      </Link>
                    )}

                    <p
                      className={
                        "e-list-item-header" +
                        (data.haveCoord || data.havePhone || data.haveMail
                          ? " e-list-item-header-buttons-container"
                          : " ") +
                        (priority && data.field0 ? " input-highlight" : "")
                      }
                    >
                      {data.havePhone ? (
                        <a
                          href={"tel:" + data.havePhone}
                          className={
                            "e-component-title e-btn-icon e-icons e-symbols e-custom-icon-phone primary-color"
                          }
                        >
                          {" "}
                        </a>
                      ) : (
                        <a
                          href={"#"}
                          className={
                            "e-component-title e-btn-icon e-icons e-symbols e-custom-icon-phone disabled-color"
                          }
                        >
                          {" "}
                        </a>
                      )}
                      {data.haveMail ? (
                        <a
                          href={"mailto:" + data.haveMail}
                          className={
                            "e-component-title e-btn-icon e-icons e-changes-track e-custom-icon-mail secondary-color"
                          }
                        >
                          {" "}
                        </a>
                      ) : (
                        <a
                          href="#"
                          className={
                            "e-component-title e-btn-icon e-icons e-changes-track e-custom-icon-mail disabled-color"
                          }
                        >
                          {" "}
                        </a>
                      )}
                      {data.haveCoords ? (
                        <span
                          onClick={() => {
                            window.open(
                              "https://maps.google.com?q=" +
                                data.haveCoords.latitude +
                                "," +
                                data.haveCoords.longitude
                            );
                          }}
                          className={
                            "e-component-title e-btn-icon e-icons e-location e-custom-icon-location success-color"
                          }
                        ></span>
                      ) : (
                        <span
                          className={
                            "e-component-title e-btn-icon e-icons e-location e-custom-icon-location disabled-color"
                          }
                        ></span>
                      )}
                    </p>
                    <div class="flex-left flex-middle">
                      {!data.locked &&
                        !this.state.notGroupable &&
                        !this.props.noToolBar &&
                        !Array.isArray(data.id) && (
                          <input
                            className="task-check-a"
                            id={"0TASK_CHECK_" + data.id}
                            style={{ height: 22, width: 22 }}
                            type="checkbox"
                          />
                        )}
                      <p
                        className={
                          (data.field0 ? "e-list-item-header" : "") +
                          (data.haveCoord || data.havePhone || data.haveMail
                            ? " xxx-e-list-item-header-buttons-container"
                            : " ") +
                          (priority && data.field0 ? " input-highlight" : "")
                        }
                      >
                        {" "}
                        <span className="e-float-text">
                          {" "}
                          {b.VARLABEL + ": "}
                        </span>
                        <b>
                          <span className="e-float-text">
                            {data.field0 ? data.field0 : " - "}
                          </span>
                          {data.totalTasks && data.field0 && (
                            <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
                              {"+" + data.totalTasks}{" "}
                            </span>
                          )}
                        </b>
                      </p>
                      {Array.isArray(data.id) && data.isgroupable === "YES" && (
                        <p className="e-list-item-header">
                          <span
                            onClick={() => {
                              console.log("hi");
                              this.contextMenuClick({
                                item: { id: "ungrouptasks", ids: data.id },
                              });
                            }}
                            className="e-component-title e-btn-icon e-icons e-double-resize-1 e-custom-icon-group info-color"
                          ></span>
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <p
                    className={
                      "e-list-item-body" +
                      (priority && data["field" + index]
                        ? " input-highlight"
                        : "")
                    }
                    style={{
                      marginTop: data.totalTasks ? 10 : 0,
                    }}
                  >
                    <span className="e-float-text">
                      {" "}
                      {data["field" + index] && b.VARLABEL + ": "}
                    </span>
                    <b>
                      <span className="e-float-text">
                        {Array.isArray(data["field" + index] || "")
                          ? data["field" + index][0]
                            ? data["field" + index][1]
                            : "-"
                          : data["field" + index]
                          ? data["field" + index]
                          : "-"}{" "}
                      </span>
                    </b>
                    {Array.isArray(data["field" + index]) && (
                      <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
                        {"+" + data.totalTasks}{" "}
                      </span>
                    )}{" "}
                  </p>
                );
              }
            }
          })}
        </div>
        <div className="row no-padding mx-1">
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            {data.locked ? (
              <ButtonComponent
                cssClass={"e-flat mobile-button e-secondary"}
                iconCss="e-btn-sb-icons e-icons e-lock"
                iconPosition="Top"
              >
                {data.assigned ? (
                  <> {this.props.defConfig[this.props.defLang].release} </>
                ) : (
                  <> {this.props.defConfig[this.props.defLang].tasktaken} </>
                )}{" "}
              </ButtonComponent>
            ) : (
              <ButtonComponent
                cssClass={
                  "e-flat e-" +
                  (data.assigned ? "dark" : "secondary") +
                  " mobile-button"
                }
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: data.assigned ? "RELEASE" : "TAKE" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons  e-hand-gestures"
                iconPosition="Top"
              >
                {data.assigned ? (
                  <> {this.props.defConfig[this.props.defLang].release} </>
                ) : (
                  <> {this.props.defConfig[this.props.defLang].tasktaken} </>
                )}{" "}
              </ButtonComponent>
            )}
          </div>
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            <ButtonComponent
              cssClass="e-flat e-info mobile-button"
              onClick={() => console.log(data)}
              iconCss="e-btn-sb-icons e-icons e-comments"
              iconPosition="Top"
            >
              {this.props.defConfig[this.props.defLang].comment}
            </ButtonComponent>
          </div>
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            {data.locked ? (
              <ButtonComponent
                style={{ color: "#000" }}
                cssClass="e-flat e-light mobile-button"
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: "VIEW" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons e-eye"
                iconPosition="Top"
              >
                {this.props.defConfig[this.props.defLang].view}{" "}
              </ButtonComponent>
            ) : (
              <ButtonComponent
                cssClass="e-flat e-success mobile-button"
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: "RESOLVE" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons e-check"
                iconPosition="Top"
              >
                {this.props.defConfig[this.props.defLang].executelabel}
              </ButtonComponent>
            )}
          </div>
        </div>
      </div>
    );
  }

  listTemplateU(data) {
    let params = "";
    if (data.subtasks.length > 0) {
      let tasks = data.id.toString().replace(",", "_");
      params = tasks + "_" + data.parentid;
    } else {
      params = data.id + "_" + 0;
    }

    return (
      <div>
        <div className="e-list-wrapper e-list-multi-line">
          {this.props.header.map((b, index) => {
            if (index < maxItemperRow) {
              let priority = false;
              let pos = this.props.header.findIndex(
                (item) => item.VARNAME === b.VARNAME
              );
              if (pos >= 0) {
                if (this.props.header[pos].REMARKABLE === "YES") {
                  priority = true;
                }
              }
              if (!data["field" + index] && index > 0) {
                return "";
              } else {
                return index == 0 ? (
                  <>
                    {this.props.showTaskInfo && (
                      <Link to={"/getTasks/" + data.taskid + "/" + data.flowid}>
                        <p
                          className="e-list-item-body text-right"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {"Flow: "} <b> {data["flowname"]} </b>{" "}
                        </p>
                        <p
                          className="e-list-item-body text-right mb-1"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {"Task: "} <b> {data["taskname"]} </b>{" "}
                        </p>
                      </Link>
                    )}

                    <p
                      className={
                        "e-list-item-header" +
                        (data.haveCoord || data.havePhone || data.haveMail
                          ? " e-list-item-header-buttons-container"
                          : " ") +
                        (priority && data.field0 ? " input-highlight" : "")
                      }
                    >
                      {data.havePhone ? (
                        <a
                          href={"tel:" + data.havePhone}
                          className={
                            "e-component-title e-btn-icon e-icons e-symbols e-custom-icon-phone primary-color"
                          }
                        >
                          {" "}
                        </a>
                      ) : (
                        <a
                          href={"#"}
                          className={
                            "e-component-title e-btn-icon e-icons e-symbols e-custom-icon-phone disabled-color"
                          }
                        >
                          {" "}
                        </a>
                      )}
                      {data.haveMail ? (
                        <a
                          href={"mailto:" + data.haveMail}
                          className={
                            "e-component-title e-btn-icon e-icons e-changes-track e-custom-icon-mail secondary-color"
                          }
                        >
                          {" "}
                        </a>
                      ) : (
                        <a
                          href="#"
                          className={
                            "e-component-title e-btn-icon e-icons e-changes-track e-custom-icon-mail disabled-color"
                          }
                        >
                          {" "}
                        </a>
                      )}
                      {data.haveCoords ? (
                        <span
                          onClick={() => {
                            window.open(
                              "https://maps.google.com?q=" +
                                data.haveCoords.latitude +
                                "," +
                                data.haveCoords.longitude
                            );
                          }}
                          className={
                            "e-component-title e-btn-icon e-icons e-location e-custom-icon-location success-color"
                          }
                        ></span>
                      ) : (
                        <span
                          className={
                            "e-component-title e-btn-icon e-icons e-location e-custom-icon-location disabled-color"
                          }
                        ></span>
                      )}
                    </p>
                    <div class="flex-left flex-middle">
                      {!data.locked &&
                        !this.state.notGroupable &&
                        !this.props.noToolBar &&
                        !Array.isArray(data.id) && (
                          <input
                            className="task-check-u"
                            id={"1TASK_CHECK_" + data.id}
                            style={{ height: 22, width: 22 }}
                            type="checkbox"
                          />
                        )}
                      <p
                        className={
                          (data.field0 ? "e-list-item-header" : "") +
                          (data.haveCoord || data.havePhone || data.haveMail
                            ? " xxx-e-list-item-header-buttons-container"
                            : " ") +
                          (priority && data.field0 ? " input-highlight" : "")
                        }
                      >
                        {" "}
                        <span className="e-float-text">
                          {" "}
                          {b.VARLABEL + ": "}
                        </span>
                        <b>
                          <span className="e-float-text">
                            {data.field0 ? data.field0 : " - "}
                          </span>
                          {data.totalTasks && data.field0 && (
                            <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
                              {"+" + data.totalTasks}{" "}
                            </span>
                          )}
                        </b>
                      </p>
                      {Array.isArray(data.id) && data.isgroupable === "YES" && (
                        <p className="e-list-item-header">
                          <span
                            onClick={() => {
                              console.log("hi");
                              this.contextMenuClick({
                                item: { id: "ungrouptasks", ids: data.id },
                              });
                            }}
                            className="e-component-title e-btn-icon e-icons e-double-resize-1 e-custom-icon-group info-color"
                          ></span>
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <p
                    className={
                      "e-list-item-body" +
                      (priority && data["field" + index]
                        ? " input-highlight"
                        : "")
                    }
                    style={{
                      marginTop: data.totalTasks ? 10 : 0,
                    }}
                  >
                    <span className="e-float-text">
                      {" "}
                      {data["field" + index] && b.VARLABEL + ": "}
                    </span>
                    <b>
                      <span className="e-float-text">
                        {Array.isArray(data["field" + index] || "")
                          ? data["field" + index][0]
                            ? data["field" + index][1]
                            : "-"
                          : data["field" + index]
                          ? data["field" + index]
                          : "-"}{" "}
                      </span>
                    </b>
                    {Array.isArray(data["field" + index]) && (
                      <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
                        {"+" + data.totalTasks}{" "}
                      </span>
                    )}{" "}
                  </p>
                );
              }
            }
          })}
        </div>
        <div className="row no-padding mx-1">
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            {data.locked ? (
              <ButtonComponent
                cssClass={"e-flat mobile-button e-secondary"}
                iconCss="e-btn-sb-icons e-icons e-lock"
                iconPosition="Top"
              >
                {data.assigned ? (
                  <> {this.props.defConfig[this.props.defLang].release} </>
                ) : (
                  <> {this.props.defConfig[this.props.defLang].tasktaken} </>
                )}{" "}
              </ButtonComponent>
            ) : (
              <ButtonComponent
                cssClass={
                  "e-flat e-" +
                  (data.assigned ? "dark" : "secondary") +
                  " mobile-button"
                }
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: data.assigned ? "RELEASE" : "TAKE" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons  e-hand-gestures"
                iconPosition="Top"
              >
                {data.assigned ? (
                  <> {this.props.defConfig[this.props.defLang].release} </>
                ) : (
                  <> {this.props.defConfig[this.props.defLang].takelabel} </>
                )}{" "}
              </ButtonComponent>
            )}
          </div>
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            <ButtonComponent
              cssClass="e-flat e-info mobile-button"
              onClick={() => console.log(data)}
              iconCss="e-btn-sb-icons e-icons e-comments"
              iconPosition="Top"
            >
              {this.props.defConfig[this.props.defLang].comment}
            </ButtonComponent>
          </div>
          <div className={"no-padding col-xs-4 col-sm-4 text-center"}>
            {data.locked ? (
              <ButtonComponent
                style={{ color: "#000" }}
                cssClass="e-flat e-light mobile-button"
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: "VIEW" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons e-eye"
                iconPosition="Top"
              >
                {this.props.defConfig[this.props.defLang].view}{" "}
              </ButtonComponent>
            ) : (
              <ButtonComponent
                cssClass="e-flat e-success mobile-button"
                onClick={() => {
                  let args = {
                    element: { parentNode: { parentNode: { id: params } } },
                    item: { value: "RESOLVE" },
                  };
                  this.setAction(args);
                }}
                iconCss="e-btn-sb-icons e-icons e-check"
                iconPosition="Top"
              >
                {this.props.defConfig[this.props.defLang].executelabel}
              </ButtonComponent>
            )}
          </div>
        </div>
      </div>
    );
  }

  onChangeCheck = (data, action) => {
    //console.log(data, action)
    //selectedTasks
  };

  checkClick = async (event) => {
    let elem = event.target;

    if (elem.className && elem.id) {
      let _id = elem.id.split("_");
      let id = _id[2];
      //      document.getElementById(elem.id).checked = true;
      const selectedTasks = this.state.selectedTasks;
      if (selectedTasks.indexOf(id) < 0) {
        selectedTasks.push(id);
      } else {
        selectedTasks.splice(selectedTasks.indexOf(id), 1);
      }
    }
    //console.log("selectedTasks", this.state.selectedTasks);
  };
  setActiveTask = (args) => {
    this.setState({
      ...this.state,
      activeTabIndex: args.selectedIndex,
      formDialogStatus: false,
    });
  };
  contextMenuClick = async (args) => {
    this.setState({ ...this.state, formDialogStatus: false });
    let tasks = [];
    let activeTabIndex = this.state.activeTabIndex;
    if (args.item) {
      if (args.item.id === "selectall") {
        if (activeTabIndex === 0) {
          tasks = this.getAssigned();
        } else if (activeTabIndex === 1) {
          tasks = this.getUnassigned();
        } else if (activeTabIndex === 2) {
          tasks = this.getAll();
        }
        let _selectedTasks = [];
        if (!this.state.selAll) {
          tasks.map(function (key) {
            if (key.subtasks.length === 0) {
              if (!key.locked) {
                _selectedTasks.push(key.id.toString());
              }
              //_selectedTasks.push({ id: key.id.toString(), locked: key.locked });
            } else {
              console.log("ya agr", key);
            }
          });

          this.setState({
            ...this.state,
            selectedTasks: _selectedTasks,
            selAll: true,
          });

          setTimeout(
            async function () {
              tasks.map(function (key) {
                if (key.subtasks.length === 0) {
                  if (!key.locked) {
                    document.getElementById(
                      activeTabIndex + "TASK_CHECK_" + key.id
                    ).checked = true;
                  }
                }
              });
            }.bind(this),
            10
          );
        } else {
          this.setState({
            ...this.state,
            selectedTasks: _selectedTasks,
            selAll: false,
          });
          setTimeout(
            async function () {
              tasks.map(function (key) {
                if (key.subtasks.length === 0) {
                  if (!key.locked) {
                    document.getElementById(
                      activeTabIndex + "TASK_CHECK_" + key.id
                    ).checked = false;
                  }
                }
              });
            }.bind(this),
            10
          );
        }
        console.log("selectedTasks final", _selectedTasks);
      } else if (args.item.id === "grouptask") {
        if (this.state.selectedTasks.length > 0) {
          this.setState({ ...this.state, loading: true });
          let selectedrecords = this.state.selectedTasks;
          selectedrecords = selectedrecords.filter((data) => !data.locked);

          let fixedselectedrecords = [];
          selectedrecords.map(function (key, index) {
            if (key.id) {
              fixedselectedrecords.push(key);
            } else {
              fixedselectedrecords.push({ id: key });
            }
          });

          let resp = await changeTasks(fixedselectedrecords, "group");

          this.props.setMessage(resp);
          if (resp.status) {
            this.refreshDataLocal(args);
          } else {
            this.setState({ ...this.state, loading: false, selectedTasks: [] });
          }
        } else {
          this.props.setMessage({
            status: false,
            message: this.props.defConfig[this.props.defLang].notaskselected,
          });
        }
      } else if (args.item.id === "ungrouptasks") {
        if (args.item.ids.length > 1) {
          this.setState({ ...this.state, loading: true });

          let fixedselectedrecords = [];
          args.item.ids.map(function (key, index) {
            if (key.id) {
              fixedselectedrecords.push(key);
            } else {
              fixedselectedrecords.push({ id: key });
            }
          });

          let resp = await changeTasks(fixedselectedrecords, "ungroup");

          this.props.setMessage(resp);
          if (resp.status) {
            this.refreshDataLocal(args);
          } else {
            this.setState({ ...this.state, loading: false, selectedTasks: [] });
          }
        } else {
          this.props.setMessage({
            status: false,
            message: this.props.defConfig[this.props.defLang].notaskselected,
          });
        }
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener("change", this.checkClick);
  }

  componentDidMount() {
    console.log("v2");
    // Obtener todos los campos de entrada en la página
    const inputFields = document.querySelectorAll("input");

    // Iterar sobre cada campo de entrada y deshabilitar el autocompletado
    inputFields.forEach((input) => {
      input.setAttribute("autocomplete", "new-password");
    });

    const searchFields = document.querySelectorAll('input[type="search"]');

    // Iterar sobre cada campo de búsqueda y deshabilitar el autocompletado
    searchFields.forEach((searchField) => {
      searchField.setAttribute("autocomplete", "off");
      searchField.setAttribute("type", "text");
    });

    document.addEventListener("change", this.checkClick);
    let data = this.props.dataSource;
    let vars = this.props.vars;
    let header = this.props.header;

    let defConfig = this.props.defConfig;
    let defLang = this.props.defLang;

    let toolbarOptions = [
      {
        text: defConfig[defLang].refreshlabel,
        prefixIcon: "e-icons e-refresh",
        tooltipText: defConfig[defLang].refreshlabel,
        id: "refresh",
      },
    ];

    if (!this.props.noToolBar) {
      if (data.length > 0) {
        if (data[0].isassignable === "YES") {
          toolbarOptions.push({
            text: defConfig[defLang].take,
            prefixIcon: "e-icons e-pan",
            tooltipText: defConfig[defLang].take,
            id: "take",
          });
        }
      }
      if (this.props.tasktypeId === 53) {
        toolbarOptions.push({
          prefixIcon: "e-icons e-group-2",
          id: "executegroup",
          tooltipText: defConfig[defLang].executetaskslabel,
          text: defConfig[defLang].executetaskslabel,
        });
      } else {
        if (data.length > 0) {
          if (data[0].isgroupable === "YES") {
            toolbarOptions.push({
              prefixIcon: "e-icons e-group-2",
              id: "group",
              tooltipText: defConfig[defLang].grouptasks,
              text: defConfig[defLang].grouptasks,
            });
          } else {
            this.setState({ ...this.state, notGroupable: true });
          }
        }
      }
      toolbarOptions.push("ExpandAll", "CollapseAll", "Print", "Search");
    }

    this.setState({
      ...this.state,
      data: data,
      header: header,
      vars: vars,
      toolbarOptions: toolbarOptions,
      wHeight: window.innerHeight - 361,
      isMobile: Browser.isDevice,
    });

    //console.log("load", this.props)
    if (this.state.treegridTaskObj) {
      if (this.state.treegridTaskObj) {
        this.state.treegridTaskObj.collapseAll();
      }
    }

    setTimeout(
      async function () {
        //console.log("liberamos")
        try {
          if (this.state.treegridTaskObj) {
            this.state.treegridTaskObj.collapseAll();
          }
        } catch (EX) {
          console.log("EX COL");
        }
      }.bind(this),
      100
    );
  }

  actionComplete = (args) => {
    if (args.requestType == "refresh") {
      if (this.state.treegridTaskObj) {
        this.state.treegridTaskObj.collapseAll();
      }
    }
  };

  progessTemplate(props) {
    let percentage = props[props.column.field];
    return (
      <div id="myProgress" className="pbar">
        {props.Status === "Inactive" ? (
          <div
            id="myBar"
            className="bar progressdisable"
            style={{ width: percentage + "%" }}
          >
            <div id="pbarlabel" className="barlabel">
              {percentage + "%"}
            </div>
          </div>
        ) : (
          <div id="myBar" className="bar" style={{ width: percentage + "%" }}>
            <div
              id="pbarlabel"
              className={percentage === 0 ? "pbarlabel-0" : "barlabel"}
            >
              {percentage + "%"}
            </div>
          </div>
        )}
      </div>
    );
  }

  setAction = async (args, fortemporalyIds) => {
    this.setState({ ...this.state, formDialogStatus: false });
    let data = [];
    try {
      if (fortemporalyIds) {
        data = fortemporalyIds;
        //data.push("-1")
      } else {
        data = args.element.parentNode.parentNode.id
          .replace("-popup", "")
          .split("_");
      }
    } catch (ex) {
      console.log("No data");
    }

    let id;

    let searchField = "id";
    if (data[data.length - 1] === "0" || data.length == 2) {
      id = data[0];
    } else {
      id = data[data.length - 1];
      searchField = "parentid";
    }

    if (args.item.value == "UNGROUP") {
      let pos = find(this.state.data, id, searchField);
      let tasks = this.state.data[pos].subtasks;
      this.setState({ ...this.state, loading: true });
      let resp = await changeTasks(tasks, "ungroup");
      this.props.setMessage(resp);
      if (resp.status) {
        this.refreshDataLocal(args);
      } else {
        this.setState({ ...this.state, loading: false, selectedTasks: [] });
      }
    } else if (args.item.value == "PROGRESS") {
      let progress = findValueById(
        this.state.data,
        id,
        searchField,
        "progress"
      );
      //console.log(progress)
      progressValue = progress;
      if (progressObj) {
        progressObj["value"] = progress;
      }
      progressDialog.show();
    } else if (args.item.value == "COMMENT") {
      this.setState({
        ...this.state,
        id: id,
      });
      commentDialog.show();
    } else if (args.item.value == "TAKE") {
      let pos = find(this.state.data, id, searchField);
      let task = this.state.data[pos];

      const selectedrecords = [{ id: task.id }];
      this.setState({ ...this.state, loading: true });

      let resp = await changeTasks(selectedrecords, "take");
      this.props.setMessage(resp);
      if (resp.status) {
        this.refreshDataLocal(args);
      } else {
        this.setState({ ...this.state, loading: false });
      }
    } else if (args.item.value == "RELEASE") {
      let pos = find(this.state.data, id, searchField);
      let task = this.state.data[pos];

      const selectedrecords = [{ id: task.id }];
      this.setState({ ...this.state, loading: true });

      let resp = await changeTasks(selectedrecords, "release");
      this.props.setMessage(resp);
      if (resp.status) {
        this.refreshDataLocal(args);
      } else {
        this.setState({ ...this.state, loading: false });
      }
    } else if (args.item.value == "RESOLVE") {
      //from here
      this.setState({
        ...this.state,
        loading: true,
        flowData: [],
        formDataTask: [],
      });
      let taskActions = [];
      let response = await preparTaskForm(
        id,
        searchField,
        this.state.data,
        this.state.vars,
        this.props.header,
        fortemporalyIds
      );
      //console.log("NEW REPONSE",response)
      if (response.task) {
        let task = response.task;
        //console.log(task.options)

        //taskActions = task.options
        setTimeout(
          async function () {
            //console.log("liberamos", wfptaskid)
            this.setState({
              ...this.state,
              flowData: response.flowData,
              taskActions: task.options,
              formDataTask: response.formData,
              datapostload: response.datapostload,
              datasubtitle: response.datasubtitle,
              datatitle: response.datatitle,
              taskHeader: response.taskHeader,
              taskName: response.taskName,
              isParent: response.isParent,
              id: response.taskId,
              commentary: response.commentary,
              wfpflowid: response.wfpflowid,
              wfptaskid: response.wfptaskid,
              officecode: response.officecode,
              readOnly: false,
              formDialogStatus: true,
              loading: false,
            });
          }.bind(this),
          500
        );
      } else {
        this.setState({
          ...this.state,
          formDataTask: [],
          formDialogStatus: false,
        });
      }
    } else if (args.item.value == "VIEW") {
      //from here
      this.setState({
        ...this.state,
        loading: true,
        flowData: [],
        formDataTask: [],
      });
      let taskActions = [];
      let response = await preparTaskForm(
        id,
        searchField,
        this.state.data,
        this.state.vars,
        this.props.header,
        fortemporalyIds
      );
      //console.log("NEW REPONSE",response)

      if (response.task) {
        let task = response.task;

        setTimeout(
          async function () {
            this.setState({
              ...this.state,
              flowData: response.flowData,

              taskActions: [],
              formDataTask: response.formData,
              datapostload: response.datapostload,
              datasubtitle: response.datasubtitle,
              datatitle: response.datatitle,
              taskHeader: response.taskHeader,
              taskName: response.taskName,
              isParent: response.isParent,
              id: response.taskId,
              wfpflowid: response.wfpflowid,
              wfptaskid: response.wfptaskid,
              officecode: response.officecode,
              readOnly: true,
              formDialogStatus: true,
              loading: false,
            });
          }.bind(this),
          500
        );
      } else {
        this.setState({
          ...this.state,
          formDataTask: [],
          formDialogStatus: false,
        });
      }
    }
  };

  getFormInfo = async (formcode) => {
    const formDialogs = this.state.formDialogs;
    let index = formDialogs.findIndex((item) => item.id === formcode);
    let resp;

    if (index < 0) {
      resp = await getTemplateForm(formcode);
      if (resp.status) {
        formDialogs.push({ id: formcode, resp: resp });
      }
    } else {
      resp = this.state.formDialogs[index].resp;
    }
    return resp;
  };

  saveProgress(args) {
    //console.log(progressObj.value)
    progressDialog.hide();
  }

  async saveCommentary(args) {
    //commentDialog.hide();

    let data = {
      id: this.state.id,
      commentary: textareaObj.value,
    };
    let response = await saveCommentary(data);
    this.props.setMessage(response);
    if (response.status) {
      commentDialog.hide();
    }

    //dispatch(ProcessActions.setMessage(response));
  }

  actionTemplate(props) {
    let id = props[props.column.field];
    let menu = this.state.actions;

    if (Array.isArray(id) && props.isgroupable === "YES") {
      menu = this.state.actions2;
    }
    if (props["userowner"] <= 2) {
      return (
        <DropDownButtonComponent
          id={props.id.toString().replaceAll(",", "_") + "_" + props.parentid}
          select={this.setAction.bind(this)}
          cssClass="e-success"
          items={menu}
          iconCss="e-icons e-edit"
        ></DropDownButtonComponent>
      );
    } else {
      return (
        <ButtonComponent
          cssClass="e-warning"
          iconCss="e-icons e-error-treeview"
        >
          {" "}
        </ButtonComponent>
      );
    }
  }

  firstValueTemplate(props) {
    if (Array.isArray(props.id)) {
      let value;
      if (Array.isArray(props.field0)) {
        value = props.field0[0];
        props.field0.map(function (key) {
          if (value === "") {
            value = key;
          }
        });
      } else {
        value = props.field0;
      }

      return (
        <div>
          {value}
          <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
            {"+" + props.totalTasks}{" "}
          </span>
        </div>
      );
    } else {
      return props.field0;
    }
  }

  firstValueTemplate1(props) {
    if (props.field1 && Array.isArray(props.field1)) {
      let value = props.field1[0];
      props.field1.map(function (key) {
        if (value === "") {
          value = key;
        } else {
        }
      });
      return (
        <div>
          {value}
          <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
            {"+" + props.totalTasks}{" "}
          </span>
        </div>
      );
    } else {
      return props.field1;
    }
  }

  firstValueTemplate2(props) {
    if (props.field2 && Array.isArray(props.field2)) {
      let value = props.field2[0];
      props.field2.map(function (key) {
        if (value === "") {
          value = key;
        } else {
        }
      });
      return (
        <div>
          {value}
          <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
            {"+" + props.totalTasks}{" "}
          </span>
        </div>
      );
    } else {
      return props.field2;
    }
  }

  firstValueTemplate3(props) {
    if (props.field3 && Array.isArray(props.field3)) {
      let value = props.field3[0];
      props.field3.map(function (key) {
        if (value === "") {
          value = key;
        } else {
        }
      });
      return (
        <div>
          {value}
          <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
            {"+" + props.totalTasks}{" "}
          </span>
        </div>
      );
    } else {
      return props.field3;
    }
  }

  firstValueTemplate4(props) {
    if (props.field4 && Array.isArray(props.field4)) {
      let value = props.field4[0];
      props.field4.map(function (key) {
        if (value === "") {
          value = key;
        } else {
        }
      });
      return (
        <div>
          {value}
          <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
            {"+" + props.totalTasks}{" "}
          </span>
        </div>
      );
    } else {
      return props.field4;
    }
  }

  firstValueTemplate5(props) {
    if (props.field5 && Array.isArray(props.field5)) {
      let value = props.field5[0];
      props.field5.map(function (key) {
        if (value === "") {
          value = key;
        } else {
        }
      });
      return (
        <div>
          {value}
          <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
            {"+" + props.totalTasks}{" "}
          </span>
        </div>
      );
    } else {
      return props.field5;
    }
  }

  firstValueTemplate6(props) {
    if (props.field6 && Array.isArray(props.field6)) {
      let value = props.field6[0];
      props.field6.map(function (key) {
        if (value === "") {
          value = key;
        } else {
        }
      });
      return (
        <div>
          {value}
          <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">
            {"+" + props.totalTasks}{" "}
          </span>
        </div>
      );
    } else {
      return props.field6;
    }
  }

  contextToolbarMenuTaskClick = async (args) => {
    var selectedrecords = this.state.treegridTaskObj.getCheckedRecords();

    //console.log(args.item.id )
    if (this.state.treegridTaskObj && args.item.id === "_gridcontrol_search") {
      setTimeout(
        async function () {
          let searchBar = document.getElementById("_gridcontrol_searchbar");

          if (searchBar) {
            searchBar.setAttribute("autocomplete", "off");
            searchBar.setAttribute("type", "text");
            //        console.log( searchBar)
          }
        }.bind(this),
        250
      );
    }

    if (this.state.treegridTaskObj && args.item.id === "refresh") {
      this.refreshData(args);
    } else if (this.state.treegridTaskObj && args.item.id === "executegroup") {
      let fortemporalyIds = [];
      console.log(selectedrecords);
      for (let r in selectedrecords) {
        console.log("IDDDD", selectedrecords[r].id);
        fortemporalyIds.push(selectedrecords[r].id);
      }
      let args = { item: { value: "RESOLVE" } };
      await this.setAction(args, fortemporalyIds);

      console.log("executegroup", args, selectedrecords);
    }
    if (this.state.treegridTaskObj && args.item.id === "group") {
      selectedrecords = selectedrecords.filter((data) => !data.locked);

      this.setState({ ...this.state, loading: true });
      let resp = await changeTasks(selectedrecords, "group");
      this.props.setMessage(resp);
      if (resp.status) {
        this.refreshDataLocal(args);
      } else {
        this.setState({ ...this.state, loading: false });
      }
    }
    if (this.state.treegridTaskObj && args.item.id === "take") {
      this.setState({ ...this.state, loading: true });

      if (selectedrecords.length === 0) {
        this.props.setMessage({ status: false, message: "No tasks selected" });

        setTimeout(
          async function () {
            this.setState({ ...this.state, loading: false });
          }.bind(this),
          250
        );
      } else {
        let resp = await changeTasks(selectedrecords, "take");
        this.props.setMessage(resp);
        if (resp.status) {
          this.refreshDataLocal(args);
        } else {
          this.setState({ ...this.state, loading: false });
        }
      }
    } else {
    }
  };

  refreshDataLocal = async (args) => {
    this.setState({
      ...this.state,
      loading: true,
      formDialogStatus: false,
      data: [],
    });
    let resp = await prepareGetTasks({
      taskId: this.props.taskId,
      flowId: this.props.flowId,
    });

    setTimeout(
      async function () {
        this.setState({
          ...this.state,
          loading: false,
          data: resp.activetasks,
          vars: resp.flowvars,
          selectedTasks: [],
        });
      }.bind(this),
      1000
    );

    setTimeout(
      async function () {
        if (this.state.treegridTaskObj) {
          this.state.treegridTaskObj.collapseAll();
        }
      }.bind(this),
      1250
    );
  };

  refreshData = async (args) => {
    console.log("refreshing");
    this.props.refreshData(); //CHECK!!!
    /*
    this.setState({  ...this.state, loading:true, formDialogStatus:false, data:[] }) 
      let resp = await prepareGetTasks ({taskId:this.props.taskId, flowId: this.props.flowId })
       
      setTimeout( async function() { 
        this.setState({  ...this.state,  loading:false, data: resp.activetasks , vars: resp.flowvars})         
      }.bind(this),1000)

      setTimeout( async function() { 
        if (this.state.treegridTaskObj){
          this.state.treegridTaskObj.collapseAll()
        }
      }.bind(this),1250)*/
  };

  onChangeForm = async (wfpflowid, wfptaskid, name, value) => {
    console.log(wfpflowid, wfptaskid, name, value);
    console.log("this.state.vars", this.state.vars);
    let pos = -1;
    this.state.vars.map(function (key, index) {
      if (key.WFPFLOWID === wfpflowid && key.VARNAME === name) {
        pos = index;
      }
    });
    console.log(pos);
    this.state.vars[pos]["VARVALUE"] = value;
  };

  rowDataBound = (args) => {
    if (args.data.userowner == 1) {
      args.row.style.backgroundColor = "#dbffdb";
    }
  };
  formDialogClose = () => {
    this.setState({ ...this.state, loading: true });
    setTimeout(
      async function () {
        this.setState({
          ...this.state,
          loading: false,
          formDialogStatus: false,
        });
      }.bind(this),
      500
    );

    setTimeout(
      async function () {
        if (this.state.treegridTaskObj) {
          this.state.treegridTaskObj.collapseAll();
        }
      }.bind(this),
      550
    );
  };

  textBox = (args) => {
    return (
      <div>
        <span className="e-input-group e-control-wrapper">
          <input
            onChange={this.onChange}
            className="e-control e-textbox e-lib e-input"
            type="text"
            id="keySearch"
            name="keySearch"
            placeholder="Search..."
          />
          <span className="e-icons e-search e-input-group-icon"></span>
        </span>
      </div>
    );
  };

  onChange = (event) => {
    this.setState({
      ...this.state,
      keySearch: event.target.value.toLowerCase(),
    });
  };

  render() {
    return (
      <>
        <div
          className="control-pane"
          style={{ padding: Browser.isDevice ? "0" : "10px 15px" }}
        >
          {this.state.loading && <Loading contained={true} />}
          <div className="control-section">
            <DialogComponent
              ref={(scope) => {
                progressDialog = scope;
              }}
              width="350px"
              height="300px"
              id="progressDialog"
              header={this.state.dialogProgressHeader}
              showCloseIcon={true}
              isModal={true}
              buttons={this.state.progressDlgButtons}
              position={this.state.position}
              visible={false}
            >
              <div className="my-50">
                <SliderComponent
                  id="slider"
                  value={progressValue}
                  min={0}
                  max={100}
                  showButtons={true}
                  ticks={{ placement: "Before", largeStep: 10 }}
                  tooltip={{
                    placement: "Before",
                    isVisible: true,
                    showOn: "Focus",
                  }}
                  type="MinRange"
                  ref={(slider) => {
                    progressObj = slider;
                  }}
                />
              </div>
            </DialogComponent>
            <DialogComponent
              ref={(scope) => {
                commentDialog = scope;
              }}
              width="350px"
              height="250px"
              id="commentDialog"
              header={this.state.dialogCommentHeader}
              showCloseIcon={true}
              isModal={true}
              buttons={this.state.commentDlgButtons}
              position={this.state.position}
              visible={false}
            >
              <div className="mt-30">
                <TextBoxComponent
                  id="default"
                  rows={3}
                  multiline={true}
                  floatLabelType="Auto"
                  placeholder="Enter your commnet"
                  ref={(scope) => {
                    textareaObj = scope;
                  }}
                ></TextBoxComponent>
              </div>
            </DialogComponent>
            <DialogExecuteTask
              id={this.state.id}
              commentary={this.state.commentary}
              flowId={this.props.flowId}
              taskId={this.props.taskId}
              varSchema={this.props.header}
              wfpflowid={this.state.wfpflowid}
              officecode={this.state.officecode}
              loading={this.state.loading}
              refreshData={this.refreshData}
              onChangeForm={this.onChangeForm}
              wfptaskid={this.state.wfptaskid}
              isParent={this.state.isParent}
              status={this.state.formDialogStatus}
              header={this.state.taskHeader}
              taskName={this.state.taskName}
              close={this.formDialogClose}
              flowData={this.state.flowData}
              data={this.state.formDataTask}
              datapostload={this.state.datapostload}
              actions={this.state.taskActions}
              animationSettings={this.state.animationSettings}
            />

            {this.state.loading ? (
              ""
            ) : this.state.isMobile ? (
              <div className="control-pane">
                {/*----------------mobile-----------------------*/}
                {this.state.data.length > 0 && this.props.header.length > 0 && (
                  <div className="no-padding">
                    <div className="px-15 mt-10">
                      {!this.state.notGroupable && !this.props.noToolBar ? (
                        <ToolbarComponent
                          id="toolbar_tasks"
                          ref={(toolbar) => (toolbarObj = toolbar)}
                          overflowMode="Popup"
                          cssClass="template"
                          clicked={this.contextMenuClick}
                        >
                          <ItemsDirective>
                            <ItemDirective
                              text={
                                this.state.selAll
                                  ? this.props.defConfig[this.props.defLang]
                                      .deselectall
                                  : this.props.defConfig[this.props.defLang]
                                      .selectall
                              }
                              align="Left"
                              id="selectall"
                              type="Button"
                              cssClass="toobar-button e-primary"
                            />
                            <ItemDirective
                              text={
                                this.props.defConfig[this.props.defLang]
                                  .grouptasks
                              }
                              align="Left"
                              id="grouptask"
                              type="Button"
                              cssClass="toobar-button e-success"
                            />
                            <ItemDirective type="Separator" />
                            <ItemDirective
                              cssClass="find"
                              type="Input"
                              template={this.textBox}
                              overflow="Show"
                              align="Right"
                            />
                          </ItemsDirective>
                        </ToolbarComponent>
                      ) : (
                        <ToolbarComponent
                          id="toolbar_tasks"
                          ref={(toolbar) => (toolbarObj = toolbar)}
                          overflowMode="Popup"
                          cssClass="template"
                          clicked={this.contextMenuClick}
                        >
                          <ItemsDirective>
                            <ItemDirective
                              cssClass="find"
                              type="Input"
                              template={this.textBox}
                              overflow="Show"
                              align="Right"
                            />
                          </ItemsDirective>
                        </ToolbarComponent>
                      )}
                    </div>
                    <div className="layout">
                      <div id="list-container">
                        {!this.props.noToolBar ? (
                          <>
                            <TabComponent
                              id="tab"
                              selected={this.setActiveTask}
                            >
                              <TabItemsDirective>
                                <TabItemDirective
                                  header={this.headerText[0]}
                                  content={"#assigned"}
                                />
                                <TabItemDirective
                                  header={this.headerText[1]}
                                  content={"#unassigned"}
                                />
                                <TabItemDirective
                                  header={this.headerText[2]}
                                  content={"#all"}
                                />
                              </TabItemsDirective>
                            </TabComponent>
                            <ListViewComponent
                              id="assigned"
                              dataSource={
                                this.state.selectingAll
                                  ? []
                                  : this.getAssigned()
                              }
                              fields={this.fields}
                              style={this.styleNone}
                              cssClass="e-list-template"
                              template={this.listTemplateA.bind(this)}
                            ></ListViewComponent>
                            <ListViewComponent
                              id="unassigned"
                              dataSource={
                                this.state.selectingAll
                                  ? []
                                  : this.getUnassigned()
                              }
                              fields={this.fields}
                              style={this.styleNone}
                              cssClass="e-list-template"
                              template={this.listTemplateU.bind(this)}
                            ></ListViewComponent>
                            <ListViewComponent
                              id="all"
                              dataSource={
                                this.state.selectingAll ? [] : this.getAll()
                              }
                              fields={this.fields}
                              style={this.styleNone}
                              cssClass="e-list-template"
                              template={this.listTemplate.bind(this)}
                            ></ListViewComponent>
                          </>
                        ) : (
                          <>
                            <TabComponent
                              id="tab"
                              selected={this.setActiveTask}
                            >
                              <TabItemsDirective>
                                <TabItemDirective
                                  header={this.headerText[0]}
                                  content={"#assigned"}
                                />
                              </TabItemsDirective>
                            </TabComponent>
                            <ListViewComponent
                              id="assigned"
                              dataSource={
                                this.state.selectingAll
                                  ? []
                                  : this.getAssigned()
                              }
                              fields={this.fields}
                              style={this.styleNone}
                              cssClass="e-list-template"
                              template={this.listTemplateA.bind(this)}
                            ></ListViewComponent>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/*----------------mobile-----------------------*/}
              </div>
            ) : (
              <>
                {/*----------------web-----------------------*/}
                {this.state.data.length > 0 && this.props.header.length > 0 && (
                  <TreeGridComponent
                    enableAdaptiveUI={true}
                    actionComplete={this.actionComplete.bind(this)}
                    toolbarClick={this.contextToolbarMenuTaskClick}
                    rowDataBound={this.rowDataBound}
                    toolbar={this.state.toolbarOptions}
                    autoCheckHierarchy={true}
                    ref={(treegrid) => (this.state.treegridTaskObj = treegrid)}
                    dataSource={this.state.data}
                    treeColumnIndex={1}
                    allowPaging={true}
                    pageSettings={{
                      pageSizes: true,
                      pageSize: 30,
                      pageCount: 4,
                    }}
                    childMapping="subtasks"
                    height={
                      this.props.fixedHeight
                        ? this.props.fixedHeight
                        : this.state.wHeight
                    }
                  >
                    {this.props.showTaskInfo /*single task */ ? (
                      <ColumnsDirective>
                        <ColumnDirective
                          field="id"
                          headerText={
                            this.props.defConfig[this.props.defLang]
                              .actionslabel
                          }
                          width="97"
                          textAlign="Right"
                          template={this.actionTemplate.bind(this)}
                        ></ColumnDirective>

                        <ColumnDirective
                          field="startDate"
                          showCheckbox={true}
                          headerText={
                            this.props.defConfig[this.props.defLang]
                              .startdatelabel
                          }
                          width="160"
                          format="yMd"
                        />
                        {this.props.header.map((b, index) => {
                          return index == 0 ? (
                            <ColumnDirective
                              field={"field" + index}
                              headerText={b.VARLABEL}
                              width="150"
                              template={this.firstValueTemplate.bind(this)}
                            />
                          ) : (
                            <ColumnDirective
                              field={"field" + index}
                              headerText={b.VARLABEL}
                              width="150"
                            />
                          );
                        })}
                      </ColumnsDirective>
                    ) : (
                      /*multiple tasks*/
                      <ColumnsDirective>
                        <ColumnDirective
                          field="id"
                          headerText={
                            this.props.defConfig[this.props.defLang]
                              .actionslabel
                          }
                          width="97"
                          textAlign="Right"
                          template={this.actionTemplate.bind(this)}
                        ></ColumnDirective>

                        <ColumnDirective
                          field="startDate"
                          showCheckbox={true}
                          headerText={
                            this.props.defConfig[this.props.defLang]
                              .startdatelabel
                          }
                          width="160"
                          format="yMd"
                        />
                        {this.props.header.map((b, index) => {
                          switch (index) {
                            case 0:
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={"field" + index}
                                  headerText={b.VARLABEL}
                                  width="150"
                                  template={this.firstValueTemplate.bind(this)}
                                />
                              );
                            case 1:
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={"field" + index}
                                  headerText={b.VARLABEL}
                                  width="150"
                                  template={this.firstValueTemplate1.bind(this)}
                                />
                              );
                            case 2:
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={"field" + index}
                                  headerText={b.VARLABEL}
                                  width="150"
                                  template={this.firstValueTemplate2.bind(this)}
                                />
                              );
                            case 3:
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={"field" + index}
                                  headerText={b.VARLABEL}
                                  width="150"
                                  template={this.firstValueTemplate3.bind(this)}
                                />
                              );
                            case 4:
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={"field" + index}
                                  headerText={b.VARLABEL}
                                  width="150"
                                  template={this.firstValueTemplate4.bind(this)}
                                />
                              );
                            case 5:
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={"field" + index}
                                  headerText={b.VARLABEL}
                                  width="150"
                                  template={this.firstValueTemplate5.bind(this)}
                                />
                              );
                            case 6:
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={"field" + index}
                                  headerText={b.VARLABEL}
                                  width="150"
                                  template={this.firstValueTemplate6.bind(this)}
                                />
                              );

                            default:
                              return (
                                <ColumnDirective
                                  key={index}
                                  field={"field" + index}
                                  headerText={b.VARLABEL}
                                  width="150"
                                />
                              );
                          }
                        })}
                      </ColumnsDirective>
                    )}
                    <Inject services={[Toolbar, Filter, Page]} />
                  </TreeGridComponent>
                )}

                {/*----------------web-----------------------*/}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default CompTreeGrid;
