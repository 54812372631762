import * as React from 'react';


const ConnectorTypes = (props) => {

	  
	  return (
		<>
		<div className="row mb-10 mt-10">
			<div className=" col-xs-12 ml-1">
				<h4>Choose your connector type:</h4>
			</div>	
		</div>
		<div className="row  mt-10 flex-middle" style={{minHeight:window.innerHeight-430}}>
		<div className=" col-xs-12 col-sm-12  col-md-12 col-lg-6 col-xl-6 flex-center ">
			
			<div onClick={()=>{props.changePlan(1)}}  className={"banner-connector-"+ ( props.activeType===1?"wrapper": "single")+ " wow fadeIn animated hover-up"}>
				<div className= {"text-center flex-middle banner-connector"+ ( props.activeType===1?"-active": "")}>
					<div>
						<span className="h3 plan-title" >PRIVATE 	</span>
						<p className="text-center">Just your organizaation can use this connector</p>
					</div>
				</div>
			</div>
		</div>

		<div className=" col-xs-12 col-sm-12  col-md-12 col-lg-6 col-xl-6 flex-center ">
			<div onClick={()=>{props.changePlan(2)}} className={"banner-connector-"+ ( props.activeType===2?"wrapper": "single")+ " wow fadeIn animated hover-up"}>				
				<div className= {"text-center flex-middle banner-connector"+ ( props.activeType===2?"-active": "")}>
					<div>
						<span className="h3 plan-title" >PUBLIC</span>
						<p className="text-center">Everyone can use this connector and you can Earn money for use</p>
					</div>
				</div>
			</div>
		</div>

		 

	</div>
	</>
	  );

  
};

ConnectorTypes.propTypes = {};

export default ConnectorTypes;

