import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GenericForm from "../../components/forms/GenericForm";
import LoadingForm from "../../components/forms/LoadingForm";
import Plans from "../../components/forms/Plans";
import Congratulations from "../../components/forms/Congratulations";
import ProcessingRequest from "../../components/forms/ProcessingRequest";
import Utils from "../../utils/Utils";
import { validateForm , find, registerNewOrganization, createNewOrganization, clearformData} from '../../utils/Functions';
import * as ProcessActions from "../../redux/actions/process.actions";

import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar';
import "./Login.css";

let linear;
const Login = (props) => {
	const { defConfig, params, defLang, activeForm, usertoken} = useSelector((state) => state.Login);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	const  progressLoad = (args) => {
        let selectedTheme = location.hash.split('/')[1];
        selectedTheme = selectedTheme ? selectedTheme : 'Material';
        args.progressBar.theme = (selectedTheme.charAt(0).toUpperCase() +
            selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast');
            args.progressBar.trackColor = '#000';
			args.progressBar.progressColor = '#4c38c9';

    };

	const [linearValue,setLinearValue] = React.useState(1);

	const [step,setStep] = React.useState(0);
	const [activePlan,setActivePlan] = React.useState(2);
	const [formValidation, setFormValidation] = React.useState([]);
	const [values,setValues] = React.useState({});	
	const [values2,setValues2] = React.useState({});
	const [loading,setLoading] = React.useState(false);

	const [registerFormInputs,setregisterFormInputs] = React.useState(Utils.registerFormInputs);
	const [confirmFormInputs,setConfirmFormInputs] = React.useState(Utils.confirmFormInputs);

	const onChange = (event) => { 
		values[event.target.name] = event.target.value
		let pos = find(registerFormInputs,event.target.name, "name" )
		registerFormInputs[pos].value= event.target.value
	}

	const onChange2 = (event) => { 
		values2[event.target.name] = event.target.value
		let pos = find(confirmFormInputs,event.target.name, "name" )
		confirmFormInputs[pos].value= event.target.value
	}

	const changePlan = (plan) => {
		setActivePlan(plan)
	}
	const optionButton = async(option) => {
		let newStep = option==="next"?step+1: step-1
		if (newStep ===-1){
			navigate("/login");
		}
		if (newStep===0 ){
			setLinearValue(1)
			setStep(newStep)
		}else if(newStep===1 ){
			setLinearValue(33)
			setStep(newStep)
		}else if(newStep===2 ){
			let validResponse = await validateForm(registerFormInputs,values);
			setLoading(true)
			setTimeout( async function() {
				setFormValidation(validResponse.messages)	
				setregisterFormInputs(validResponse.formData)
				
				if (validResponse.messages.length===0){
					let registerNewOrg = await registerNewOrganization(values);
					if (registerNewOrg.status){
						setLinearValue(66)
						setStep(newStep)
					}else{
						dispatch(ProcessActions.setMessage(registerNewOrg))
					}
				}
				setLoading(false)
				clearformData(confirmFormInputs);
			}.bind(this),50)
		}else if(newStep===3 ){
			//console.log("values S3:", values, values2)
			let validResponse = await validateForm(confirmFormInputs,values);
			setLoading(true)
			//console.log(validResponse)
			setTimeout( async function() {
				setFormValidation(validResponse.messages)	
				setConfirmFormInputs(validResponse.formData)
				
				if (validResponse.messages.length===0){
					let registerNewOrg = await createNewOrganization(values, values2);
					//console.log(registerNewOrg)
					if (registerNewOrg.status){
						setLinearValue(100)
						newStep++;
						setStep(newStep)
						
					}else{
						dispatch(ProcessActions.setMessage(registerNewOrg))
					}
					setLoading(false)
				}else{
					console.log("eerr")
					setLoading(false)
				}
				
			}.bind(this),50)
		}
    };

	React.useEffect(()=>{
		//console.log("hello from", location, window.location.hostname);
		if (usertoken){
			navigate("/dashboard");
		}

		clearformData(registerFormInputs);
		clearformData(confirmFormInputs);

		let _values ={}
		for (let i in registerFormInputs){
			_values[registerFormInputs[i].name] = registerFormInputs[i].value
		}
		setValues(_values)

	},[usertoken])

	
  
  return (  	
	<div className='control-pane-register'>
                <div className='control-section card-control-section basic_card_layout'>
                    <div className="e-card-resize-container">
                        <div className='row'>
                            <div className="row card-layout flex-center">
                                <div className=" col-xs-12 col-sm-12  col-md-12 col-lg-10 col-xl-9">
                                    <div className="e-card" id="basic_card">
                                        <div className="e-card-header">
                                            <div className="e-card-header-caption">
                                                <div className={step===4 && "text-center"}>
													<img className="logo-mini" src="/images/logo_mini.png"/>
													{step!==4 && <span className="h3"> Flexible pricing that matches your needs   </span>}
												</div>
                                            </div>
                                        </div>
                                        <div className="e-card-content">
											<div className="row ">
												<div className=" col-xs-12 col-md-10 col-lg-6 col-xl-4 ">																								
													{step<3 && 
														<p className=" mb-2">	
															<span>Let’s get started. Which of these plan suits your needs?</span> 
														</p>		
													}												
												</div>
                                        	</div>
											<div className="row">
												<div className=" col-xs-12">
												{step===0 &&
													<ProgressBarComponent id="success"  type='Linear' height='40' width='100%' 
													value={1}   role='Secondary'   load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}
												{step===1 &&
													<ProgressBarComponent id="success" type='Linear' height='40' width='100%' 
													value={33}   role='Secondary'  load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}
												{step===2 &&
													<ProgressBarComponent id="success" type='Linear' height='40' width='100%' 
													value={66}    role='Secondary'  load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}

												{step===3 &&
													<ProgressBarComponent id="success" type='Linear' height='40' width='100%' 
													value={95}    role='Secondary'  load={progressLoad.bind(this)}>
													</ProgressBarComponent>
												}
												</div>
                                        	</div>
											{step===0 &&
												<Plans changePlan={changePlan} activePlan={activePlan}/>
											}
											{step===1 &&
												<div className="row ">
													<div className="flex-center ">
														<div className=" col-xs-12 col-md-10 col-lg-8 col-xl-6 flex-center">
															<div   className=" banner-form-wrapper wow fadeIn animated hover-up">
																<div className="banner-form">
																	{!loading ?
																		<GenericForm 		
																			datasubtitle="Organization info"
																			data={registerFormInputs} 
																			formValidation={formValidation}
																			buttonposition="center"
																			onChange={onChange}
																			buttons={[]}
																		/> 
																	:
																		<LoadingForm 		
																			datasubtitle="Organization info"
																			data={registerFormInputs} 
																			formValidation={formValidation}
																			buttonposition="center" 
																		/> 
																	}
																</div>
															</div>
														</div>
													</div>
												</div>
											}

											{step===2 &&
												<div className="row banner-container" style={{marginBottom: 4}}>
													<div className="flex-center ">
														<div className=" col-xs-12 col-md-10 col-lg-8 col-xl-6 flex-center mt-65">
															<div   className=" banner-form-wrapper wow fadeIn animated hover-up">
																<div className="banner-form">

																<div className="row mt-20 text-center">
																	<div className=" col-xs-12">
																		<h4> Finally, validate your email</h4>
																	</div>
																</div>
																	{!loading ?
																		<GenericForm  
																			data={confirmFormInputs} 
																			formValidation={formValidation}
																			buttonposition="center"
																			onChange={onChange2}
																			buttons={[]}
																		/> 
																	:
																		<LoadingForm 		 
																			data={confirmFormInputs} 
																			formValidation={formValidation}
																			buttonposition="center" 
																		/> 
																	}
																</div>
															</div>
														</div>
													</div>
												</div>
											}
											{step===3 &&
												<ProcessingRequest />
											}
											{step===4 &&
												<Congratulations />
											}

                                        </div>
										
											<div className="e-card-actions">
											{step<3 && <>
												<div className="mb-1 col-xs-6 text-left">
													<button style={{minWidth:120}} onClick={()=> { if (step===2) {clearformData(registerFormInputs); clearformData(confirmFormInputs); } optionButton("back");  }} className="e-btn e-light">
														Back
													</button>
												</div>
												<div className="mb-1 col-xs-6 text-right">
													<button onClick={()=> {optionButton("next")}} style={{minWidth:120}} className="e-btn e-primary">
													{step===2 ?"Create my Workspace" :"Next"}
													</button>
												</div></>
											}
											</div>
										
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  );
};

Login.propTypes = {};

export default Login;
