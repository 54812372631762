import React from "react";
import { useRoutes, Navigate, useNavigate, useParams,useSearchParams } from "react-router-dom";
import MainLayout from "../layout/MainLayout";

import Login from "../pages/login/Login";
import Register from "../pages/login/Register";
import Forgot from "../pages/login/Forgot-password";

import Home from "../pages/home/Home";
import Dashboard from "../pages/dashboard/Dashboard";
import ManageDiagram from "../pages/admin/ManageDiagram/ManageDiagram";
import ManageOrgChart from "../pages/admin/ManageDiagram/ManageOrgChart";
import ManageForm from "../pages/admin/ManageDiagram/ManageForm";
import ManageDashboard from "../pages/admin/ManageDiagram/ManageDashboard";
import ManageCustomPage from "../pages/custom/ManageCustomPage";

import ManageConnector from "../pages/admin/ManageConnectors/ManageConnector";
import ListConnectors from "../pages/admin/ManageConnectors/ListConnectors";


import ListData from "../pages/admin/ListData";

import ListTasks from "../pages/engine/ListTasks";
import SearchWF from "../pages/engine/SearchWF";

import * as Actions from "../redux/actions/user.actions";
import * as ProcessActions from "../redux/actions/process.actions";
import Loading from "../components/customdesign/Loading";

import { useDispatch, useSelector } from "react-redux";
const Routes = (props) => {
  const {    
    token,
    defConfig,
    defLang

  } = props;

  const dispatch = useDispatch(); 
  const { requestingResumenTasks, tasks  } = useSelector((state) => state.Login);
 
  React.useEffect(()=>{
    if (tasks === undefined){
      //console.log("GETTING RES TASKs", tasks)
      dispatch(Actions.getResumeTasksByUser());  
    }
  },[ tasks])

  const setLoading = (status) => {
 
    if (status){
      dispatch(ProcessActions.processing());  
    }else{
      dispatch(ProcessActions.processed());  
    }
    
  };

  const WrapperManageDiagram = (props) => {

    const [search, setSearch] = useSearchParams();
    const params = useParams();
    const navigate = useNavigate()
    //console.log(props, search)

    return <ManageDiagram  {...{...props, match: {params} ,navigate:navigate, defConfig :defConfig, defLang:defLang, setLoading:setLoading, search:search } } />
  }
  const WrapperManageOrgChart = (props) => {
    const params = useParams();
    const navigate = useNavigate()
    //console.log(props)
    return <ManageOrgChart  {...{...props, match: {params} ,navigate:navigate, defConfig :defConfig, defLang:defLang, setLoading:setLoading } } />
  }

  
  const routes = [
    {
      path: "/",
      element: <MainLayout   /> ,
      children: [
       
        {
          path: "/login",
          element: <Login />,
        },

        {
          path: "/register",
          element: <Register />,
        },
        {
          path: "/forgot-password",
          element: <Forgot />,
        },
        {
          path: "/",
          element:   <Home />,
        },
        {
          path: "/dashboard",
          element: <Navigate to={"/"} />,
        },
        /*
        {
          path: "/manage/:componentName/:action/:id",
          exact:true,
          element: <ManageComponent />,
        },*/
        {
          path: "/manage/:componentName",
          exact:true,
          element: <ListData />,
        },
        {
          path: "/manage-page/:pageid",
          exact:true,
          element: <ManageCustomPage />,
        },
        
        {
          path: "/manageForm/:id",
          exact:true,
          element: <ManageForm />,
        },

        {
          path: "/manageConnector/:id",
          exact:true,
          element: <ManageConnector />,
        },

        {
          path: "/public/connectors",
          exact:true,
          element: <ListConnectors />,
        },
        
        {
          path: "/manageDashboard/:id",
          exact:true,
          element: <ManageDashboard />,
        },
        {
          path: "/SearchWF",
          exact:true,
          element: <SearchWF />,
        },

        

        {
          path: "/getTasks/:taskId/:flowId",
          exact:true,
          element: <ListTasks />,
        },
        /*
        {
          path: "/manageDiagram",
          element: <ManageDiagram />,
        },
         {
          path: "/manageDiagram/:id",
          element: <ManageDiagram defConfig={defConfig} defLang={defLang} setLoading={setLoading} />,
        },
        */
        
        {
          path: "/manageOrgChart",
          element: <WrapperManageOrgChart />,
        },
        
        {
          path: "/manageDiagram/:id",
          element: <WrapperManageDiagram />,
        },
      ],
    },
  ];

  const router = useRoutes(routes);

  return <>  
    {!requestingResumenTasks? router :<Loading/>}
  </>;
};

export default Routes;
