import * as React from 'react';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import './properties.component.css';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DataManager, Query, WebApiAdaptor,WebMethodAdaptor } from '@syncfusion/ej2-data';
import { getCookie } from '../../utils/Functions';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Page, Sort, RowDD, Selection , Inject } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import Loading from "../customdesign/Loading";

var gridOptInstance
var apimethodObj
var tabInstanceOpt
class CompFormAction extends React.Component{    
    
    
	constructor(props) {
        super(props);
        //this.onAdd = this.onAdd.bind(this); 
        this.state = {
           // isExecuting:true ,
           // dataSource : [],
			option:"",
            value:"",
            headertext:  [ { text: "Static Data" }, { text: "Dynamic Data" }],
            options:[],
            optionText:"",
            optionValue:"", 
            apiurl:"",
            apidata:"",
            text:"",
            name: this.props.name, 
            type: this.props.type,
            value:"",
            apimethod:"get",
            apitoken:"NOTOKEN",
            datatype:"static",
            masterLoading:true,
        }
    }
 
	componentDidMount = () => {	 
        
        setTimeout( async function() {
            if (Array.isArray(this.props.value)){
                this.setState({ ...this.state, options : this.props.value, masterLoading : false })
            }else{
                this.setState({ ...this.state, masterLoading : false })
            }

            
        }.bind(this),250)            
    

	}
    
    dialogTemplate = (props) => {
    
        return (<DialogFormTemplate {...props} />);
    }

    editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog', newRowPosition: 'Bottom', template: this.dialogTemplate };
    pageSettings = { pageCount: 5 };
    validationRule = { required: true };
    toolbarOptions = ['Add', 'Delete', 'Edit', 'Cancel' , 'Search'];
    onChange = async(event) => {  
		try{	
            if (event.target){
                this.setState({  ...this.state, [event.target.name ]: event.target.value });			 
            }else{
                this.setState({  ...this.state, apimethod:  apimethodObj.value });			 
            }
            if (!event.target?.temporal){
                this.props.onChange({target: this.state})
            }
            
		}catch(Exc){
			console.log(">>>>Err", Exc)
			if ( action!="" ){
				console.log("action", action)
			}
		}		
	};

    testApi=()=> {
        
        var token=getCookie(process.env.REACT_APP_NAME+'_usertoken')

        if(this.state.apitoken){
			if(this.state.apitoken!=="" && this.state.apitoken!=="NOTOKEN"){
				token = this.state.apitoken
			}
		}
        let url = this.state.apiurl
		if(this.state.apiurl.indexOf("http")<0 ){
			url = process.env.REACT_APP_API_CONFIG+ this.state.apiurl

		} 
        var remoteData  = new DataManager({
			url: url, 
			adaptor: this.state.apimethod==="post"? new WebMethodAdaptor : new WebApiAdaptor,
			headers:[ {'Authorization': 'Bearer ' + token}, {"getInfo": "simplified"} ] ,
			crossDomain: true
		});
        
		let remoteFields =  { text: this.state.text , value: this.state.value };
		let query = new Query().addParams( '$apidata' , this.state.apidata).select([this.state.text,this.state.value]).take(1000).requiresCount();
         
        this.setState({  ...this.state,   loading:true });

         
        setTimeout( async function() {            
            this.setState({  ...this.state, remoteData:remoteData, query: query, remoteFields:remoteFields,loading:false});			 
        }.bind(this),500)
    }

    
    loadingData = ()=> {
        return (
            <div style={{height:330}}>
                <Loading/>
            </div>
        )
    }

    
  clickHandler = async (args) => {
		
    if (  args.item.id === "savevars"){    
      var selectedRows = gridOptInstance.dataSource.filter( (it, index) => {
        if (it.OPTIONCODE == args.data.OPTIONCODE &&  it.SORTORDER == args.data.SORTORDER ) {
          items.push(index);
          gridOptInstance.deleteRecord("OPTIONCODE", it);  

        }/*&& it.SORTNAME!= gridOptInstance.dataSource[delItem].SORTNAME*/
        gridOptInstance.selectRows(items);
      }); 
      gridOptInstance.addRecord({ SORTORDER: 34, OPTIONCODE: "f", VARLABEL: "f", DEFVALUE: "f" } )
    }
    

  };
  actionComplete = (args) => {
 
    if (!args.item){

      if (args.requestType==="save") {
        let delItem=-1
        for (let k= 0; k < gridOptInstance.dataSource.length; k++) {
          let key = gridOptInstance.dataSource[k]
          //console.log("comparando",  args.data.OPTIONCODE == key.OPTIONCODE)
          if ( args.data.OPTIONCODE == key.OPTIONCODE){
            delItem ++
          }
        }
        if (delItem>0){
          let items=[]
          var selectedRows = gridOptInstance.dataSource.filter( (it, index) => { 
            
            if (it.OPTIONCODE == args.data.OPTIONCODE) {
              items.push(index);
              gridOptInstance.deleteRecord("OPTIONCODE", it);  

            }/*&& it.SORTNAME!= gridOptInstance.dataSource[delItem].SORTNAME*/
            gridOptInstance.selectRows(items);
          }); 
        
          selectedRows = gridOptInstance.getSelectedRecords().filter(it => it.OPTIONCODE == args.data.OPTIONCODE /*&& it.SORTNAME!= gridOptInstance.dataSource[delItem].SORTNAME*/); 
          selectedRows.forEach((element) => { 
              //gridOptInstance.deleteRecord("OPTIONCODE", element);  
          }); 


        }
        
        this.props.onChange({target:{ name: this.props.name, value: gridOptInstance.dataSource} })

      }

      
    }
    
  }
 
    
    render() {
        return (this.state.masterLoading? <Loading/>:
                
            <div className='control-pane'>
                <div className='control-section'>
                    <div className='col-12'>

                    <GridComponent 
                        height='180' 
                        toolbarClick={this.clickHandler.bind(this)}
                        actionComplete={this.actionComplete.bind(this)}
                        ref={(dialog) => (gridOptInstance = dialog)}
                        dataSource={this.state.options} 
                        pageSettings={this.pageSettings} 
                        toolbar={this.toolbarOptions} 
                        allowPaging={false} 
                        allowRowDragAndDrop={true}
                        allowSorting={true} 
                        /*sortSettings={sortingOptions}*/
                        editSettings={this.editSettings}>
                        <ColumnsDirective>
                        {/** <ColumnDirective field='SORTORDER' headerText='Sort Order' width='120' validationRules={numberRules}  textAlign='Right' editType='numericedit' ></ColumnDirective> */}
                            <ColumnDirective field='OPTIONCODE'   headerText='Option code'  width='230'  isPrimaryKey={true} validationRules={this.validationRule} ></ColumnDirective>
                            <ColumnDirective field='OPTIONVALUE'  headerText='Option label' width='130' ></ColumnDirective>
                            <ColumnDirective field='ACTION'  headerText='Action'  width='80'></ColumnDirective>
                            <ColumnDirective field='METHOD'  headerText='Method'  width='130'></ColumnDirective>
                            
                            
                        </ColumnsDirective>
                        <Inject services={[Page, Toolbar, Edit, Sort, RowDD, Selection]}/>
                    </GridComponent>
                </div>
            </div>
        </div>

        );
    }
}


function DialogFormTemplate(props) {
 
    let OPTIONCODE;
    let OPTIONVALUE;
    let ACTION; 
    const [val, setval] = React.useState(props);
     //console.log(props)
  
    
    function onChange(args) {
         let key = args.target.name;
        let value = args.target.value;
        setval(prevVal => ({ ...prevVal, [key]: value }));
  
        //console.log(val)
    }
   
    let data = val;
    return (<div>
        <div className="form-row mt-2">
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <input ref={input => OPTIONCODE = input} id="OPTIONCODE" name="OPTIONCODE" type="text" disabled={!data.isAdd} value={data.OPTIONCODE} onChange={onChange.bind(this)}/>
                    <span className="e-float-line"></span>
                    <label className="e-float-text e-label-top"> Var Code</label>
                </div>
            </div>
        </div>       
        <div className="form-row">   
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <input ref={input => OPTIONVALUE = input} value={data.OPTIONVALUE} id="OPTIONVALUE" name="OPTIONVALUE" type="text" onChange={onChange.bind(this)}/>
                    <span className="e-float-line"></span>
                    <label className="e-float-text e-label-top">Option Label</label>
                </div>
            </div>
        </div>

        <div className="form-row">
            <div className="form-group col-md-12"> 
            <DropDownListComponent name="METHOD" onChange={onChange.bind(this)} id="METHOD" value={data.METHOD?data.METHOD:"POST" } dataSource={[ {text:"POST",value:"POST"},{text:"GET",value:"GET"},{text:"PUT",value:"PUT"}  ]} fields={{ text: 'text', value: 'value' }} placeholder="Method" popupHeight='300px' floatLabelType='Always'></DropDownListComponent>
            </div>
        </div>

        <div className="form-row">   
            <div className="form-group col-md-12">
                <div className="e-float-input e-control-wrapper">
                    <input ref={input => ACTION = input} value={data.ACTION} id="ACTION" name="ACTION" type="text" onChange={onChange.bind(this)}/>
                    <span className="e-float-line"></span>
                    <label className="e-float-text e-label-top">Action</label>
                </div>
            </div>
        </div>
        
         
        
    </div>);
  }

CompFormAction.propTypes = {};

export default CompFormAction;

