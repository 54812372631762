import React, { Component } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import GenericForm from "../forms/GenericForm";
import ApiClient from "../../api/ApiClient";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DiagramComponent, Diagram, SymbolPaletteComponent,BpmnDiagrams, ConnectorConstraints, DataBinding ,NodeConstraints, PortConstraints, SnapConstraints, PortVisibility, Inject, 
  
  NodeConstraint,
  randomId, cloneObject, AnnotationConstraints
} from "@syncfusion/ej2-react-diagrams";

import { ProgressBarComponent, ProgressBarAnnotationsDirective, ProgressBarAnnotationDirective,  ProgressAnnotation } from '@syncfusion/ej2-react-progressbar';
var listDialogDiagram;
let diagramHistoryInstance
let interval = [ 1, 9, 0.25, 9.75, 0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75];
let gridlines = {
    lineColor: "#e0e0e0",
    lineIntervals: interval
};
import { find, findValueById, prepareGetHistoryFlow} from '../../utils/Functions';
import Loading from "../customdesign/LoadingPercentage";


let linearTwo;
 
class DialogFlowDiagram extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nodesLoaded:false,
      cont:0,
      requesting:false,
      nodes:[],
      wHeight:window.innerHeight,
      wWidth: window.innerWidth,
      connectors:[],
      content1 :  '<div id="point1" style="font-size:18px;font-weight:bold;color:#fff;fill:#000"><span>100%</span></div>'
    }
  }

  handleResize = () =>{
    console.log(window.innerHeight)
  	this.setState({
			wWidth: window.innerWidth,
			wHeight: window.innerHeight
		})
	};

  componentDidMount=async()=>{
    console.log("this.props",this.props)
    let response = await ApiClient.doPost("/actors/"+"get-all", { });
    let actors = response.result  

    response = await ApiClient.doPost("/tasks/"+"get-all", { });
    let tasks=[]
    for (let i in response.result){
        let task = response.result[i]
        task["TASKID"] =  task.TASKID
        tasks.push(task)
    }  
    window.addEventListener("resize", this.handleResize);
    this.setState({ actors: actors, tasks:tasks});

    let data ={
      wfpflowid : this.props.id
    }    
    response = await prepareGetHistoryFlow(data)
    let executedTasks =[]
    let activeTasks =[]
    let errorTasks=[]
    response.tasks.map(function (t){
      if(t.STATUSID ===7){
        executedTasks.push(t.TASKID)        
      }else if(t.STATUSID ===5){
        errorTasks.push(t.TASKID)        
      }else{
        activeTasks.push(t.TASKID)        
      } 
    })

    await this.getFlowData(this.props.flowId,this.props.version, executedTasks, activeTasks,errorTasks)
    this.setState({  nodesLoaded:true});    
  }

  componentWillUnmount=()=>{
    console.log("bye")
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.state.intervalId);
  }

  
  getFlowData = async(flowid, _version , executedTasks, activeTasks, errorTasks ) => {
    let version=1
    let nodes = []
    let lastVersion= 1

    console.log(executedTasks)
    console.log(activeTasks)
    console.log(errorTasks)

    let response = await ApiClient.doPost("/flows/"+"get-single", { id:flowid });

    if(response.status){   
      
      let flowData     = response.data
      let flowName     = flowData.FLOW
      let flowVars     = response.flowVars
      let definitions  = response.definitions
      let flowVersions = []
      let nodeIds =[]
      let actorIds = []
      let connectors=[]
      let invalidActors=[]
      if (definitions.length>0){             

        definitions.sort(function (a, b){
          return ((b.VERSION  < a.VERSION) ? -1 : ((b.VERSION > a.VERSION) ? 1 : 0));
        })                
        definitions.map(function (d){
          flowVersions.push({ VERSIONNAME: "Version "+d.VERSION, VERSION: d.VERSION })
          if (lastVersion < d.VERSION){
            lastVersion= d.VERSION
          }
        })                          
        let pos = find ( definitions, flowData.CURRENTVERSION ===0?1: flowData.CURRENTVERSION, "VERSION" )
        if(_version){
          pos = find ( definitions, _version, "VERSION" )
        }
        version  = definitions[pos].VERSION
        let diagram = JSON.parse(definitions[pos].FLOWDEFINITION)
        let lanes=[]
        let tmpActors =[]
        let contActor =1
        for (let a in diagram.tasks){
          let actor = diagram.tasks[a]
          if (tmpActors.indexOf(actor.actorId)<0 ){
            let isValidActor =false
            let fillActorColor = "#f0eeff"
            if (actor.actorId >0 ){
              for (let v in this.state.actors){
                let dbActor = this.state.actors[v]
                if ( dbActor.ACTORID === actor.actorId && dbActor.ACTOR === actor.actorName ){
                  isValidActor = true;
                }
              }
              if (!isValidActor){
                fillActorColor ="#f77b7b"                 
                invalidActors.push(actor.actorName)
              }
            }
            
            actorIds.push( {nodeId: contActor+actor.actorName+"0", actorId: actor.actorId , actorName:actor.actorName  }  )
            let children =[]
 console.log("diagram.tasks", diagram.tasks)
            for (let t in diagram.tasks){
              let task = diagram.tasks[t]
              console.log("1task.taskId",task.taskId)
              if (task.actorId === actor.actorId ){

                let fillActivityColor ="#c6c0ec" 
                let fillAutomaticColor = "#F0EEFF"
                let fillTraColor = "#f0eeff"
                let color="#000"
                let isValidTask =false

                console.log("2task.taskId",task.taskId)

                if ( executedTasks.indexOf(task.taskId)>=0 ){
                  fillActivityColor ="#bfbfbf" 
                  fillAutomaticColor = "#bfbfbf"
                  fillTraColor = "#bfbfbf"
                  color="#000"
                }else{
                  if ( activeTasks.indexOf(task.taskId)>=0 ){
                    fillActivityColor ="#4d841d" 
                    fillAutomaticColor = "#4d841d"
                    fillTraColor = "#bfbfbf"
                    color="#fff"
                  }else{
                    console.log("en")
                    if ( errorTasks.indexOf(task.taskId)>=0 ){
                      console.log("tras")
                      fillActivityColor ="#c62828" 
                      fillAutomaticColor = "#c62828"
                      fillTraColor = "#c62828"
                      color="#fff"
                    }
                  }
                }

                if (task.taskType === "TASK" || task.taskType=="AUTOMATIC"){
                  for (let v in this.state.tasks){
                    let dbTask = this.state.tasks[v]
                    if ( dbTask.TASKID === task.taskId && dbTask.TASK === task.taskName ){
                      isValidTask = true;
                    }
                  }
                }
                let taskObj = {}
                nodeIds.push( {nodeId:task.taskTemplateId,taskId:task.taskId , task:task.taskName }  )

                if (task.taskName === "<START>"){
                  taskObj = {
                    id: task.taskTemplateId, width: 50,  height: 50, shape: {
                      type: 'Bpmn', shape: 'Event',
                      event: { event: 'Start'  }
                    },
                    style: { fill: '#4c38c9' },
                    ports: [
                      {
                        id: 'port',
                        offset: { x: 0.96, y: 0.5 },
                        visibility: PortVisibility.Visible,
                        style: { fill: '#4c38c9' },
                        constraints: PortConstraints.Default | PortConstraints.Draw,
                      },
                    ],
                    margin: { left: task.left, top: task.top },
                    constraints: NodeConstraints.Default & ~NodeConstraints.Drag & ~NodeConstraints.Select & ~NodeConstraints.Resize & ~NodeConstraints.Delete,
                    annotations: [{
                        content: 'START',
                        style: { color: '#fff' },
                        constraints: AnnotationConstraints.ReadOnly,
                    }]
                  }
                }else if ( task.taskName==="<END>"){
                  taskObj = {
                    id: task.taskTemplateId,  width: 50,  height: 50,   shape: {
                      type: 'Bpmn', shape: 'Event',
                      event: { event: 'End', trigger: 'Termination' }
                    },                                       
                    margin: { left: task.left, top: task.top },
                    style: { fill: '#4c38c9' },
                    constraints: NodeConstraints.Default & ~NodeConstraints.Drag & ~NodeConstraints.Select & ~NodeConstraints.Resize & ~NodeConstraints.Delete,
                    annotations: [{
                        content: 'END',
                        style: { color: '#4c38c9', backgroundColor: '#c62828', border: "2px solid #4c38c9" },
                        constraints: AnnotationConstraints.ReadOnly,
                    }]                                 
                  }

                }else{

                  if (task.taskType ==="JOIN" || task.taskType ==="XOR" || task.taskType ==="GROUP" || task.taskType ==="KILLER" || task.taskType ==="WAIT"){

                    let inputLabel = task.input
                    let taskType = "Parallel"
                    if (task.taskType === "XOR" ){
                      taskType="ParallelEventBased"                                            
                    }else if (task.taskType === "GROUP" ){
                      taskType==="Complex"
                    }else if ( task.taskType === "KILLER" ){
                      taskType ="Exclusive"
                    }else if (task.taskType === "WAIT"){
                      taskType = "Inclusive"
                      inputLabel = task.output + " - " + task.input
                      //console.log("WAIT",task)
                    }

                    taskObj = {
                      id: task.taskTemplateId,  width: 50, height: 50, offsetX: 100, offsetY: 100,
                      shape: { type: 'Bpmn', shape: 'Gateway', gateway: { type: taskType } },
                      style: { fill: fillTraColor },
                      constraints: NodeConstraints.Default & ~NodeConstraints.Drag & ~NodeConstraints.Select & ~NodeConstraints.Resize & ~NodeConstraints.Delete,
                      margin: { left: task.left, top: task.top },
                      ports: [
                        {
                          id: 'port',
                          offset: { x: 0.96, y: 0.5 },
                          visibility: PortVisibility.Visible,
                          style: { fill: '#4c38c9' },
                          constraints: PortConstraints.Default | PortConstraints.Draw,
                        },
                      ],
                      annotations: [{
                          content: inputLabel , 
                          constraints: AnnotationConstraints.ReadOnly,
                          offset:{ x:0.5, y:-0.125},
                          verticalAlignment:"Top",
                          horizontalAlignment :"Center",
                          style: { 
                          bold:true,
                          fill :"#4c38c9", 
                          color :"#fff",
                          textWrapping:"NoWrap"
                          }
                      },                                        
                      {
                        content: task.taskName, 
                        constraints: AnnotationConstraints.ReadOnly,
                        offset:{ x:0.5, y:1.1},
                        verticalAlignment:"Top",
                        horizontalAlignment :"Center",
                        style: { 
                          bold:true,
                          textAlign: 'Center',
                          fill :"#f0eeff", 
                          color :"#000",
                          textWrapping:"WrapWithOverflow"
                        }
                      }
                    
                    ]
                    }
                  }else if (task.taskType=="TASK"){
                    taskObj =  {
                      id: task.taskTemplateId,  width: 185, height: 70, offsetX: 700, offsetY: 700,
                      shape: {
                        type: 'Bpmn', shape: 'Activity', activity: {
                          activity: 'Task', task: { type: 'Task' }
                        },
                      },        
                      margin: { left: task.left, top: task.top },
                      style: { strokeWidth: 2, strokeColor: '#4c38c9' , fill: fillActivityColor, color: "#fff"  },
                      constraints: NodeConstraints.Default & ~NodeConstraints.Drag & ~NodeConstraints.Select & ~NodeConstraints.Resize & ~NodeConstraints.Delete,
                      ports: [
                        {
                          id: 'port',
                          offset: { x: 0.96, y: 0.5 },
                          visibility: PortVisibility.Visible,
                          style: { fill: '#4c38c9' },
                          constraints: PortConstraints.Default | PortConstraints.Draw,
                        },
                      ],                                            
                      annotations: [{
                        content: task.taskName,  
                        constraints: AnnotationConstraints.ReadOnly,
                        style : {
                          bold:true,
                          fontSize:16,
                          color:"#fff"
                        }
                      },
                      {
                        content: task.input,  
                        constraints: AnnotationConstraints.ReadOnly,
                        offset:{ x:0.02, y:0.95},          
                        verticalAlignment:"Bottom",
                        horizontalAlignment :"Left",  
                        style : task.input===""?{}:{
                          bold:true,
                          fill :"#fff", 
                          color :"#000",
                          textWrapping:"NoWrap"
                        }
                      },
                      {
                        content: task.output,
                        constraints: AnnotationConstraints.ReadOnly,
                        offset:{ x:0.9, y:0.95},
                        verticalAlignment:"Bottom",
                        horizontalAlignment :"Center",
                        style : task.output===""?{}:{
                          bold:true,
                          fill :"#fff", 
                          color :"#000",
                          textWrapping:"NoWrap"
                        },
                      }]
                    }
                  }else if (task.taskType=="AUTOMATIC"){
                    taskObj =  {
                      id: task.taskTemplateId,  width: 185, height: 70, offsetX: 700, offsetY: 700,
                      shape: {
                        type: 'Bpmn', shape: 'Activity', activity: {
                          activity: 'Task', task: { type: 'Automatic' }
                        },
                      },
                      constraints: NodeConstraints.Default & ~NodeConstraints.Drag & ~NodeConstraints.Select & ~NodeConstraints.Resize & ~NodeConstraints.Delete,
                      annotations: [{
                        content: task.taskName,  
                        constraints: AnnotationConstraints.ReadOnly,
                        style : {
                          bold:true,
                          fontSize:16,
                          color:color
                        }
                      },
                      {
                        content: task.input,  
                        constraints: AnnotationConstraints.ReadOnly,
                        offset:{ x:0.02, y:0.95},          
                        verticalAlignment:"Bottom",
                        horizontalAlignment :"Left",  
                        style : task.input===""?{}:{
                          bold:true,
                          fill :"#fff", 
                          color :"#000",
                          textWrapping:"NoWrap"
                        }
                      },
                      {
                        content: task.output,
                        constraints: AnnotationConstraints.ReadOnly,
                        offset:{ x:0.9, y:0.95},
                        verticalAlignment:"Bottom",
                        horizontalAlignment :"Center",
                        style : task.output===""?{}:{
                          bold:true,
                          fill :"#fff", 
                          color :"#000",
                          textWrapping:"NoWrap"
                        },
                      }],
                      margin: { left: task.left, top: task.top },
                      
                      style: { strokeWidth: 2, strokeColor: '#757575', fill: fillAutomaticColor },
                      ports: [
                        {
                          id: 'port',
                          offset: { x: 0.96, y: 0.5 },
                          visibility: PortVisibility.Visible,
                          style: { fill: '#4c38c9' },
                          constraints: PortConstraints.Default | PortConstraints.Draw,
                        },          
                      ],
                    }  
                  }
                }
                children.push(
                  taskObj
                )
              }
            }

            let actorH = findValueById( diagram.canvas.laneHeights, "1"+actor.actorName+"0", "actorId", "height")
            console.log("actorH", actorH, actor.actorName)

            let lane = {
              id: actor.actorName,
              height: actorH==="NOT FOUND"?100:actorH,
              header: {
                annotation: { content: actor.actorName==="mainmaster"? 'Main':actor.actorName }, width: 50,
                style: { fontSize: 11 , fill: fillActorColor }
              },
              children: children,
              constraints: NodeConstraints.Default & ~NodeConstraints.Drag & ~NodeConstraints.Select & ~NodeConstraints.Resize & ~NodeConstraints.Delete,
            }


            lanes.push(lane)
            tmpActors.push(actor.actorId )
          }
        }
        
        for (let t in diagram.transitions){
          let transition = diagram.transitions[t]
          connectors.push({             
            type: 'Orthogonal', direction: 'Right' ,
            targetDecorator: { shape: 'Arrow', 
            style: { strokeColor: '#4c38c9', fill: '#4c38c9' } }, 
            constraints: ConnectorConstraints.Default & ~ConnectorConstraints.Select & ~ConnectorConstraints.Drag & ~ConnectorConstraints.Resize,
            annotations: [{
              //content: transition.option,
              height: 50, 
              offset: 0.5,
              template: '<span class="transition-label">'+ transition.option+'</span>' ,
            }],
            cornerRadius: 8,
            style: {
              textAlign: 'center',
              strokeColor:"#4c38c9",
              strokeWidth:1,
            },
            //constraints: ConnectorConstraints.Default & ConnectorConstraints.InheritLineRouting ,
            sourceID: transition.sourceID, targetID: transition.targetID } );
        }
        nodes = [        
          {
            id:"1",
            shape: {
              id: "base",
              type: 'SwimLane',
              orientation: 'Horizontal',
              constraints: NodeConstraints.Default & ~NodeConstraints.Drag & ~NodeConstraints.Select & ~NodeConstraints.Resize & ~NodeConstraints.Delete,
              //Intialize header to swimlane
              header: {
                annotation: { content: 'FLOW:'+flowName , style: { fontSize: 18,  bold: true, fill: "#f0eeff" , strokeColor:'#7f6df2' } },
                height: 45, style: { background: "#f0eeff", strokeColor:'#7f6df2', fill: "#f0eeff" },
              },
              lanes: lanes,
              phases: [
                {
                  id: 'version' , offset: 120,
                  header: { annotation: { content: 'version: '+version } }, style: { fill: '#fff' }
                }
              ],
              phaseSize: 20,
            },
            offsetX:  diagram.canvas.offsetX?diagram.canvas.offsetX:440, offsetY: diagram.canvas.offsetY?diagram.canvas.offsetY:120,
            width:  diagram.canvas.width?diagram.canvas.width:850
          }
        ]
      }
 
      this.setState({  invalidActors, flowName: flowName, flowId: flowData.FLOWID,lastVersion:lastVersion, isExecuting: false , nodes: nodes, nodeIds: nodeIds,connectors:connectors, actorIds:actorIds, version:version,definitions:definitions, flowVersions:flowVersions, flowVars:flowVars,  });    

      if ( invalidActors.length>0 ){
        console.log("INVALID", invalidActors)
      }

      setTimeout(  function() {                       
        diagramHistoryInstance.fitToPage({     canZoomIn:false });
      }.bind(this),50)
      
    }        
  }

  refreshWOState = async (nodes) => {
    
    let cont = this.state.cont
    this.setState({cont: cont+1});

    if (cont<60){
      this.setState({requesting: true});  
      let data ={
        wfpflowid : this.props.id
      }
      
      let response = await prepareGetHistoryFlow(data)
      
      let executedTasks =[]

      response.tasks.map(function (t){
            executedTasks.push(t.TASKID)        
      })
      
      let supportedNodes = ["Gateway", "Activity"]
      //console.log("diagramHistoryInstance.nodes",diagramHistoryInstance.nodes)
      
      for (let n in diagramHistoryInstance.nodes){
        let node = diagramHistoryInstance.nodes[n]

        if ( supportedNodes.indexOf(node.shape.shape)>=0 ){
          if (node.annotations.length>0){
            //console.log(node.annotations[0].content)
            let taskName = node.annotations[0].content
            let pos1 = taskName.indexOf("(")
            let pos2 = taskName.indexOf(")")
            let task = parseInt( taskName.substr ( pos1+1, taskName.length - pos2))
           // console.log(task, executedTasks)

            if ( executedTasks.indexOf(task)>=0){
              diagramHistoryInstance.nodes[n].style = { fill: '#6FAAB0' }
            }else{
              diagramHistoryInstance.nodes[n].style = { fill: '#f7f300' }
            }
          }
        }
      }
      this.setState({requesting: false});  

    }else{
      console.log("closing timer")
      clearInterval(this.state.intervalId);
    }
  }

  progressLoad = (args) => {
    let selectedTheme = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.progressBar.progressColor = '#FFFFFF';
    args.progressBar.theme = (selectedTheme.charAt(0).toUpperCase() +
        selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast');
    if (args.progressBar.element.id === 'full-background') {
        //console.log("selectedTheme",selectedTheme)
        args.progressBar.trackColor = '#007bff';
        args.progressBar.opacity = '1';
        args.progressBar.progressColor = '#fff';
    }
  };
 

  render() {
    return (
      <>
        
        <DialogComponent
          id={"listDialogDiagram"+ this.props.componentName}
          showCloseIcon={true}
          animationSettings={this.props.animationSettings}
          closeOnEscape={false}
          ref={(dialog) => (listDialogDiagram = dialog)}
          isModal={true}
          open={this.props.open} 
          close={this.props.close}
          cssClass="full-dialog"
          header={"Flow Execution Diagram FlowId:"+
          this.props.flowId}      
          width="75%" /*{ window.innerWidth>1650?"35%":window.innerWidth>1240?"50%":"75%"}*/
          height="100%"
          position={ { X: 'right', Y: 'top' }}
          visible={this.props.status} 
        >               
          <div className='control-pane'  >
            
            <div className="form-container" >  
              <div id="diagram-space"  >
                {this.state.nodes.length>0?
                  <DiagramComponent 
                      id="diagramHistoryInstance"
                      ref={diagram => (diagramHistoryInstance = diagram)} 
                      width={"100%"} height={this.state.wHeight-100} 
                      snapSettings={{
                          horizontalGridlines: gridlines,
                          verticalGridlines: gridlines
                      }}                                
                      nodes={this.state.nodes}                                      
                      getConnectorDefaults={(obj) => {
                          if (obj.id.indexOf("connector") !== -1) {
                              obj.style = {
                                  textAlign: 'center',
                                  strokeColor:"#4c38c9",
                                  strokeWidth:1,
                              } 
                          }
                      }}   
                      created={() => { 
                          for(let i in this.state.connectors){
                            diagramHistoryInstance.add(this.state.connectors[i]);
                          } 
                      }}                                          
                      AllowEditOnDblClick={false}  
                    ><Inject services={[ BpmnDiagrams, DataBinding]}/>
                  </DiagramComponent>
                :""}
                *Refreshing each 5 seconds {this.state.nodes.length}
              </div>                    
            </div>  
              
          </div> 
        </DialogComponent>
      </>
    );
  }
}

export default DialogFlowDiagram;
