import * as React from 'react';
import ApiClient from "../../../api/ApiClient"; 
import EndPoint from "../../../api/Endpoints";
import { DiagramComponent, DataBinding, HierarchicalTree, ImageElement , TextElement, DiagramTools, Inject, DiagramContextMenu, StackPanel, NodeConstraints,AnnotationConstraints } from "@syncfusion/ej2-react-diagrams";
import { ListViewComponent } from '@syncfusion/ej2-react-lists';

import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DialogUtility } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Ajax } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import Loading from "../../../components/customdesign/LoadingPercentage";
import LoadingSimple from "../../../components/customdesign/Loading";
import GenericForm from "../../../components/forms/GenericForm";
import { ToastComponent } from '@syncfusion/ej2-react-notifications'; 
import {
    ProgressBarComponent, 
} from '@syncfusion/ej2-react-progressbar';
let diagramInstance;
let dialogInstanceOrg;
let dialogInstanceOrg2;
let dialogInstanceOrg3;
let toastInstanceOrg
let listParents;
let toolbarObj;
let sourceID;
let targetID;
let sourceDropdown;
let targetDropdown;
let dialogDeleteObj;
let nodeData = [];

let interval = [ 1, 9, 0.25, 9.75, 0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75,0.25,9.75];
let gridlines = {
    lineColor: "#fff",
    /*lineColor: "#e0e0e0",*/
    lineIntervals: interval
};

import { getCookie,prepareForm, find, mergeFormData, clearformData,findValueById } from '../../../utils/Functions';
import { getPalette } from '../../../utils/Utils';
var token=getCookie(process.env.REACT_APP_NAME+'_usertoken')
let wHeight= window.innerHeight
let wWidth= window.innerWidth

class ManageOrgChart extends React.Component{    
   
    constructor(props) {
        super(props);
         
        this.buttons = [{
                click: this.manageItem,
                buttonModel: {
                    content: 'Change',
                    isPrimary: true
                }
            },
            {
                click: ()=> {dialogInstanceOrg2.hide()},
                buttonModel: {
                    content: 'Cancel', 
                }

        }];
        this.state = {
            isExecuting:true ,
            isImporting:false ,
            dataSource : [],
            dataSourceTotal:[],

            contextMenu :{
                show: true, 
                items: [
                        { text: 'Add Departament', iconCss: 'align-top-3', id: 'Add' },
                        { text: 'Add Actor', iconCss: 'align-top-3', id: 'AddActor' },
                        { text: 'Edit', id: 'Edit',  },  
                        { text: 'Change Top Departament', id: 'ChangeParent',  },  
                        { text: 'Delete'      , iconCss: 'align-top-3', id: 'Delete' },
                      
                ],
              showCustomMenuOnly: true,
            },
            formFields:[],
            formFields3:[],
            
            selectedNodeId: 0,
            parentId:-1,
            newParentId:0,
            action:""
        }
    }

    footerDialogTemplate = ()=> {
        return (<div>
       
                    
                    <ButtonComponent                                
                        className={ "ml-10 e-success"}
                        disabled={this.state.isProcessing}
                        onClick={this.manageItem}
                        style={{ textTransform:"uppercase", fontWeight:  "500"  }}
                    > {!this.state.isProcessing?
                          this.props.defConfig[this.props.defLang].savedatalabel
                        :
                         "processing"
                    
                        }
                    </ButtonComponent>
                    <ButtonComponent                                
                        className={ "e-btn ml-10 e-outlined-secondary"}
                        onClick={()=>{ dialogInstanceOrg.hide() }}
                        style={{ textTransform:"uppercase", fontWeight:  "500"  }}
                    >{this.props.defConfig[this.props.defLang].cancellabel}
                    </ButtonComponent>
        
      </div>);
    }

    footerDialogTemplate3 = ()=> {
        return (<div>
       
                    
                    <ButtonComponent                                
                        className={ "ml-10 e-success"}
                        disabled={this.state.isProcessing}
                        onClick={this.manageItem}
                        style={{ textTransform:"uppercase", fontWeight:  "500"  }}
                    > {!this.state.isProcessing?
                          this.props.defConfig[this.props.defLang].savedatalabel
                        :
                         "processing"
                    
                        }
                    </ButtonComponent>
                    <ButtonComponent                                
                        className={ "e-btn ml-10 e-outlined-secondary"}
                        onClick={()=>{ dialogInstanceOrg3.hide() }}
                        style={{ textTransform:"uppercase", fontWeight:  "500"  }}
                    >{this.props.defConfig[this.props.defLang].cancellabel}
                    </ButtonComponent>
        
      </div>);
    }
    componentDidMount = async() => {
        //this.props.togleRequest()
        var token=getCookie(process.env.REACT_APP_NAME+'_usertoken')
        if (token){
            let response = await ApiClient.doPost( EndPoint["getOrganizations"] , { });
            let dataSource = response.result
            let dataSourceTotal = []
    
            for (let i in response.result){
                let key = response.result[i]
                dataSourceTotal.push(key)
            }
    
            let data={
                    code	 : "organizations",
                    defLang	 : "en-us",
                    domainid :	6
            }
            let formFields
            response = await ApiClient.doPost(EndPoint["getDataByCodeLanguage"], data);
            if (response.status) {
                formFields= await prepareForm (response.data.CONFIG)
            }
    
            data={
                code	 : "actors",
                defLang	 : "en-us",
                domainid :	6
            }
            let formFields3
            response = await ApiClient.doPost(EndPoint["getDataByCodeLanguage"], data);
            if (response.status) {
                formFields3= await prepareForm (response.data.CONFIG)
            }
            //console.log(formFields3)
            response = await ApiClient.doPost(EndPoint["getActorsAndOrgs"], { });
            let actors = response.result
            for (let i in response.result){
                let key = {
                    PARENTID       : response.result[i].ORGANIZATIONID,
                    ORGANIZATIONID : "ACT"+response.result[i].ACTORID,
                    CODE           : "",
                    ACTOR          : response.result[i].ACTOR,
                    NAME           : response.result[i].ACTOR
                }
    
                dataSourceTotal.push(key)
            }
 
            
            this.setState({ dataSource: dataSource,dataSourceTotal:dataSourceTotal, isExecuting:false, formFields : formFields, actors:response.result, formFields3:formFields3 });   
        }else{
            this.setState({   isExecuting:false });   
        }
         
    }

    goBack = ()=>{
        this.props.navigate('/manage/organizations');
    }

    contextMenuOpen =(args) => {
        let hiddenId= []
    
        if (diagramInstance.selectedItems.nodes.length ==1){
            
            if (diagramInstance.selectedItems.nodes[0].data.ORGANIZATIONID===0  ){
                hiddenId = ["Delete", "Edit", "ChangeParent",  "AddActor" ]    
            }else{
                hiddenId = ["AddActor" ]    
            }

            if (diagramInstance.selectedItems.nodes[0].data.ACTOR  ){
                hiddenId = ["Add",  "AddActor",  "ChangeParent" ]    
            }

        }else{
            hiddenId = ["Add", "Delete", "Edit", "ChangeParent", "AddActor" ]
        }

        args.hiddenItems = hiddenId;
    }

    onDoubleClick =(args) => {
        
        this.setState({ isProcessing: false})
        let selectedItem, selectedNodeId
        if (diagramInstance.selectedItems.nodes.length > 0) {
            selectedItem = diagramInstance.selectedItems.nodes[0];
            selectedNodeId = selectedItem.data.ORGANIZATIONID
        }
        if (selectedNodeId) {
            if (selectedNodeId!==0){

                if (selectedItem.data.ACTOR){                            
                    dialogInstanceOrg3.header = "Edit Actor";
                    let pos = find ( this.state.actors, selectedNodeId, "ACTORID" )
                    let _formFields3 = this.state.formFields3
                    _formFields3 = clearformData (_formFields3)
                    _formFields3 = mergeFormData (_formFields3, this.state.actors[pos])
                    this.setState({ formFields3:[] });  
                    dialogInstanceOrg3.show();
                    setTimeout( async function() {
                        this.setState({ formFields3 : _formFields3, action:"editOrg", selectedNodeId:selectedNodeId, parentId:0 });    
                    }.bind(this),1)
                    
                }else{
                    dialogInstanceOrg.header = "Edit Organization Item";
                    let pos = find ( this.state.dataSource, selectedNodeId, "ORGANIZATIONID" )
                    let _formFields = this.state.formFields
                    _formFields = clearformData (_formFields)
                    _formFields = mergeFormData (_formFields, this.state.dataSource[pos])
                    this.setState({ formFields:[] });  
                    dialogInstanceOrg.show();
                    setTimeout( async function() {
                        this.setState({ formFields : _formFields, action:"editActor", selectedNodeId:selectedNodeId, parentId:0 });    
                    }.bind(this),1)
                }
                
            }
        }

    }
    contextMenuClick =(args) => {

        this.setState({ isProcessing: false})
        let selectedItem, selectedNodeId
        if (diagramInstance.selectedItems.nodes.length > 0) {
            selectedItem = diagramInstance.selectedItems.nodes[0];
            selectedNodeId = selectedItem.data.ORGANIZATIONID
        }
       
        if (!selectedNodeId && selectedNodeId!=0 ) {
            if (args.item.id ==="Export"){
                download(diagramInstance.saveDiagram());
            }else  if (args.item.id ==="Import"){
                this.setState({ isImporting: true})
                document
                .getElementsByClassName("e-file-select-wrap")[0]
                .querySelector("button")
                .click();
            }else if(args.item.id ==="Save"){
                console.log(diagramInstance)
            }else if(args.item.id ==="AddActor"){
                dialogInstanceOrg3.header = "Add New Actor";                        
                let _formFields3 = this.state.formFields3
                _formFields3 = clearformData (_formFields3)
                this.setState({ formFields3:[] });                  
                dialogInstanceOrg3.show();    
                setTimeout( async function() {
                    this.setState({  formFields3 : _formFields3, action:"addActor" });    
                }.bind(this),1)
            }

            

        }else{
            this.setState({ selectedNodeId : selectedNodeId }); 
    
            switch (args.item.id) {
                case 'Add':
                   
                    dialogInstanceOrg.header = "Add New Organization Item";                        
                    let _formFields = this.state.formFields
                    _formFields = clearformData (_formFields)
                    this.setState({ formFields:[] });  
                    dialogInstanceOrg.show();    
                    setTimeout( async function() {
                        this.setState({ formFields : _formFields, action:"addOrg",selectedNodeId:-1, parentId: selectedNodeId });    
                    }.bind(this),1)
                    
                    break;

                
                case 'Edit':
                    if (selectedNodeId==0){
                        alert("Root is not editable")
                    }else{

                        if (selectedItem.data.ACTOR){                            
                            dialogInstanceOrg3.header = "Edit Actor";
                            let pos = find ( this.state.actors, selectedNodeId.replace("ACT",""), "ACTORID" )
                            let _formFields3 = this.state.formFields3
                            _formFields3 = clearformData (_formFields3)
                            _formFields3 = mergeFormData (_formFields3, this.state.actors[pos])
                            this.setState({ formFields3:[], action:"editActor" });  
                            dialogInstanceOrg3.show();
                            setTimeout( async function() {
                                this.setState({ formFields3 : _formFields3, selectedNodeId:selectedNodeId, parentId:0 });    
                            }.bind(this),1)
                            
                        }else{
                            dialogInstanceOrg.header = "Edit Organization Item";
                            let pos = find ( this.state.dataSource, selectedNodeId, "ORGANIZATIONID" )
                            let _formFields = this.state.formFields
                            _formFields = clearformData (_formFields)
                            _formFields = mergeFormData (_formFields, this.state.dataSource[pos])
                            this.setState({ formFields:[] , action:"editOrg" });  
                            dialogInstanceOrg.show();
                            setTimeout( async function() {
                                this.setState({ formFields : _formFields, selectedNodeId:selectedNodeId, parentId:0 });    
                            }.bind(this),1)
                        }

                    }
                    
                    break;
                case  'ChangeParent':
                    if (selectedNodeId==0){
                        alert("Root is not editable")
                    }else{
                        if (selectedItem.data.ACTOR){                              
                            this.setState({  selectedNodeId:selectedNodeId,newParentId: selectedItem.data.PARENTID, parentId:  selectedItem.data.PARENTID , action:"changeActorParent" });
                            dialogInstanceOrg2.show();    
                        }else{ 
                            this.setState({  selectedNodeId:selectedNodeId,newParentId: selectedItem.data.PARENTID, parentId: selectedItem.data.PARENTID, action:"changeOrgParent" });
                            dialogInstanceOrg2.show();    
                        }
                    }
                    break;
                case 'Delete':
                    if (selectedNodeId==0){
                        alert("You cannot delete root")
                    }else{

                        if (selectedItem.data.ACTOR){ 
                            this.setState({  selectedNodeId:selectedNodeId, parentId:-1, action:"deleteActor" });
                        }else{
                            this.setState({  selectedNodeId:selectedNodeId, parentId:-1, action:"deleteOrg" });
                        }
                        dialogDeleteObj = DialogUtility.confirm({

                            title: ' Delete Item',
                            content: '<p class="mt-2"><span class= "e-icons e-changes-reject" style="float: left;padding-right: 10px;font-size: 25px;display: inline;">'+
                                     '</span>Are you sure you want to permanently delete this file?</p>',
                            okButton: { text: 'YES', click: this.manageItem.bind(this) },
                            cancelButton: { text: 'No', click: ()=>{dialogDeleteObj.hide() } },
                                                  
                            position: { X: 'center', Y: 'center' },
                            width:"280px"
                        });
                    }
            }
        }        
    }

    onChange3 =(event) => {

        this.state.formFields3.map(function(a) {
            let val =""
            if ( a["name"] == event.target.name){
                if (event.target.type){
                    if (event.target.type ==="switch"){
                        if ( a["value"] ===""){
                            val = "YES"
                        }else{
                            val = ""
                        }
                    }
                }else{
                    val = event.target.value
                }

                a["value"] = val
            }
            return a ;
        });

    }
    onChange =(event) => {

        this.state.formFields.map(function(a) {
            let val =""
            if ( a["name"] == event.target.name){
                if (event.target.type){
                    if (event.target.type ==="switch"){
                        if ( a["value"] ===""){
                            val = "YES"
                        }else{
                            val = ""
                        }
                    }
                }else{
                    val = event.target.value
                }

                a["value"] = val
            }
            return a ;
        });

    }

    
    manageItem = async(event) => {
       

        let id = this.state.selectedNodeId
        try{
            if (id.indexOf("ACT")>=0){
                id = parseInt(id.replace("ACT", ""))
            }
        }catch(Ex){

        }
        
        let isValid= true
        
        var parentid = listParents.value
 
        if (isValid){
            this.setState({ isExecuting: true})
            var formData={
                id : id,
                parentid : listParents.value
            }

            let response ={}
            let action=this.state.action
            if (action =="deleteOrg"){
                dialogDeleteObj.hide()
                response = await ApiClient.doPut( EndPoint["deleteOrganizations"] , formData);

            }else if (action =="deleteActor"){
                dialogDeleteObj.hide()
                response = await ApiClient.doPut( EndPoint["deleteActors"] , formData);
               
            }else if (action =="editOrg"){
                
                dialogInstanceOrg.hide()
                this.state.formFields.map(function(a) {
                    formData[a.name] = a.value                
                });              
                response = await ApiClient.doPut( EndPoint["updateOrganizations"] , formData);

            }else if (action =="editActor"){
               
                dialogInstanceOrg3.hide()
                this.state.formFields3.map(function(a) {
                    formData[a.name] = a.value                
                }); 
                response = await ApiClient.doPut( EndPoint["updateActors"] , formData);
            
            }else if (action =="addOrg"){
                
                dialogInstanceOrg.hide()
                this.state.formFields.map(function(a) {
                    formData[a.name] = a.value                
                });
                response = await ApiClient.doPut( EndPoint["setOrganizations"] , formData);

            }else if (action =="addActor"){
               
                dialogInstanceOrg3.hide()
                this.state.formFields3.map(function(a) {
                    formData[a.name] = a.value                
                });
                response = await ApiClient.doPut( EndPoint["setActors"] , formData);

            }else  if (action =="changeActorParent"){
                dialogInstanceOrg2.hide()
                response = await ApiClient.doPut( EndPoint["updateParentActors"] , formData);

            }else if (action =="changeOrgParent"){
                dialogInstanceOrg2.hide()
                response = await ApiClient.doPut( EndPoint["updateParentOrganizations"] , formData);
               
            }
     
            if (response.status){ 
                const dataSource = this.state.dataSource
                const dataSourceTotal = this.state.dataSourceTotal
                const actors = this.state.actors
                let newItem={}
                if (action==="changeActorParent"){

                    let pos = find ( dataSourceTotal, this.state.selectedNodeId, "ORGANIZATIONID" )
                    dataSourceTotal[pos]["PARENTID"] = parentid

                }else if (action==="changeOrgParent"){

                    let pos = find ( dataSourceTotal, this.state.selectedNodeId, "ORGANIZATIONID" )
                    
                    dataSourceTotal[pos]["PARENTID"] = parentid

                }else if (action==="addOrg"){
                    
                    this.state.formFields.map(function(a) {
                        newItem[(a.name).toUpperCase() ] = a.value
                    });    
                    newItem["ORGANIZATIONID"] = response.id
                    newItem["PARENTID"]  =  parseInt(this.state.parentId)
                    dataSource.push(newItem )
                    dataSourceTotal.push(newItem )
            
                }else if (action==="addActor"){
                    

                    this.state.formFields3.map(function(a) {
                        newItem[(a.name).toUpperCase() ] = a.value
                    });    
    
                    newItem["ORGANIZATIONID"] = response.id
                    newItem["PARENTID"]  =  parseInt(this.state.parentId)
                    newItem["CODE"]  =  newItem.ACTOR
                    newItem["NAME"]  =  ""                    
                    let newActor= 
                        {   ACTORID: response.id,
                            ACTOR: newItem.ACTOR,                            
                            DESCRIPTION: newItem.DESCRIPTION,
                            ORGANIZATIONID: parseInt(this.state.parentId),                          
                            STATUSID: parseInt(newItem.STATUSID)
                        }

                    this.state.dataSourceTotal.push(newItem )
                    
                    actors.push (newActor)
                }else if (action==="editActor"){
 
                    let pos = find ( dataSourceTotal, this.state.selectedNodeId, "ORGANIZATIONID" )
                    let pos2 = find ( actors, id, "ACTORID" )

                    this.state.formFields3.map(function(a) {
                        newItem[(a.name).toUpperCase() ] = a.value
                    });   
                    
                    var fields = Object.keys(dataSourceTotal[pos])
                    var fieldsAc = Object.keys(actors[pos2])
 
                    dataSourceTotal[pos]["PARENTID"] = newItem.ORGANIZATIONID
                    dataSourceTotal[pos]["ORGANIZATIONID"] = this.state.selectedNodeId
                    dataSourceTotal[pos]["CODE"] =  newItem.ACTOR
                    dataSourceTotal[pos]["ACTOR"] = newItem.ACTOR
                    dataSourceTotal[pos]["NAME"] = ""
                   
					fieldsAc.map(function (key) {
                        if (newItem[key] ){  
                            actors[pos2][key] =  newItem[key] 
                        }
					}); 
                    

                }else if (action==="editOrg"){
 
                    let posT = find ( dataSourceTotal, this.state.selectedNodeId, "ORGANIZATIONID" )
                    let pos = find ( dataSource, this.state.selectedNodeId, "ORGANIZATIONID" )

                    this.state.formFields.map(function(a) {
                        newItem[(a.name).toUpperCase() ] = a.value
                    });   

                    var fields = Object.keys(dataSource[pos])
                    var fieldsT = Object.keys(dataSourceTotal[posT])
                   
                    
					fieldsT.map(function (key) {
                        if (newItem[key] && key!=="PARENTID" ){  
                            dataSourceTotal[posT][key] =  newItem[key] 
                        }
					});        
                    
					fields.map(function (key) {
                        if (newItem[key] && key!=="PARENTID" ){  
                            dataSource[pos][key] =  newItem[key] 
                        }
					});    

                }else if (action==="deleteOrg"){

                    let pos = find ( dataSource, this.state.selectedNodeId, "ORGANIZATIONID" )
                    let pos2 = find ( dataSourceTotal, this.state.selectedNodeId, "ORGANIZATIONID" )
                    let newParentId =  dataSourceTotal[pos2]["PARENTID"]

                    dataSourceTotal.map(function (key) {
                        if (key["PARENTID"] == dataSourceTotal[pos]["ORGANIZATIONID"] ){  
                            key["PARENTID"] = newParentId
                        }
					}); 
                    dataSource.splice(pos, 1)
                    dataSourceTotal.splice(pos2, 1)                    

                }else if (action==="deleteActor"){

                    let pos = find ( dataSourceTotal, this.state.selectedNodeId, "ORGANIZATIONID" )
                    let pos2 = find ( actors, this.state.selectedNodeId, "ACTORID" )
                    
                    dataSourceTotal.splice(pos, 1)
                    actors.splice(pos2, 1)                    

                }
                this.setState({ dataSource: dataSource, isExecuting:false})

                setTimeout(function () {
                    toastInstanceOrg.show({
                        content: "Organigram Item "+action,  cssClass: 'e-toast-success', icon: 'e-success toast-icons' 
                    });                     
                    
                }.bind(this), 200);
                
            }else{
                this.setState({ isExecuting:false})
                setTimeout(function () {
                    toastInstanceOrg.show({
                        content: "Organigram Chart Error",  cssClass: 'e-toast-error', icon: 'e-error toast-icons' 
                    });                     
                }.bind(this), 200);
            }
        }
    }

    setNodeTemplate = (obj, diagram) => {
        
        let fill =  getPalette().primary
        let color = getPalette().primary
        let strokeColor =  getPalette().primary
        let background= '#fff'

        if (obj.data.ORGANIZATIONID == 0){
            fill =  getPalette().primary
            color =  '#fff'
            strokeColor =  getPalette().primary
            background =getPalette().primary
        }

        if (obj.data.ACTOR){
          //  fill =  '#fff'
            color =  '#000'
            background= '#fff'
            strokeColor =  '#000'
        }
        
        
        let content = new StackPanel();
        content.id = obj.id + "_outerstack";
        content.orientation = "Horizontal";
        content.style.strokeColor = strokeColor;
        content.style.fill = background;


        if (obj.data.ACTOR){
            content.style.strokeWidth= 2;
            content.style.strokeDashArray= '2 2';
        }

        content.padding = { left: 5, right: 10, top: 5, bottom: 5 };
        let image = new ImageElement();
       
        image.style.strokeColor = "none";
        if (obj.data.ORGANIZATIONID == 0){
            image.source = "/images/root.png";
            image.width = 0;
            image.height = 0;

        }else{
            if (obj.data.ACTOR){
                image.source = "/images/actor.svg";
                image.width = 35;
                image.height = 30;
            }else{
                image.source = "/images/department.svg";
                image.width = 30;
                image.height = 30;
            }
            
        }
        
        image.id = obj.id + "_pic";
        let innerStack = new StackPanel();
        innerStack.style.strokeColor = "none";        
        //innerStack.style.padding = 10;      
        innerStack.style.fill = background;
        innerStack.margin = { left: 5, right: 0, top: 0, bottom: 0 };
        innerStack.id = obj.id + "_innerstack";
        let text = new TextElement();
        text.content = obj.data.CODE.toUpperCase();
        text.style.color = color;
        text.style.bold = true;
        text.style.strokeColor = "none";
        text.style.fill = "none";
        text.id = obj.id + "_text1";
        if (obj.data.ORGANIZATIONID == 0){
            text.style.fontSize = 20;
        }else{
            if (obj.data.ACTOR){
                text.style.fontSize = 1;
            }else{
                text.style.fontSize = 16;
            }    
            
        }
        let desigText = new TextElement();
        desigText.margin = { left: 0, right: 0, top: 5, bottom: 0 };
        if (obj.data.ORGANIZATIONID == 0){
            desigText.content = "";            
        }else{
            desigText.content = obj.data.NAME;
        }
            

        desigText.style.color =fill;
        desigText.style.strokeColor = "none";
        desigText.style.fill = "none";
        desigText.style.textWrapping = "Wrap";
        desigText.style.fontFamily = "NunitoSans";
        if (obj.data.ORGANIZATIONID == 0){
            desigText.style.fontSize = 1;
        }else{

            desigText.style.fontSize = 14;
            
        }

        
        desigText.id = obj.id + "_desig";
        innerStack.children = [text, desigText];
        content.children = [image, innerStack];
        return content;
    }
   
    render() {
        return (<div>
      <div className='control-section'>

        <ToastComponent ref={(toast) => { toastInstanceOrg = toast; }}  position={{X: 'Right', Y: 'Bottom'}} showCloseButton={true}  ></ToastComponent>
        <div style={{ /*width: '100%'*/ }}>
            {this.state.isExecuting && <Loading/>}
            {this.state.isImporting && <LoadingSimple/>}
            
            <ToolbarComponent id='toolbar_diagram' ref={toolbar => (toolbarObj = toolbar)} items={[
                {
                    tooltipText: 'Add',
                    prefixIcon: 'e-ddb-crudicons e-add',
                    id: 'Add',
                    text: 'Add Departament'
                },
                {
                    tooltipText: 'Add Actor',
                    prefixIcon: 'e-ddb-crudicons e-add',
                    id: 'AddActor',
                    text: 'Add Actor'
                },
                {
                    type: 'Separator'
                },
                {
                    tooltipText: 'Edit',
                    prefixIcon: 'e-ddb-crudicons e-update',
                    id: 'Edit',
                    text: 'Edit'
                },
                {
                    type: 'Separator'
                },
                {
                    tooltipText: 'Delete',
                    prefixIcon: 'e-ddb-crudicons e-delete',
                    id: 'Delete',
                    text: 'Delete'
                },
                {
                    type: 'Separator'
                },
                {
                    text: 'Change Top Departament',
                    tooltipText: 'ChangeParent',
                    prefixIcon: 'e-reset',
                    id: 'ChangeParent'
                },
                { type: "Separator" },
                {
                    text: "Save All",
                    id: "Save",
                    tooltipText: "Save All",
                    prefixIcon: "e-save"
                },
                { type: "Separator" },
                {
                    text: "Export",
                    id: "Export",
                    tooltipText: "Export",
                    prefixIcon: "e-diagram-icons e-diagram-export"
                },                
                {
                    text: "Import",
                    id: "Import",
                    tooltipText: "Import",
                    prefixIcon: "e-diagram-icons e-diagram-open"
                },

            ]} clicked={this.contextMenuClick}  />
        </div>
        <div style={{ /*width: '100%', */borderWidth: '0 1px 1px 1px', borderStyle: 'solid', borderColor: '#fff' ,  overflow: "hidden"  }}>

            <div id='diagram-space'  >
                <div className="row border-bottom-style-0" >
                    {this.state.dataSourceTotal.length>0  && !this.state.isExecuting?
                        <DiagramComponent id="container" 
                            width={  wWidth-  ( wWidth<1000? 0:320)} 
                            height={wHeight-220} style={{  overflow: "hidden" }}
                            //Configures data source
                            ref={diagram => (diagramInstance = diagram)} 
                            snapSettings={{
                                horizontalGridlines: gridlines,
                                verticalGridlines: gridlines
                            }}                            
                            contextMenuSettings={this.state.contextMenu} 
                            contextMenuOpen={this.contextMenuOpen} 
                            contextMenuClick={this.contextMenuClick} 
                            doubleClick={this.onDoubleClick.bind(this) }
                            dataSourceSettings={{
                                
                                    id: 'ORGANIZATIONID',
                                    parentId: 'PARENTID',
                                    dataManager: new DataManager(this.state.dataSourceTotal),
                                }} 
                            layout={{
                                    type: 'OrganizationalChart',
                                    horizontalSpacing: 15,
                                    verticalSpacing: 50,
                                    margin: {
                                        top: 10,
                                        left: 10,
                                        right: 10,
                                        bottom: 10,
                                    },
                                }}                          
                            getConnectorDefaults={(connector, diagram) => {
                                    connector.type = 'Orthogonal';
                                    connector.style.strokeColor = '#4d4d4d';
                                    connector.targetDecorator.shape = 'None';
                                    connector.constraints= NodeConstraints.Default & ~NodeConstraints.Rotate & ~NodeConstraints.Drag & ~NodeConstraints.Resize;
                                }} 
                            //Disables all interactions except zoom/pan
                            tool={ [DiagramTools.ZoomPan, DiagramTools.SingleSelect ] } 
                            setNodeTemplate={(obj, diagram) => {
                                return this.setNodeTemplate(obj, diagram);
                            }}
                        >
                            <Inject services={[DataBinding, DiagramContextMenu, HierarchicalTree]}/>
                        </DiagramComponent>

                    :
                        <div style={{height:wHeight-217}}>
                                        
                        </div>
                    }
                     <div style={{display:"none"}}>
                        <UploaderComponent type="file" id="fileupload" asyncSettings={{
                            saveUrl: "https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save",
                            removeUrl: "https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove"
                        }} success={onUploadSuccess.bind(this)}/>
                    </div>
                </div>

                <div className="row" style={{height: 47}} >
                    <div className={"col-xs-12 no-padding text-right" }>
                        <ButtonComponent                                
                            className={ "e-btn e-outlined-secondary"}
                            onClick={()=>{ this.goBack() }}
                            iconCss='e-icons e-back'
                            style={{ fontWeight:  "500" ,position: "relative", top: 8, marginRight:3}}
                        >{this.props.defConfig[this.props.defLang].backlabel}
                        </ButtonComponent>
                       
                    </div>
                </div>

         </div>
    </div>
        </div>
       
        <DialogComponent id='editDialog'  footerTemplate={this.footerDialogTemplate.bind(this)} width={"50%"} ref={dialog => (dialogInstanceOrg = dialog)} visible={false} isModal={true} showCloseIcon={true} >
            <div className='mb-2'>
            {this.state.formFields.length>0 &&
                <GenericForm 
                    data={this.state.formFields.length>0?this.state.formFields:[]} 
                    onChange={this.onChange}
                    buttons={
                     []}
                /> 
            }
            </div>
            
        </DialogComponent>

        <DialogComponent id='editDialog2' header="Change Top Department"  buttons={this.buttons} width={"25%"}  cssClass="no-border-dialog" 
        ref={dialog => (dialogInstanceOrg2 = dialog)} visible={false} isModal={true} showCloseIcon={true} >
            <div  >

            {this.state.dataSource.length>0  && !this.state.isExecuting?
          
            <ComboBoxComponent ref={(scope) => { listParents= scope; }} 
					ignoreAccent={true} allowFiltering={true}
					name={"parents"} 
					cssClass={"e-custom-combobox"}
					floatLabelType="auto"
					value={this.state.parentId}
					fields={{ text: 'NAME', value: 'ORGANIZATIONID' }}
				 	dataSource={this.state.dataSource} placeholder={""}/>		
              
                            
            :""}
            </div>
            
        </DialogComponent>

        <DialogComponent id='editDialog3'  footerTemplate={this.footerDialogTemplate3.bind(this)} width={"50%"}  ref={dialog => (dialogInstanceOrg3 = dialog)} visible={false} isModal={true} showCloseIcon={true} >
            <div className='mb-2'>
            {this.state.formFields3.length>0 &&
                <GenericForm 
                    data={this.state.formFields3.length>0?this.state.formFields3:[]} 
                    onChange={this.onChange3}
                    buttons={
                     []}
                /> 
            }
            </div>
            
        </DialogComponent>

      </div>);
    }
}

function onUploadSuccess(args) {

    

    let file1 = args.file;
    let file = file1.rawFile;
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = loadDiagram;
    
    const dataSource = this.state.dataSource
    setTimeout( async function() {

       
        let connectors=[]
        for(let i in diagramInstance.connectors){
            connectors.push( { sourceID: diagramInstance.connectors[i].sourceID, targetID: diagramInstance.connectors[i].targetID } )
        }
 
        let templateNodes=[]
        for(let i in diagramInstance.nodes){
            let node=diagramInstance.nodes[i]
            let _data = node.annotations[0].content.split(":")

            let pos = find ( dataSource, _data[0], "CODE" )
            let id = -1
            if (pos>=0){
                id = dataSource[pos].ORGANIZATIONID
            }
            templateNodes.push({
                templateId: node.id,
                code : _data[0],
                name : _data[1],
                id   : id
            } )
        } 

        for(let i in  dataSource){
            let node= dataSource[i]             
            let pos = find (templateNodes,  node.CODE, "code")

            if (pos<0){ 
                dataSource.splice(i,1)
                let pos2 = find (templateNodes,  node.ORGANIZATIONID, "id")
                var formData={
                    id : node.ORGANIZATIONID,
                }
                response = await ApiClient.doPut( EndPoint["deleteOrganizations"] , formData);
                 
                if (pos2>=0){ 
                    templateNodes.splice(pos2, 1)
                }
                
            } 
        }

        for(let i in diagramInstance.nodes){
            let node=diagramInstance.nodes[i]
  
            let _data = node.annotations[0].content.split(":")
            let code = _data[0] 
            let pos = find ( dataSource, code, "CODE" )

            if (pos<0){

                let parent = findValueById( connectors,node.id, "targetID", "sourceID" )     
                let parentId =  findValueById( templateNodes,parent, "templateId", "id" )     
                let formData = { 
                    parentid: parentId, 
                    name: _data[1], 
                    code: _data[0], 
                    statusid: "4", 
                    token: "", 
                    email: "", 
                    contactfirstname: "", 
                    contactlastname: "", 
                    imagepath: "",
                    description:""
                } 
                  var  response  =  await ApiClient.doPut( EndPoint["setOrganizations"] , formData);

                  if (response.status){
                    dataSource.push( {
                       
                            ADDRESS: "",
                            CODE: _data[0],
                            CONTACTEMAIL: "",
                            CONTACTFIRSTNAME: "",
                            CONTACTLASTNAME: "",
                            DESCRIPTION: "",
                            EMAIL: "",
                            IMAGEPATH: "",
                            LATITUDE: null,
                            LONGITUDE: null,
                            NAME: _data[1],
                            ORGANIZATIONID:  response.id,
                            PARENTID: parentId,
                            STATUS: "ENABLED",
                            STATUSID: 4,
                            TOKEN : ""
                    })
                    let pos = find (templateNodes,   _data[0], "code")
                    templateNodes[pos].id = response.id
                  }
                 
                
            } 
        }
        this.setState({ isImporting: false})
    }.bind(this),500)
   

}
//Load the diagraming object.
function loadDiagram(event) {

    diagramInstance.loadDiagram(event.target.result);
}

function download(data) {
    if (window.navigator.msSaveBlob) {
        var blob = new Blob([data], { type: "data:text/json;charset=utf-8," });
        window.navigator.msSaveOrOpenBlob(blob, "Diagram.json");
    }
    else {
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(data);
        var a = document.createElement("a");
        a.href = dataStr;
        a.download = "Diagram.json";
        document.body.appendChild(a);
        a.click();
        a.remove();
    }
}


export default ManageOrgChart;