import * as React from 'react';
import Typography from "../customdesign/Typography";
import { useNavigate, useLocation } from "react-router-dom";
const Congratulations = (props) => {
	const navigate = useNavigate();
	  
	return (
		<div className="row flex-center">
			<div className=" col-xs-12 col-sm-12  col-md-12 col-lg-8 col-xl-7 flex-center ">
				<div className={"banner-finish-wrapper wow fadeIn animated hover-up"}>
					<div className="banner-congratulation text-center">
						{/*<img className="logo-mini" src="/images/logo_mini.png"/> */}
						<div className="congratulation-header">
							<span className="e-icons e-circle-check" style={{color:"#fff", fontSize:"7rem"}}> </span>
						</div>
						<Typography variant={"h1"} style={{color:"#fff"}}>
							Congratulations!
						</Typography>						
												
					</div>
					<div className="banner-finish">
						<Typography variant={"h4"} mt={2} mb={2}>
							Your account has been Successfully created. Please check your mailbox, you are going to receive an email with your login info.
						</Typography>
						<div className="text-center mt-50">
							<button onClick={()=> { navigate("/login") }} style={{minWidth:120}} className="e-btn e-primary">
								Return to login
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Congratulations.propTypes = {};

export default Congratulations;

