import React from "react";
   
const Typography = (props) => {
  
		return (
	      <div className={
	      	(props.variant) 
	      	+ " "+ (props.mt ? "mt-"+props.mt :"" ) 
	      	+ " "+ (props.mb ? "mb-"+props.mb :"") 
			+ " "+ (props.ml ? "ml-"+props.ml :"") 
	      	+ " "+ (props.pl ? "pl-"+props.pl :"") 
			+ " "+ (props.pl ? "ml-"+props.ml :"") 

			  + " "+ (props.color ? props.color+"-color":"") 
	      	
	      	} style={props.style}>
	        {props.children}			
	      </div>
	  );
	 
};
export default Typography;
