import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import { ListViewComponent } from '@syncfusion/ej2-react-lists';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";

import { simplePostData} from '../../utils/Functions';
import ApiClient from "../../api/ApiClient";
var listList
var listDialogInstance;

const DialogInitFlowTemplate = (props) => {

  
  
  const header = props.itemType=="actors"? "Set Actors": props.itemType=="offices"?"Set Offices": "Set Users"

  const { defConfig,  defLang, } = useSelector((state) => state.Login);
  const { componentConfig, processSuccess,  processError, requesting, processMessage, initialValues, validationSchema, rows} = useSelector( (state) => state.Process );
 
  const dispatch = useDispatch();   

  const [list,setList] = React.useState([]); 
  
  const [fields,setFields] = React.useState({}); 

  React.useEffect(() => {
    
    //console.log("DDD", props, rows)
    if (props.status && props.id>=0){
            
      if (rows.length>0){
        console.log("rows:", rows, props.itemType)
        setList(rows)
        
        if (props.itemType=="actors"){
          let _fields = { value: 'ACTORID', id: 'ACTORID', text: 'ACTOR' };
          setFields(_fields)
        }else if (props.itemType=="users"){
          let _fields = { value: 'USERID', id: 'USERID', text: 'USERNAME' };
          setFields(_fields) 
        }else if (props.itemType=="usersO"){
          let _fields = { value: 'USERID', id: 'USERID', text: 'USERNAME' };
          setFields(_fields) 
        }else if (props.itemType=="offices"){
          let _fields = { value: 'OFFICEID', id: 'OFFICEID', text: 'OFFICE' };
          setFields(_fields)
        }
      }  
    }
    
  }, [props, rows]);
  
    

  const submitForm = async (event) => {
    
    console.log("values",  listList.getSelectedItems().data)
    
    let selectedItems=[]
    let apiName="set-actors-users"
    
    listList.getSelectedItems().data.map(function (key) {
      if (props.itemType=="actors"){
        selectedItems.push( key.ACTORID)
      }else if (props.itemType=="users"){
        selectedItems.push( key.USERID)
      }else if (props.itemType=="offices"){
        apiName="set-offices-users"
        selectedItems.push( key.OFFICEID)
      }else if (props.itemType=="usersO"){
        apiName="set-offices-users"
        selectedItems.push( key.USERID)
      }
    }); 

    
      dispatch(ProcessActions.processData( props.componentName,apiName,{ id: props.id, type: props.itemType,  items: selectedItems } , props.header ) )

    //props.submitForm(values)
    listDialogInstance.hide()
  };

  
 
  return (
    
    <DialogComponent
			id="listDialogInstance"
			showCloseIcon={true}
			closeOnEscape={false}
      animationSettings={props.animationSettings}
			ref={(dialog) => (listDialogInstance = dialog)}
			isModal={true}
			open={props.open} 
      close={props.close}
			header={header}
      buttons={
      [
        {
            click: () => {
              submitForm();
            },
            buttonModel: {
                content: 'Save',
                isPrimary: true,
            },
        },
        {
            click: () => {
                //props.close();
                listDialogInstance.hide();
            },
            buttonModel: {
                content: 'Cancel',
                isSecondary: true,
            },
        },
      ]}
			
			height="100%"
			className="partial-form"
			position={ { X: 'right', Y: 'top' }}
			visible={props.status} 
		>               
    
    <div className='control-pane'>
      <div className='control-section'>
        <h4>Please select items to assign  to the {props.itemType==="usersO"?"users":props.itemType }</h4>
        <div className='col-12 mt-10'>
          {!requesting &&
            <ListViewComponent id="view-list-flat" 	ref={(dialog) => (listList = dialog)} fields={fields} dataSource={list} showCheckBox={true}></ListViewComponent>
          }
        </div>
         
      </div>
    </div>
    </DialogComponent>
  );
};
DialogInitFlowTemplate.propTypes = {};

export default DialogInitFlowTemplate;
