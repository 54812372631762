import React, { useState } from "react";
import PropTypes from "prop-types";
  
import Utils from "../../utils/Utils";
import { useSelector, useDispatch } from "react-redux";

//import Mapbox from "../../components/mapbox/Mapbox";
const Home = (props) => {
	const { defConfig, params, defLang } = useSelector((state) => state.Login);
	  
  return (
     <>Hello!</>
  );
};

Home.propTypes = {};

export default Home;
