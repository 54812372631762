import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
 	ButtonComponent,
  } from "@syncfusion/ej2-react-buttons";


import Table from "../../components/table/table";
import Typography from "../../components/customdesign/Typography"; 
import * as ProcessActions from "../../redux/actions/process.actions";
 
  
const ListData = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { defConfig, params, defLang, activeForm, menu } = useSelector((state) => state.Login);
	const { componentConfig } = useSelector((state) => state.Process);
	
	const [title,setTitle]    = React.useState("" )
 
	let  { componentName } = useParams();	
 	React.useEffect(()=>{

		dispatch(ProcessActions.getComponentConfig(componentName, defLang))	

		menu.map(function (key) {
			 
			if(key.code==componentName){
				setTitle(key.title)
			}else{
				if (key.items){
				  key.items.map(function (key2) {
					
					if(key2.code==componentName){
						setTitle( key.title +" > "+  key2.title)
					}else{
						if (key2.items){						
							key2.items.map(function (key3) {
								if(key3.code==componentName){
									setTitle( key.title +" > "+  key2.title +" > "+  key3.title)
								}
							}); 
						}
					}

	  
				  }); 
				}
			}
		}); 

  },[menu])
  
  return (
	
	<div className="row">
    	{componentConfig.columns &&
    		<>
				{componentConfig.name &&
					<Typography variant="h3" mb={"2"} mt={"1"}   style={{ textTransform: "capitalize", display:"flex" }} >
						<span className={"e-"+componentName }>   </span> 

						<span className="e-component-title"> {componentConfig.title}</span>
					</Typography>
				}
				<div className="col-xs-12 no-padding">
					<Table componentName={componentName}   />						
				</div>
			</>
    	}
   	</div>
  );
}

ListData.propTypes = {
//  window: ListData.func,
};

export default ListData;
