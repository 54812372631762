import * as React from "react";
import { Link, redirect, Navigate } from "react-router-dom";
import "./ManageDiagram.css";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DiagramComponent,
  Diagram,
  SymbolPaletteComponent,
  BpmnDiagrams,
  DataBinding,
  NodeConstraints,
  PortConstraints,
  SnapConstraints,
  PortVisibility,
  Inject,
  UndoRedo,
  DiagramContextMenu,
  SelectorConstraints,
  ConnectorBridging,
  DiagramConstraints,
  LineRouting,
  ConnectorConstraints,
  NodeConstraint,
  randomId,
  cloneObject,
  AnnotationConstraints,
} from "@syncfusion/ej2-react-diagrams";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import ApiClient from "../../../api/ApiClient";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import Loading from "../../../components/customdesign/LoadingPercentage";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  findValueById,
  prepareForm,
  getCookie,
  find,
} from "../../../utils/Functions";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
import DialogTemplates from "../../../components/dialog/DialogTemplates";
import {
  ToolbarComponent,
  MenuComponent,
  ItemsDirective,
  ItemDirective,
} from "@syncfusion/ej2-react-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import LoadingSimple from "../../../components/customdesign/Loading";
import { colGroup, columnSelectionComplete } from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import GenericForm from "../../../components/forms/GenericForm";
import { getPalette } from "../../../utils/Utils";

let wHeight = window.innerHeight;
let toastInstanceDiagram;

/*
let dialogInstance;
let actorDialogInstance
let taskDialogInstance
*/
let transitionType;
let dialogInstanceFlow;
let optionValue;
let inputValue;
let outputValue;
let transitionValue;
let taskListInstance;
let flowVarListInstance;
let dialogInstanceCaution;
let dialogInstanceNew;
let actors = [];
let tasktypes = [];
let tasks = [];
let taskType;
let actorListInstance;
let nodesClear;
let dialogObj;

let flowVersionsListInstance;
let animationSettings = { effect: "Zoom" };
let animationSettings2 = { effect: "SlideLeft" };
let tmpArgs = {};
let diagram;
var actorName = "";

let darkColor = "#C7D4DF";
let lightColor = "#f5f5f5";
let pathData =
  "M 120 24.9999 C 120 38.8072 109.642 50 96.8653 50 L 23.135" +
  " 50 C 10.3578 50 0 38.8072 0 24.9999 L 0 24.9999 C" +
  "0 11.1928 10.3578 0 23.135 0 L 96.8653 0 C 109.642 0 120 11.1928 120 24.9999 Z";
let middle = 0;
let port = [
  {
    id: "Port1",
    offset: { x: 0, y: 0.5 },
    visibility: PortVisibility.Connect | PortVisibility.Hover,
    constraints: PortConstraints.Default | PortConstraints.Draw,
  },
  {
    id: "Port2",
    offset: { x: 0.5, y: 0 },
    visibility: PortVisibility.Connect | PortVisibility.Hover,
    constraints: PortConstraints.Default | PortConstraints.Draw,
  },
  {
    id: "Port3",
    offset: { x: 1, y: 0.5 },
    visibility: PortVisibility.Connect | PortVisibility.Hover,
    constraints: PortConstraints.Default | PortConstraints.Draw,
  },
  {
    id: "Port4",
    offset: { x: 0.5, y: 1 },
    visibility: PortVisibility.Connect | PortVisibility.Hover,
    constraints: PortConstraints.Default | PortConstraints.Draw,
  },
];
const SAMPLE_CSS = `.sb-mobile-palette {
    width: 20%;
    /*margin-top:10px;
    padding-left:10px;*/
    border:0px;
            /*height: 242px;*/
            float: left;

}
/*
#palette-space {
  border: 1px solid rgba(0, 0, 0, 0.12);
}*/
`;

let handles = [
  {
    name: "Clone",
    pathData:
      "M0,2.4879999 L0.986,2.4879999 0.986,9.0139999 6.9950027,9.0139999 6.9950027,10 0.986,10 C0.70400238,10 0.47000122,9.9060001 0.28100207,9.7180004 0.09400177,9.5300007 0,9.2959995 0,9.0139999 z M3.0050011,0 L9.0140038,0 C9.2960014,0 9.5300026,0.093999863 9.7190018,0.28199956 9.906002,0.47000027 10,0.70399952 10,0.986 L10,6.9949989 C10,7.2770004 9.906002,7.5160007 9.7190018,7.7110004 9.5300026,7.9069996 9.2960014,8.0049992 9.0140038,8.0049992 L3.0050011,8.0049992 C2.7070007,8.0049992 2.4650002,7.9069996 2.2770004,7.7110004 2.0890007,7.5160007 1.9950027,7.2770004 1.9950027,6.9949989 L1.9950027,0.986 C1.9950027,0.70399952 2.0890007,0.47000027 2.2770004,0.28199956 2.4650002,0.093999863 2.7070007,0 3.0050011,0 z",
    visible: true,
    offset: 1,
    side: "Bottom",
    margin: { top: 0, bottom: 0, left: 0, right: 0 },
  },
  {
    name: "Delete",
    pathData:
      "M0.54700077,2.2130003 L7.2129992,2.2130003 7.2129992,8.8800011 C7.2129992,9.1920013 7.1049975,9.4570007 6.8879985,9.6739998 6.6709994,9.8910007 6.406,10 6.0939997,10 L1.6659999,10 C1.3539997,10 1.0890004,9.8910007 0.87200136,9.6739998 0.65500242,9.4570007 0.54700071,9.1920013 0.54700077,8.8800011 z M2.4999992,0 L5.2600006,0 5.8329986,0.54600048 7.7599996,0.54600048 7.7599996,1.6660004 0,1.6660004 0,0.54600048 1.9270014,0.54600048 z",
    visible: true,
    offset: 0,
    side: "Bottom",
    margin: { top: 0, bottom: 0, left: 0, right: 0 },
  },
  {
    name: "Draw",
    pathData:
      "M3.9730001,0 L8.9730001,5.0000007 3.9730001,10.000001 3.9730001,7.0090005 0,7.0090005 0,2.9910006 3.9730001,2.9910006 z",
    visible: true,
    offset: 0.5,
    side: "Right",
    margin: { top: 0, bottom: 0, left: 0, right: 0 },
  },
];

//Initializes the connectors for the diagram.

//Initialize the palettes for the symbol palatte

let swimlaneShapes = [
  {
    id: "stackCanvas1",
    addInfo: { tooltip: "Horizontal Flow" },
    shape: {
      type: "SwimLane",
      lanes: [
        {
          id: "lane1",
          style: { strokeColor: "#757575" },
          height: 60,
          width: 150,
          header: {
            width: 50,
            height: 50,
            style: { strokeColor: "#757575", fontSize: 11 },
            annotation: { content: "MAIN" },
            width: 50,
            annotations: [
              {
                content: "",
              },
            ],
          },
        },
      ],
      orientation: "Horizontal",
      isLane: true,
    },
    height: 60,
    width: 140,
    offsetX: 70,
    offsetY: 30,
  },
  {
    id: "stackCanvas2",
    addInfo: { tooltip: "Vertical Flow" },
    shape: {
      type: "SwimLane",
      lanes: [
        {
          id: "lane1",
          style: { strokeColor: "#757575" },
          height: 150,
          width: 60,
          header: {
            width: 50,
            height: 50,
            style: { strokeColor: "#757575", fontSize: 11 },
            annotation: { content: "MAIN" },
            width: 50,
          },
        },
      ],
      orientation: "Vertical",
      isLane: true,
    },
    height: 140,
    width: 60,
    // style: { fill: '#f5f5f5' },
    offsetX: 70,
    offsetY: 30,
  },

  {
    id: "verticalPhase",
    addInfo: { tooltip: "Vertical phase" },
    shape: {
      type: "SwimLane",
      phases: [
        {
          style: {
            strokeWidth: 1,
            strokeDashArray: "3,3",
            strokeColor: "#757575",
          },
        },
      ],
      annotations: [{ text: "" }],
      orientation: "Vertical",
      isPhase: true,
    },
    height: 60,
    width: 140,
    style: { strokeColor: "#757575" },
  },
  {
    id: "horizontalPhase",
    addInfo: { tooltip: "Horizontal phase" },
    shape: {
      type: "SwimLane",
      phases: [
        {
          style: {
            strokeWidth: 1,
            strokeDashArray: "3,3",
            strokeColor: "#757575",
          },
        },
      ],
      annotations: [{ text: "" }],
      orientation: "Horizontal",
      isPhase: true,
    },
    height: 60,
    width: 140,
    style: { strokeColor: "#757575" },
  },
];

let bpmnShapes = [
  {
    id: "Start",
    addInfo: { tooltip: "Start" },
    width: 50,
    height: 50,
    shape: {
      type: "Bpmn",
      shape: "Event",
      event: { event: "Start" },
    },
    style: { fill: getPalette().primary },
    ports: [
      {
        id: "port",
        offset: { x: 0.96, y: 0.5 },
        visibility: PortVisibility.Visible,
        style: { fill: getPalette().primary },
        constraints: PortConstraints.Default | PortConstraints.Draw,
      },
    ],
    symbolInfo: { description: { text: "Start" } },
    annotations: [
      {
        content: "START",
        style: { color: "#fff" },
        constraints: AnnotationConstraints.ReadOnly,
      },
    ],
  },
  {
    id: "End",
    addInfo: { tooltip: "End" },
    width: 50,
    height: 50,
    /*offsetX: 665, offsetY: 230, */ shape: {
      type: "Bpmn",
      shape: "Event",
      event: { event: "End", trigger: "Termination" },
    },

    style: { fill: getPalette().primary },
    symbolInfo: { description: { text: "End" } },
    annotations: [
      {
        content: "END",
        style: {
          color: getPalette().primary,
          backgroundColor: "#c62828",
          border: "2px solid " + getPalette().primary,
        },
        constraints: AnnotationConstraints.ReadOnly,
      },
    ],
  },
  {
    id: "Task",
    addInfo: { tooltip: "Activity" },
    width: 185,
    height: 70,
    offsetX: 700,
    offsetY: 700,
    shape: {
      type: "Bpmn",
      shape: "Activity",
      activity: {
        activity: "Task",
        task: { type: "Task" },
      },
    },
    style: {
      strokeWidth: 2,
      strokeColor: getPalette().primary,
      fill: getPalette().manualTask,
    },
    symbolInfo: { description: { text: "Task" } },
    ports: [
      {
        id: "port",
        offset: { x: 0.96, y: 0.5 },
        visibility: PortVisibility.Visible,
        style: { fill: getPalette().primary },
        constraints: PortConstraints.Default | PortConstraints.Draw,
      },
    ],
    annotations: [
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
        style: {
          bold: true,
          fontSize: 16,
        },
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
        offset: { x: 0.02, y: 0.95 },
        verticalAlignment: "Bottom",
        horizontalAlignment: "Left",
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
        offset: { x: 0.9, y: 0.95 },
        verticalAlignment: "Bottom",
        horizontalAlignment: "Center",
      },
    ],
  },
  {
    id: "TaskService",
    addInfo: { tooltip: "Automatic" },
    width: 185,
    height: 70,
    offsetX: 700,
    offsetY: 700,
    shape: {
      type: "Bpmn",
      shape: "Activity",
      activity: {
        activity: "Task",
        task: { type: "Automatic" },
      },
    },
    symbolInfo: { description: { text: "Automatic" } },
    annotations: [
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
        style: {
          bold: true,
          fontSize: 16,
        },
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
        offset: { x: 0.02, y: 0.95 },
        verticalAlignment: "Bottom",
        horizontalAlignment: "Left",
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
        offset: { x: 0.9, y: 0.95 },
        verticalAlignment: "Bottom",
        horizontalAlignment: "Center",
      },
    ],
    style: {
      strokeWidth: 2,
      strokeColor: getPalette().traTask,
      fill: getPalette().autoTask,
    },
    ports: [
      {
        id: "port",
        offset: { x: 0.96, y: 0.5 },
        visibility: PortVisibility.Visible,
        style: { fill: getPalette().primary },
        constraints: PortConstraints.Default | PortConstraints.Draw,
      },
    ],
  },

  {
    id: "TransitionType1",
    addInfo: { tooltip: "JOIN" },
    width: 50,
    height: 50,
    offsetX: 100,
    offsetY: 100,
    shape: { type: "Bpmn", shape: "Gateway", gateway: { type: "Parallel" } },
    style: { fill: getPalette().traTask },
    symbolInfo: { description: { text: "JOIN" } },
    ports: [
      {
        id: "port",
        offset: { x: 0.96, y: 0.5 },
        visibility: PortVisibility.Visible,
        style: { fill: getPalette().primary },
        constraints: PortConstraints.Default | PortConstraints.Draw,
      },
    ],
    annotations: [
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
    ],
  },
  {
    id: "TransitionType2",
    addInfo: { tooltip: "XOR" },
    width: 50,
    height: 50,
    offsetX: 100,
    offsetY: 100,
    shape: {
      type: "Bpmn",
      shape: "Gateway",
      gateway: { type: "ParallelEventBased" },
    },
    style: { fill: getPalette().traTask },
    symbolInfo: { description: { text: "XOR" } },
    ports: [
      {
        id: "port",
        offset: { x: 0.96, y: 0.5 },
        visibility: PortVisibility.Visible,
        style: { fill: getPalette().primary },
        constraints: PortConstraints.Default | PortConstraints.Draw,
      },
    ],

    annotations: [
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
    ],
  },
  {
    id: "TransitionType3",
    addInfo: { tooltip: "CONCENTRATOR" },
    width: 50,
    height: 50,
    offsetX: 100,
    offsetY: 100,

    shape: { type: "Bpmn", shape: "Gateway", gateway: { type: "Complex" } },
    style: { fill: getPalette().traTask },
    symbolInfo: { description: { text: "GROUPER" } },
    ports: [
      {
        id: "port",
        offset: { x: 0.96, y: 0.5 },
        visibility: PortVisibility.Visible,
        style: { fill: getPalette().primary },
        constraints: PortConstraints.Default | PortConstraints.Draw,
      },
    ],
    annotations: [
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
    ],
  },
  {
    id: "TransitionType4",
    addInfo: { tooltip: "KILLER" },
    width: 50,
    height: 50,
    offsetX: 100,
    offsetY: 100,
    shape: { type: "Bpmn", shape: "Gateway", gateway: { type: "Exclusive" } },
    style: { fill: getPalette().traTask },
    symbolInfo: { description: { text: "KILLER" } },
    ports: [
      {
        id: "port",
        offset: { x: 0.96, y: 0.5 },
        visibility: PortVisibility.Visible,
        style: { fill: getPalette().primary },
        constraints: PortConstraints.Default | PortConstraints.Draw,
      },
    ],
    annotations: [
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
    ],
  },

  {
    id: "TransitionType5",
    addInfo: { tooltip: "WAIT" },
    width: 50,
    height: 50,
    offsetX: 100,
    offsetY: 100,
    shape: { type: "Bpmn", shape: "Gateway", gateway: { type: "Inclusive" } },
    style: { fill: getPalette().traTask },
    symbolInfo: { description: { text: "WAIT" } },
    ports: [
      {
        id: "port",
        offset: { x: 0.96, y: 0.5 },
        visibility: PortVisibility.Visible,
        style: { fill: getPalette().primary },
        constraints: PortConstraints.Default | PortConstraints.Draw,
      },
    ],
    annotations: [
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
      {
        content: "",
        constraints: AnnotationConstraints.ReadOnly,
      },
    ],
  },
];

let connectorSymbols = [
  {
    id: "Link1",
    type: "Orthogonal",
    sourcePoint: { x: 0, y: 0 },
    targetPoint: { x: 40, y: 40 },
    targetDecorator: {
      shape: "Arrow",
      style: { strokeColor: getPalette().primary, fill: getPalette().primary },
    },
    style: { strokeWidth: 1, strokeColor: getPalette().primary },
    symbolInfo: { description: { text: "Orthogonal" } },
    annotations: [
      /*
                {
                content: '',                
                verticalAlignment : "Top",
                horizontalAlignment : "Center",
                }*/
    ],
  },
  {
    id: "Link3",
    type: "Straight",
    sourcePoint: { x: 0, y: 0 },
    targetPoint: { x: 40, y: 40 },
    symbolInfo: { description: { text: "Straight" } },
    annotations: [],
    targetDecorator: {
      shape: "Arrow",
      style: { strokeColor: getPalette().primary, fill: getPalette().primary },
    },
    style: { strokeWidth: 1, strokeColor: getPalette().primary },
  },
];

let palettes = [
  /*
        {
            id: 'swimlaneShapes', expanded: true,
            title: 'Flow Design',
            symbols: swimlaneShapes
        },
*/
  {
    id: "Bpmn",
    expanded: true,
    symbols: bpmnShapes,

    title: "Task Types",
    iconCss: "e-diagram-icons1 e-diagram-flow",
  },

  {
    id: "connectors",
    expanded: true,
    symbols: connectorSymbols,
    iconCss: "e-diagram-icons1 e-diagram-connector",
    title: "Connectors",
  },
];

let contextMenu = {
  show: true,
  items: [
    {
      text: "Lane Properties",
      id: "Edit",
      items: [
        {
          text: "add Actor Before",
          id: "addActorBefore",
          target: ".e-diagramcontent",
        },
        {
          text: "add Actor After",
          id: "addActorAfter",
          target: ".e-diagramcontent",
        },
        { text: "Change Actor", id: "changeActor", target: ".e-actor" },

        //{text:"Move label Up"     , id: "moveAnnotationUp"   , target: ".e-up" } ,
        //{text:"Movel label Down"  , id: "moveAnnotationDown" , target: ".e-down" }
      ],
    },
    {
      text: "Task Properties",
      id: "TaskProperty",
      items: [
        {
          text: "Define Task",
          id: "DefineTask",
          iconCss: "e-task e-bpmn-icons e-DefineTask",
        },
        {
          text: "Define Inputs",
          id: "Inputs",
          iconCss: "e-task e-bpmn-icons e-Inputs",
        },
        {
          text: "Rename",
          id: "Rename",
          iconCss: "e-task e-bpmn-icons e-Outputs",
        },
      ],
    },

    {
      text: "Transition Properties",
      id: "TransitionProperty",
      items: [
        {
          text: "Define Parameters",
          id: "TransitionParameters",
          iconCss: "align-top-3",
        },
      ],
    },

    {
      text: "Versioning",
      id: "Versioning",
      items: [
        { text: "Add version", id: "addVersion", iconCss: "e-changes-next" },
        {
          text: "Change version",
          id: "changeVersion",
          iconCss: "e-changes-track",
        },
      ],
    },
  ],
  showCustomMenuOnly: true,
};

let interval = [
  1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
  0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
];
let gridlines = {
  /*lineColor: "#e0e0e0",*/
  lineColor: "#fff",
  lineIntervals: interval,
};
let diagramInstance;

class ManageDiagram extends React.Component {
  menuContentButtons;
  menuContentButtons2;
  menuContentButtons2b;
  fields = { text: "Label", value: "Name" };

  constructor(props) {
    super(props);
    this.state = {
      isExecuting: true,
      isSaving: false,
      actors: [],
      tasks: [],
      newTasksArr: [],
      newActorsArr: [],
      newflowVars: [],
      definitions: [],
      flowVars: [],
      menuContent: "actor",
      flowVersions: [],
      clearNode: "",

      toolBarOptions: [
        {
          text: "Reset",
          tooltipText: "Reset",
          prefixIcon: "e-diagram-icons e-diagram-new",
        },
        { type: "Separator" },
        {
          text: "Export",
          tooltipText: "Export",
          prefixIcon: "e-diagram-icons e-diagram-export",
        },
        { type: "Separator" },
        {
          text: "Import",
          tooltipText: "Import",
          prefixIcon: "e-diagram-icons e-diagram-open",
        },
        {
          text: "Templates",
          tooltipText: "Templates",
          prefixIcon: "e-diagram-icons e-diagram-open",
        },
        { type: "Separator" },
        {
          text: "Undo",
          tooltipText: "Undo",
          prefixIcon: "e-icons e-undo",
        },
        {
          text: "Redo",
          tooltipText: "Redo",
          prefixIcon: "e-icons e-redo",
        },

        {
          text: "Change version",
          tooltipText: "Change version",
          prefixIcon: "e-icons e-changes-next",
        },

        {
          text: "Add version",
          tooltipText: "Add version",
          prefixIcon: "e-icons e-changes-track",
        },

        {
          text: "My connectors",
          tooltipText: "My connectors",
          prefixIcon: "e-icons e-code-view",
        },
      ],
      dialogTemplate: false,
      formFields: [],
      compIds: [],
      compIdsDesc: [],
      formData: {},
      dataSourceTotal: [],
      nodes: [],
      nodeIds: [],
      actorName: "",
      actorIds: [],
      version: 1,
      connectors: [],
      flowId: -1,
      flowName: "",
      invalidTasks: [],
      invalidActors: [],
      connectorValue: "",
      transitions: [],
      showTransitionWait: false,
      transitionType: "DYNAMIC",
      customConnectors: [],
      currentInputs: [],
      currentName: "",
      currentId: -1,
      posInputs: -1,
      transitionTypes: [
        { value: "DYNAMIC", text: "DYNAMIC" },
        { value: "STATIC", text: "STATIC" },
      ],
    };
    this.menuContentButtons2 = [
      {
        click: closeMenuContent2.bind(this),
        buttonModel: {
          content: "I got it",
          isPrimary: true,
          type: "button",
        },
      },
    ];
    this.menuContentButtons2b = [
      {
        click: menuContentButtons2b.bind(this),
        buttonModel: {
          content: "I got it",
          isPrimary: true,
          type: "button",
        },
      },
    ];

    this.menuContentButtons = [
      {
        click: setMenuContent.bind(this),
        buttonModel: {
          content: "Save",
          isPrimary: true,
          type: "button",
        },
      },
      {
        click: closeMenuContent.bind(this),
        buttonModel: {
          content: "Cancel",
          isPrimary: false,
          type: "button",
        },
      },
    ];
  }
  getConnectors = async () => {
    try {
      /*let response = await ApiClient.doPost(
        process.env.REACT_APP_MASTER_API_CONFIG +
          "/public/components/get-connectors",
        { type: "my-connectors" }
      );*/
      let response = await ApiClient.doPost(
        process.env.REACT_APP_API_CONFIG +
          "/components/get-connectors",
        { type: "my-connectors" }
      );
      this.setState({ customConnectors: response.result });
    } catch (e) {
      console.log("no api server");
      this.setState({ customConnectors: [] });
    }
  };

  callBackAction = async (event) => {
    if (this.state.nodes) {
      var nodes = this.state.nodes;
      //console.log("nodes", nodes)

      if (nodes[0]?.shape?.lanes[0]?.children) {
        const children = nodes[0].shape.lanes[0].children;
        let newChild = {};
        if (event.target.id.indexOf("LOADconnector_") >= 0) {
          const compIds = this.state.compIds;
          let compId = event.target.id.replace("LOADconnector_", "");

          console.log("compId", compId);
          /*let response = await ApiClient.doPost(
            process.env.REACT_APP_MASTER_API_CONFIG +
              "/public/components/get-connector-by-org",
            { id: compId }
          );*/

          let response = await ApiClient.doPost(
            process.env.REACT_APP_API_CONFIG +
              "/components/get-connector-by-org",
            { id: compId }
          );

          if (response.status) {
            let connectorname = response.data.connectorname;
            const compIdsDesc = this.state.compIdsDesc;
            this.setState({ addConncetor: true });
            let cont = 1;
            if (compIds.indexOf(compId) < 0) {
              compIdsDesc.push({
                id: compId,
                scriptinputs: response.data.scriptinputs,
              });
              compIds.push(compId);
            } else {
              for (let i in compIds) {
                if (compIds[i] === compId) {
                  cont++;
                }
              }
              compId = compId;
              connectorname = connectorname + "_" + cont;
              compIdsDesc.push({
                id: compId + "_" + cont,
                scriptinputs: response.data.scriptinputs,
              });
              compIds.push(compId);
            }

            newChild = {
              id: "TaskConnector___" + compId + (cont > 1 ? "_" + cont : ""),
              addInfo: { tooltip: "Activity" },
              width: 185,
              height: 70,
              offsetX: 900,
              offsetY: 700,
              shape: {
                type: "Bpmn",
                shape: "Activity",
                activity: {
                  activity: "Task",
                  task: { type: "PRIVATECONN" },
                },
              },
              style: {
                strokeWidth: 2,
                strokeColor: getPalette().connector,
                fill: getPalette().connector,
              },
              symbolInfo: { description: { text: "Task" } },
              ports: [
                {
                  id: "port",
                  offset: { x: 0.96, y: 0.5 },
                  visibility: PortVisibility.Visible,
                  style: { fill: getPalette().primary },
                  constraints: PortConstraints.Default | PortConstraints.Draw,
                },
              ],
              annotations: [
                {
                  content: connectorname,
                  constraints: AnnotationConstraints.ReadOnly,
                  style: {
                    bold: true,
                    fontSize: 16,
                  },
                },
                {
                  content: "",
                  constraints: AnnotationConstraints.ReadOnly,
                  offset: { x: 0.02, y: 0.95 },
                  verticalAlignment: "Bottom",
                  horizontalAlignment: "Left",
                },
                {
                  content: "",
                  constraints: AnnotationConstraints.ReadOnly,
                  offset: { x: 0.9, y: 0.95 },
                  verticalAlignment: "Bottom",
                  horizontalAlignment: "Center",
                },
              ],
            };

            //console.log("compIds", compIds);
            //console.log("compIdsDesc", compIdsDesc);

            const nodeIds = this.state.nodeIds;
            setTimeout(
              function () {
                console.log("new nodes", nodes);
                children.push(newChild);
                let selectedItem =
                  "TaskConnector___" + compId + (cont > 1 ? "_" + cont : "");

                let pos = find(nodeIds, selectedItem, "nodeId");
                if (pos < 0) {
                  nodeIds.push({
                    nodeId: selectedItem,
                    taskId: parseInt(compId),
                    task: connectorname,
                  });
                } else {
                  nodeIds[pos] = {
                    nodeId: selectedItem,
                    taskId: parseInt(compId),
                    task: connectorname,
                  };
                }

                this.setState({
                  showMenuDialog: false,
                  addConncetor: false,
                  compIds: compIds,
                  compIdsDesc: compIdsDesc,
                  nodeIds: nodeIds,
                });
              }.bind(this),
              10
            );
          }
        }
      }
    }
  };

  onUploadSuccess = (args) => {
    let file1 = args.file;
    let file = file1.rawFile;
    let reader = new FileReader();

    this.setState({ dialogTemplate: false });
    dialogInstanceFlow.hide();

    reader.readAsText(file);
    reader.onloadend = this.loadDiagram;
  };
  //Load the diagraming object.

  loadDiagram = (event) => {
    try {
      let _response = JSON.parse(event.target.result);
      let data = {
        CURRENTVERSION: 1,
        DESCRIPTION: _response.formData.description,
        FLOW: _response.formData.flow,
        FLOWCODE: _response.formData.flowcode,
        FLOWDEFINITION: _response.flowdefinition,
      };

      let formData = {};
      this.state.formFields.map(function (a) {
        formData[a.name] = _response.formData[a.name];
      });
      let definitions = [
        {
          FLOWID: _response.id,
          VERSION: _response.version,
          FLOWDEFINITION: _response.flowdefinition,
        },
      ];
      let flowVars = _response.components.flowVars;

      let response = {
        data,
        definitions,
        flowVars,
        status: true,
      };
      const tasks = this.state.originalTasks;
      const actors = this.state.actors;
      let newTasks = false;
      let newTasksArr = [];
      let newActorsArr = [];
      _response.components.tasks.map(function (a) {
        let pos = tasks.findIndex((item) => item.TASK === a.TASK);
        if (pos >= 0) {
        } else {
          newTasks = true;
          newTasksArr.push(a);
          tasks.push(a);
        }
      });

      if (newTasksArr.length > 0 || newActorsArr.length > 0) {
        dialogInstanceNew.show();
      }

      this.setState({
        ...this.state,
        formData: formData,
        newTasksArr: newTasksArr,
        newActorsArr: newActorsArr,
        newflowVars: _response.components.flowVars,
      });

      //console.log(response);
      this.createDiagram(response);
    } catch (Exc) {
      console.log(">>>>>>Exc:", Exc);
    }
    //let data =
    //diagramInstance.loadDiagram(event.target.result);
  };

  componentDidMount = async () => {
    var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
    if (token) {
      let response = await ApiClient.doPost("/actors/" + "get-all", {});
      let actors = response.result;
      /*
            response = await ApiClient.doPost("/params/getDataByDomainLanguage", { domainid:15});
            let tasktypes=response.result
            */

      response = await ApiClient.doPost("/tasks/" + "get-all", {});
      let tasks = [];
      for (let i in response.result) {
        let task = response.result[i];
        task["TASKID"] = task.TASKID;
        tasks.push(task);
      }
      await this.getConnectors();

      this.setState({
        actors: actors,
        /* tasktypes:tasktypes, */ originalTasks: tasks,
        tasks: tasks,
      });
      await this.getFlowData();
    } else {
      this.setState({ isExecuting: false });
    }
  };

  getFlowData = async (flowid, _version) => {
    let response;

    let data = {
      code: "flows",
      defLang: "en-us",
      domainid: 6,
    };

    response = { status: false };
    const responseF = await ApiClient.doPost(
      "/params/getDataByCodeLanguage",
      data
    );
    if (responseF.status) {
      let formFields = await prepareForm(responseF.data.CONFIG);
      //console.log("formFields",formFields)
      this.setState({ showNewFlow: true, formFields: formFields });
    }

    if (this.props.match.params.id == "0" && !flowid) {
      //console.log("es 0")

      let templateId = this.props.search.get("template");

      if (templateId && !this.state.flowLoaded) {
        /*
                let response2 = await ApiClient.doPost("https://testback-admin.karyoo.com/public/api/v1/components/get-templates", {type: "flow" });
                for (let i in response2.result)   {
                    let key =  response2.result[i]
                    if ( key.ID==templateId ){
                        
                        let config = key.CONFIG
                        try{
                            config = JSON.parse(key.CONFIG)
                        }catch(Ex){
                            //console.log(Ex)
                        }
                        //console.log(config)
                        let formData = config.formData?config.formData:{}
                        this.setState({  formData:formData });    
                        response["templateDefinition"] = key.DATA
                        response["flowName"] = formData.flow
                        response["data"]["FLOW"] = formData.flow
                    }
                }
                this.setState({ flowLoaded: true });    */
      } else {
        dialogInstanceFlow.show();
      }
    } else {
      if (flowid) {
        response = await ApiClient.doPost("/flows/" + "get-single", {
          id: flowid,
        });
      } else {
        response = await ApiClient.doPost("/flows/" + "get-single", {
          id: this.props.match.params.id,
        });
      }

      let formData = {};
      this.state.formFields.map(function (a) {
        formData[a.name] = response.data[a.name.toUpperCase()];
      });

      this.setState({ formData: formData });
      this.createDiagram(response, _version);
    }
  };

  createDiagram = async (response, _version) => {
    console.log(response, _version);
    let version = 1;
    let lastVersion = 1;
    let nodes = [];
    const compIdsDesc = this.state.compIdsDesc;
    const compIds = this.state.compIds;
    if (response.status) {
      let flowData = response.data;
      let flowName = flowData.FLOW;
      let flowVars = response.flowVars;
      let definitions = response.definitions;
      let flowVersions = [];
      let nodeIds = [];
      let actorIds = [];
      let connectors = [];
      let invalidTasks = [];
      let invalidActors = [];

      if (definitions.length > 0) {
        definitions.sort(function (a, b) {
          return b.VERSION < a.VERSION ? -1 : b.VERSION > a.VERSION ? 1 : 0;
        });
        definitions.map(function (d) {
          flowVersions.push({
            VERSIONNAME: "Version " + d.VERSION,
            VERSION: d.VERSION,
          });
          if (lastVersion < d.VERSION) {
            lastVersion = d.VERSION;
          }
        });
        console.log(
          "flowData.CURRENTVERSION::::::",
          flowData.CURRENTVERSION,
          definitions
        );
        let pos = find(
          definitions,
          flowData.CURRENTVERSION === 0 ? 1 : flowData.CURRENTVERSION,
          "VERSION"
        );
        if (_version) {
          //console.log("fuerza ", _version)
          pos = find(definitions, _version, "VERSION");
        }
        version = definitions[pos].VERSION;
        let diagram = JSON.parse(definitions[pos].FLOWDEFINITION);

        //  console.log("diagram",diagram, version)
        let lanes = [];
        let tmpActors = [];

        let contActor = 1;
        for (let a in diagram.tasks) {
          let actor = diagram.tasks[a];

          if (tmpActors.indexOf(actor.actorId) < 0) {
            //new lane
            let isValidActor = false;
            let fillActorColor = getPalette().autoTask;
            if (actor.actorId > 0) {
              for (let v in this.state.actors) {
                let dbActor = this.state.actors[v];
                if (
                  //dbActor.ACTORID === actor.actorId &&
                  dbActor.ACTOR === actor.actorName
                ) {
                  isValidActor = true;
                }
              }
              if (!isValidActor) {
                fillActorColor = "#f77b7b";

                invalidActors.push(actor.actorName);
              }
            }

            actorIds.push({
              nodeId: contActor + actor.actorName + "0",
              actorId: actor.actorId,
              actorName: actor.actorName,
            });
            let children = [];
            for (let t in diagram.tasks) {
              let task = diagram.tasks[t];

              if (task.actorId === actor.actorId) {
                let fillActivityColor = getPalette().manualTask;
                let fillAutomaticColor = getPalette().autoTask;
                let isValidTask = false;

                if (task.taskType === "TASK" || task.taskType == "AUTOMATIC") {
                  for (let v in this.state.originalTasks) {
                    let dbTask = this.state.originalTasks[v];
                    if (
                      //dbTask.TASKID === task.taskId &&
                      dbTask.TASK === task.taskName
                    ) {
                      isValidTask = true;
                    }
                  }
                  if (!isValidTask) {
                    fillActivityColor = "#f77b7b";
                    fillAutomaticColor = "#ffc6c6";
                    invalidTasks.push(task.taskName);
                  }
                }
                let taskObj = {};
                nodeIds.push({
                  nodeId: task.taskTemplateId,
                  taskId: task.taskId,
                  task: task.taskName,
                });

                if (task.taskName === "<START>") {
                  taskObj = {
                    id: task.taskTemplateId,
                    width: 50,
                    height: 50,
                    shape: {
                      type: "Bpmn",
                      shape: "Event",
                      event: { event: "Start" },
                    },
                    style: { fill: getPalette().primary },
                    ports: [
                      {
                        id: "port",
                        offset: { x: 0.96, y: 0.5 },
                        visibility: PortVisibility.Visible,
                        style: { fill: getPalette().primary },
                        constraints:
                          PortConstraints.Default | PortConstraints.Draw,
                      },
                    ],
                    margin: { left: task.left, top: task.top },
                    annotations: [
                      {
                        content: "START",
                        style: { color: "#fff" },
                        constraints: AnnotationConstraints.ReadOnly,
                      },
                    ],
                  };
                } else if (task.taskName === "<END>") {
                  taskObj = {
                    id: task.taskTemplateId,
                    width: 50,
                    height: 50,
                    shape: {
                      type: "Bpmn",
                      shape: "Event",
                      event: { event: "End", trigger: "Termination" },
                    },
                    margin: { left: task.left, top: task.top },
                    style: { fill: getPalette().primary },
                    annotations: [
                      {
                        content: "END",
                        style: {
                          color: getPalette().primary,
                          backgroundColor: "#c62828",
                          border: "2px solid " + getPalette().primary,
                        },
                        constraints: AnnotationConstraints.ReadOnly,
                      },
                    ],
                  };
                } else {
                  if (
                    task.taskType === "JOIN" ||
                    task.taskType === "XOR" ||
                    task.taskType === "CONCENTRATOR" ||
                    task.taskType === "KILLER" ||
                    task.taskType === "WAIT"
                  ) {
                    let inputLabel = task.input;
                    let taskType = "Parallel";
                    if (task.taskType === "XOR") {
                      taskType = "ParallelEventBased";
                    } else if (task.taskType === "CONCENTRATOR") {
                      taskType = "Complex";
                    } else if (task.taskType === "KILLER") {
                      taskType = "Exclusive";
                    } else if (task.taskType === "WAIT") {
                      taskType = "Inclusive";

                      if (task.output === "DYNAMIC") {
                        console.log("din!!!!!!!!", task.input);
                        inputLabel = task.input;
                      } else {
                        console.log("no din!!!");
                        inputLabel = task.output + " - " + task.input;
                      }
                    }

                    taskObj = {
                      id: task.taskTemplateId,
                      width: 50,
                      height: 50,
                      offsetX: 100,
                      offsetY: 100,
                      shape: {
                        type: "Bpmn",
                        shape: "Gateway",
                        gateway: { type: taskType },
                      },
                      style: { fill: getPalette().traTask },
                      margin: { left: task.left, top: task.top },
                      ports: [
                        {
                          id: "port",
                          offset: { x: 0.96, y: 0.5 },
                          visibility: PortVisibility.Visible,
                          style: { fill: getPalette().primary },
                          constraints:
                            PortConstraints.Default | PortConstraints.Draw,
                        },
                      ],
                      annotations: task.input
                        ? [
                            {
                              content: inputLabel,
                              constraints: AnnotationConstraints.ReadOnly,
                              offset: { x: 0.5, y: -0.125 },
                              verticalAlignment: "Top",
                              horizontalAlignment: "Center",
                              style: {
                                bold: true,
                                fill: getPalette().primary,
                                color: "#fff",
                                textWrapping: "NoWrap",
                              },
                            },
                            {
                              content: task.taskName,
                              constraints: AnnotationConstraints.ReadOnly,
                              offset: { x: 0.5, y: 1.1 },
                              verticalAlignment: "Top",
                              horizontalAlignment: "Center",
                              style: {
                                bold: true,
                                textAlign: "Center",
                                fill: getPalette().autoTask,
                                color: "#000",
                                textWrapping: "WrapWithOverflow",
                              },
                            },
                          ]
                        : [
                            {
                              content: task.taskName,
                              constraints: AnnotationConstraints.ReadOnly,
                              offset: { x: 0.5, y: 1.1 },
                              verticalAlignment: "Top",
                              horizontalAlignment: "Center",
                              style: {
                                bold: true,
                                textAlign: "Center",
                                fill: getPalette().autoTask,
                                color: "#000",
                                textWrapping: "WrapWithOverflow",
                              },
                            },
                          ],
                    };
                  } else if (task.taskType == "TASK") {
                    taskObj = {
                      id: task.taskTemplateId,
                      width: 185,
                      height: 70,
                      offsetX: 700,
                      offsetY: 700,
                      shape: {
                        type: "Bpmn",
                        shape: "Activity",
                        activity: {
                          activity: "Task",
                          task: { type: "Task" },
                        },
                      },
                      margin: { left: task.left, top: task.top },
                      style: {
                        strokeWidth: 2,
                        strokeColor: getPalette().primary,
                        fill: fillActivityColor,
                      },
                      ports: [
                        {
                          id: "port",
                          offset: { x: 0.96, y: 0.5 },
                          visibility: PortVisibility.Visible,
                          style: { fill: getPalette().primary },
                          constraints:
                            PortConstraints.Default | PortConstraints.Draw,
                        },
                      ],
                      annotations: [
                        {
                          content: task.taskName,
                          constraints: AnnotationConstraints.ReadOnly,
                          style: {
                            bold: true,
                            fontSize: 16,
                          },
                        },
                        {
                          content: task.input,
                          constraints: AnnotationConstraints.ReadOnly,
                          offset: { x: 0.02, y: 0.95 },
                          verticalAlignment: "Bottom",
                          horizontalAlignment: "Left",
                          style:
                            task.input === ""
                              ? {}
                              : {
                                  bold: true,
                                  fill: "#fff",
                                  color: "#000",
                                  textWrapping: "NoWrap",
                                },
                        },
                        {
                          content: task.output,
                          constraints: AnnotationConstraints.ReadOnly,
                          offset: { x: 0.9, y: 0.95 },
                          verticalAlignment: "Bottom",
                          horizontalAlignment: "Center",
                          style:
                            task.output === ""
                              ? {}
                              : {
                                  bold: true,
                                  fill: "#fff",
                                  color: "#000",
                                  textWrapping: "NoWrap",
                                },
                        },
                      ],
                    };
                  } else if (task.taskType == "AUTOMATIC") {
                    taskObj = {
                      id: task.taskTemplateId,
                      width: 185,
                      height: 70,
                      offsetX: 700,
                      offsetY: 700,
                      shape: {
                        type: "Bpmn",
                        shape: "Activity",
                        activity: {
                          activity: "Task",
                          task: { type: "Automatic" },
                        },
                      },
                      annotations: [
                        {
                          content: task.taskName,
                          constraints: AnnotationConstraints.ReadOnly,
                          style: {
                            bold: true,
                            fontSize: 16,
                          },
                        },
                        {
                          content: task.input,
                          constraints: AnnotationConstraints.ReadOnly,
                          offset: { x: 0.02, y: 0.95 },
                          verticalAlignment: "Bottom",
                          horizontalAlignment: "Left",
                          style:
                            task.input === ""
                              ? {}
                              : {
                                  bold: true,
                                  fill: "#fff",
                                  color: "#000",
                                  textWrapping: "NoWrap",
                                },
                        },
                        {
                          content: task.output,
                          constraints: AnnotationConstraints.ReadOnly,
                          offset: { x: 0.9, y: 0.95 },
                          verticalAlignment: "Bottom",
                          horizontalAlignment: "Center",
                          style:
                            task.output === ""
                              ? {}
                              : {
                                  bold: true,
                                  fill: "#fff",
                                  color: "#000",
                                  textWrapping: "NoWrap",
                                },
                        },
                      ],
                      margin: { left: task.left, top: task.top },

                      style: {
                        strokeWidth: 2,
                        strokeColor: "#757575",
                        fill: fillAutomaticColor,
                      },
                      ports: [
                        {
                          id: "port",
                          offset: { x: 0.96, y: 0.5 },
                          visibility: PortVisibility.Visible,
                          style: { fill: getPalette().primary },
                          constraints:
                            PortConstraints.Default | PortConstraints.Draw,
                        },
                      ],
                    };
                  } else if (task.taskType == "PRIVATECONN") {
                    let inputs = [];
                    /*let response = await ApiClient.doPost(
                      process.env.REACT_APP_MASTER_API_CONFIG +
                        "/public/components/get-connector-by-org",
                      { id: task.taskId }
                    );*/
                    let response = await ApiClient.doPost(
                      process.env.REACT_APP_API_CONFIG +
                        "/components/get-connector-by-org",
                      { id: task.taskId }
                    );
                    let inputValues = task.input.split("|");
                    if (response.status) {
                      inputs = response.data.scriptinputs;
                    }
                    inputValues.map(function (key) {
                      let value = key.split("=");
                      inputs.map(function (input) {
                        if (input.INPUTNAME === value[0]) {
                          input["value"] = value[1];
                        }
                      });
                    });

                    let ptaskId = task.taskId.toString();

                    let posT = compIdsDesc.findIndex(
                      (item) => item.id === ptaskId
                    );
                    if (posT >= 0) {
                      let cont = 1;
                      compIds.map(function (key) {
                        if (key == ptaskId) {
                          cont++;
                        }
                      });
                      ptaskId = ptaskId + "_" + cont;
                    }

                    compIdsDesc.push({
                      id: ptaskId,
                      scriptinputs: inputs,
                    });

                    compIds.push(task.taskId.toString());

                    taskObj = {
                      id: task.taskTemplateId,
                      width: 185,
                      height: 70,
                      offsetX: 700,
                      offsetY: 700,
                      shape: {
                        type: "Bpmn",
                        shape: "Activity",
                        activity: {
                          activity: "Task",
                          task: { type: "PRIVATECONN" },
                        },
                      },
                      margin: { left: task.left, top: task.top },

                      style: {
                        strokeWidth: 2,
                        strokeColor: getPalette().connector,
                        fill: getPalette().connector,
                      },
                      symbolInfo: { description: { text: "Task" } },
                      ports: [
                        {
                          id: "port",
                          offset: { x: 0.96, y: 0.5 },
                          visibility: PortVisibility.Visible,
                          style: { fill: getPalette().primary },
                          constraints:
                            PortConstraints.Default | PortConstraints.Draw,
                        },
                      ],
                      annotations: [
                        {
                          content: task.taskName,
                          constraints: AnnotationConstraints.ReadOnly,
                          style: {
                            bold: true,
                            fontSize: 16,
                          },
                        },
                      ],
                    };
                  }
                }
                children.push(taskObj);
              }
            }
            //            console.log(diagram);
            let actorH = findValueById(
              diagram.canvas.laneHeights,
              "1" + actor.actorName + "0",
              "actorId",
              "height"
            );

            let lane = {
              id: actor.actorName,
              height: actorH === "NOT FOUND" ? 100 : actorH,
              header: {
                annotation: {
                  content:
                    actor.actorName === "mainmaster" ? "Main" : actor.actorName,
                },
                width: 50,
                style: { fontSize: 11, fill: fillActorColor },
              },
              children: children,
            };

            lanes.push(lane);
            tmpActors.push(actor.actorId);
          }
        }

        for (let t in diagram.transitions) {
          let transition = diagram.transitions[t];
          connectors.push({
            type: "Orthogonal",
            direction: "Right",
            targetDecorator: {
              shape: "Arrow",
              style: {
                strokeColor: getPalette().primary,
                fill: getPalette().primary,
              },
            },
            annotations: [
              {
                //content: transition.option,
                height: 50,
                offset: 0.5,
                template:
                  '<span class="transition-label">' +
                  transition.option +
                  "</span>",
              },
            ],
            cornerRadius: 8,
            style: {
              textAlign: "center",
              strokeColor: getPalette().primary,
              strokeWidth: 1,
            },
            //constraints: ConnectorConstraints.Default & ConnectorConstraints.InheritLineRouting ,
            sourceID: transition.sourceID,
            targetID: transition.targetID,
          });
        }
        /*
                console.log("lanes", lanes)
                console.log("nodeIds", nodeIds)
                console.log("actorIds",actorIds)
                console.log("connectors",connectors)
        */
        nodes = [
          {
            id: "1",
            shape: {
              id: "base",
              type: "SwimLane",
              orientation: "Horizontal",
              //Intialize header to swimlane
              header: {
                annotation: {
                  content: "FLOW:" + flowName,
                  style: {
                    fontSize: 18,
                    bold: true,
                    fill: getPalette().autoTask,
                    strokeColor: getPalette().secondary,
                  },
                },
                height: 45,
                style: {
                  background: getPalette().autoTask,
                  strokeColor: getPalette().secondary,
                  fill: getPalette().autoTask,
                },
              },

              lanes: lanes,
              phases: [
                {
                  id: "version",
                  offset: 120,
                  header: { annotation: { content: "version: " + version } },
                  style: { fill: "#fff" },
                },
              ],
              phaseSize: 20,
            },
            offsetX: diagram.canvas.offsetX ? diagram.canvas.offsetX : 440,
            offsetY: diagram.canvas.offsetY ? diagram.canvas.offsetY : 120,
            //height: diagram.canvas.height?diagram.canvas.height:200,
            width: diagram.canvas.width ? diagram.canvas.width : 850,
          },
        ];
        console.log("nodes", nodes);
      } else {
        console.log("nuevo");

        nodes = [
          {
            id: "1",
            shape: {
              id: "base",
              type: "SwimLane",
              orientation: "Horizontal",
              //Intialize header to swimlane
              header: {
                annotation: {
                  content: "FLOW:" + flowName,
                  style: {
                    fontSize: 18,
                    bold: true,
                    fill: getPalette().traTask,
                    strokeColor: getPalette().traTask,
                  },
                },
                height: 45,
                style: {
                  background: getPalette().traTask,
                  strokeColor: getPalette().traTask,
                  fill: getPalette().traTask,
                },
              },

              lanes: [
                {
                  id: "mainmaster",
                  height: 135,
                  header: {
                    annotation: { content: "Main" },
                    width: 50,
                    style: { fontSize: 11, fill: getPalette().traTask },
                  },
                  children: [],
                },
              ],
              phases: [
                {
                  id: "version 1",
                  offset: 120,
                  header: { annotation: { content: "version 1" } },
                  style: { fontSize: 16, fill: "#fff" },
                },
              ],
              phaseSize: 20,
            },
            offsetX: 440,
            offsetY: 120,
            height: 200,
            width: 850,
          },
        ];
      }

      //      console.log("compIdsDesc::::", compIdsDesc);
      //    console.log("compIds::::", compIds);

      this.setState({
        invalidTasks,
        invalidActors,
        compIds,
        compIdsDesc,
        flowName: flowName,
        flowId: flowData.FLOWID,
        lastVersion: lastVersion,
        isExecuting: false,
        nodes: nodes,
        nodeIds: nodeIds,
        connectors: connectors,
        actorIds: actorIds,
        version: version,
        definitions: definitions,
        flowVersions: flowVersions,
        flowVars: flowVars,
      });

      if (invalidTasks.length > 0 || invalidActors.length > 0) {
        dialogInstanceCaution.show();
      }

      setTimeout(
        function () {
          diagramInstance.fitToPage({ mode: "Width", canZoomIn: false });
        }.bind(this),
        50
      );
    }
  };

  dragOver = (args) => {
    let obj = args.element;
  };

  positionChange = (args) => {
    //check for move outsidde the swinlane
    //console.log("node:", args, diagramInstance.selectedItems.nodes)
  };

  checkOutSide = (args) => {
    let isValid = true;
    for (let i in diagramInstance.nodes) {
      let node = diagramInstance.nodes[i];
      if (
        node.id.indexOf("Task") >= 0 ||
        node.id.indexOf("Transition") >= 0 ||
        node.id.indexOf("Start") >= 0 ||
        node.id.indexOf("End") >= 0
      ) {
        //console.log("CHECK", node.id, node.parentId)
        if (node.parentId == "") {
          //console.log("deleted")
          diagramInstance.remove(node);
          isValid = false;
        }
      }
    }
    return isValid;
  };
  getValue = (args) => {
    let undoStack = diagramInstance.historyManager.undoStack;
    let redoStack = diagramInstance.historyManager.redoStack;

    //console.log("CHECKING", args)

    let undo = [];
    for (let i = 0; i < undoStack.length; i++) {
      undo.push({ text: undoStack[i].type, value: undoStack[i].type });
    }
    let redo = [];
    for (let i = 0; i < redoStack.length; i++) {
      redo.push({ text: redoStack[i].type, value: redoStack[i].type });
    }
    if (undoStack.length > 0 && args.source.length > 0) {
      let obj = args.source[0];
      if (
        (undoStack[0].type === "CollectionChanged" ||
          undoStack[0].type === "ChildCollectionChanged" ||
          undoStack[0].type === "PositionChanged") &&
        obj.parentId === ""
      ) {
        if (args.source[0].type === "Orthogonal") {
          if (
            args.source[0].sourceID === "" ||
            args.source[0].targetID === "" ||
            args.source[0].sourceID === args.source[0].targetID
          ) {
            setTimeout(
              function () {
                //diagramInstance.remove(args.source[0])
                console.log("get out");
              }.bind(this),
              50
            );
          } else {
            if (args.source[0].annotations.length === 0) {
              setTimeout(
                function () {
                  this.setState({
                    showMenuDialog: true,
                    menuDialogTitle: "Set Option value",
                    menuContent: "transitionName",
                    connectorValue: args.source[0].id,
                  });
                }.bind(this),
                50
              );
            } else {
              console.log("!!!ANNOTATION ALREADY DEFINED", args.source[0]);
            }
          }
        } else {
          setTimeout(
            function () {
              let isValid = this.checkOutSide();
              if (isValid) {
                console.log("isvalid", args.change.type);
                if (
                  args.change.type === "ChildCollectionChanged" ||
                  args.change.type === "CollectionChanged"
                ) {
                  console.log("ChildCollectionChanged", args.source[0].id);
                  if (args.source[0].id.indexOf("Task") >= 0) {
                    if (diagramInstance.selectedItems.nodes.length > 0) {
                      let bpmnShape =
                        diagramInstance.selectedItems.nodes[0].shape;
                      let taskType = bpmnShape.activity.task.type;
                      if (taskListInstance) {
                        taskListInstance["value"] = null;
                        taskListInstance["text"] = null;
                      }
                      let tasktypeId = 50;
                      if (taskType === "Automatic") {
                        tasktypeId = 52;
                      }
                      let fixedTasks = [];
                      this.state.originalTasks.map(function (key, item) {
                        if (key.TASKTYPEID === tasktypeId) {
                          fixedTasks.push(key);
                        }
                      });
                      this.setState({
                        showMenuDialog: true,
                        menuDialogTitle: "Select Task",
                        menuContent: "task",
                        taskType: taskType,
                        tasks: fixedTasks,
                      });
                    }
                  } else if (args.source[0].id.indexOf("Transition") >= 0) {
                    //console.log("Transition");
                    if (diagramInstance.selectedItems.nodes.length > 0) {
                      let showTransitionWait = false;
                      if (
                        diagramInstance.selectedItems.nodes[0].shape.gateway
                          .type === "Inclusive"
                      ) {
                        showTransitionWait = true;
                      }
                      if (flowVarListInstance) {
                        flowVarListInstance["value"] = null;
                        flowVarListInstance["text"] = null;
                      }
                      this.setState({
                        showMenuDialog: true,
                        menuDialogTitle: "Define Parameters",
                        menuContent: "transitionionParams",
                        transitionShapeType:
                          diagramInstance.selectedItems.nodes[0].shape.gateway
                            .type,
                        showTransitionWait: showTransitionWait,
                      });
                    }
                  }
                }
              }
            }.bind(this),
            50
          );
        }
      } else {
        if (undoStack[0].type === "ChildCollectionChanged") {
          let isValid = true;
          let firtTime = true;
          let strToEval = "";
          if (obj) {
            if (obj.id.indexOf("Start") >= 0) {
              strToEval = "Start";
            }
            if (obj.id.indexOf("End") >= 0) {
              strToEval = "End";
            }
          }
          if (strToEval !== "") {
            for (let i in diagramInstance.nodes) {
              let node = diagramInstance.nodes[i];
              //  console.log("CHECK", node.id)
              if (node.id.indexOf(strToEval) >= 0) {
                if (firtTime) {
                  firtTime = false;
                } else {
                  isValid = false;
                }
              }
            }
          }
          if (!isValid) {
            setTimeout(
              function () {
                diagramInstance.undo();
                diagramInstance.clearHistory();
              }.bind(this),
              10
            );
          }
        }
      }
    }
    //console.log("END CHECKING")
  };

  dragEnter = (args) => {};

  exportFlow = async () => {
    console.log("nodes", diagramInstance.nodes);
    console.log("connectors", diagramInstance.connectors);
    console.log("nodeIds", this.state.nodeIds);
    console.log("actorIds", this.state.actorIds);
    let isValid = true;
    let isValidStart = false;
    let isValidEnd = false;
    let isValidTran = true;
    let isValidConn = true;
    let fixedData = [];
    let fixedTransitions = [];
    let laneHeights = [];
    let errorMessage = "";
    let canvas = {};
    for (let i in diagramInstance.nodes) {
      let node = diagramInstance.nodes[i];
      const nodeIds = this.state.nodeIds;
      const actorIds = this.state.actorIds;
      let left = node.margin.left;
      let top = node.margin.top;
      let actorId = 0;
      let actorName = "";

      if (node.id === "1") {
        canvas["offsetX"] = node.offsetX;
        canvas["offsetY"] = node.offsetY;
        canvas["width"] = node.width;
        canvas["height"] = node.height;
      }

      if (
        node.id.indexOf("Start") >= 0 ||
        node.id.indexOf("End") >= 0 ||
        node.id.indexOf("Transition") >= 0 ||
        node.id.indexOf("Task") >= 0
      ) {
        actorId = findValueById(
          this.state.actorIds,
          node.parentId,
          "nodeId",
          "actorId"
        );
        actorName = findValueById(
          this.state.actorIds,
          node.parentId,
          "nodeId",
          "actorName"
        );

        let parentNodeId = find(
          diagramInstance.nodes,
          node.parentId
            .toString()
            .substr(0, node.parentId.toString().length - 1) + "_0_header",
          "id"
        );

        if (actorId == "NOT FOUND") {
          if (node.id.indexOf("Start") >= 0) {
            actorId = 0;
            actorName = "mainmaster";
          } else if (node.id.indexOf("End") >= 0) {
            actorId = 0;
            actorName = "mainmaster";
          }
        }
        // console.log("NODE data:", node.id, node.parentId, actorName, actorId);
      } else {
        let pos = find(this.state.actorIds, node.id, "nodeId");
        if (pos >= 0) {
          laneHeights.push({ actorId: node.id, height: node.wrapper.height });
        }
      }

      let task = {
        taskTemplateId: node.id,
        top,
        left,
        input: "",
        output: "",
        actorId,
        actorName,
      };

      if (node.id.indexOf("Start") >= 0) {
        isValidStart = true;
        task["taskName"] = "<START>";
        task["taskType"] = "START";
        task["taskId"] = -1;
        fixedData.push(task);
      } else if (node.id.indexOf("End") >= 0) {
        isValidEnd = true;
        task["taskName"] = "<END>";
        task["taskType"] = "END";
        task["taskId"] = 0;
        fixedData.push(task);
      } else if (node.id.indexOf("Transition") >= 0) {
        let flowVar = node.annotations[0].content;
        let taskName = node.annotations[1].content;

        let taskType = "JOIN";
        if (node.shape.gateway.type === "ParallelEventBased") {
          taskType = "XOR";
          task["output"] = taskType;
        } else if (node.shape.gateway.type === "Complex") {
          taskType = "CONCENTRATOR";
          task["output"] = taskType;
        } else if (node.shape.gateway.type === "Exclusive") {
          taskType = "KILLER";
          task["output"] = taskType;
        } else if (node.shape.gateway.type === "Inclusive") {
          taskType = "WAIT";
          //console.log("TRX",node.annotations )
          if (flowVar.indexOf("STATIC - ") >= 0) {
            flowVar = flowVar.replace("STATIC - ", "");
            task["output"] = "STATIC";
          } else {
            task["output"] = "DYNAMIC";
          }
        }
        if (flowVar == "") {
          isValidTran = false;
        }

        task["taskName"] = taskName;
        task["taskType"] = taskType;
        task["taskId"] = -2;
        task["input"] = flowVar;

        fixedData.push(task);
      } else if (node.id.indexOf("Task") >= 0) {
        let taskId = findValueById(nodeIds, node.id, "nodeId", "taskId");
        if (taskId != "NOT FOUND") {
          task["taskName"] = node.annotations[0].content;
          task["taskType"] = node.shape.activity.task.type.toUpperCase();
          task["taskId"] = taskId;
          if (node.id.indexOf("TaskConnector___") >= 0) {
            let pos = this.state.compIdsDesc.findIndex(
              (item) => item.id === node.id.replace("TaskConnector___", "")
            );
            let inputs = [];
            for (let i in this.state.compIdsDesc[pos].scriptinputs) {
              let inp = this.state.compIdsDesc[pos].scriptinputs[i];
              if (!inp.value && inp.IS_REQUIRED === "YES") {
                errorMessage =
                  inp.INPUTLABEL +
                  " for connector " +
                  task.taskName +
                  " are not defined";
                isValid = false;
              }
              inputs.push(inp.INPUTNAME + "=" + (inp.value ? inp.value : ""));
            }
            task["input"] = inputs.toString().replaceAll(",", "|");
          } else {
            task["input"] = node.annotations[1].content.replaceAll(",", "|");
            task["output"] = node.annotations[2].content.replaceAll(",", "|");
          }

          fixedData.push(task);
        } else {
          console.log("NO DATA!!!");
          errorMessage = "Task(s) not Defined";
          isValid = false;
        }
      }
    }

    if (errorMessage !== "") {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: errorMessage,
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    } else {
      for (let c in diagramInstance.connectors) {
        let conn = diagramInstance.connectors[c];

        let option = "Accept";
        let sourceTaskId = findValueById(
          fixedData,
          conn.sourceID,
          "taskTemplateId",
          "taskId"
        );
        let sourceName = findValueById(
          fixedData,
          conn.sourceID,
          "taskTemplateId",
          "taskName"
        );
        let sourceTaskType = findValueById(
          fixedData,
          conn.sourceID,
          "taskTemplateId",
          "taskType"
        );
        let sourceActorId = findValueById(
          fixedData,
          conn.sourceID,
          "taskTemplateId",
          "actorId"
        );

        let targetTaskId = findValueById(
          fixedData,
          conn.targetID,
          "taskTemplateId",
          "taskId"
        );
        let targetName = findValueById(
          fixedData,
          conn.targetID,
          "taskTemplateId",
          "taskName"
        );
        let targetTaskType = findValueById(
          fixedData,
          conn.targetID,
          "taskTemplateId",
          "taskType"
        );
        let targetActorId = findValueById(
          fixedData,
          conn.targetID,
          "taskTemplateId",
          "actorId"
        );

        if (conn.annotations.length > 0) {
          if (conn.annotations[0].content != "") {
            option = conn.annotations[0].content;
          }

          if (conn.annotations[0].template != "") {
            option = conn.annotations[0].template
              .replace('<span class="transition-label">', "")
              .replace("</span>", "");
          }
        }

        if (sourceTaskId === "" || targetTaskId === "") {
          isValidConn = false;
        }

        fixedTransitions.push({
          sourceTaskId,
          sourceID: conn.sourceID,
          targetTaskId,
          targetID: conn.targetID,
          option,
          sourceName,
          targetName,
          sourceTaskType,
          sourceActorId,
          targetTaskType,
          targetActorId,
        });
      }
    }

    if (!isValidConn) {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "NOT all nodes are connected",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }

    if (!isValidTran) {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "NOT all transitions are defined",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }

    if (!isValidStart) {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "[Start node] NOT Detected",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }

    if (!isValidEnd) {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "[End node] NOT Detected",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }

    if (isValid) {
      try {
        let components = {
          tasks: [],
          actors: [],
          formData: this.state.formData,
          flowVars: this.state.flowVars,
        };
        for (let v in fixedData) {
          let task = fixedData[v];
          if (task.taskId > 0) {
            if (task.taskType === "TASK" || task.taskType === "AUTOMATIC") {
              let pos = this.state.originalTasks.findIndex(
                (item) => item.TASKID === task.taskId
              );
              components.tasks.push(this.state.originalTasks[pos]);
            }
          }
        }

        for (let v in laneHeights) {
          let actor = laneHeights[v];
          if (actor.actorId !== "1mainmaster0") {
            let pos = this.state.actors.findIndex(
              (item) => "1" + item.ACTOR + "0" === actor.actorId
            );
            components.actors.push(this.state.actors[pos]);
          }
        }

        canvas["laneHeights"] = laneHeights;
        let flowdefinition = {
          tasks: fixedData,
          transitions: fixedTransitions,
          version: this.state.version,
          canvas: canvas,
        };
        let data = {
          flowdefinition: JSON.stringify(flowdefinition),
          id: this.props.match.params.id,
          formData: this.state.formData,
          tasks: fixedData,
          transitions: fixedTransitions,
          version: this.state.version,
          canvas: canvas,
          components: components,
        };

        //console.log("components", components);

        download(JSON.stringify(data));
      } catch (ex) {
        console.log("Ex", ex);
      }
    }
  };

  submitForm = async () => {
    console.log("nodes", diagramInstance.nodes);
    console.log("connectors", diagramInstance.connectors);
    console.log("nodeIds", this.state.nodeIds);
    console.log("actorIds", this.state.actorIds);
    let isValid = true;
    let isValidStart = false;
    let isValidEnd = false;
    let isValidTran = true;
    let isValidConn = true;
    let fixedData = [];
    let fixedTransitions = [];
    let laneHeights = [];
    let errorMessage = "";
    let canvas = {};
    for (let i in diagramInstance.nodes) {
      let node = diagramInstance.nodes[i];
      const nodeIds = this.state.nodeIds;
      const actorIds = this.state.actorIds;
      let left = node.margin.left;
      let top = node.margin.top;
      let actorId = 0;
      let actorName = "";

      if (node.id === "1") {
        canvas["offsetX"] = node.offsetX;
        canvas["offsetY"] = node.offsetY;
        canvas["width"] = node.width;
        canvas["height"] = node.height;
      }

      if (
        node.id.indexOf("Start") >= 0 ||
        node.id.indexOf("End") >= 0 ||
        node.id.indexOf("Transition") >= 0 ||
        node.id.indexOf("Task") >= 0
      ) {
        actorId = findValueById(
          this.state.actorIds,
          node.parentId,
          "nodeId",
          "actorId"
        );
        actorName = findValueById(
          this.state.actorIds,
          node.parentId,
          "nodeId",
          "actorName"
        );

        let parentNodeId = find(
          diagramInstance.nodes,
          node.parentId
            .toString()
            .substr(0, node.parentId.toString().length - 1) + "_0_header",
          "id"
        );

        if (actorId == "NOT FOUND") {
          if (node.id.indexOf("Start") >= 0) {
            actorId = 0;
            actorName = "mainmaster";
          } else if (node.id.indexOf("End") >= 0) {
            actorId = 0;
            actorName = "mainmaster";
          }
        }
        console.log("NODE data:", node.id, node.parentId, actorName, actorId);
      } else {
        let pos = find(this.state.actorIds, node.id, "nodeId");
        if (pos >= 0) {
          laneHeights.push({ actorId: node.id, height: node.wrapper.height });
          console.log("other", node.wrapper.height, node.id);
        }
      }

      let task = {
        taskTemplateId: node.id,
        top,
        left,
        input: "",
        output: "",
        actorId,
        actorName,
      };

      if (node.id.indexOf("Start") >= 0) {
        isValidStart = true;
        task["taskName"] = "<START>";
        task["taskType"] = "START";
        task["taskId"] = -1;
        fixedData.push(task);
      } else if (node.id.indexOf("End") >= 0) {
        isValidEnd = true;
        task["taskName"] = "<END>";
        task["taskType"] = "END";
        task["taskId"] = 0;
        fixedData.push(task);
      } else if (node.id.indexOf("Transition") >= 0) {
        let flowVar = node.annotations[0].content;
        let taskName = node.annotations[1].content;

        let taskType = "JOIN";
        if (node.shape.gateway.type === "ParallelEventBased") {
          taskType = "XOR";
          task["output"] = taskType;
        } else if (node.shape.gateway.type === "Complex") {
          taskType = "CONCENTRATOR";
          task["output"] = taskType;
        } else if (node.shape.gateway.type === "Exclusive") {
          taskType = "KILLER";
          task["output"] = taskType;
        } else if (node.shape.gateway.type === "Inclusive") {
          taskType = "WAIT";
          //console.log("TRX",node.annotations )
          if (flowVar.indexOf("STATIC - ") >= 0) {
            flowVar = flowVar.replace("STATIC - ", "");
            task["output"] = "STATIC";
          } else {
            task["output"] = "DYNAMIC";
          }
        }
        if (flowVar == "") {
          isValidTran = false;
        }

        task["taskName"] = taskName;
        task["taskType"] = taskType;
        task["taskId"] = -2;
        task["input"] = flowVar;

        fixedData.push(task);
      } else if (node.id.indexOf("Task") >= 0) {
        console.log(node.id);
        let taskId = findValueById(nodeIds, node.id, "nodeId", "taskId");
        if (taskId != "NOT FOUND") {
          task["taskName"] = node.annotations[0].content;
          task["taskType"] = node.shape.activity.task.type.toUpperCase();
          task["taskId"] = taskId;
          if (node.id.indexOf("TaskConnector___") >= 0) {
            let pos = this.state.compIdsDesc.findIndex(
              (item) => item.id === node.id.replace("TaskConnector___", "")
            );
            let inputs = [];
            for (let i in this.state.compIdsDesc[pos].scriptinputs) {
              let inp = this.state.compIdsDesc[pos].scriptinputs[i];
              if (!inp.value && inp.IS_REQUIRED === "YES") {
                errorMessage =
                  inp.INPUTLABEL +
                  " for connector " +
                  task.taskName +
                  " are not defined";
                isValid = false;
                console.log("TAAAA", task);
              }
              inputs.push(inp.INPUTNAME + "=" + (inp.value ? inp.value : ""));
            }
            task["input"] = inputs.toString().replaceAll(",", "|");
          } else {
            task["input"] = node.annotations[1].content.replaceAll(",", "|");
            task["output"] = node.annotations[2].content.replaceAll(",", "|");
          }

          fixedData.push(task);
        } else {
          console.log("NO DATA!!!");
          errorMessage = "Task(s) not Defined";
          isValid = false;
        }
      }
    }

    if (errorMessage !== "") {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: errorMessage,
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    } else {
      for (let c in diagramInstance.connectors) {
        let conn = diagramInstance.connectors[c];
        console.log(conn.sourceID, conn.targetID, conn.annotations);
        let option = "Accept";
        let sourceTaskId = findValueById(
          fixedData,
          conn.sourceID,
          "taskTemplateId",
          "taskId"
        );
        let sourceName = findValueById(
          fixedData,
          conn.sourceID,
          "taskTemplateId",
          "taskName"
        );
        let sourceTaskType = findValueById(
          fixedData,
          conn.sourceID,
          "taskTemplateId",
          "taskType"
        );
        let sourceActorId = findValueById(
          fixedData,
          conn.sourceID,
          "taskTemplateId",
          "actorId"
        );

        let targetTaskId = findValueById(
          fixedData,
          conn.targetID,
          "taskTemplateId",
          "taskId"
        );
        let targetName = findValueById(
          fixedData,
          conn.targetID,
          "taskTemplateId",
          "taskName"
        );
        let targetTaskType = findValueById(
          fixedData,
          conn.targetID,
          "taskTemplateId",
          "taskType"
        );
        let targetActorId = findValueById(
          fixedData,
          conn.targetID,
          "taskTemplateId",
          "actorId"
        );

        if (conn.annotations.length > 0) {
          if (conn.annotations[0].content != "") {
            option = conn.annotations[0].content;
          }

          if (conn.annotations[0].template != "") {
            console.log("TEMPLATEEE", conn.annotations[0].template);

            option = conn.annotations[0].template
              .replace('<span class="transition-label">', "")
              .replace("</span>", "");
          }
        }

        if (sourceTaskId === "" || targetTaskId === "") {
          isValidConn = false;
        }

        fixedTransitions.push({
          sourceTaskId,
          sourceID: conn.sourceID,
          targetTaskId,
          targetID: conn.targetID,
          option,
          sourceName,
          targetName,
          sourceTaskType,
          sourceActorId,
          targetTaskType,
          targetActorId,
        });
      }
    }

    if (!isValidConn) {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "NOT all nodes are connected",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }

    if (!isValidTran) {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "NOT all transitions are defined",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }

    if (!isValidStart) {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "[Start node] NOT Detected",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }

    if (!isValidEnd) {
      isValid = false;
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "[End node] NOT Detected",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }

    console.log("fixedData", fixedData);
    console.log("fixedTransitions", fixedTransitions);
    console.log("laneHeights", laneHeights);

    if (isValid) {
      try {
        canvas["laneHeights"] = laneHeights;

        this.setState({ isSaving: true });
        let flowdefinition = {
          tasks: fixedData,
          transitions: fixedTransitions,
          version: this.state.version,
          canvas: canvas,
        };
        let data = {
          flowdefinition: JSON.stringify(flowdefinition),
          id: this.props.match.params.id,
          formData: this.state.formData,
          tasks: fixedData,
          transitions: fixedTransitions,
          version: this.state.version,
          newTasks: this.state.newTasksArr,
          newActorsArr: this.state.newActorsArr,
          newflowVars: this.state.newflowVars,
        };

        console.log(data);
        let response = await ApiClient.doPut("/flows/update-diagram", data);
        var message = response.message;
        if (response.status) {
          setTimeout(
            function () {
              console.log(response.message);
              toastInstanceDiagram.show({
                content: "Diagram Saved",
                cssClass: "e-toast-success",
                icon: "e-success toast-icons",
              });
            }.bind(this),
            200
          );
          if (this.props.match.params.id === "0") {
            this.props.navigate("/manageDiagram/" + response.id);
          } else {
            console.log("ya no");
          }
        } else {
          setTimeout(
            function () {
              toastInstanceDiagram.show({
                content: "Error Saving Diagram",
                cssClass: "e-toast-error",
                icon: "e-error toast-icons",
              });
            }.bind(this),
            200
          );
        }

        this.setState({ isSaving: false });
      } catch (ex) {
        console.log("Ex", ex);
        this.setState({ isSaving: false });
      }
    }
  };

  goBack = () => {
    console.log("goBack");
    this.props.navigate("/manage/flows");
    /*
        this.setState({ goBack: true})        
        //this.props.navigate('/manage/flows');
        return redirect("/manage/flows");
        */
  };

  dialogTemplateClose = () => {
    this.setState({ dialogTemplate: false });
  };
  dialogTemplateOpen = () => {
    this.setState({ dialogTemplate: true });
  };

  setTemplate = (formData) => {
    console.log("set");
    this.setState({ dialogTemplate: false, isExecuting: true });

    setTimeout(
      async function () {
        this.setState({ isExecuting: false });
        this.loadDiagram({ target: { result: JSON.stringify(formData.DATA) } });
      }.bind(this),
      1000
    );
  };

  onChange = (event) => {
    this.state.formFields.map(function (a) {
      let val = "";
      if (a["name"] == event.target.name) {
        if (event.target.type) {
          if (event.target.type === "switch") {
            if (a["value"] === "") {
              val = "YES";
            } else {
              val = "";
            }
          }
        } else {
          val = event.target.value;
        }

        a["value"] = val;
      }
      return a;
    });
  };

  onChangeSI = (event) => {
    const currentInputs = this.state.currentInputs;
    let pos = currentInputs.findIndex(
      (item) => item.INPUTNAME === event.target.name
    );
    currentInputs[pos]["value"] = event.target.value;
    this.setState({ currentInputs: currentInputs });
  };

  footerDialogFlow = () => {
    return (
      <div>
        <ButtonComponent
          className={"ml-10 e-primary"}
          onClick={() => {
            document
              .getElementsByClassName("e-file-select-wrap")[0]
              .querySelector("button")
              .click();
          }}
          style={{ textTransform: "uppercase", fontWeight: "500" }}
        >
          Import
        </ButtonComponent>

        <ButtonComponent
          className={"ml-10 e-success"}
          disabled={this.state.isProcessing}
          onClick={this.saveTempFlow}
          style={{ textTransform: "uppercase", fontWeight: "500" }}
        >
          {" "}
          {!this.state.isProcessing
            ? this.props.defConfig[this.props.defLang].savedatalabel
            : "processing"}
        </ButtonComponent>
      </div>
    );
  };

  saveTempFlow = async (event) => {
    this.setState({ dialogTemplate: false });
    let formData = {};
    this.state.formFields.map(function (a) {
      formData[a.name] = a.value;
    });

    let nodes = [
      {
        id: "1",
        shape: {
          id: "base",
          type: "SwimLane",
          orientation: "Horizontal",
          //Intialize header to swimlane
          header: {
            annotation: {
              content: "FLOW:" + formData["flow"],
              style: {
                fontSize: 18,
                bold: true,
                fill: getPalette().traTask,
                strokeColor: getPalette().traTask,
              },
            },
            height: 45,
            style: {
              background: getPalette().traTask,
              strokeColor: getPalette().traTask,
              fill: getPalette().traTask,
            },
          },

          lanes: [
            {
              id: "mainmaster",
              height: 135,
              header: {
                annotation: { content: "Main" },
                width: 50,
                style: { fontSize: 11, fill: getPalette().traTask },
              },
              children: [],
            },
          ],
          phases: [
            {
              id: "version 1",
              offset: 120,
              header: { annotation: { content: "version 1" } },
              style: { fontSize: 16, fill: "#fff" },
            },
          ],
          phaseSize: 20,
        },
        offsetX: 440,
        offsetY: 120,
        height: 200,
        width: 850,
      },
    ];

    this.setState({
      isExecuting: false,
      nodes: nodes,
      formData: formData,
      flowName: formData["flow"],
      version: 1,
    });
    console.log("fin");
    setTimeout(
      function () {
        diagramInstance.fitToPage();
      }.bind(this),
      10
    );
    dialogInstanceFlow.hide();
  };

  onFiltering = (e) => {
    let query = new Query();
    query =
      e.text !== ""
        ? query.where("VARNAME", "startswith", e.text, true)
        : query;
    e.updateData(this.state.flowVars, query);
    if (document.getElementById("nodata")) {
      document.getElementById("btn").onclick = function () {
        let customValue = document.getElementById("customvalue").value;
        let newItem = { VARNAME: customValue, VARNAME: customValue };
        flowVarListInstance.dataSource.push(newItem);
        flowVarListInstance.hidePopup();
        flowVarListInstance.addItem(newItem);
        flowVarListInstance.value = customValue;
      };
    }
  };
  render() {
    return (
      <>
        <DialogComponent
          id="dialogInstanceFlow"
          header={"New flow Info"}
          footerTemplate={this.footerDialogFlow.bind(this)}
          width={"50%"}
          height={660}
          ref={(dialog) => (dialogInstanceFlow = dialog)}
          visible={false}
          isModal={true}
        >
          <div>
            {this.state.formFields.length > 0 && (
              <GenericForm
                data={
                  this.state.formFields.length > 0 ? this.state.formFields : []
                }
                onChange={this.onChange}
                buttons={[]}
              />
            )}
          </div>
        </DialogComponent>
        <DialogTemplates
          callBackAction={this.callBackAction}
          setTemplate={this.setTemplate}
          status={this.state.dialogTemplate}
          componentName={"template"}
          animationSettings={animationSettings2}
          templateType="flow"
          close={this.dialogTemplateClose}
          open={this.dialogTemplateOpen}
          hideButton={true}
        />

        <ToastComponent
          ref={(toast) => {
            toastInstanceDiagram = toast;
          }}
          position={{ X: "Right", Y: "Bottom" }}
          showCloseButton={true}
       
        ></ToastComponent>

        <div style={{ display: "none" }}>
          <UploaderComponent
            type="file"
            id="fileupload"
            asyncSettings={{
              saveUrl:
                "https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save",
              removeUrl:
                "https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove",
            }}
            success={this.onUploadSuccess}
          />
        </div>

        <DialogComponent
          id="invalidTasks"
          closeOnEscape={true}
          isModal={true}
          visible={false}
          buttons={this.menuContentButtons2}
          header={
            '<img class="dialog-icon" src="/images/caution.png" alt="CAUTION">' +
            "CAUTION"
          }
          width="370px"
          ref={(dialog) => (dialogInstanceCaution = dialog)}
          animationSettings={animationSettings}
        >
          <div style={{ minHeight: 100, paddingTop: 5 }}>
            {this.state.invalidTasks.length > 0 && (
              <>
                <div className="h5 mb-1">
                  Some TASKS are deleted or renamed from your Tasks Inventory:
                </div>

                {this.state.invalidTasks.map((item, i) => {
                  return (
                    <div className="item" key={"t" + i}>
                      {item}
                    </div>
                  );
                })}
              </>
            )}
            {this.state.invalidActors.length > 0 && (
              <>
                <div className="h5 mb-1">
                  Some ACTORS are deleted or renamed from your Actors Inventory:
                </div>

                {this.state.invalidActors.map((item, i) => {
                  return (
                    <div className="item" key={"a" + i}>
                      {item}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </DialogComponent>

        <DialogComponent
          id="newTasks"
          closeOnEscape={true}
          isModal={true}
          visible={false}
          buttons={this.menuContentButtons2b}
          header={"Warning"}
          width="370px"
          ref={(dialog) => (dialogInstanceNew = dialog)}
          animationSettings={animationSettings}
        >
          <div style={{ minHeight: 100, paddingTop: 5 }}>
            {this.state.newTasksArr.length > 0 && (
              <>
                <div className="h5 mb-1">
                  Some TASKS need to be added in your Tasks Inventory
                </div>

                {this.state.newTasksArr.map((item, i) => {
                  return (
                    <div className="item" key={"t" + i}>
                      {item.TASK}
                    </div>
                  );
                })}
              </>
            )}
            {this.state.newActorsArr.length > 0 && (
              <>
                <div className="h5 mb-1">
                  Some ACTORS need to be added in your Actors Inventory
                </div>

                {this.state.newActorsArr.map((item, i) => {
                  return (
                    <div className="item" key={"a" + i}>
                      {item.ACTOR}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </DialogComponent>

        <DialogComponent
          id="optionDialog"
          closeOnEscape={false}
          isModal={true}
          buttons={
            this.state.menuContent === "selectConnector"
              ? [
                  {
                    click: closeMenuContent.bind(this),
                    buttonModel: {
                      content: "Cancel",
                      isPrimary: false,
                      type: "button",
                    },
                  },
                ]
              : this.menuContentButtons
          }
          header={this.state.menuDialogTitle}
          width={this.state.menuContent === "selectConnector" ? "80%" : "335px"}
          height={this.state.menuContent === "selectConnector" ? "80%" : "auto"}
          visible={this.state.showMenuDialog ? true : false}
          animationSettings={animationSettings}
        >
          <div style={{ minHeight: 100, paddingTop: 10 }}>
            <div
              style={{
                display: this.state.menuContent === "actor" ? "block" : "none",
                visibility:
                  this.state.menuContent === "actor" ? "initial" : "hidden",
              }}
            >
              <ComboBoxComponent
                ref={(scope) => {
                  actorListInstance = scope;
                }}
                ignoreAccent={true}
                allowFiltering={true}
                name={"actors"}
                cssClass={"e-custom-combobox"}
                floatLabelType="auto"
                fields={{ text: "ACTOR", value: "ACTOR" }}
                dataSource={this.state.actors}
                placeholder="Actors"
              />
            </div>

            <div
              style={{
                display: this.state.menuContent === "task" ? "block" : "none",
                visibility:
                  this.state.menuContent === "task" ? "initial" : "hidden",
              }}
            >
              <div className="row">
                <div className="col-12 my-1">
                  <span className="h6"> Task Name</span>
                  <AutoCompleteComponent
                    id="tasks"
                    fields={{
                      groupBy: "FLOWGROUP",
                      value: "TASKID",
                      text: "TASK",
                    }}
                    showPopupButton={true}
                    dataSource={this.state.tasks}
                    ref={(AutoComplete) => {
                      taskListInstance = AutoComplete;
                    }}
                    placeholder="e.g. Task 1"
                  />
                </div>
                <div className="col-12 my-1">
                  <span className="h6"> Input Value (Optional) </span>
                  <input
                    className="e-input"
                    type="text"
                    ref={(scope) => {
                      inputValue = scope;
                    }}
                  />
                </div>
                <div className="col-12 my-1">
                  <span className="h6"> Output Value (Optional) </span>
                  <input
                    className="e-input"
                    type="text"
                    ref={(scope) => {
                      outputValue = scope;
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display:
                  this.state.menuContent === "transitionionParams"
                    ? "block"
                    : "none",
                visibility:
                  this.state.menuContent === "transitionionParams"
                    ? "initial"
                    : "hidden",
              }}
            >
              <div className="row">
                <div className="col-12 my-1">
                  <span className="h6"> Transition Name </span>
                  <input
                    className="e-input"
                    type="text"
                    ref={(scope) => {
                      transitionValue = scope;
                    }}
                  />
                </div>
                <div
                  className="col-12 my-1"
                  style={{
                    display: this.state.showTransitionWait ? "block" : "none",
                    visibility: this.state.showTransitionWait
                      ? "initial"
                      : "hidden",
                  }}
                >
                  <span className="h6"> Transition Type </span>
                  <ComboBoxComponent
                    id="transitionType"
                    ref={(ComboBox) => {
                      transitionType = ComboBox;
                    }}
                    dataSource={this.state.transitionTypes}
                    value={this.state.transitionType}
                    allowFiltering={false}
                    placeholder="Select a Type"
                    popupHeight="270px"
                  />
                </div>
                <div className="col-12 my-1">
                  <span className="h6"> Input Value </span>
                  <ComboBoxComponent
                    id="customvalue"
                    ref={(ComboBox) => {
                      flowVarListInstance = ComboBox;
                    }}
                    dataSource={this.state.flowVars}
                    filtering={this.onFiltering.bind(this)}
                    allowFiltering={true}
                    fields={{ value: "VARNAME", text: "VARNAME" }}
                    noRecordsTemplate={
                      '<div id="nodata"> No matched item, do you want to add it as new item in list?</div> <button id="btn" class="e-control e-btn">Add New Item</button>'
                    }
                    placeholder="e.g. Var 1"
                    popupHeight="270px"
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display:
                  this.state.menuContent === "changeVersion" ? "block" : "none",
                visibility:
                  this.state.menuContent === "changeVersion"
                    ? "initial"
                    : "hidden",
              }}
            >
              <AutoCompleteComponent
                id="version"
                fields={{ text: "VERSIONNAME", value: "VERSION" }}
                showPopupButton={true}
                dataSource={this.state.flowVersions}
                ref={(AutoComplete) => {
                  flowVersionsListInstance = AutoComplete;
                }}
                placeholder="e.g. Version 1"
              />
            </div>

            <div
              style={{
                display:
                  this.state.menuContent === "transitionName"
                    ? "block"
                    : "none",
                visibility:
                  this.state.menuContent === "transitionName"
                    ? "initial"
                    : "hidden",
              }}
            >
              <TextBoxComponent
                ref={(scope) => {
                  optionValue = scope;
                }}
                placeholder="Option Value"
                cssClass="e-outline"
                floatLabelType="Auto"
              />
            </div>

            <div
              style={{
                display:
                  this.state.menuContent === "addVersion" ? "block" : "none",
                visibility:
                  this.state.menuContent === "addVersion"
                    ? "initial"
                    : "hidden",
              }}
            >
              Confirm to create a new verion (Version{" "}
              {this.state.lastVersion + 1})
            </div>

            <div
              style={{
                display: this.state.menuContent === "inputs" ? "block" : "none",
                visibility:
                  this.state.menuContent === "inputs" ? "initial" : "hidden",
              }}
            >
              {this.state.currentInputs.map((item, index) => {
                return (
                  <>
                    <div className="col-xs-12" key={"col1_" + item.COMPONENTID}>
                      <TextBoxComponent
                        id={item.INPUTNAME}
                        data-msg-containerid={item.INPUTNAME + "Error"}
                        name={item.INPUTNAME}
                        placeholder={item.INPUTLABEL}
                        value={item.value ? item.value : ""}
                        onChange={({ value }) =>
                          this.onChangeSI({
                            target: { name: item.INPUTNAME, value },
                          })
                        }
                        cssClass={
                          (item.className ? item.className : "e-outline") +
                          (item.invalid ? " e-error" : "")
                        }
                        floatLabelType="Auto"
                      />
                    </div>
                  </>
                );
              })}
              <div className="col-xs-12">*Use [] to define a flow variable</div>
            </div>

            <div
              style={{
                display: this.state.menuContent === "rename" ? "block" : "none",
                visibility:
                  this.state.menuContent === "rename" ? "initial" : "hidden",
              }}
            >
              <div className="col-xs-12">
                <TextBoxComponent
                  data-msg-containerid={"currentNameError"}
                  name={"currentName"}
                  placeholder={"Tak Name"}
                  value={this.state.currentName}
                  onChange={({ value }) =>
                    this.setState({ currentName: value })
                  }
                  cssClass={"e-outline"}
                  floatLabelType="Auto"
                />
              </div>
            </div>

            <div
              style={{
                display:
                  this.state.menuContent === "selectConnector"
                    ? "block"
                    : "none",
                visibility:
                  this.state.menuContent === "selectConnector"
                    ? "initial"
                    : "hidden",
              }}
            >
              {this.state.customConnectors.map((item, index) => {
                return (
                  <div
                    className="col-lg-4 col-sm-6 mt-1"
                    key={"col1_" + item.COMPONENTID}
                  >
                    <div className="card-connector">
                      <div className="row item-connector">
                        <div
                          className="col-12"
                          key={"col2_" + item.COMPONENTID}
                        >
                          <div className="title">
                            {item.COMPONENTNAME.length > 20
                              ? item.COMPONENTNAME.substr(0, 17) + "..."
                              : item.COMPONENTNAME}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div
                          className="col-12 body"
                          key={"col2_" + item.COMPONENTID}
                        >
                          {item.DESCRIPTION.length > 60
                            ? item.DESCRIPTION.substr(0, 57) + "..."
                            : item.DESCRIPTION}
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div
                          className="col-12 body text-right"
                          key={"col3_" + item.COMPONENTID}
                        >
                          {this.state.compIds.indexOf(
                            item.COMPONENTID + "_xxxx"
                          ) < 0 ? (
                            <ButtonComponent
                              key={"btn" + index}
                              className="e-success-mini"
                              id={"LOADconnector_" + item.COMPONENTID}
                              name="Use_Template"
                            >
                              <span
                                className="e-icons e-check"
                                id={"LOADconnector_" + item.COMPONENTID}
                              >
                                {" "}
                                USE{" "}
                              </span>
                            </ButtonComponent>
                          ) : (
                            <ButtonComponent
                              key={"btn" + index}
                              disabled={true}
                              className="e-btn e-dark-mini"
                              id={"LOADconnector_" + item.COMPONENTID}
                              name="Use_Template"
                            >
                              <span className="e-icons e-check"> USED </span>
                            </ButtonComponent>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </DialogComponent>

        <style>{SAMPLE_CSS}</style>
        <div className="control-section">
          {this.state.isSaving && <LoadingSimple />}
          {this.state.isExecuting && <Loading timer={5000} />}
          <div style={{}}>
            <ToolbarComponent
              id="toolbar_diagram"
              style={{ width: "100%", height: "45px" }}
              clicked={contextToolbarMenuClick.bind(this)}
              items={this.state.toolBarOptions}
            ></ToolbarComponent>
          </div>

          <div
            className="row"
            style={{
              /*width: '100%', */ borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#fff",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <div id="palette-space" style={{ width: "20%", maxWidth: "240px" }}>
              <SymbolPaletteComponent
                id="symbolpalette"
                expandMode="Multiple"
                palettes={palettes}
                width={"100%"}
                symbolHeight={90}
                symbolWidth={70}
                getSymbolInfo={(symbol) => {
                  if (symbol["symbolInfo"] !== undefined) {
                    return {
                      width: 70,
                      height: 70,
                      //Add or Remove the Text for Symbol palette item.
                      description: {
                        //Defines the symbol description
                        //text: symbol["text"],
                        text: symbol.symbolInfo.description.text,
                        //Defines how to handle the text when its size exceeds the given symbol size
                        overflow: "Wrap",
                        fontSize: 11,
                      },
                    };
                  }
                  return {
                    description: {
                      text: symbol.shape["shape"],
                    },
                  };
                }}
                getNodeDefaults={(symbol) => {
                  symbol.style.strokeColor = "#757575";
                }}
                /*getSymbolInfo={(symbol) => {
                                            return { tooltip: symbol.addInfo ? symbol.addInfo['tooltip'] : symbol.id };
                                        }}*/
              >
                <Inject
                  services={[
                    BpmnDiagrams,
                    UndoRedo,
                    DiagramContextMenu,
                    DataBinding,
                  ]}
                />
              </SymbolPaletteComponent>
            </div>

            <div
              id="diagram-space"
              style={{ width: "80%", height: wHeight - 217 }}
            >
              {this.state.nodes.length > 0 && !this.state.addConncetor ? (
                <div style={{}}>
                  <DiagramComponent
                    id="diagram"
                    ref={(diagram) => (diagramInstance = diagram)}
                    width={"100%"}
                    height={wHeight - 220}
                    snapSettings={{
                      horizontalGridlines: gridlines,
                      verticalGridlines: gridlines,
                    }}
                    contextMenuSettings={contextMenu}
                    contextMenuOpen={contextMenuOpen}
                    contextMenuClick={contextMenuClick.bind(this)}
                    doubleClick={onDoubleClick.bind(this)}
                    nodes={this.state.nodes}
                    historyChange={(args) => {
                      this.getValue(args);
                    }}
                    getConnectorDefaults={(obj) => {
                      if (obj.id.indexOf("connector") !== -1) {
                        obj.style = {
                          textAlign: "center",
                          strokeColor: getPalette().primary,
                          strokeWidth: 1,
                        };
                        /*
                                                obj.annotations =  [{
                                                    //content: transition.option,
                                                    height: 50, 
                                                    offset: 0.5,
                                                    template: '<span class="transition-label" >Accept </span>' 
                                               }]*/
                      }
                    }}
                    created={() => {
                      // Adds to the Diagram
                      for (let i in this.state.connectors) {
                        diagramInstance.add(this.state.connectors[i]);
                      }
                    }}
                    constraints={
                      DiagramConstraints.Default | DiagramConstraints.Bridging
                    }
                    /*necessary???*/
                    AllowEditOnDblClick={false}
                    /*connectors={this.state.connectors}*/
                    dragOver={this.dragOver}
                    positionChange={this.positionChange}
                    dragEnter={this.dragEnter}
                  >
                    <Inject
                      services={[
                        Diagram,
                        BpmnDiagrams,
                        ConnectorBridging,
                        UndoRedo,
                        DiagramContextMenu,
                        DataBinding,
                      ]}
                    />
                  </DiagramComponent>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <div className="row" style={{ height: 47 }}>
            <div className={"col-xs-12 no-padding text-right"}>
              <ButtonComponent
                className={"ml-10 e-primary"}
                onClick={this.submitForm}
                iconCss="e-icons e-save-2"
                style={{
                  fontWeight: "500",
                  position: "relative",
                  top: 8,
                  marginRight: 3,
                }}
              >
                {this.props.defConfig[this.props.defLang].savelabel}
              </ButtonComponent>
              <ButtonComponent
                className="ml-10 e-success e-btn"
                iconCss="e-icons e-export"
                onClick={() => {
                  exportForm();
                }}
                style={{
                  fontWeight: "500",
                  position: "relative",
                  top: 8,
                  marginRight: 3,
                }}
              >
                Export
              </ButtonComponent>
              <ButtonComponent
                className={"e-btn ml-10 e-outlined-secondary"}
                iconCss="e-icons e-save-as"
                onClick={() => {}}
                style={{
                  fontWeight: "500",
                  position: "relative",
                  top: 8,
                  marginRight: 3,
                }}
              >
                Save Template
              </ButtonComponent>

              <ButtonComponent
                className={"e-btn e-outlined-secondary"}
                onClick={() => {
                  this.goBack();
                }}
                iconCss="e-icons e-back"
                style={{
                  fontWeight: "500",
                  position: "relative",
                  top: 8,
                  marginRight: 3,
                }}
              >
                {this.props.defConfig[this.props.defLang].backlabel}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//save the diagram object in json data.
function download(data) {
  if (window.navigator.msSaveBlob) {
    var blob = new Blob([data], { type: "data:text/json;charset=utf-8," });
    window.navigator.msSaveOrOpenBlob(blob, "Diagram.json");
  } else {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(data);
    var a = document.createElement("a");
    a.href = dataStr;
    a.download = "Diagram.json";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}

function setMenuContent() {
  console.log("setMenuContent", this.state.menuContent);
  let resp = true;
  if (this.state.menuContent === "actor") {
    let actorName = actorListInstance.value;
    let actorId = this.state.actors[actorListInstance.index].ACTORID;

    const actorIds = this.state.actorIds;
    let newActorId = setActor(actorName, actorIds);
    if (diagramInstance.selectedItems.nodes.length > 0) {
      const actorIds = this.state.actorIds;
      let selectedItem = diagramInstance.selectedItems.nodes[0];
      let pos = find(actorIds, selectedItem.id, "nodeId");
      if (pos < 0) {
        actorIds.push({ nodeId: selectedItem.id, actorId, actorName });
      } else {
        actorIds[pos] = { nodeId: selectedItem.id, actorId, actorName };
      }
    } else {
      actorIds.push({ nodeId: newActorId, actorId, actorName });
    }
  } else if (this.state.menuContent === "task") {
    var nodes = this.state.nodes;
    const children = nodes[0].shape.lanes[0].children;
    let taskId = this.state.tasks[taskListInstance.index].TASKID;
    let task = this.state.tasks[taskListInstance.index].TASK;
    let selectedItem = diagramInstance.selectedItems.nodes[0];
    //let taskType= this.state.tasks[taskListInstance.index].TASKTYPE

    console.log("TASK_", this.state.tasks[taskListInstance.index]);
    setTask(task);
    const nodeIds = this.state.nodeIds;
    let pos = find(nodeIds, selectedItem.id, "nodeId");
    if (pos < 0) {
      nodeIds.push({ nodeId: selectedItem.id, taskId, task });
    } else {
      nodeIds[pos] = { nodeId: selectedItem.id, taskId, task };
    }
    console.log("nodeIds", nodeIds);
  } else if (this.state.menuContent === "transitionionParams") {
    resp = setTransitionParams(this.state.transitionShapeType);

    if (!resp) {
      setTimeout(
        function () {
          toastInstanceDiagram.show({
            //content: errorMessage,  cssClass: 'e-toast-error', icon: 'e-success toast-icons'
            title: "Validation Error",
            content: "Select all parameters",
            cssClass: "e-toast-warning",
            icon: "e-error toast-icons",
          });
        }.bind(this),
        200
      );
    }
  } else if (this.state.menuContent === "changeVersion") {
    let version =
      this.state.flowVersions[flowVersionsListInstance.index].VERSION;
    diagramInstance.clear();
    this.setState({ isExecuting: true, nodes: [] });
    setTimeout(
      async function () {
        //console.log("reset")
        this.getFlowData(this.state.flowId, version);
        //diagramInstance.loadDiagram(newFlowDefinition);
      }.bind(this),
      10
    );
  } else if (this.state.menuContent === "addVersion") {
    let lastVersion = this.state.lastVersion;
    lastVersion++;
    if (diagramInstance.selectedItems.nodes.length > 0) {
      let selectedItem = diagramInstance.selectedItems.nodes[0];
      selectedItem.annotations[0].content = "Version " + lastVersion;
    }
    this.setState({ version: lastVersion, lastVersion: lastVersion });
  } else if (this.state.menuContent === "transitionName") {
    let pos = find(diagramInstance.connectors, this.state.connectorValue, "id");
    let obj = diagramInstance.connectors[pos];
    setTimeout(
      function () {
        let annotation = [
          {
            height: 50,
            offset: 0.5,
            //content : optionValue.value,
            template:
              '<span class="transition-label">' + optionValue.value + "</span>",
          },
        ];
        diagramInstance.addLabels(obj, annotation);
      }.bind(this),
      50
    );
  } else if (this.state.menuContent === "inputs") {
    const compIdsDesc = this.state.compIdsDesc;
    compIdsDesc[this.state.posInputs]["scriptinputs"] =
      this.state.currentInputs;
    this.setState({ compIdsDesc: compIdsDesc });
  } else if (this.state.menuContent === "rename") {
    var nodes = this.state.nodes;
    const children = nodes[0].shape.lanes[0].children;
    let selectedItem = diagramInstance.selectedItems.nodes[0];
    selectedItem.annotations[0].content = this.state.currentName;
    const nodeIds = this.state.nodeIds;
    let pos = find(nodeIds, this.state.currentId, "nodeId");
    nodeIds[pos]["task"] = this.state.currentName;
    this.setState({ nodeIds: nodeIds });
  }

  if (resp) {
    this.setState({ showMenuDialog: false });
  }
}

function closeMenuContent() {
  this.setState({ showMenuDialog: false });
}

function closeMenuContent2() {
  dialogInstanceCaution.hide();
}
function menuContentButtons2b() {
  dialogInstanceNew.hide();
}

function setActor(actorName, actorIds) {
  let swimlane = diagramInstance.getObject(
    diagramInstance.selectedItems.nodes[0].parentId
  );
  let shape = swimlane.shape;

  let currentActors = [];
  for (let s in actorIds) {
    let _actorName = actorIds[s].actorName;
    currentActors.push(_actorName);
  }
  if (tmpArgs.item) {
    if (
      diagramInstance.selectedItems.nodes.length > 0 &&
      diagramInstance.selectedItems.nodes[0].isLane
    ) {
      let index;

      if (currentActors.indexOf(actorName) < 0) {
        //console.log("1");
        let node = diagramInstance.selectedItems.nodes[0];
        let existingLane = cloneObject(shape.lanes[0]);
        let newLane = {
          header: {
            width: existingLane.header.width,
            height: existingLane.header.height,
            style: existingLane.header.style,
            annotation: {
              content: actorName,
              constraints: AnnotationConstraints.ReadOnly,
            },
            annotations: [
              {
                actorname: actorName,
                constraints: AnnotationConstraints.ReadOnly,
              },
            ],
          },
          style: existingLane.style,
          height: 200, //existingLane.height,
          width: existingLane.width,
        };
        console.log("2");
        if (shape.orientation === "Horizontal") {
          console.log("3");
          let exclude = 0;
          exclude += shape.header ? 1 : 0;
          exclude += shape.phases.length ? 1 : 0;
          index = node.rowIndex - exclude;
          newLane.header.width = existingLane.header.width;
          newLane.header.height = 200; //existingLane.height;
        } else {
          index = node.columnIndex - shape.phases.length ? 1 : 0;
          newLane.header.width = existingLane.width;
          newLane.header.height = existingLane.header.height;
        }
        console.log("4", tmpArgs.item.id);
        if (tmpArgs.item.id === "addActorBefore") {
          diagramInstance.addLanes(swimlane, [newLane], index);
        } else {
          diagramInstance.addLanes(swimlane, [newLane], index + 1);
        }
        diagramInstance.clearSelection();
        console.log("5");
      } else {
        alert("Actor already defined");
      }
    }
    return diagramInstance.nodes[diagramInstance.nodes.length - 2].id;
  } else {
    if (currentActors.indexOf(actorName) < 0) {
      if (diagramInstance.selectedItems.nodes.length > 0) {
        let selectedItem =
          diagramInstance.selectedItems.nodes[0].shape.header[0];
        console.log("selectedItem", selectedItem);
        selectedItem.annotation.content = actorName;
      }
    } else {
      alert("Actor already defined");
    }
    return 0;
  }
}

function setTransitionParams(transitionShapeType) {
  console.log("setTransitionParams", transitionShapeType);
  if (
    diagramInstance.selectedItems.nodes.length > 0 &&
    flowVarListInstance.index >= 0 &&
    transitionValue.value
  ) {
    let selectedItem = diagramInstance.selectedItems.nodes[0];
    if (transitionShapeType === "Inclusive") {
      if (transitionType.value === "STATIC") {
        selectedItem.annotations[0].content =
          transitionType.value + " - " + flowVarListInstance.value;
        selectedItem.annotations[0].style.textWrapping = "NoWrap";
      } else {
        selectedItem.annotations[0].content = flowVarListInstance.value;
      }
    } else {
      let taskType = "JOIN";
      if (transitionShapeType === "ParallelEventBased") {
        taskType = "XOR";
      } else if (transitionShapeType === "Complex") {
        taskType = "CONCENTRATOR";
      } else if (transitionShapeType === "Exclusive") {
        taskType = "KILLER";
      }

      selectedItem.annotations[0].content = flowVarListInstance.value
        ? flowVarListInstance.value
        : taskType;
    }

    selectedItem.annotations[0].offset.x = 0.5;
    selectedItem.annotations[0].offset.y = -0.125;
    selectedItem.annotations[0].verticalAlignment = "Top";
    selectedItem.annotations[0].horizontalAlignment = "Center";
    selectedItem.annotations[0].style.bold = true;
    selectedItem.annotations[0].style.fill = getPalette().primary;
    selectedItem.annotations[0].style.color = "#fff";

    selectedItem.annotations[1].content = transitionValue.value;
    selectedItem.annotations[1].offset.x = 0.5;
    selectedItem.annotations[1].offset.y = 1.1;
    selectedItem.annotations[1].verticalAlignment = "Top";
    selectedItem.annotations[1].horizontalAlignment = "Center";
    selectedItem.annotations[1].style.bold = true;
    selectedItem.annotations[1].style.fill = getPalette().autoTask;
    selectedItem.annotations[1].style.color = "#000";

    return true;
  } else {
    return false;
  }
}

function setTask(task) {
  if (diagramInstance.selectedItems.nodes.length > 0) {
    let selectedItem = diagramInstance.selectedItems.nodes[0];
    selectedItem.annotations[0].content = task;

    if (inputValue.value) {
      selectedItem.annotations[1].content = inputValue.value;
      if (inputValue.value !== "") {
        selectedItem.annotations[1].style = {
          bold: true,
          fill: "#fff",
          color: "#000",
          textWrapping: "NoWrap",
        };
      }
    }
    if (outputValue.value) {
      selectedItem.annotations[2].content = outputValue.value;
      if (outputValue.value !== "") {
        selectedItem.annotations[2].style = {
          bold: true,
          fill: "#fff",
          color: "#000",
          textWrapping: "NoWrap",
        };
      }
    }
  }
}

function contextMenuOpen(args) {
  let hiddenId = [];
  taskType = "";

  console.log("sel", diagramInstance.selectedItems.nodes[0]);

  if (diagramInstance.selectedItems.nodes.length > 0) {
    let bpmnShape = diagramInstance.selectedItems.nodes[0].shape;
    console.log("bpmnShape", bpmnShape);

    if (bpmnShape.shape == "Rectangle") {
      let selectedItem = diagramInstance.selectedItems.nodes[0];

      hiddenId = [
        "TaskProperty",
        "TransitionProperty",
        "TransitionParameters" /*'moveAnnotationUp', 'moveAnnotationDown' */,
      ];

      if (selectedItem.shape.header) {
        if (selectedItem.shape.header.length > 0) {
          if (
            selectedItem.shape.header[0].annotation &&
            selectedItem.id.indexOf("mainmaster") >= 0
          ) {
            hiddenId.push("changeActor");
            hiddenId.push("addActorBefore");
            hiddenId.push("Versioning");
          } else {
            //hiddenId.push('changeActor')
            hiddenId.push("addActorBefore");
            hiddenId.push("Versioning");
          }
        } else {
          console.log("nueva opcion", selectedItem.shape.header);
          hiddenId.push("Edit");
          hiddenId.push("Versioning");
        }
      } else {
        console.log("nueva opcion");
        hiddenId.push("Edit");
      }
    } else if (bpmnShape.shape == "Gateway") {
      hiddenId = ["Edit", "TaskProperty", "Versioning"];
    } else if (bpmnShape.shape == "Activity") {
      console.log("ACT", bpmnShape.activity.task.type);
      hiddenId = [
        "Edit",
        "TransitionProperty",
        "TransitionParameters",
        "Versioning",
      ];

      if (bpmnShape.activity.task.type === "PRIVATECONN") {
        hiddenId.push("DefineTask");
      } else {
        hiddenId.push("Inputs", "Outputs", "Rename");
      }

      taskType = bpmnShape.activity.task.type;
    } else {
      hiddenId = [
        "TaskProperty",
        "Edit",
        "TransitionProperty",
        "TransitionParameters",
        "Versioning",
      ];
    }
  } else {
    hiddenId = [
      "TaskProperty",
      "Edit",
      "TransitionProperty",
      "TransitionParameters",
      "Versioning",
    ];
  }

  if (diagramInstance.selectedItems.connectors.length > 0) {
    console.log("aqui");
    hiddenId = [
      "TaskProperty",
      "TransitionProperty",
      "TransitionParameters",
      "changeActor",
      "addActorBefore",
      "addActorAfter" /*"Versioning" /*, "Edit"*/,
    ];
  }

  args.hiddenItems = hiddenId;
}

function contextToolbarMenuClick(args) {
  //console.log("state", args, this.state )

  if (args.item.text === "Reset") {
    diagramInstance.clear();

    let nodes = [
      {
        id: "1",
        shape: {
          id: "base",
          type: "SwimLane",
          orientation: "Horizontal",
          //Intialize header to swimlane
          header: {
            annotation: {
              content: "FLOW:" + this.state.flowName,
              style: {
                fill: getPalette().traTask,
                strokeColor: getPalette().traTask,
              },
            },
            height: 45,
            style: {
              fontSize: 18,
              background: getPalette().traTask,
              strokeColor: getPalette().traTask,
              fill: getPalette().traTask,
            },
          },

          lanes: [
            {
              id: "mainmaster",
              height: 100,
              header: {
                annotation: { content: "Main" },
                width: 50,
                style: { fontSize: 11, fill: getPalette().traTask },
              },
              children: [],
            },
          ],
          phases: [
            {
              id: "version 1",
              offset: 120,
              header: {
                annotation: { content: "version: " + this.state.version },
              },
              style: { fontSize: 16, fill: "#fff" },
            },
          ],
          phaseSize: 20,
        },
        offsetX: 440,
        offsetY: 120,
        height: 200,
        width: 850,
      },
    ];

    this.setState({ isExecuting: true, nodes: [], connectors: [] });

    setTimeout(
      function () {
        this.setState({ isExecuting: false, nodes: nodes });
      }.bind(this),
      1000
    );
  } else if (args.item.text === "Import") {
    document
      .getElementsByClassName("e-file-select-wrap")[0]
      .querySelector("button")
      .click();
  } else if (args.item.text === "Templates") {
    this.setState({ dialogTemplate: true });
  } else if (args.item.text === "Export") {
    this.exportFlow();
  } else if (args.item.text === "Change version") {
    if (flowVersionsListInstance) {
      flowVersionsListInstance["value"] = null;
      flowVersionsListInstance["text"] = null;
    }
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Change Version",
      menuContent: "changeVersion",
    });
  } else if (args.item.text === "My connectors") {
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Select Custom Connector",
      menuContent: "selectConnector",
    });
  } else if (args.item.text === "Add version") {
    if (flowVersionsListInstance) {
      flowVersionsListInstance["value"] = null;
      flowVersionsListInstance["text"] = null;
    }
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Add new Version",
      menuContent: "addVersion",
    });
  } else if (args.item.text === "Change Position") {
    let nodeToAdd = swimlaneShapes[0];
    //nodeToAdd["id"] = 10

    nodeToAdd["offsetX"] = 110;
    nodeToAdd["offsetY"] = 200;

    //console.log(diagramInstance.nodes)

    let newItems = [];
    let newConnectors = [];

    let posI = 0;
    let existingLane;
    let fixedPosition = 0;
    let fixexPhase;

    for (let c in diagramInstance.connectors) {
      let connector = diagramInstance.connectors[c];
      let fixConnector = cloneObject(connector);
      newConnectors.push(fixConnector);
    }

    for (let i in diagramInstance.nodes) {
      let node = diagramInstance.nodes[i];
      //console.log(node.shape.type,node.shape.id, node.parentId )
      if (node.shape.type == "SwimLane") {
        posI = i;
        existingLane = cloneObject(node);

        for (let l in existingLane.shape.lanes) {
          //     existingLane.shape.lanes[l].id = "h"+existingLane.shape.lanes[l].id
          existingLane.shape.lanes[l].children = [];
        }

        existingLane.shape.phases = [];

        let curPos = existingLane.container.orientation;

        if (curPos == "Horizontal") {
          existingLane.container.orientation = "Vertical";
          existingLane.shape.orientation = "Vertical";
        } else {
          existingLane.container.orientation = "Horizontal";
          existingLane.shape.orientation = "Horizontal";
        }

        fixedPosition = existingLane.width + existingLane.offsetX;
      } else if (node.shape.type == "Bpmn") {
        //console.log("es un elemento", node.offsetX, fixedPosition)
        let fixNode = cloneObject(node);
        fixNode.parentId = "";
        fixNode.margin = {};
        fixNode.offsetX = fixedPosition;

        //let X = fixNode.offsetX
        //let Y = fixNode.offsetY
        //fixNode.offsetX=Y
        //fixNode.offsetY=X
        //fixNode.wrapper.offsetX= fixedPosition
        delete fixNode.tooltip;
        newItems.push(fixNode);
      } else if (node.shape.type == "Basic") {
        //note used
        if (node.PhaseHeaderParent) {
          fixexPhase = cloneObject(node);
        }
      }
    }

    for (let i in diagramInstance.nodes) {
      let node = diagramInstance.nodes[i];
      //            diagramInstance.remove(node)
    }
    diagramInstance.clear();

    diagramInstance.add(existingLane);

    for (let n in newItems) {
      diagramInstance.add(newItems[n]);
    }
    for (let c in newConnectors) {
      diagramInstance.add(newConnectors[c]);
    }
    diagramInstance.dataBind();
  } else if (args.item.text === "Undo") {
    diagramInstance.undo();
  } else if (args.item.text === "Redo") {
    diagramInstance.redo();
  }
}

function onDoubleClick(args) {
  //console.log("args", args.source.id)

  if (args.source.id.indexOf("TaskService") >= 0) {
    if (taskListInstance) {
      taskListInstance["value"] = null;
      taskListInstance["text"] = null;
    }

    let tasktypeId = 52;
    let fixedTasks = [];
    this.state.originalTasks.map(function (key, item) {
      if (key.TASKTYPEID === tasktypeId) {
        fixedTasks.push(key);
      }
    });
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Select Task",
      menuContent: "task",
      taskType: taskType,
      tasks: fixedTasks,
    });
  } else if (args.source.id.indexOf("Task") >= 0) {
    if (taskListInstance) {
      taskListInstance["value"] = null;
      taskListInstance["text"] = null;
    }

    let tasktypeId = 50;

    let fixedTasks = [];
    this.state.originalTasks.map(function (key, item) {
      if (key.TASKTYPEID === tasktypeId) {
        fixedTasks.push(key);
      }
    });
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Select Task",
      menuContent: "task",
      taskType: taskType,
      tasks: fixedTasks,
    });
  } else if (args.source.id.indexOf("changeVersion") >= 0) {
    if (flowVersionsListInstance) {
      flowVersionsListInstance["value"] = null;
      flowVersionsListInstance["text"] = null;
    }
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Change Version",
      menuContent: "changeVersion",
    });
  } else if (args.source.id.indexOf("addVersion") >= 0) {
    if (flowVersionsListInstance) {
      flowVersionsListInstance["value"] = null;
      flowVersionsListInstance["text"] = null;
    }
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Add new Version",
      menuContent: "addVersion",
    });
  } else if (args.source.id.indexOf("Transition") >= 0) {
    let showTransitionWait = false;
    if (
      diagramInstance.selectedItems.nodes[0].shape.gateway.type === "Inclusive"
    ) {
      showTransitionWait = true;
    }
    if (flowVarListInstance) {
      flowVarListInstance["value"] = null;
      flowVarListInstance["text"] = null;
    }
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Define Parameters",
      menuContent: "transitionionParams",
      showTransitionWait: showTransitionWait,
    });
  }

  return false;
}

function contextMenuClick(args) {
  console.log("contextMenuClick", args.item.id, taskType, this);

  inputValue.value = "";
  outputValue.value = "";

  optionValue.value = "";
  transitionValue.value = "";

  if (diagramInstance.selectedItems.nodes.length > 0) {
    let bpmnShape = diagramInstance.selectedItems.nodes[0].shape;

    if (bpmnShape.gateway) {
      if (args.item.iconCss) {
        if (args.item.iconCss.indexOf("e-gate") > -1) {
          let task = args.item.id.replace("Gateway", "");
          if (bpmnShape.shape === "Gateway") {
            bpmnShape.gateway.type = task;
          }
        }
      }
    }
  }

  if (args.item.id === "addActorBefore" || args.item.id === "addActorAfter") {
    //document.getElementById("selectedActor").style.display = "none";
    tmpArgs = args;
    actorName = "";

    this.setState({
      actorName: "",
      showMenuDialog: true,
      menuDialogTitle: "Select Actor",
      menuContent: "actor",
    });
    //dialogInstanceActor.show()
  } else if (args.item.id == "changePosition") {
    for (let n in diagramInstance.nodes) {
      //console.log("checking", diagramInstance.nodes[n].shape.type)
      if (diagramInstance.nodes[n].shape.type === "SwimLane") {
        console.log("es true", diagramInstance.nodes[n].shape);
        let shape = diagramInstance.nodes[n].shape;
        shape.orientation = "Horizontal";
        diagramInstance.dataBind();
        //diagramInstance.updateData();
      }
    }
  } else if (args.item.id == "changeActor") {
    tmpArgs = {};

    if (diagramInstance.selectedItems.nodes.length > 0) {
      let selectedItem = diagramInstance.selectedItems.nodes[0];
      //console.log("selectedItem", selectedItem)
      actorName = selectedItem.shape.header[0].annotation.content;

      this.setState({
        actorName: actorName,
        showMenuDialog: true,
        menuDialogTitle: "Change Actor",
        menuContent: "actor",
      });
      //dialogInstanceActor.show();
    }
  } else if (args.item.id == "DefineTask") {
    if (diagramInstance.selectedItems.nodes.length > 0) {
      if (taskListInstance) {
        taskListInstance["value"] = null;
        taskListInstance["text"] = null;
      }

      let tasktypeId = 50;
      if (taskType === "Automatic") {
        tasktypeId = 52;
      }
      let fixedTasks = [];
      this.state.originalTasks.map(function (key, item) {
        if (key.TASKTYPEID === tasktypeId) {
          fixedTasks.push(key);
        }
      });
      this.setState({
        showMenuDialog: true,
        menuDialogTitle: "Select Task",
        menuContent: "task",
        taskType: taskType,
        tasks: fixedTasks,
      });
    }
  } else if (args.item.id === "TransitionParameters") {
    var showTransitionWait = false;
    if (
      diagramInstance.selectedItems.nodes[0].shape.gateway.type === "Inclusive"
    ) {
      showTransitionWait = true;
    }
    if (diagramInstance.selectedItems.nodes.length > 0) {
      if (flowVarListInstance) {
        flowVarListInstance["value"] = null;
        flowVarListInstance["text"] = null;
      }
      this.setState({
        showMenuDialog: true,
        menuDialogTitle: "Define Parameters",
        menuContent: "transitionionParams",
        showTransitionWait: showTransitionWait,
      });
    }
  } else if (args.item.id.indexOf("changeVersion") >= 0) {
    if (flowVersionsListInstance) {
      flowVersionsListInstance["value"] = null;
      flowVersionsListInstance["text"] = null;
    }
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Change Version",
      menuContent: "changeVersion",
    });
  } else if (args.item.id.indexOf("addVersion") >= 0) {
    if (flowVersionsListInstance) {
      flowVersionsListInstance["value"] = null;
      flowVersionsListInstance["text"] = null;
    }
    this.setState({
      showMenuDialog: true,
      menuDialogTitle: "Add new Version",
      menuContent: "addVersion",
    });
  } else if (args.item.id.indexOf("Inputs") >= 0) {
    console.log("NODEEE", diagramInstance.selectedItems.nodes[0]);
    console.log(diagramInstance.selectedItems.nodes[0].id);
    if (taskType === "PRIVATECONN") {
      let id = diagramInstance.selectedItems.nodes[0].id.replace(
        "TaskConnector___",
        ""
      );
      console.log("id", id, this.state.compIdsDesc);
      let pos = this.state.compIdsDesc.findIndex((item) => item.id === id);
      let scriptinputs = this.state.compIdsDesc[pos].scriptinputs;
      this.setState({
        showMenuDialog: true,
        menuDialogTitle: "Join Inputs to task",
        menuContent: "inputs",
        currentInputs: scriptinputs,
        posInputs: pos,
      });
    }
  } else if (args.item.id.indexOf("Rename") >= 0) {
    if (taskType === "PRIVATECONN") {
      let id = diagramInstance.selectedItems.nodes[0].id.replace(
        "TaskConnector___",
        ""
      );
      this.setState({
        showMenuDialog: true,
        menuDialogTitle: "Rename Task",
        menuContent: "rename",
        currentId: diagramInstance.selectedItems.nodes[0].id,
        currentName:
          diagramInstance.selectedItems.nodes[0].annotations[0].content,
      });
    }
  }
  diagramInstance.dataBind();
}

export default ManageDiagram;
