import * as React from 'react';


const Plans = (props) => {

	  
	  return (
		<div className="row">
		<div className=" col-xs-12 col-sm-12  col-md-12 col-lg-4 col-xl-4 flex-center ">
			
			<div onClick={()=>{props.changePlan(1)}}  className={"banner-features-"+ ( props.activePlan===1?"wrapper": "single")+ " wow fadeIn animated hover-up"}>
				<div className= {"banner-features"+ ( props.activePlan===1?"-active": "")}>
					<h3 className="mb-2" >Free Trial (15 days)</h3>

					<p>Limited Features</p>
					<p>Simple use cases</p>
					<p>Only 5 users</p>
					<p>50 automatic tasks per day</p>
					<p>Only 5 flows</p>
					<p>Only 10 custom forms</p>
					<p>&nbsp; </p>
					<p className="not-included">Create custom pages</p>
					<span className="divider"></span>

					<p>*Expired time pass to Standard plan</p>

				</div>
			</div>
		</div>

		<div className=" col-xs-12 col-sm-12  col-md-12 col-lg-4 col-xl-4 flex-center ">
			<div onClick={()=>{props.changePlan(2)}} className={"banner-features-"+ ( props.activePlan===2?"wrapper": "single")+ " wow fadeIn animated hover-up"}>				
				<div className= {"banner-features"+ ( props.activePlan===2?"-active": "")}>
					<h3 className="mb-2 plan-title" >Standard</h3>
					
					<p>Template Center</p>
					<p>Complex use cases</p>
					<p>Up to 50 users </p>
					<p>2000 automatic tasks per day</p>
					<p>(over t3.micro Amarzon EC2 instance)</p>
					<p>Up to 50 flows</p>
					<p>Up to 10 custom pages</p>
					<p>Unlimited custom forms</p>

					<span className="divider"></span>
					<p className="price-title">Starts at</p>
					<p className="price">$200/month</p>
						 
				  
				</div>
			</div>
		</div>

		<div className=" col-xs-12 col-sm-12  col-md-12 col-lg-4 col-xl-4 flex-center ">
			<div onClick={()=>{props.changePlan(3)}} className={"banner-features-"+ ( props.activePlan===3?"wrapper": "single")+ " wow fadeIn animated hover-up"}>
				<div className= {"banner-features"+ ( props.activePlan===3?"-active": "")}>

					<h3 className="mb-2 plan-title"  >Pro</h3>
					
					<p>Template Center</p>
					<p>Complex use cases</p>
					<p>Up to 200 users </p>
					<p>Unlimited automatic tasks per day</p>
					<p>(over t3.medium Amarzon EC2 instance)</p>
					<p>Unlimited flows</p>
					<p>Unlimited custom pages</p>
					<p>Unlimited custom forms</p>

					<span className="divider"></span>

					<p className="price-title">Starts at</p>
					<p className="price">$300/month</p>
						
				</div>
			</div>
		</div>

	</div>
	  );

  
};

Plans.propTypes = {};

export default Plans;

