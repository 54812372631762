import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as ProcessActions from "../../redux/actions/process.actions";
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Page,  Inject } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";

import { simplePostData, getUserInfo} from '../../utils/Functions';
import ApiClient from "../../api/ApiClient";
var varsGridInstance
var initFlowDialogInstance;

const DialogInitFlowTemplate = (props) => {

  const fields = { value: 'CODE', text: 'NAME' };
  const fieldsV = { value: 'version', text: 'versionlabel' };
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top'  };
  const validationRule = { required: true };
  const pageSettings = { pageCount: 5 };
  const header = "Init Flow (testing)"

  const { defConfig,  defLang, } = useSelector((state) => state.Login);
  const { componentConfig, processSuccess,  processError, requesting, processMessage, initialValues, validationSchema, rows} = useSelector( (state) => state.Process );

  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const [flowVars,setFlowVars] = React.useState(0); 

  const [offices,setOffices] = React.useState([]); 
  const [office,setOffice] = React.useState(0); 

  const [versions,setVersions] = React.useState([]); 
  const [version,setVersion] = React.useState(0); 

  React.useEffect(() => {
       
    if (props.status && props.id>0){
      //console.log("cargamos");
      if (rows.length>0){
        //console.log("rows:", rows)
        setFlowVars(rows)

        if(versions.length==0){         
          FetchVersions()      
        }
        
        if(offices.length==0){         
          FetchOffices()      
        }

      }       
    }
    
  }, [props, rows]);
  
  const FetchVersions = async()=> {
    let response = await ApiClient.doPost("/flows/"+"get-flow-versions", { id: props.id});
    let _versions =[]
    
    response.result.map(( key) => {
      _versions.push ({version: key.VERSION, versionlabel: "Version "+key.VERSION, definition: key.FLOWDEFINITION } )
    });
    
    setVersions(_versions)
    setVersion(response.result[0].VERSION)
  }


  const FetchOffices = async()=> {
    let response = await ApiClient.doPost("/organizations/"+"get-all", { });
    setOffices(response.result)
    setOffice(response.result[0].CODE)
  }

  const sortingOptions = { columns: [{ field: 'VARNAME', direction: 'Ascending' }] };

  const submitForm = async (event) => {
    
    
    let flowData ={
      organization: getUserInfo().ORGANIZATIONCODE,
      flowcode: props.code,
      officecode: office,
      params: varsGridInstance.dataSource 
    }
    varsGridInstance.dataSource .map(function (key, item) {
      key["var"]= key["VARNAME"]
      key["value"]= key["DEFVALUE"]
    }); 
    dispatch(ProcessActions.processData( "flows", "init-flow", flowData, props.header ) )
    initFlowDialogInstance.hide()
    
  };

  const clickHandler = async (args) => {
		
    if (  args.item.id === "savevars"){    
      var selectedRows = varsGridInstance.dataSource.filter( (it, index) => {
        if (it.VARNAME == args.data.VARNAME &&  it.SORTORDER == args.data.SORTORDER ) {
          items.push(index);
          varsGridInstance.deleteRecord("VARNAME", it);  
        } 
        varsGridInstance.selectRows(items);
      }); 
      varsGridInstance.addRecord({ SORTORDER: 34, VARNAME: "f", VARLABEL: "f", DEFVALUE: "f" } )
    }
  };
  const actionComplete = (args) => {
	
    if (!args.item){

      if (args.requestType==="save") {
        let delItem=-1
        for (let k= 0; k < varsGridInstance.dataSource.length; k++) {
          let key = varsGridInstance.dataSource[k]
          //console.log("comparando",  args.data.VARNAME == key.VARNAME)
          if ( args.data.VARNAME == key.VARNAME){
            delItem ++
          }
        }        
      }
      
    }else{
        console.log("ac", args.item)
    }
  }

  const handleOnChange = async(event) => {
    setOffice(event.CODE)
  }
  const handleOnChangeV = async(event) => {
    setVersion(event.version)
  }
 

  return (
    <DialogComponent
			id="initFlowDialogInstance"
			showCloseIcon={true}
      animationSettings={props.animationSettings}
			closeOnEscape={false}
			ref={(dialog) => (initFlowDialogInstance = dialog)}
			isModal={true}
			open={props.open} 
      close={props.close}
			header={header}
      buttons={
      [
        {
            click: () => {
              submitForm();
            },
            buttonModel: {
                content: 'Init Flow',
                isPrimary: true,
            },
        },
        {
            click: () => {
                //props.close();
                initFlowDialogInstance.hide();
            },
            buttonModel: {
                content: 'CANCEL',
                isSecondary: true,
            },
        },
      ]}
			
			height="100%"
			className="partial-form"
			position={ { X: 'right', Y: 'top' }}
			visible={props.status} 
		>               
    
    <div className='control-pane'>
      <div className='control-section'>
        <div className='col-12'>
        {flowVars.length>0 &&
          <>

            <div className='col-12 mt-1 mb-4'>
              <h5> Choose a Version</h5>
              <DropDownListComponent
                  dataSource={versions}
                  name={"versions"} 
                  fields={fieldsV}
                  select={({ itemData }) => {
                  handleOnChangeV( itemData);
                }} 
                  value={version}
                />
            </div>

            <div className='col-12 mt-1 mb-4'>
              <h5> Choose a Departament</h5>
              <DropDownListComponent
                dataSource={offices}
                name={"offices"} 
                fields={fields}
                select={({ itemData }) => {
                handleOnChange( itemData);
              }} 
                value={office}
              />
            </div>

       

          
            <GridComponent 
            toolbarClick={clickHandler.bind(this)}
                actionComplete={actionComplete.bind(this)}
                ref={(dialog) => (varsGridInstance = dialog)}
                dataSource={flowVars} 
                pageSettings={pageSettings} 
               
                /*sortSettings={sortingOptions}*/
                editSettings={editSettings}>
                <ColumnsDirective>
                {/** <ColumnDirective field='SORTORDER' headerText='Sort Order' width='120' validationRules={numberRules}  textAlign='Right' editType='numericedit' ></ColumnDirective> */}
                  <ColumnDirective field='VARNAME'   headerText='Var Name'  width='130'  isPrimaryKey={true} validationRules={validationRule} ></ColumnDirective>
                  <ColumnDirective field='DEFVALUE'  headerText='Value'  width='130'></ColumnDirective>
                  
                </ColumnsDirective>
                <Inject services={[ Toolbar, Edit]}/>
              </GridComponent>

              <span>*Double Click to edit Value </span>
            </>
          }
        </div>
         
      </div>
    </div>
    </DialogComponent>
  );
};
DialogInitFlowTemplate.propTypes = {};

export default DialogInitFlowTemplate;
