import * as React from "react";
import {
  UploaderComponent,
  FilesDirective,
  UploadedFilesDirective,
} from "@syncfusion/ej2-react-inputs";

import { getCookie } from "../../utils/Functions";

var token = getCookie(process.env.REACT_APP_NAME + "_usertoken");
var organization = getCookie(process.env.REACT_APP_NAME + "_userorg");
class CompFormFileManager extends React.Component {
  dropContainerRef;
  dropContainerEle;
  uploadObj;
  constructor(props) {
    super(props);
    this.dropContainerEle = null;
    this.dropContainerRef = (element) => {
      this.dropContainerEle = element;
    };
    //this.onAdd = this.onAdd.bind(this);
    this.state = {
      isExecuting: true,
      filemanagerObj: {},
      dataSource: [],
      dashboardObj: {},
      minFileSize: 1000,
      maxFileSize: 5000000,
      dialogInstanceField3: {},
      count: 0,
      asyncSettings: {
        saveUrl:
          process.env.REACT_APP_API_CONFIG +
          "/files/uploadS3?webToken=" +
          token +
          (this.props.rest?.defaultvalue
            ? "&path=" + this.props.rest.defaultvalue
            : ""),
        removeUrl:
          process.env.REACT_APP_API_CONFIG +
          "/files/deleteS3?webToken=" +
          token +
          (this.props.rest?.defaultvalue
            ? "&path=" + this.props.rest.defaultvalue
            : ""),
      },
      allowedExtensions: ".doc, .docx, .xls, .xlsx, .pdf, .png, .jpg",
      hostUrl: process.env.REACT_APP_API_CONFIG,
      contextmenuItems: [
        "Open",
        "|",
        "Cut",
        "Copy",
        "Delete",
        "Rename",
        "|",
        "Details",
      ],

      formFields: [],
      formVisible: false,
      completeFields: [],
      sizeCol: 6,
      fields: [],
      fieldId: "",
      datapostload: "",
      filedOptions: [
        { text: "Full Size", value: 12 },
        { text: "Medium Size", value: 6 },
        { text: "Small", value: 4 },
        { text: "Extra Small", value: 2 },
      ],

      localeText: {
        browse: "Seleccionar archivo", // Aquí puedes poner el texto en español que desees
        cancel: "Cancelar",
        clear: "Limpiar",
        dropArea: "Arrastra y suelta archivos aquí o haz clic para seleccionar",
        error: "Error",
        remove: "Eliminar",
        retry: "Reintentar",
        uploadSelectedFiles: "Subir archivos seleccionados",
      },
    };
  }

  rendereComplete() {
    this.uploadObj.dropArea = this.dropContainerEle;
    this.uploadObj.element.setAttribute("name", "UploadFiles");
    this.uploadObj.dataBind();
  }

  componentDidMount = async () => {
    //console.log("this::::::::::::", this.props);
    if (this.props.value) {
      if (this.props.value != "") {
        setTimeout(
          async function () {
            const liElements = document.body.querySelectorAll(
              ".e-upload-file-list"
            );
            // console.log(liElements);
            for (let element of liElements) {
              //     console.log("e", element);

              let spans = element.getElementsByClassName("e-file-type");
              for (let span of spans) {
                span.style.cursor = "pointer";
                span.style.width = "35%";
                // console.log(span);
              }

              spans = element.getElementsByClassName("e-file-name");
              for (let span of spans) {
                span.style.cursor = "pointer";
                //  console.log(span);
              }

              element.addEventListener("click", this.openFile);

              // File path have to update from server end in response status description.
            }
          }.bind(this),
          1050
        );
      }
    }
  };

  openFile = (event) => {
    let type = event.target.getAttribute("class");
    if (type === "e-file-name" || type === "e-file-type") {
      /*console.log(
        event,
        event.target,
        event.target.parentNode.parentNode.getAttribute("data-file-name")
      );*/
      let file =
        event.target.parentNode.parentNode.getAttribute("data-file-name");

      let downloadFile = window.open(
        process.env.REACT_APP_S3PATH_IMAGES + "/" + organization + file,
        "_blank"
      );

      if (!downloadFile) {
        alert(
          "No se pudo abrir la ventana. Asegúrate de permitir ventanas emergentes en tu navegador."
        );
      }
    }
  };

  onChange = (event) => {
    const formFields = this.state.formFields.map(function (a) {
      let val = "NO";
      if (a["name"] == event.target.name) {
        if (event.target.type) {
          if (event.target.type === "switch") {
            if (a["value"] === "YES") {
              val = "NO";
            } else {
              val = "YES";
            }
            a["value"] = val;
          } else if (event.target.type === "options") {
            let data = event.target;
            let value = {};
            if (data.datatype == "static") {
              value = {
                datatype: data.datatype,
                options: data.options,
              };
              //   a["values"] = data.options
              a["value"] = value;
            } else {
              value = {
                datatype: data.datatype,
                apidata: data.apidata,
                apimethod: data.apimethod,
                apitoken: data.apitoken,
                apiurl: data.apiurl,
                text: data.text,
                value: data.value,
              };
              val = value;
              // a["api"] = value
              a["value"] = value;
            }
          }
        } else {
          val = event.target.value;
          a["value"] = val;
        }
      }
      return a;
    });

    //console.log("formFields",formFields)
  };

  saveField = (event) => {
    //console.log("save");

    this.onChangeForm();
    this.state.dialogInstanceField3.hide();
  };

  onFileOpen(args) {
    let file = args.fileDetails;
    if (file.isFile) {
      args.cancel = true;
      if (file.size <= 0) {
        file.size = 10000;
      }
      this.fileUploadObj.files = [
        { name: file.name, size: file.size, type: file.type },
      ];
      this.state.dialogInstanceField3.hide();
    }
  }
  btnClick() {
    this.state.dialogInstanceField3.show();
    this.dialogOpen();
    this.state.filemanagerObj.path = "/";
    this.state.filemanagerObj.selectedItems = [];
    this.state.filemanagerObj.refresh();
  }
  contextToolbarMenuTaskClick = async (args) => {
    //  console.log(args.item.text);
    //  console.log(args.fileDetails);
  };

  onFileSelected(args) {
    args.filesData.splice(5);
    let filesData = this.uploadObj.getFilesData();
    let allFiles = filesData.concat(args.filesData);
    if (allFiles.length > 5) {
      for (let i = 0; i < allFiles.length; i++) {
        if (allFiles.length > 5) {
          allFiles.shift();
        }
      }
      args.filesData = allFiles;
      args.modifiedFilesData = args.filesData;
    }
    args.isModified = true;
  }
  onRemoveFile = (args) => {
    args.postRawFile = false;
  };

  onUploadSuccess = (args) => {
    // fetching the generated li elements
    let liElements = document.body.querySelectorAll(".e-upload-file-list");
    //console.log(args);
    let defaultvalue = "";
    if (this.props.rest?.defaultvalue) {
      defaultvalue =
        "/" + this.props.rest.defaultvalue.replaceAll("___", "/") + "/";
    }

    let files = [];
    this.uploadObj.filesData.map(function (key) {
      files.push(defaultvalue + key.name);
    });

    //console.log("args.operation",args.operation, files,  args.file.name)

    if (args.operation === "remove") {
      let pos = files.findIndex(
        (item) =>
          item.replaceAll(" ", "") ===
          defaultvalue + args.file.name.replaceAll(" ", "")
      );
      if (pos >= 0) {
        files.splice(pos, 1);
      }
    }

    let vFiles = [];
    for (let i in files) {
      if (this.props.rest?.defaultvalue && files[i].indexOf("/") < 0) {
        vFiles.push(
          "/" +
            this.props.rest.defaultvalue.replaceAll("___", "/") +
            "/" +
            files[i].replaceAll(" ", "")
        );
      } else {
        vFiles.push(files[i].replaceAll(" ", ""));
      }
    }
    this.props.onChange({
      target: {
        name: this.props.name,
        value: vFiles.toString(),
        type: "file",
        defaultvalue: this.props.rest?.defaultvalue,
      },
    });
  };

  render() {
    return (
      <div className="control-pane" ref={this.dropContainerRef}>
        <div className="control-section uploadpreview">
          <div className="upload_wrapper">
            <span className="field-form-label">{this.props.label}</span>
            <UploaderComponent
              id="file"
              type="file"
              ref={(scope) => {
                this.uploadObj = scope;
              }}
              asyncSettings={this.state.asyncSettings}
              selected={this.onFileSelected.bind(this)}
              success={(this.onUploadSuccess = this.onUploadSuccess.bind(this))}
              minFileSize={this.state.minFileSize}
              maxFileSize={this.state.maxFileSize}
              autoUpload={true}
              removing={this.onRemoveFile.bind(this)}
              allowedExtensions={this.state.allowedExtensions}
            >
              <FilesDirective>
                {this.props.value?.split(",").map((b, index) => {
                  if (b.length > 0) {
                    return (
                      <UploadedFilesDirective
                        name={b.slice(0, b.lastIndexOf("."))}
                        size={100}
                        type={b.substring(b.lastIndexOf(".") + 1)}
                      ></UploadedFilesDirective>
                    );
                  }
                })}
              </FilesDirective>
            </UploaderComponent>
          </div>
        </div>
      </div>
    );
  }
}

export default CompFormFileManager;
