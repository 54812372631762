import actionTypes from "../action.types";
import * as LoginService from "../../services/user.service";

export const activateForm = (data) => {
	 
	return {
    type: actionTypes.ACTIVE_FORM,
    payload:data
  } 
};

export const login = (data) => {
  return (dispatch) => {  
    dispatch({ type: actionTypes.LOGIN_REQUEST });
    LoginService.Login(dispatch, data);
  };
};


export const logout = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOGOUT });
  };
};

export const CreateUser = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST });
    LoginService.CreateUser(dispatch, data);
  };
};

export const RecoveryPass= (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST });
    LoginService.RecoveryPass(dispatch, data);
  };
};

export const updatePassword = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.PROCESS_REQUEST, payload: data });
    LoginService.updatePassword(dispatch, data);
  };
};

export const loginSuccess = (data) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: data,
  };
};

export const loginFailed = (data) => {
  return {
    type: actionTypes.LOGIN_FAILED,
    payload: data,
  };
};

export const getResumeTasksByUser = (data) => {
  return (dispatch) => {  
    dispatch({ type: actionTypes.RESUME_TASKS_REQUEST });
    LoginService.getResumeTasksByUser(dispatch, data);
  };
};

export const getSimpleResumeTasksByUser = (data) => {
  return (dispatch) => {  
    dispatch({ type: actionTypes.RESUME_TASKS_S_REQUEST });
    LoginService.getSimpleResumeTasksByUser(dispatch, data);
  };
};

export const getTasksSuccess = (data) => {
  return {
    type: actionTypes.RESUME_TASKS_LOADED,
    payload: data,
  };
};

export const getTasksFailed = (data) => {
  return {
    type: actionTypes.RESUME_TASKS_FAILED,
    payload: data,
  };
};

export const recoverySuccess = (data) => {
  return {
    type: actionTypes.RECOVERY_SUCCESS,
    payload: data,
  };
};

export const recoveryFailed = (data) => {
  return {
    type: actionTypes.RECOVERY_FAILED,
    payload: data,
  };
};

