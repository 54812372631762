import actionTypes from "../action.types";
import {
  getUserRol,
  getCookie,
  setCookie,
  createValidationSchema,
  clearformData,
  mergeFormData,
} from "../../utils/Functions";

var defLang = process.env.REACT_APP_DEF_LANGUAGE;
const initialState = {
  processSuccess: false,
  processFailed: false,
  processMessage: "",
  processError: "",
  proccessname: "initial",

  fetchSuccess: false,
  fetchFailed: false,
  fetchError: "",
  rows: [],
  tasksheader: [],
  tasksFailed: true,
  tasksSuccess: false,
  tasksError: "",

  historyFailed: true,
  historySuccess: false,
  historyError: "",
  historyHeader: [],
  historyTasks: [],
  historyFlows: [],

  createdId: 0,
  requesting: false,
  requesting2: false,
  formFields: [],
  refreshResumeTasks: false,
  formConfig: { initial: true },
  initialValues: {},
  validationSchema: {},
  componentConfig: { initial: true },
  customPageConfig: { initial: true },
  reloadPage: false,
  defLang: defLang ? defLang : process.env.REACT_APP_DEF_LANGUAGE,
  deShortLng: (defLang
    ? defLang
    : process.env.REACT_APP_DEF_LANGUAGE
  ).substring(0, 2),
};

const ProcessReducer = (state = initialState, action) => {
  const { type, payload } = action;
  //console.log("type PROC", type, payload)
  switch (type) {
    case actionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        processMessage: "",
        processError: "",
      };
    case actionTypes.PROCESS_REQUEST:
      let activeToken = localStorage.getItem(
        process.env.REACT_APP_NAME + "_session"
      );

      var reloadPage = false;
      if (!activeToken) {
        reloadPage = true;
        setCookie(process.env.REACT_APP_NAME + "_usertoken", null, 0.00001);
        setCookie(process.env.REACT_APP_NAME + "_username", null, 0.00001);
      } else {
        let sessionDate = new Date(activeToken);
        var currentDate = new Date();
        var numberOfMlSeconds = sessionDate.getTime();
        var addMlSeconds =
          60 *
          60000 *
          (process.env.REACT_APP_SESSION_DURATION
            ? process.env.REACT_APP_SESSION_DURATION
            : 2);
        //addMlSeconds = 1*60*1000;
        var newDate = new Date(numberOfMlSeconds + addMlSeconds);
        //console.log("ok",sessionDate)
        //console.log("maximo", newDate)
        //console.log("currentDate", currentDate)
        if (currentDate > newDate) {
          reloadPage = true;
          setCookie(process.env.REACT_APP_NAME + "_usertoken", null, 0.00001);
          setCookie(process.env.REACT_APP_NAME + "_username", null, 0.00001);
        } else {
          //console.log("REFRESH")
          localStorage.setItem(
            process.env.REACT_APP_NAME + "_session",
            new Date()
          );
        }
      }

      let componentConfig = state.componentConfig;
      if (payload.proccessname == "getComponentConfig") {
        componentConfig["requesting"] = true;
      }

      let customPageConfig = state.customPageConfig;
      if (payload.proccessname == "getCustomPageConfig") {
        //console.log("REQUESTING!!!")
        customPageConfig["requesting"] = true;
        customPageConfig["initial"] = false;
        
      }

      let formFieldsNew = state.formFields;
      if (payload.proccessname == "getFormData") {
        let formFieldsNew = clearformData(state.formFields);
      }

      if (payload.proccessname == "getTasks") {
        return {
          ...state,
          proccessname: payload.proccessname,
          requesting: true,
          createdId: 0,
          reloadPage: reloadPage,
          processSuccess: false,
          processFailed: false,
          processMessage: "",
          activetasks: [],
          processError: "",
          componentConfig: componentConfig,
          customPageConfig: customPageConfig,
          formFields: formFieldsNew,
          refreshResumeTasks: false,
        };
      } else {
        //console.log("set state",customPageConfig)
        return {
          ...state,
          proccessname: payload.proccessname,
          requesting: true,
          createdId: 0,
          reloadPage: reloadPage,
          processSuccess: false,
          processFailed: false,
          processMessage: "",
          processError: "",
          componentConfig: componentConfig,
          customPageConfig: customPageConfig,
          formFields: formFieldsNew,
          refreshResumeTasks: false,
        };
      }

    case actionTypes.PROCESS_RESET:
      return {
        ...state,
        processSuccess: false,
        processFailed: false,
        processMessage: "",
      };

    case actionTypes.PROCESS_SUCCESS:
      //console.log("succ",payload	)
      let _formFields = state.formFields;
      if (payload.data) {
        _formFields = mergeFormData(state.formFields, payload.data);
      }

      if (payload.componentConfig) {
        return {
          ...state,
          requesting: false,
          processSuccess: true,
          formFields: _formFields,
          processFailed: false,
          processMessage:
            payload["message_" + state.deShortLng] || payload["message"],
          componentConfig: payload.componentConfig,
          createdId: payload.createdId,
        };
      } else if (payload.refreshResumeTasks) {
        return {
          ...state,
          requesting: false,
          processSuccess: true,
          processFailed: false,
          processMessage:
            payload["message_" + state.deShortLng] || payload["message"],
          createdId: payload.createdId,
          refreshResumeTasks: true,
          activetasks: undefined,
        };
      } else {
        return {
          ...state,
          requesting: false,
          processSuccess: true,
          processFailed: false,
          processMessage:
            payload["message_" + state.deShortLng] || payload["message"],
          createdId: payload.createdId,
        };
      }

    case actionTypes.PROCESS_FAILED:
      return {
        ...state,
        requesting: false,
        processFailed: true,
        processSuccess: false,
        processError:
          payload["message_" + state.deShortLng] || payload["message"],
      };

    case actionTypes.PROCESS_MESSAGE_SUCC:
      console.log(payload);
      return {
        ...state,
        requesting: false,
        processFailed: false,
        processSuccess: true,
        processMessage:
          payload["message_" + state.deShortLng] || payload["message"],
      };

    case actionTypes.PROCESS_ERROR:
      return {
        ...state,
        requesting: false,
        processFailed: true,
        processSuccess: false,
        processError: payload.error ? payload.error : payload.message,
      };
    case actionTypes.FORM_LOADED:
      let validationSchemaF = createValidationSchema(payload);
      let initialValuesF = {};
      payload.map(function (key, item) {
        initialValuesF[key.name] = key.value;
      });
      return {
        ...state,
        requesting: false,
        formFields: payload,
        initialValues: initialValuesF,
        validationSchema: validationSchemaF,
        processSuccess: false,
      };
    case actionTypes.FORM_ERROR:
      return {
        ...state,
        requesting: false,
        formFields: [],
        initialValues: {},
        validationSchema: {},
        processSuccess: false,
      };
    case actionTypes.CLEAR_FORM:
      let formFields = clearformData(state.formFields);

      //console.log("clear",payload)
      if (payload) {
        formFields = mergeFormData(formFields, payload);
        return {
          ...state,
          requesting: false,
          initialValues: payload,
          processSuccess: false,
          formFields: formFields,
        };
      } else {
        return {
          ...state,
          requesting: false,
          processSuccess: false,
          formFields: formFields,
        };
      }

    case actionTypes.COMPONENT_LOADED:
      payload["requesting"] = false;

      let validationSchema = createValidationSchema(payload.formFields);
      let initialValues = {};
      payload.formFields.map(function (key, item) {
        initialValues[key.name] = key.value;
      });
      //console.log("COMPONENT_LOADED",payload	)
      return {
        ...state,
        requesting: false,
        formFields: [],
        initialValues: {},
        validationSchema: {},
        componentConfig: payload.componentConfig,
        formFields: payload.formFields,
        initialValues: initialValues,
        validationSchema: validationSchema,
        processSuccess: false,
      };
    case actionTypes.COMPONENT_ERROR:
      return {
        ...state,
        requesting: false,
        formFields: [],
        initialValues: {},
        validationSchema: {},
        componentConfig: { error: true },
        processSuccess: false,
      };

    case actionTypes.CPAGE_LOADED:
      payload["requesting"] = false;
      return {
        ...state,
        requesting: false,
        customPageConfig: payload.customPageConfig,
        processSuccess: false,
      };
    case actionTypes.CPAGE_RESET:
      
      return {
        ...state,
        requesting: false,
        customPageConfig: { initial: true },
        processSuccess: false,
      };
    case actionTypes.CPAGE_ERROR:
      return {
        ...state,
        requesting: false,
        customPageConfig: { error: true },
        processSuccess: false,
      };
    case actionTypes.PROCESSING:
      return {
        ...state,
        requesting: true,
      };
    case actionTypes.PROCESSED:
      return {
        ...state,
        requesting: false,
      };

    case actionTypes.PROCESSING2:
      return {
        ...state,
        requesting2: true,
      };
    case actionTypes.PROCESSED2:
      return {
        ...state,
        requesting2: false,
      };

    case actionTypes.FETCH_REQUEST:
      var token = getCookie(FETCH.env.REACT_APP_NAME + "_usertoken");
      var username = getCookie(FETCH.env.REACT_APP_NAME + "_username");

      var reloadPage = false;
      if (!token) {
        reloadPage = true;
      }

      setCookie(
        FETCH.env.REACT_APP_NAME + "_usertoken",
        token,
        FETCH.env.REACT_APP_SESSION_DURATION
      );
      setCookie(
        FETCH.env.REACT_APP_NAME + "_username",
        username,
        FETCH.env.REACT_APP_SESSION_DURATION
      );
      return {
        ...state,
        proccessname: payload.proccessname,
        requesting: true,
        reloadPage: reloadPage,
        fetchSuccess: false,
        fetchFailed: false,
        rows: [],
      };

    case actionTypes.FETCH_RESET:
      return {
        ...state,
        fetchSuccess: false,
        fetchFailed: false,
        rows: [],
      };

    case actionTypes.FETCH_SUCCESS:
      //console.log (payload);
      return {
        ...state,
        requesting: false,
        fetchSuccess: true,
        fetchFailed: false,
        rows: payload.result,
      };

    case actionTypes.FETCH_FAILED:
      return {
        ...state,
        requesting: false,
        fetchFailed: true,
        fetchSuccess: false,
        fetchError:
          payload["message_" + state.deShortLng] || payload["message"],
        rows: [],
      };
    case actionTypes.FETCH_ERROR:
      return {
        ...state,
        requesting: false,
        fetchFailed: true,
        fetchSuccess: false,
        fetchError: payload.error ? payload.error : payload.message,
        rows: [],
      };

    case actionTypes.TASKS_LOADED:
      //console.log ("TASKS_LOADED", payload);
      return {
        ...state,
        requesting: false,
        tasksSuccess: true,
        tasksFailed: false,
        tasksheader: payload.tasksheader,
        activetasks: payload.activetasks,
        flowvars: payload.flowvars,
      };

    case actionTypes.TASKS_FAILED:
      return {
        ...state,
        requesting: false,
        tasksFailed: true,
        tasksSuccess: false,
        tasksError: payload.error ? payload.error : payload.message,
        activetasks: [],
        flowvars: [],
      };

    case actionTypes.HISTORY_LOADED:
      //console.log ("HISTORY_LOADED", payload);
      return {
        ...state,
        requesting: false,
        historySuccess: true,
        historyFailed: false,
        historyHeader: payload.header,
        historyTasks: payload.tasks,
        historyFlows: payload.flows,
      };

    case actionTypes.HISTORY_FAILED:
      return {
        ...state,
        requesting: false,
        historyFailed: true,
        historySuccess: false,
        historyError: payload.error ? payload.error : payload.message,
        historyHeader: [],
        historyTasks: [],
        historyFlows: [],
      };

    default:
      return {
        ...state,
        processError: false,
        processSuccess: false,
        processMessage: null,
        processError: null,
      };
      return state;
  }
};

export default ProcessReducer;
