import * as React from 'react';
import Typography from "../customdesign/Typography";
import { useNavigate, useLocation } from "react-router-dom";
const Congratulations = (props) => {
	const navigate = useNavigate();
	  
	return (
		<div className="row flex-center" style={{minHeight:window.innerHeight-313}}>
			<div className=" col-xs-12 col-sm-12  col-md-10 col-lg-6 col-xl-5 flex-center ">
				<div className={"banner-finish-wrapper wow fadeIn animated hover-up"}>
					<div className="banner-congratulation text-center">
						{/*<img className="logo-mini" src="/images/logo_mini.png"/> */}
						<div className="congratulation-header">
							<span className="e-icons e-circle-check" style={{color:"#fff", fontSize:"7rem"}}> </span>
						</div>
						<Typography variant={"h2"} style={{color:"#fff"}}>
							Connector configuration finished!
						</Typography>						
												
					</div>
					<div className="banner-finish">
						<Typography variant={"h4"} mt={2} mb={2} style={{textAlign: "center" }}>
							Your connector has been Successfully saved.
						</Typography>
						<div className="text-center mt-50">
							<button onClick={()=> { navigate("/manage/connectors") }} style={{minWidth:120}} className="e-btn e-primary">
								Return to my connectors
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Congratulations.propTypes = {};

export default Congratulations;

