export default {
  login: "/auth/login",
  getDataByCodeLanguage: "/params/getDataByCodeLanguage",

  getDataByDomainLanguage: "/params/getDataByDomainLanguage",
  getInitialConf: "/auth/getInitialConf",
  getFiles: "/files/getfilesS3",
  createFileFolder: "/files/create-folderS3",
  uploadFile: "/files/uploadS3",
  deleteFile: "/files/deleteS3",
  getResumeTasksByUser: "users/get-resume-tasks",
  getActiveTasksByUser: "engine-users/get-active-tasks",
  executeTask: "engine-users/execute-task",
  getHistoryFlows: "/engine-users/get-history-flows",
  getHistoryJobs: "/engine-users/get-history-jobs",
  getHistoryFlow: "/engine-users/get-history-flow",
  changeTasks: "/engine-users/change-tasks",
  saveCommentary: "/engine-users/save-commentary",

  RecoveryPass: "/public/users/recover-password",
  getTemplateForm: "/forms/get-single-by-code",
  updateDiagramFlow: "/flows/update-diagram",

  getFlows: "/flows/get-all",
  getFlowById: "/flows/get-single",
  getActors: "/actors/get-all",
  getTasks: "/tasks/get-all",
  getFlowVars: "/flows/get-flow-vars",
  initFlow: "/flows/init-flow",

  getOrganizations: "/organizations/get-all",
  setOrganizations: "/organizations/create",
  updateOrganizations: "/organizations/update",
  deleteOrganizations: "/organizations/delete",
  updateParentOrganizations: "/organizations/update-parent",
  getActors: "/actors/get-all",
  getActorsAndOrgs: "/actors/getActorsAndOrgs",
  setActors: "/actors/create",
  updateActors: "/actors/update",
  deleteActors: "/actors/delete",
  updateParentActors: "/actors/update-parent",

  saveTemplate: "/components/create",
  getComponentById: "/components/get-single",
  getPrivateTemplates: "/components/get-templates",

  getComponentReport: "/components/get-component-report",

  initSocket: "/engine-users/init-listener",
  updatePassword: "/users/update-password",

  getRoles: "/usergroups/get-all",
  saveFlowField: "/engine-users/save-flow-field",
  testApi: "/engine-users/test-api",

  bulkData: "/files/bulk-data",
  getBulkData: "/files/get-bulk-data",
};
